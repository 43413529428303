import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Segment,
  Header,
  Form,
  Icon,
  DropdownProps,
  Table,
} from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import CreateEditSubSector from "./CreateEditSubSector";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { ISubSectorFormValues } from "../../../../app/models/subSector";
import DisableSubSector from "./DisableSubSector";

const SubSectorList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { openModal } = rootStore.modalStore;

  const {
    getSubSectorFromId,
    subSectorMasterDropDownValues,
    getSubSector,
    subSectorList,
    loadingSubSectors,
  } = rootStore.subSectorStore;
  const [selectedSubSectorId, setSelectedSubSectorId] = useState<any>(-1);

  useEffect(() => {
    getSubSector();
  }, [getSubSector]);

  return (
    <FinalForm
      onSubmit={() => {}}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <br />
          <br />
          <Segment style={{ marginLeft: "75px" }}>
            <Segment color="violet">
              <Header as="h4" icon textAlign="center">
                <Header.Content>SUB-SECTOR</Header.Content>
              </Header>
            </Segment>
            <Form>
              <Button
                color="blue"
                content="Create Sub-Sector"
                floated="right"
                onClick={() => {
                  openRightPanel("Create Sub-Sector", <CreateEditSubSector />);
                }}
              />

              <Dropdown
                search
                selection
                options={subSectorMasterDropDownValues}
                loading={loadingSubSectors}
                placeholder="Select Sub-Sector"
                onChange={(event, data: DropdownProps) => {
                  if (data.value) setSelectedSubSectorId(data.value);
                }}
              ></Dropdown>

              <br />
              <br />

              <Button.Group attached="bottom">
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    openRightPanel(
                      "Edit Sub-Sector",
                      <CreateEditSubSector
                        editSubSector={getSubSectorFromId(selectedSubSectorId)}
                      />
                    );
                  }}
                >
                  <Icon name="edit" />
                  View & Edit
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    if (selectedSubSectorId === "") {
                      toast.error("Please select from dropdown");
                    } else {
                      let subSector: ISubSectorFormValues = getSubSectorFromId(
                        selectedSubSectorId
                      );

                      openModal(
                        `${
                          subSector.isActive ? "Disable" : "Enable"
                        } SubSector`,
                        <DisableSubSector disableEnableSubSector={subSector} />
                      );
                    }
                  }}
                >
                  <Icon name="trash" />
                  Disable & Enable{" "}
                </Button>
              </Button.Group>
            </Form>
            <div className="table-scroll">
              <Table collapsing color="violet">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>Sector</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Sub-Sector</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {subSectorList &&
                    subSectorList.map((subSector) => (
                      <Table.Row key={subSector.subSectorId}>
                        <Table.Cell>{subSector.sectorName}</Table.Cell>
                        <Table.Cell>{subSector.subSectorName}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </Segment>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(SubSectorList);
