import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IContactCount, IContactValues } from '../../app/models/contact';



export default class ContactStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable contactNew: IContactValues[] = [];
  @observable loadingContact = false;
  @observable selectedContactDetail: IContactValues | undefined = undefined;
  @observable contactList: IContactValues[] = [];
  @observable contactCount: IContactCount | undefined;


  @action createContact = async (user: IContactValues) => {
    let dataMap: Map<string, string> = new Map();
    dataMap.set('FirstName', user.firstName);
    dataMap.set('LastName', user.lastName);
    dataMap.set('Mobile', user.phone);
    dataMap.set('Email', user.email);
    dataMap.set('Message', user.message);

    const newUser = await agent.ContactUser.create(user);
    console.log(newUser)

    runInAction(() => {
      this.contactNew = [...this.contactNew, newUser];
      console.log(this.contactNew);

      // this.updateDropdownValues();
      if (user.sender === "Admin") {
        toast.info("Message sent");
      }
      else {
        toast.info("Thank you for contacting us, our Team will reach you shortly ");
      }
      this.rootStore.modalStore.closeModal();
    });
  };

  @action readContact = async (key : string) => {
    const readcontact = await agent.ContactUser.read(key);
    runInAction(() => {
      console.log(readcontact);
      });

  };

  @action fedEndoresedContact = async (user: IContactValues) => {
    let dataMap: Map<string, string> = new Map();
    dataMap.set('FirstName', user.firstName);
    dataMap.set('LastName', user.lastName);
    dataMap.set('Mobile', user.phone);
    dataMap.set('Email', user.email);
    dataMap.set('Message', user.message);

    const newUser = await agent.ContactUser.create(user);
    console.log(newUser)

    runInAction(() => {
      this.contactNew = [...this.contactNew, newUser];
      console.log(this.contactNew);
      this.rootStore.modalStore.closeModal();
    });
  };

  @action getContactList = async (key: string) => {
    try {
      this.loadingContact = true;
      const responseList: IContactValues[] = await agent.ContactUser.list(key);
      console.log(responseList);
      runInAction(() => {
        this.contactList = responseList;
        this.loadingContact = false;
      });

    } catch (error) {
      throw error;
    } 
  }

  @action getContactCount = async (key: string) => {
    try {
      this.loadingContact = true;
      const responseCount: IContactCount = await agent.ContactUser.count(key);
      console.log(responseCount);
      runInAction(() => {
        this.contactCount = responseCount;
      });

    } catch (error) {
      throw error;
    } finally {

      runInAction(
        () => {
          this.loadingContact = false;
        }
      )

    }
  }

  

}
