import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Header, Grid, Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../../stores/rootStore';
import PhotoWidgetDropzone from './PhotoWidget';


interface IProps {
  loading: boolean;
  optionType: string;


  uploadDocument: (fileName: string) => void;

}

const DocumentUploadWidget: React.FC<IProps> = ({ loading, optionType, uploadDocument }) => {
  const [files, setFiles] = useState<any[]>([]);

  const rootStore = useContext(RootStoreContext);
  
  const handleDropdownChange = (
    event: any, data: DropdownProps
  ) => {

   
  };


  useEffect(() => {
   
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  },[]);

  return (
    <Fragment>
      <Grid>

        
        <Grid.Column width={4}>
        <Button style={{ marginLeft: '19px', backgroundColor: '#2185d0', color: 'white' }} >Add the Document</Button>
          
          <PhotoWidgetDropzone setFiles={setFiles} />
        </Grid.Column>
        <Grid.Column width={1} />


        <Grid.Column width={4}>
          <Button style={{ marginLeft: '19px', backgroundColor: '#2185d0', color: 'white' }} >Upload</Button>
          {files.length > 0 && files[0] && (



            <Fragment>

              {   files[0].type === "image/jpeg" ? <img alt={files[0].fileName}
                src={files[0].preview}
                style={{ height: '300px', width: "300px" }}
              /> :
                <iframe className='previewFile' src={files[0].preview} title={files[0].fileName} />
              }

              <br></br>
              <br></br>

              <Button.Group widths={2}>
                <Button
                  positive
                  icon='check'
                  loading={loading}
                  onClick={() => uploadDocument(files[0])}
                />
                <Button
                  icon='close'
                  disabled={loading}
                  onClick={() => setFiles([])}
                />
              </Button.Group>


            </Fragment>
          )}
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default observer(DocumentUploadWidget);