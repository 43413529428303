import React, { useContext, useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { LoadingComponent } from '../../../../app/layout/LoadingComponent';
import { history } from '../../../..';
import { PendingOrApprove } from '../../../../app/models/dashboard';

function RegistrationPending() {
    const rootStore = useContext(RootStoreContext);
    const { registrationDetail, loadingRegistrationDetail, getPendingRegistration } = rootStore.dashboardStore;
    const { getFederationInfoById } = rootStore.federationStore;
    const { getDonorById } = rootStore.donorOrgStore;
    const { getDonorIndividualById } = rootStore.donorIndividualStore;
    const { getOdPartnerById } = rootStore.partnerStore;

    useEffect(() => {
        let pendingReq = new PendingOrApprove();
        pendingReq.isPending = true;
        getPendingRegistration(pendingReq);
    }, [getPendingRegistration]);
    return (
        <div>
            <div>
                <h3 style={{ textAlign: 'center' }}> List of Partner Registration - Pending </h3>
            </div>
            <br />
            {loadingRegistrationDetail && (!registrationDetail || registrationDetail?.length === 0) &&
                <LoadingComponent content='Loading Registration' />}
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Oragnisation Id</Table.HeaderCell>
                        <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                        <Table.HeaderCell>Organization Type</Table.HeaderCell>
                        <Table.HeaderCell>View</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {registrationDetail && registrationDetail.map(
                        (registration) => (
                            <Table.Row key={registration.organisationKey}>
                                <Table.Cell>{registration.organisationKey}</Table.Cell>
                                <Table.Cell>{registration.organisationName}</Table.Cell>
                                <Table.Cell>{registration.organisationType}</Table.Cell>
                                <Table.Cell>
                                    <Button circular color='twitter' icon="angle double right"
                                        onClick={() => {
                                            if (registration.organisationKey.startsWith("ODF")) {
                                                getFederationInfoById(registration.organisationId)
                                                history.push('/dod-fedRegApproval/')
                                            }
                                            if (registration.organisationKey.startsWith("ODP")) {
                                                getOdPartnerById(registration.organisationId)
                                                history.push('/dod-odRegApproval')
                                            }
                                            if (registration.organisationKey.startsWith("ODDI")) {
                                                getDonorIndividualById(registration.organisationId)
                                                history.push('/dod-donorIndRegApproval')
                                            }
                                            if (registration.organisationKey.startsWith("ODDO")) {
                                                getDonorById(registration.organisationId)
                                                history.push('/dod-donorOrgRegApproval')
                                            }
                                        }}
                                    >
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    )}
                </Table.Body>
            </Table>
        </div>



    );
}

export default observer(RegistrationPending);