import { IApproval } from "./../models/approval";
import { IProfile } from "./../models/profile";
import {
  IQuestionExport,
  IQuestionSetupDisableFormValues,
  QuestionSetupFormValues,
} from "./../models/questionSetup";
import axios, { AxiosResponse } from "axios";
import {
  IUserProfile,
  IUserFormValues,
  ILoginFormValues,
  IChangePasswordFormValues,
  IVerifyOtpFormValues,
} from "../models/user";
import { toast } from "react-toastify";
import { history } from "../..";
import { IUsers } from "../models/userlist";
import { IDepartment } from "../models/department";
import { IBeneficiaryType } from "../models/beneficiaryType";
// import { IOdPartnerRegisterValues } from '../models/partner';
// import { IFederationRegisterValues } from '../models/federation';
import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
  OTPRegistration,
} from "../models/registrationOTP";
import {
  ISectorDisable,
  ISectorFormValues,
  SectorFormValues,
} from "../models/sector";
import {
  AreaOfAssessmentValues,
  IAreaAllocation,
  IAreaOfAssessmentValues,
} from "../models/areaOfAssessment";
import { ISubSectorFormValues, SubSectorFormValues } from "../models/subSector";
import { IQuestionAnswerValues } from "../models/questionSetup";
import { IPlanFormValues, PlanFormValues, IPlanDisable } from "../models/plan";
import {
  IManageUser,
  ManageUserValues,
  IManageUserDisable,
} from "../models/manageUser";
import {
  IAcceptPartnerEndorse,
  IFederationApproveOrReject,
  IFederationName,
  IFederationRegister,
  IPartnerEndorse,
  IFederationRegisterValues,
} from "../models/federation";
import {
  IOrganisation,
  IPendingOrApprove,
  IPincode,
  IStatewise,
  IYearAreawise,
  IYearSectorwise,
  IYearwise,
  Registration,
} from "../models/dashboard";
import {
  IDonorIndApproveOrReject,
  IDonorIndividualRegister,
  IDonorIndividualRegisterValues,
} from "../models/donorIndividual";
import {
  IOdPartnerApproveOrReject,
  IOdPartnerRegister,
  IOdPartnerRegisterValues,
} from "../models/partner";
import {
  IApprovedParterProfile,
  IDonorOrgApproveOrReject,
  IDonorOrgRegister,
  IDonorOrgRegisterValues,
} from "../models/donorOrg";
import {
  IAssessment,
  IAssessmentApproveOrReject,
  IAssessmentAreaVerify,
  IAssessmentByArea,
  IAssessmentByAreaVerify,
  IAssessmentList,
  IAssessmentSuggesstion,
  ISuggestion,
} from "../models/assessment";
//import { IFederationEndorsement, IPartnerDashboard } from '../models/partnerDashboard';
import { IActionPoints } from "../models/assessment";
import { IContactCount, IContactValues } from "../models/contact";
import {
  IAcceptedFederation,
  IActionPointDashboard,
  ICheckPreviousAssessments,
  ICommiteeMembers,
  IFederationEndorsement,
  IMonthwise,
  IODArea,
  IOrganisationLogo,
  IPartnerDashboard,
  IPoint,
  IPointPercentage,
  IPreviousAssessmentCount,
  IUpdateActionPoints,
} from "../models/partnerDashboard";
import { IState } from "../models/state";
import { IAcceptRejectPartner } from "../models/donorOrg";
import { IFederationDashboard } from "../models/federationDashboard";
import { IDonorDashboard, IPartnerDetail } from "../models/donorIndDashboard";
import { IDonorOrgDashboard, IPartnerName } from "../models/donorOrgDashboard";
import {
  IRegistrationDone,
  RegistrationDone,
} from "../models/registrationDone";
import {
  AssessmentSubmitDone,
  IAssessmentDone,
} from "../models/assessmentDone";
import {
  IRegistrationAdmin,
  RegistrationAdmin,
} from "../models/registrationAdmin";
import {
  FedRegistrationDone,
  IFedRegistrationDone,
} from "../models/fedregistrationDone";
import {
  FedRegistrationAdmin,
  IFedRegistrationAdmin,
} from "../models/fedregistrationAdmin";

//axios.defaults.baseURL = "http://localhost:5000/api";
axios.defaults.baseURL = "/api";
//axios.defaults.baseURL = "https://digitalod.azurewebsites.net/api";
axios.interceptors.request.use(
  (config) => {
    const tokenExpiry = window.localStorage.getItem("jwtExpiry");
    if (tokenExpiry) {
      let expiryDate = new Date(tokenExpiry);
      let expiryUtc = Date.UTC(
        expiryDate.getFullYear(),
        expiryDate.getMonth(),
        expiryDate.getDay(),
        expiryDate.getHours(),
        expiryDate.getMinutes(),
        expiryDate.getSeconds(),
        expiryDate.getMilliseconds()
      );
      let now = Date.now();
      if (expiryUtc < now) {
        console.log("token active");
      } else {
        console.log("token inactive");
      }
    }

    const token = window.localStorage.getItem("jwt");
    if (token)
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, async (error) => {
  const originalRequest = error.config;

  if (error.message === "Network Error" && !error.response) {
    //        toast.error('Network error - make sure backend service is running!');
  } else if (error.message === "Connection refused" && !error.response) {
    toast.error("Backend server not available at this time!");
  } else {
    const { status, data, config } = error.response;
    window.localStorage.setItem("status", status);
    if (status === 404) {
      history.push("/notfound");
    }
    console.log(originalRequest.url);
    if (
      (status === 401 || status === 500) &&
      originalRequest.url.endsWith("refresh")
    ) {
      console.log("refresh failed");

      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("userName");
      history.push("/");
      toast.info("Your session has expired, please login again");
      return Promise.reject(error);
    }
    if (
      status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.endsWith("login") &&
      !originalRequest.url.endsWith("logout") &&
      window.localStorage.getItem("jwt") != null
    ) {
      console.log("sent refresh token");
      originalRequest._retry = true;
      return axios
        .post("auth/refresh", {
          token: window.localStorage.getItem("jwt"),
          refreshToken: window.localStorage.getItem("refreshToken"),
          userName: window.localStorage.getItem("userName"),
        })
        .then((rsp) => {
          window.localStorage.setItem("jwt", rsp.data.token);

          window.localStorage.setItem("jwtExpiry", rsp.data.tokenExpires);
          window.localStorage.setItem("refreshToken", rsp.data.refreshToken);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${rsp.data.token}`;
          return axios(originalRequest);
        });
    }
    if (
      status === 400 &&
      config.method === "get" &&
      data.errors.hasOwnProperty("id")
    ) {
      history.push("/notfound");
    }
  }
  console.log(error.response);
  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;
const emptyBody = {
  data: {},
};

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  disable: (url: string) => axios.patch(url, emptyBody).then(responseBody),
  patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
  postForm: (
    url: string,
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => {
    let formData = new FormData();
    data.forEach((value: string, key: string) => {
      formData.append(key, value);
    });

    fileData.forEach((value: string, key: string) => {
      formData.append(key, value);
    });

    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
  putForm: (
    url: string,
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => {
    let formData = new FormData();
    data.forEach((value: string, key: string) => {
      formData.append(key, value);
    });

    fileData.forEach((value: string, key: string) => {
      formData.append(key, value);
    });

    return axios
      .put(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
  postMultiForm: (
    url: string,
    data: Map<string, string>,
    fileData: Map<string, Array<string>>
  ) => {
    let formData = new FormData();
    data.forEach((value: string, key: string) => {
      formData.append(key, value);
    });

    fileData.forEach((value: Array<string>, key: string) => {
      value.forEach((x) => {
        formData.append(key, x);
      });
    });

    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
  /* postForm: (url: string, data: Map<string, string>) => {
    let formData = new FormData();
    console.log(formData);
    data.forEach((value: string, key: string) => {
      formData.append(key, value);
    });
gghim8gk----,l./
    //formData.append("PhotoPath", OrganizationInfoOrBrouchefile);

    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
  post-Form1: (url: string, data: Map<string, string>, file: FileList | undefined) => {
    let formData = new FormData();
    data.forEach((value: string, key: string) => {
      formData.append(key, value);
    });
if(file){
    formData.append("OrganizationInfoOrBrouchefile", file[0]);
    // formData.append("OrganizationInfoOrBrouchefile", file[0]);
    // formData.append("OrganizationInfoOrBrouchefile", file[0]);
    // formData.append("OrganizationInfoOrBrouchefile", file[0]);
  }
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
 */
};

const Auth = {
  login: (user: ILoginFormValues): Promise<IUserProfile> =>
    requests.post("/auth/login", user),
  profile: (): Promise<IUserProfile> => requests.get("/auth/profile"),
  logout: () => requests.get("/auth/logout"),
  changePassword: (user: IChangePasswordFormValues): Promise<IUserProfile> =>
    requests.post("/auth/changepassword", user),
  forgotPassword: (mobileNumber: string): Promise<IUserProfile> =>
    requests.get(`auth/forgotPasswordSendOtp/${mobileNumber}`),
  verifyOtp: (user: IVerifyOtpFormValues): Promise<IUserProfile> =>
    requests.post("auth/resetPassword", user),
};

const Task = {
  list: () => requests.get("/task"),
};

const BeneficiaryType = {
  list: (): Promise<IBeneficiaryType[]> => requests.get("/beneficiaryType"),
  view: (id: string) => requests.get(`/beneficiaryType/${id}`),
  create: (beneficiaryType: IBeneficiaryType) =>
    requests.post(`/beneficiaryType`, beneficiaryType),
  update: (beneficiaryType: IBeneficiaryType) =>
    requests.put(
      `/beneficiaryType/${beneficiaryType.beneficiaryTypeId}`,
      beneficiaryType
    ),
  disable: (beneficiaryTypeId: string) =>
    requests.disable(`/beneficiaryType/${beneficiaryTypeId}`),
};

const OTPVerification = {
  create: (
    registrationSendOTP: IRegistrationSendOTP
  ): Promise<OTPRegistration> =>
    requests.post("/OTPVerify", registrationSendOTP),
  update: (registrationVerifyOTP: IRegistrationVerifyOTP): Promise<string> =>
    requests.put("/OTPVerify", registrationVerifyOTP),
  getVerifyByMobileOrEmail : () => requests.get("/OTPVerify"),
};

const DoneRegister = {
  create: (registerationDone: IRegistrationDone): Promise<RegistrationDone> =>
    requests.post("/RegisterDone", registerationDone),
};

const AdminRegister = {
  create: (
    registerationAdmin: IRegistrationAdmin
  ): Promise<RegistrationAdmin> =>
    requests.post("/RegisterAdmin", registerationAdmin),
};
const FedDoneRegister = {
  create: (
    fedregisterationDone: IFedRegistrationDone
  ): Promise<FedRegistrationDone> =>
    requests.post("/FedRegisterDone", fedregisterationDone),
};
const FedRegisterAdmin = {
  create: (
    fedregisterationAdmin: IFedRegistrationAdmin
  ): Promise<FedRegistrationAdmin> =>
    requests.post("/FedRegisterAdmin", fedregisterationAdmin),
};

const DoneAssessment = {
  create: (assessmentDone: string): Promise<string> =>
    requests.post("/AssessmentDone", {
      mobileContactNumber: `${assessmentDone}`,
    }),
};
const AssesmentNotify = {
  notifyUser: (mobileno) =>
    requests.post("/AssesmentNotify", {
      mobileContactNumber: `${mobileno}`,
    }),
};
const RegistrationNotify = {
  notifyUser: (mobileno) =>
    requests.post("/RegistrationNotify", {
      mobileContactNumber: `${mobileno}`,
    }),
};
const DonorRegsmsNotify = {
  notifyUser: (mobileno: IRegistrationDone) =>
    /*requests.post("/DonorRegsmsNotify", {
      mobileContactNumber: `${mobileno}`,
    }*/
    requests.post("/DonorRegsmsNotify", mobileno),
};
const DonorIndsmsNotify = {
  notifyUser: (mobileno: IRegistrationDone) =>
    requests.post("/DonorIndsmsNotify", mobileno),
};

const ODPartner = {
  createWithFile: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/Partner", data, fileData),
  editWithFile: (
    id: number,
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => requests.putForm(`/Partner/${id}`, data, fileData),
  getById: (id: number): Promise<IOdPartnerRegister> =>
    requests.get(`/Partner/${id}`),
  approve: (
    approve: IOdPartnerApproveOrReject
  ): Promise<IOdPartnerApproveOrReject> =>
    requests.put(`/Partner/Verify/${approve.PartnerBasicDetailId}`, approve),
  sendFederationEndorsement: (federationendorse: IFederationEndorsement) =>
    requests.post("/Partner/SendForFederationEndorsement", federationendorse),
  getAllPartners: (): Promise<IOdPartnerRegister[]> => requests.get(`/Partner`),
  savePartner: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/Partner/SaveForRegistration", data, fileData),
  getSavedPartner: (
    contactNumber: IOdPartnerRegisterValues
  ): Promise<IOdPartnerRegister> =>
    requests.post("/Partner/getSavedPartnerDetails", contactNumber),
};

const Federation = {
  createWithFile: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/Federation", data, fileData),
  getById: (id: number): Promise<IFederationRegister> =>
    requests.get(`/Federation/${id}`),
  approve: (
    approve: IFederationApproveOrReject
  ): Promise<IFederationApproveOrReject> =>
    requests.put(
      `/Federation/Verify/${approve.FedarationBasicDetailId}`,
      approve
    ),
  saveandnextFederation: (
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => requests.postForm("/Federation/SaveForRegistration", data, fileData),
  getSavedAndNextFederation: (
    contactNumber: IFederationRegisterValues
  ): Promise<IFederationRegister> =>
    requests.post("/Federation/getSavedFederationDetails", contactNumber),

  getWaitingPartnerById: (
    id: number,
    status: string
  ): Promise<IPartnerEndorse[]> =>
    requests.get(`/Federation/PartnerEndrose/${id}/${status}`),
  approvePartner: (approve: IAcceptPartnerEndorse) =>
    requests.put(`/Federation/AcceptPartner/`, approve),
  list: (): Promise<IFederationName[]> => requests.get("/Federation/Name"),
};

const DonorOrg = {
  createWithFile: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/DonorOrg", data, fileData),
  getById: (id: number): Promise<IDonorOrgRegister> =>
    requests.get(`/DonorOrg/${id}`),
  approve: (
    approve: IDonorOrgApproveOrReject
  ): Promise<IDonorOrgApproveOrReject> =>
    requests.put(
      `/DonorOrg/Verify/${approve.DonorOrganisationBasicDetailId}`,
      approve
    ),
  sendApproval: (send: IAcceptRejectPartner): Promise<IAcceptRejectPartner> =>
    requests.post("/DonorOrg/SendForPartnerApproval", send),
  getPartnerProfile: (donorId: number): Promise<IApprovedParterProfile[]> =>
    requests.get(`/DonorOrg/PartnerApprovalInfo/${donorId}`),
  saveandnextDonorOrg: (
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => requests.postForm("/DonorOrg/SaveDonorOrgDetails", data, fileData),
};

const DonorIndividual = {
  createWithFile: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/DonorIndividual", data, fileData),
  //create: (data: Map<string, string>) =>
  //requests.postForm("/DonorIndividual", data),
  getById: (id: number): Promise<IDonorIndividualRegister> =>
    requests.get(`/DonorIndividual/${id}`),
  approve: (
    approve: IDonorIndApproveOrReject
  ): Promise<IDonorIndApproveOrReject> =>
    requests.put(
      `/DonorIndividual/Verify/${approve.DonorIndividualBasicDetailId}`,
      approve
    ),
  sendApproval: (send: IAcceptRejectPartner): Promise<IAcceptRejectPartner> =>
    requests.post("/DonorIndividual/SendForPartnerApproval", send),
  save: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/DonorIndividual/SaveForRegistration", data, fileData),
  getSave: (
    contactNumber: IDonorIndividualRegisterValues
  ): Promise<IDonorIndividualRegister> =>
    requests.post(
      "/DonorIndividual/getSavedDonorIndividualDetails",
      contactNumber
    ),
};

const Sector = {
  create: (sectorList: ISectorFormValues): Promise<string> =>
    requests.post("/Sector", sectorList),
  list: (): Promise<SectorFormValues[]> => requests.get("/Sector"),
  listactive: (): Promise<SectorFormValues[]> => requests.get("/Sector/Active"),
  update: (sectorList: ISectorFormValues): Promise<string> =>
    requests.put(`/Sector/${sectorList.sectorId}`, sectorList),
  disable: (sectorList: ISectorDisable) =>
    requests.patch(`/Sector/${sectorList.sectorId}`, sectorList),
};

const AreaOfAssessment = {
  create: (areaList: IAreaOfAssessmentValues): Promise<string> =>
    requests.post("/Area", areaList),
  list: (): Promise<AreaOfAssessmentValues[]> => requests.get("/Area"),
  listactive: (): Promise<AreaOfAssessmentValues[]> =>
    requests.get("/Area/Active"),
  update: (areaList: IAreaOfAssessmentValues): Promise<string> =>
    requests.put(`/Area/${areaList.areaOfAssessmentId}`, areaList),
  disable: (areaList: AreaOfAssessmentValues) =>
    requests.patch(`/Area/${areaList.areaOfAssessmentId}`, areaList),
};

const AreaAllocation = {
  create: (areaList: IAreaAllocation): Promise<string> =>
    requests.post("/AreaAllocation", areaList),
  list: (): Promise<IAreaAllocation[]> => requests.get("/AreaAllocation"),
  listById: (
    sectorId?: number,
    subSectorId?: number
  ): Promise<IAreaAllocation[]> =>
    requests.get(`AreaAllocation/${sectorId}/${subSectorId}`),
  listByPartnerId: (partnerId?: number): Promise<IAreaAllocation[]> =>
    requests.get(`AreaAllocation/${partnerId}`),
};
const Assessment = {
  submit: (Assessments: IAssessmentList): Promise<IAssessmentList> =>
    requests.post(`/Assessment`, Assessments),
  save: (Assessments: IAssessmentList): Promise<IAssessmentList> =>
    requests.post(`/Assessment/save`, Assessments),
  // getById: (assessmentId: number): Promise<IAssessment> => requests.get(`/Assessment/GetByAssessment/${assessmentId}`),
  approve: (
    approve: IAssessmentApproveOrReject
  ): Promise<IAssessmentApproveOrReject> =>
    requests.put(`/Assessment/AssessmentVerify`, approve),
  createFile: (
    data: Map<string, string>,
    fileData: Map<string, Array<string>>
  ): Promise<IAssessmentList[]> =>
    requests.postMultiForm(`/Assessment/createDraftFile`, data, fileData),
  getAssessmentByArea: (
    assessmentId: number
  ): Promise<IAssessmentByAreaVerify[]> =>
    requests.get(`/Assessment/GetByAssessmentArea/${assessmentId}`),
  getAssessmentAreaVerify: (
    assessmentId: number
  ): Promise<IAssessmentAreaVerify> =>
    requests.get(`/Assessment/GetAreaVerify/${assessmentId}`),
  saveSuggesstion: (
    save: IAssessmentSuggesstion
  ): Promise<IAssessmentSuggesstion> =>
    requests.post(`/Assessment/SaveSuggestion`, save),
  getlastAssessmentByArea: (
    partnerId: number,
    verified: boolean
  ): Promise<IAssessmentByArea[]> =>
    requests.get(`/Assessment/GetLastAssessment/${partnerId}/${verified}`),
  getFullDetailsByAssessmentId: (
    assessmentId: number
  ): Promise<IAssessmentByArea[]> =>
    requests.get(`/Assessment/GetFullDetailsByAssessmentId/${assessmentId}`),

  getActionPoints: (partnerId: number): Promise<IActionPoints[]> =>
    requests.get(`/Assessment/ActionPoints/${partnerId}`),
  getActionPointsByAssessmentId: (
    assessmentId: number
  ): Promise<IActionPoints[]> =>
    requests.get(`/Assessment/ActionPointsByAssessmentId/${assessmentId}`),
  getSuggestions: (assessmentId: number): Promise<ISuggestion[]> =>
    requests.get(`/Assessment/GetSuggestion/${assessmentId}`),
  editSuggesstion: (edit: IAssessmentSuggesstion): Promise<string> =>
    requests.put(`/Assessment/${edit.assessmentVerifyId}`, edit),
  disableSuggestion: (Sug: IAssessmentSuggesstion) =>
    requests.patch(`/Assessment/Suggestion/${Sug.assessmentVerifyId}`, Sug),
  GetByAssessment: (assessmentId: number): Promise<IAssessment[]> =>
    requests.get(`/Assessment/GetByAssessment/${assessmentId}`),

  getByAssessmentId: (assessmentId: number): Promise<IAssessment> =>
    requests.get(`/Assessment/GetByAssessment/${assessmentId}`),

  uploadDocument: (data: Map<string, string>, ReportDoc: Map<string, string>) =>
    requests.postForm("/Assessment/ReportDoc", data, ReportDoc),
};

const Plan = {
  create: (planList: IPlanFormValues) => requests.post("/Plan", planList),
  update: (planList: IPlanFormValues) =>
    requests.put(`/Plan/${planList.planId}`, planList),
  list: (): Promise<PlanFormValues[]> => requests.get("/Plan"),
  getById: (id: number): Promise<IPlanFormValues> =>
    requests.get(`/Plan/${id}`),
  disable: (DisablePlan: IPlanDisable) =>
    requests.patch(`/plan/${DisablePlan.planId}`, DisablePlan),
};

const SubSector = {
  create: (subSectorList: ISubSectorFormValues): Promise<string> =>
    requests.post("/SubSector", subSectorList),
  list: (): Promise<SubSectorFormValues[]> => requests.get("/SubSector"),
  listactive: (): Promise<SubSectorFormValues[]> =>
    requests.get("/SubSector/Active"),
  update: (subSectorList: ISubSectorFormValues): Promise<string> =>
    requests.put(`/SubSector/${subSectorList.subSectorId}`, subSectorList),
  disable: (subSectorList: ISubSectorFormValues) =>
    requests.patch(`/SubSector/${subSectorList.subSectorId}`, subSectorList),
  getBySectorId: (sectorId: number): Promise<ISubSectorFormValues[]> =>
    requests.get(`SubSector/GetBySector/${sectorId}`),
};

const QuestionSetup = {
  create: (
    questionSetupList: IQuestionAnswerValues
  ): Promise<IQuestionAnswerValues> =>
    requests.post("/QuestionSetup", questionSetupList),
  list: (partnerId?: number): Promise<QuestionSetupFormValues[]> =>
    requests.get(`/QuestionSetup/Get/${partnerId}`),
  viewByIds: (
    sectorId?: number,
    subSectorId?: number,
    areaAssesmentId?: number
  ): Promise<IQuestionAnswerValues[]> =>
    requests.get(
      `QuestionSetup/GetAllQuestion/${sectorId}/${subSectorId}/${areaAssesmentId}`
    ),
  getexport: (
    sectorId: number,
    subsectorid: number,
    areaid: number
  ): Promise<IQuestionExport[]> =>
    requests.get(`QuestionSetup/GetExcel/${sectorId}/${subsectorid}/${areaid}`),
  disable: (questionSetup: IQuestionSetupDisableFormValues): Promise<String> =>
    requests.patch(`/QuestionSetup/${questionSetup.questionId}`, questionSetup),
  //edit: (questionSetup: QuestionSetupFormValues): Promise<IQuestionAnswerValues> => requests.put(`/QuestionSetup/${questionSetup.questionId}`, questionSetup),
  edit: (
    questionSetup: QuestionSetupFormValues
  ): Promise<IQuestionAnswerValues> =>
    requests.put("/QuestionSetup", questionSetup),

  viewById: (questionId?: number): Promise<IQuestionAnswerValues> =>
    requests.get(`/QuestionSetup/${questionId}`),
  importQuestion: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/QuestionSetup/Import", data, fileData),
};

const User = {
  list: (): Promise<IUsers[]> => requests.get("/user"),
  create: (userList: IUserFormValues) => requests.post("/user", userList),
  view: (id: string) => requests.get(`/user/${id}`),
  update: (userList: IUserFormValues) =>
    requests.put(`/user/${userList.id}`, userList),
  disable: (userId: string) => requests.disable(`/user/${userId}`),
};
const ManageUser = {
  create: (data: Map<string, string>, fileData: Map<string, string>) =>
    requests.postForm("/user", data, fileData),

  update: (
    id: string,
    data: Map<string, string>,
    fileData: Map<string, string>
  ) => requests.putForm(`/user/${id}`, data, fileData),
  list: (): Promise<ManageUserValues[]> => requests.get("/user"),
  getById: (id: string): Promise<IManageUser> => requests.get(`/user/${id}`),
  disable: (DisableUser: IManageUserDisable) =>
    requests.patch(`/user/${DisableUser.userId}`, DisableUser),
};
const Department = {
  list: () => requests.get("/department"),
  view: (department: IDepartment): Promise<IDepartment> =>
    requests.post("/department", department),
};

const Role = {
  list: () => requests.get("/role"),
};

const Dashboard = {
  view: async () =>
    await requests.get("/AdminDashboard/GetCountandChartDetails"),
  create: (req: IPendingOrApprove) =>
    requests.post("AdminDashboard/GetRegistrationDetails", req),
  listView: (req: IPendingOrApprove) =>
    requests.post("Assessment/GetAssessmentByVerify", req),
  searchOrg: async (orgKey: number) =>
    await requests.get(`/AdminDashboard/SearchOrganisation/${orgKey}`),
  searchState: (stateId: number, sectorId: number, pincode: number) =>
    requests.get(
      `/AdminDashboard/OrganisationByState/${stateId}/${sectorId}/${pincode}`
    ),
  getRegisterCount: () => requests.get("/AdminDashboard/GetRegistrationCount"),
  getRegistrationApproved: () =>
    requests.get("/AdminDashboard/GetRegistrationApproved"),
  getAssessmentAssessed: () =>
    requests.get("/AdminDashboard/GetAssessmentAssessed"),
  getOrgansiationName: (): Promise<IOrganisation[]> =>
    requests.get("/AdminDashboard/GetOrgansiationName"),
  getStatewiseRegistration: (): Promise<IStatewise[]> =>
    requests.get("/AdminDashboard/GetStatewiseRegistration"),
  getSectorYearwiseRegistration: (): Promise<IYearSectorwise[]> =>
    requests.get("/AdminDashboard/GetSectorYearwise"),
  getAreaYearwise: (): Promise<IYearAreawise[]> =>
    requests.get("/AdminDashboard/GetAreaYearwise"),
  getAssessmentMonthwise: (): Promise<IMonthwise[]> =>
    requests.get("/AdminDashboard/GetAssessmentMonthwise"),
  getAssessmentYearwise: (): Promise<IYearwise[]> =>
    requests.get("/AdminDashboard/GetAssessmentYearwise"),
  getPincode: (): Promise<IPincode[]> =>
    requests.get("/AdminDashboard/Pincode"),
};

const PartnerDashboard = {
  getById: (id: number): Promise<IPartnerDashboard> =>
    requests.get(`/PartnerDashboard/${id}`),
  getUnMappedEndorse: () => requests.get("Federation/GetNotEndorse"),
  updateActionPoints: (req: IUpdateActionPoints) =>
    requests.put("PartnerDashboard/UpdateActionPoints", req),
  actionPoints: (id: number): Promise<IActionPointDashboard> =>
    requests.get(`/PartnerDashboard/ActionPoints/${id}`),
  acceptedFederation: (id: number): Promise<IAcceptedFederation[]> =>
    requests.get(`/PartnerDashboard/AcceptedFederation/${id}`),
  previousAssessments: (id: number): Promise<IPreviousAssessmentCount> =>
    requests.get(`/PartnerDashboard/PreviousAssessments/${id}`),
  points: (id: number): Promise<IPoint> =>
    requests.get(`/PartnerDashboard/Point/${id}`),
  pointsByAssessmentId: (assessmentId: number): Promise<IPoint> =>
    requests.get(`/PartnerDashboard/PointByAssessmentId/${assessmentId}`),
  pointPercentage: (id: number): Promise<IPointPercentage> =>
    requests.get(`/PartnerDashboard/PointPercentage/${id}`),
  assessmentArea: (id: number): Promise<IODArea> =>
    requests.get(`/PartnerDashboard/AreawiseAssessment/${id}`),
  areabyAssessmentId: (assessmentId: number): Promise<IODArea> =>
    requests.get(
      `/PartnerDashboard/AreawiseDetailsByAssessmentId/${assessmentId}`
    ),

  checkPreviousAssessment: (id: number): Promise<ICheckPreviousAssessments> =>
    requests.get(`/PartnerDashboard/CheckPreviousAssessment/${id}`),
  CommiteeMembers: (id: number): Promise<ICommiteeMembers[]> =>
    requests.get(`/PartnerDashboard/CommiteeMembers/${id}`),
  organisationLogo:(id: number): Promise<IOrganisationLogo[]> =>
    requests.get(`/PartnerDashboard/OrganisationLogo/${id}`),
};
const FederationDashboard = {
  getById: (id: number): Promise<IFederationDashboard> =>
    requests.get(`/FederationDashboard/${id}`),
};
const DonorOrgDashboard = {
  getById: (id: number): Promise<IDonorOrgDashboard> =>
    requests.get(`/DonorOrgDashboard/${id}`),
  searchPartner: (
    sectorId: number,
    stateId: number,
    partnerId: number
  ): Promise<IPartnerName[]> =>
    requests.get(
      `DonorOrgDashboard/SearchPartner/${sectorId}/${stateId}/${partnerId}`
    ),
  getProfile: (id: number): Promise<IProfile> =>
    requests.get(`/DonorOrgDashboard/profiledetail/${id}`),
};

const ContactUser = {
  create: (contactList: IContactValues) =>
    requests.post("/contact", contactList),
  list: (key: string): Promise<IContactValues[]> =>
    requests.get(`/contact/${key}`),
  count: (key: string): Promise<IContactCount> =>
    requests.get(`/contact/Count/${key}`),
  read: (key: string): Promise<string> =>
    requests.put(`contact/ReadMessage/${key}`, key),
};

const State = {
  create: (stateList: IState): Promise<string> =>
    requests.post("/State", stateList),
  list: (): Promise<IState[]> => requests.get("/State"),
  listActive: (): Promise<IState[]> => requests.get("/State/Active"),
  update: (stateList: IState): Promise<string> =>
    requests.put(`/State/${stateList.stateId}`, stateList),
  disable: (stateList: IState) =>
    requests.patch(`/State/${stateList.stateId}`, stateList),
};

const DonorIndDashboard = {
  getById: (id: number): Promise<IDonorDashboard> =>
    requests.get(`/DonorIndividualDashboard/${id}`),
  searchPartner: (
    stateId: number,
    sectorId: number,
    partnerId: number
  ): Promise<IPartnerDetail[]> =>
    requests.get(
      `DonorIndividualDashboard/${stateId}/${sectorId}/${partnerId}`
    ),
  getProfile: (id: number): Promise<IProfile> =>
    requests.get(`/DonorIndividualDashboard/profiledetail/${id}`),
  getApproval: (donorIndId: number): Promise<IApproval[]> =>
    requests.get(`/DonorIndividual/PartnerApprovalInfo/${donorIndId}`),
};

export default {
  User,
  Auth,
  Department,
  Role,
  BeneficiaryType,
  Task,
  ODPartner,
  DoneAssessment,
  FedDoneRegister,
  DoneRegister,
  FedRegisterAdmin,
  AdminRegister,
  Federation,
  OTPVerification,
  DonorOrg,
  DonorIndividual,
  Sector,
  AreaOfAssessment,
  SubSector,
  QuestionSetup,
  Plan,
  ManageUser,
  Assessment,
  Dashboard,
  PartnerDashboard,
  FederationDashboard,
  ContactUser,
  State,
  DonorOrgDashboard,
  DonorIndDashboard,
  AreaAllocation,
  AssesmentNotify,
  RegistrationNotify,
  DonorRegsmsNotify,
  DonorIndsmsNotify,
};
