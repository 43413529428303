import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { ISubSectorFormValues } from "../models/subSector";
import { AlreadyExist } from "../common/constant";

export default class SubSectorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable subSectorList: ISubSectorFormValues[] = [];
  @observable loadingSubSectors = false;
  @observable subSectorDropdownValues: DropdownItemProps[] = [];
  @observable subSectorMasterDropDownValues: DropdownItemProps[] = [];

  @action getSubSector = async () => {
    this.loadingSubSectors = true;
    try {
      const topicsResponse = await agent.SubSector.list();
      runInAction(() => {
        this.subSectorList = topicsResponse;
        this.updateDropdownValues();
        this.loadingSubSectors = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingSubSectors = false;
      });
      console.log(error);
    }
  };
  @action getActiveSubSector = async () => {
    this.loadingSubSectors = true;
    try {
      const topicsResponse = await agent.SubSector.listactive();
      runInAction(() => {
        this.subSectorList = topicsResponse;
        this.updateDropdownValues();
        this.loadingSubSectors = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingSubSectors = false;
      });
      console.log(error);
    }
  };

  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    let masterDropdownValues: DropdownItemProps[] = [];
    this.subSectorList.map((subSector) => {
      if (subSector.isActive === true) {
        let item = {
          text: subSector.subSectorName,
          value: subSector.subSectorId,
          key: subSector.subSectorId,
        };
        dropDownValues.push(item);
      }
      let masterItem = {
        text: subSector.subSectorName,
        value: subSector.subSectorId,
        key: subSector.subSectorId,
        icon: subSector.isActive ? "check" : "close",
      };
      masterDropdownValues.push(masterItem);
      return subSector;
    });
    this.subSectorDropdownValues = dropDownValues;
    this.subSectorMasterDropDownValues = masterDropdownValues;
  }

  @action updateSectorName = (
    oldTopicName: string,
    updatedTopicName: string
  ) => {
    this.subSectorList.map((sector) => {
      if (sector.sectorName === oldTopicName)
        sector.sectorName = updatedTopicName;
      return sector;
    });
  };

  @action getSubSectorFromId = (subSectorId: number): ISubSectorFormValues => {
    console.log(this.subSectorList);
    let index = this.subSectorList.findIndex(
      (b) => b.subSectorId === subSectorId
    );
    console.log(index);
    return this.subSectorList[index];
  };

  @action getSubSectorFromSectorId = async (sectorId: number) => {
    this.loadingSubSectors = true;

    try {
      const topicsResponse = await agent.SubSector.getBySectorId(sectorId);
      runInAction(() => {
        this.subSectorList = topicsResponse;
        this.updateDropdownValues();
        this.loadingSubSectors = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingSubSectors = false;
      });
      console.log(error);
    }
  };

  @action createSubSector = async (subSector: ISubSectorFormValues) => {
    try {
      this.loadingSubSectors = true;
      const topicResponse = await agent.SubSector.create(subSector);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error(
            "Sub-Sector " + subSector.subSectorName + " already exists"
          );
          this.loadingSubSectors = false;
        } else {
          subSector.subSectorId = Number(topicResponse);
          subSector.isActive = true;
          this.subSectorList.push(subSector);
          this.updateDropdownValues();
          toast.info(
            "Sub-Sector " + subSector.subSectorName + " successfully created"
          );
          this.loadingSubSectors = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    }
  };

  @action editSubSector = async (updatedSubSector: ISubSectorFormValues) => {
    try {
      this.loadingSubSectors = true;
      const topicResponse = await agent.SubSector.update(updatedSubSector);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error(
            "Sub-Sector " + updatedSubSector.subSectorName + " already exists"
          );
          this.loadingSubSectors = false;
        } else {
          let index = this.subSectorList.findIndex(
            (sector) => sector.subSectorId === updatedSubSector.subSectorId
          );
          Object.assign(this.subSectorList[index], updatedSubSector);
          this.updateDropdownValues();
          toast.info(
            "Sub-Sector " +
              updatedSubSector.subSectorName +
              " successfully updated"
          );
          this.loadingSubSectors = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    }
  };

  @action disableSubSector = async (
    selectedSubSector: ISubSectorFormValues
  ) => {
    if (selectedSubSector.sectorId) {
      let disableSubSector: ISubSectorFormValues = {
        subSectorId: selectedSubSector.subSectorId,
        subSectorName: selectedSubSector.subSectorName,
        sectorName: selectedSubSector.sectorName,
        sectorId: selectedSubSector.sectorId,
        isActive: !selectedSubSector.isActive,
      };
      await agent.SubSector.disable(disableSubSector);
      runInAction(() => {
        let index = this.subSectorList.findIndex(
          (a) => a.subSectorId === disableSubSector.subSectorId
        );
        this.subSectorList[index].isActive = disableSubSector.isActive;
        this.updateDropdownValues();
        toast.info(
          "Sub-Sector  \t" +
            selectedSubSector.subSectorName +
            " \t successfully " +
            (disableSubSector.isActive ? "enabled" : "disabled")
        );
        this.rootStore.modalStore.closeModal();
      });
    }
  };

  @action upsertSubSector = async (sector: ISubSectorFormValues) => {
    if (sector.subSectorId === undefined || sector.subSectorId === 0)
      await this.createSubSector(sector);
    else await this.editSubSector(sector);
  };
}
