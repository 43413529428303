import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { observer } from 'mobx-react-lite';

function MonthwiseAssessment(props) {
  const admindashboard = JSON.parse(props.assessmentMonth)
  const [state] = React.useState({
    series: [{
      name: "No.of Assessment",
      data: admindashboard && admindashboard.map(
        (x) => {
          return x.sumValuebymonth
        }
      )
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: 7,
        curve: 'smooth'
      },
      xaxis: {
        type: 'month',
        categories: admindashboard && admindashboard.map(
          (x) => {
            return x.month
          }
        ),
        tickAmount: 10,

      },
      title: {
        text: '',
        align: 'left',
        style: {
          fontSize: "16px",
          color: '#666'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#FDD835'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },
      yaxis: {
        min: -10,
        max: 40,
        title: {
          text: 'No. of Assessment',
        },
      }
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );

}
export default observer(MonthwiseAssessment);