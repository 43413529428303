import React, { Fragment } from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormFieldProps,
  Form,
  DropdownItemProps,
  Grid,
  Label,
  GridColumn,
  FormCheckbox,
} from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const CheckInputGroup: React.FC<IProps> = ({
  input,
  label,
  customName,
  options,
  hidden,
  onChange,
  required=false,
  meta: { touched, error },
}) => {
  const [state] = React.useState({
    slectedValues: [] as any,
  });
  const handleItemClick = (e, data) => {
    if (data.checked) {
      state.slectedValues = state.slectedValues.concat(Number(data.value));
    } else {
      state.slectedValues = state.slectedValues.filter(
        (item) => item !== Number(data.value)
      );
    }
    input.onChange(data.value);
    if (onChange) {
      onChange(data.value);
    }
  };

  return (
    <Fragment>
      <Grid columns={4} verticalAlign="middle">
        <GridColumn width={8}>
          <Form.Field hidden={hidden}>
            {label && <label className="fieldLabel">{label}
            {required === true && <span className="mandatory-field">*</span>}
            </label>}            
          </Form.Field>
        </GridColumn>
        <GridColumn width={8}>
          <Form.Field error={touched && !!error} hidden={hidden}>
            {options &&
              options.map((item: DropdownItemProps) => (
                <Fragment key={item.key}>
                  <FormCheckbox
                    onChange={handleItemClick}
                    value={
                      typeof item.value === "number"
                        ? (item.value as number)
                        : (item.value as string)
                    }
                    label={item.text}
                    name={item.name}
                    //checked={slectedValues.data.}
                  />
                  &nbsp;&nbsp;&nbsp;
                </Fragment>
              ))}
            {
              <input
                type={hidden}
                name={customName}
                value={state.slectedValues as any}
              ></input>
            }
          </Form.Field>

          {touched && error && (
            <Label basic color="red">
              {error}
            </Label>
          )}
        </GridColumn>
      </Grid>
    </Fragment>
  );
};

export default CheckInputGroup;
