import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import {
  IOdPartnerApproveOrReject,
  IOdPartnerRegister, 
  IOdPartnerRegisterValues, 
  OdPartnerRegisterValues,
} from "../models/partner";
import { history } from "../..";
import { IRegistrationDone } from "../models/registrationDone";
import {  TryAgain } from "../common/constant"; 
import { IRegistrationAdmin  } from "../models/registrationAdmin";
export default class PartnerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable partnerDropDownValues : any[] = [];
  @observable partnerList: IOdPartnerRegister[] = [];
  @observable loadingUsers: boolean = false;
  @observable userDropdownValues: DropdownItemProps[] = [];
  @observable roleDropdownValues: DropdownItemProps[] = [
    {
      text: "Administrator",
      value: "Administrator",
      key: 1,
    },
  ];

  @observable sectorValues: DropdownItemProps[] = [
    { text: "Mission", value: 1, key: 1 },
    { text: "Church", value: 2, key: 2 },
    { text: "Medical", value: 3, key: 3 },
    { text: "Education", value: 4, key: 4 },
    { text: "Others", value: 5, key: 5 },
  ];

  

  @observable loadingOdPartnerDetail: boolean = false;
  @observable IsRegister: boolean = false;
  @observable odPartnerDetail?: IOdPartnerRegister | any;
  @observable odPartnerGet?: OdPartnerRegisterValues | any;
  @observable odPartnerApproveOrReject: IOdPartnerApproveOrReject | any;
  @observable savedPartner: IOdPartnerRegisterValues | undefined;
  @observable creatingPartner = false;
  @observable loadingSavedPartnerDetails = false;
  @observable partners : IOdPartnerRegister[] = []


  @observable userSendSms: IRegistrationDone | undefined;
  @observable userSendSmsAdmin: IRegistrationAdmin | undefined;
  //@observable userVerifySms: IRegistrationVerifySms | undefined;
  @observable loadingSendSms: boolean = false;
  @observable loadingSendSmsAdmin: boolean = false;  //@observable loadingVerifySms: boolean = false;
  //@observable verifySmsSuccess: boolean = false;
  @observable isRegisterComplete : boolean = false;
  @observable index :number=  1;


  @action creatOdPartner = async (newUser: OdPartnerRegisterValues) => {
    try {
      this.IsRegister = true;
      let dataMap: Map<string, any> = new Map();
      if (newUser.OrganizationName !== undefined)
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (newUser.YearOfInception !== undefined)
        dataMap.set("YearOfInception", newUser.YearOfInception);
      if (newUser.RegisteredAddress !== undefined)
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (newUser.CommunicationAddress !== undefined)
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.Pincode !== undefined)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined) dataMap.set("State", newUser.State);
      if (newUser.OfficialEmailId !== undefined)
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined)
        dataMap.set("Password", newUser.Password);
      if (newUser.OfficeContactumber !== undefined) {
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      }
      if (newUser.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
      if (newUser.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.SectorId !== undefined)
        dataMap.set("SectorId", newUser.SectorId);
      if (newUser.SubsectorId !== undefined)
        dataMap.set("SubsectorId", newUser.SubsectorId);
        if (newUser.FedarationBasicDetailIds !== undefined)
        dataMap.set(
          "FedarationBasicDetailIds",
          newUser?.FedarationBasicDetailIds?String(newUser?.FedarationBasicDetailIds): "0" );  
      if (newUser.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined)
        dataMap.set("TwitterID", newUser.TwitterID);
        if (newUser.LinkedInID !== undefined)
        dataMap.set("LinkedInID", newUser.LinkedInID);
      if (newUser.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.FounderTitle !== undefined)
        dataMap.set("FounderTitle", newUser.FounderTitle);
      if (newUser.FounderFirstName !== undefined)
        dataMap.set("FounderFirstName", newUser.FounderFirstName);
      if (newUser.FounderLastName !== undefined)
        dataMap.set("FounderLastName", newUser.FounderLastName);
      if (newUser.FounderMobile !== undefined)
        dataMap.set("FounderMobile", newUser.FounderMobile);
      if (newUser.FounderEmailId !== undefined)
        dataMap.set("FounderEmailId", newUser.FounderEmailId);
      if (newUser.FounderDesignation !== undefined)
        dataMap.set("FounderDesignation", newUser.FounderDesignation);
      if (newUser.CEOTitle !== undefined)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (newUser.CEODesignation !== undefined)
        dataMap.set("CEODesignation", newUser.CEODesignation);

      if (newUser.ActivityReport !== undefined)
        dataMap.set("ActivityReport", newUser.ActivityReport);
      if (newUser.OrganisationBudget !== undefined)
        dataMap.set("OrganisationBudget", newUser.OrganisationBudget);
      if (newUser.AuditStatement !== undefined)
        dataMap.set("RecentAuditstatement", newUser.AuditStatement);
      console.log(dataMap);
      if (newUser.CEOPhotoFile) {
        if (newUser.CEOPhotoFile !== undefined)
          dataMap.set("CEOPhotoFile", newUser.CEOPhotoFile.toString());
      }
      if (newUser.MissionStatement !== undefined)
        dataMap.set("MissionStatement", newUser.MissionStatement);
      if (newUser.FaithStatement !== undefined)
        dataMap.set("FaithStatement", newUser.FaithStatement);
      if (newUser.VisionStatement !== undefined)
        dataMap.set("VisionStatement", newUser.VisionStatement);
      if (newUser.CoreValues !== undefined)
        dataMap.set("CoreValues", newUser.CoreValues);
      if (newUser.ObjectiveOfOrganization !== undefined)
        dataMap.set("ObjectiveOfOrganization", newUser.ObjectiveOfOrganization);
      if (newUser.MajorActivities !== undefined)
        dataMap.set("MajorActivities", newUser.MajorActivities);
      if (newUser.NumberOfCommiteeMember !== undefined)
        dataMap.set("NumberOfCommiteeMember", newUser.NumberOfCommiteeMember);

      let fileMap: Map<string, string> = new Map();
      if (newUser.OrganizationInfoOrBrouchefile)
        fileMap.set(
          "OrganizationInfoOrBrouchefile",
          newUser.OrganizationInfoOrBrouchefile
        );

        if (newUser.OrganizationLogoFile)
        fileMap.set("OrganizationLogoFile", newUser.OrganizationLogoFile);
        console.log(newUser.OrganizationLogoFile)
      
      if (newUser.CEOPhotoFile)
        fileMap.set("CEOPhotoFile", newUser.CEOPhotoFile);
      if (newUser.TrusteeorManagementProfileFile)
        fileMap.set(
          "TrusteeorManagementProfileFile",
          newUser.TrusteeorManagementProfileFile
        );

      if (newUser.AuditStatement)
        fileMap.set("RecentAuditstatement", newUser.AuditStatement);

      if (newUser.ActivityReport)
        fileMap.set("ActivityReport", newUser.ActivityReport);
      if (newUser.OrganisationBudget)
        fileMap.set("OrganisationBudget", newUser.OrganisationBudget);
      if (newUser.RefTitle1 !== undefined)
        dataMap.set("RefTitle1", newUser.RefTitle1);
      if (newUser.RefTitle2 !== undefined)
        dataMap.set("RefTitle2", newUser.RefTitle2);
      if (newUser.RefFirstName1 !== undefined)
        dataMap.set("RefFirstName1", newUser.RefFirstName1);
      if (newUser.RefFirstName2 !== undefined)
        dataMap.set("RefFirstName2", newUser.RefFirstName2);
      if (newUser.RefLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.RefLastName1);
      if (newUser.RefLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.RefLastName1);
      if (newUser.RefLastName2 !== undefined)
        dataMap.set("RefLastName2", newUser.RefLastName2);
      if (newUser.RefMobile1 !== undefined)
        dataMap.set("RefMobile1", newUser.RefMobile1);
      if (newUser.RefMobile2 !== undefined)
        dataMap.set("RefMobile2", newUser.RefMobile2);
        if (newUser.RefEmail1 !== undefined && newUser.RefEmail1 !== null)
        dataMap.set("RefEmail1", newUser.RefEmail1);
      if (newUser.RefEmail2 !== undefined && newUser.RefEmail2 !== null)
        dataMap.set("RefEmail2", newUser.RefEmail2);
      if (newUser.RefDesignation1 !== undefined)
        dataMap.set("RefDesignation1", newUser.RefDesignation1);
      if (newUser.RefDesignation2 !== undefined)
        dataMap.set("RefDesignation2", newUser.RefDesignation2);
      if (newUser.RefOrganization1 !== undefined)
        dataMap.set("RefOrganization1", newUser.RefOrganization1);
      if (newUser.RefOrganization2 !== undefined)
        dataMap.set("RefOrganization2", newUser.RefOrganization2);

      if (newUser.ContactTitle !== undefined)
        dataMap.set("ContactTitle", newUser.ContactTitle);

      if (newUser.ContactFirstName !== undefined)
        dataMap.set("ContactFirstName", newUser.ContactFirstName);

      if (newUser.ContactLastName !== undefined)
        dataMap.set("ContactLastName", newUser.ContactLastName);

      if (newUser.ContactMobile !== undefined)
        dataMap.set("ContactMobile", newUser.ContactMobile);

      if (newUser.ContactEmailId !== undefined)
        dataMap.set("ContactEmailId", newUser.ContactEmailId);

      if (newUser.ContactDesignation !== undefined)
        dataMap.set("ContactDesignation", newUser.ContactDesignation);

      // var obj1 = newUser.PartnerContactPersonJson;
      // var contactObj = JSON.stringify(obj1);
      // dataMap.set("PartnerContactPersonJson", contactObj);
      // console.log(dataMap);
      const userResponse = await agent.ODPartner.createWithFile(
        dataMap,
        fileMap
      );
      runInAction(() => {
        this.IsRegister = false;
        this.partnerList = [...this.partnerList, userResponse];
        newUser.PartnerId = userResponse.partnerBasicDetailId;
        
        this.updatePartnerDropdownValues();
        if (userResponse === -1) {
          toast.error("Email id already Registered.Try using different id");
          this.isRegisterComplete=false;
        } else {
          this.isRegisterComplete=true;
          toast.info("Registered Successfully.Our Admin will review and approve shortly.");
          history.push("/thankyou");
        }
      });
    } catch (error) {
      toast.error("Please try again after some time");
    }
  };

  @action editOdPartner = async (newUser: OdPartnerRegisterValues) => {
    try {
      this.IsRegister = true;
      let dataMap: Map<string, any> = new Map();
      if (newUser.PartnerId !== undefined)
        dataMap.set("PartnerBasicDetailId", newUser.PartnerId);
      if (newUser.RefId1 !== undefined) dataMap.set("RefId1", newUser.RefId1);
      if (newUser.RefId2 !== undefined) dataMap.set("RefId2", newUser.RefId2);
      if(newUser.ContactId !== undefined) dataMap.set("ContactId", newUser.ContactId);
      if (newUser.OrganizationName !== undefined && newUser.OrganizationName !== null)
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (newUser.YearOfInception !== undefined && newUser.YearOfInception !== null)
        dataMap.set("YearOfInception", newUser.YearOfInception);
      if (newUser.RegisteredAddress !== undefined && newUser.RegisteredAddress !== null)
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (newUser.CommunicationAddress !== undefined && newUser.CommunicationAddress !== null)
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.Pincode !== undefined && newUser.Pincode !== null)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined && newUser.State !== null) dataMap.set("State", newUser.State);
      if (newUser.OfficialEmailId !== undefined && newUser.OfficialEmailId !== null)
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined && newUser.Password !== null)
        dataMap.set("Password", newUser.Password);
      if (newUser.OfficeContactumber !== undefined && newUser.OfficeContactumber !== null) {
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      }
      if (newUser.MobileContactNumber !== undefined && newUser.MobileContactNumber !== null)
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
        if (newUser.State !== undefined && newUser.State !== null)
        dataMap.set("State", newUser.State);
      if (newUser.WebsiteAddress !== undefined && newUser.WebsiteAddress !== null)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.SectorId !== undefined && newUser.SectorId !== null)
        dataMap.set("SectorId", newUser.SectorId);
      if (newUser.SubsectorId !== undefined && newUser.SubsectorId !== null)
        dataMap.set("SubsectorId", newUser.SubsectorId);
        if (newUser.FedarationBasicDetailIds !== undefined)
        dataMap.set(
          "FedarationBasicDetailIds",
          newUser?.FedarationBasicDetailIds?String(newUser?.FedarationBasicDetailIds): "0" ); 
      if (newUser.WhatsappNumber !== undefined && newUser.WhatsappNumber !== null)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined && newUser.FacebookID !== null)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined && newUser.TwitterID !== null)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (newUser.OtherMediaLink !== undefined && newUser.OtherMediaLink !== null)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.FounderTitle !== undefined && newUser.FounderTitle !== null)
        dataMap.set("FounderTitle", newUser.FounderTitle);
      if (newUser.FounderFirstName !== undefined && newUser.FounderFirstName !== null)
        dataMap.set("FounderFirstName", newUser.FounderFirstName);
      if (newUser.FounderLastName !== undefined && newUser.FounderLastName !== null)
        dataMap.set("FounderLastName", newUser.FounderLastName);
      if (newUser.FounderMobile !== undefined && newUser.FounderMobile !== null)
        dataMap.set("FounderMobile", newUser.FounderMobile);
      if (newUser.FounderEmailId !== undefined && newUser.FounderEmailId !== null)
        dataMap.set("FounderEmailId", newUser.FounderEmailId);
      if (newUser.FounderDesignation !== undefined && newUser.FounderDesignation !== null)
        dataMap.set("FounderDesignation", newUser.FounderDesignation);
      if (newUser.CEOTitle !== undefined && newUser.CEOTitle !== null)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined && newUser.CEOFirstName !== null)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined && newUser.CEOLastName !== null)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined && newUser.CEOMobile !== null)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined && newUser.CEOEmailId !== null)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (newUser.CEODesignation !== undefined && newUser.CEODesignation !== null)
        dataMap.set("CEODesignation", newUser.CEODesignation);

      if (newUser.ActivityReport !== undefined && newUser.ActivityReport !== null)
        dataMap.set("ActivityReport", newUser.ActivityReport);
      if (newUser.OrganisationBudget !== undefined && newUser.OrganisationBudget !== null)
        dataMap.set("OrganisationBudget", newUser.OrganisationBudget);
      if (newUser.AuditStatement !== undefined && newUser.AuditStatement !== null)
        dataMap.set("RecentAuditstatement", newUser.AuditStatement);
      console.log(dataMap);
      if (newUser.TrusteeorManagementProfileFile) {
        if (newUser.TrusteeorManagementProfileFile !== undefined  && newUser.TrusteeorManagementProfileFile !== null)
          dataMap.set(
            "TrusteeorManagementProfileFile",
            newUser.TrusteeorManagementProfileFile.toString()
          );
      }
      if (newUser.CEOPhotoFile) {
        if (newUser.CEOPhotoFile !== undefined && newUser.CEOPhotoFile !== null)
          dataMap.set("CEOPhotoFile", newUser.CEOPhotoFile.toString());
      }
      if (newUser.MissionStatement !== undefined && newUser.MissionStatement !== null)
        dataMap.set("MissionStatement", newUser.MissionStatement);
      if (newUser.FaithStatement !== undefined && newUser.FaithStatement !== null)
        dataMap.set("FaithStatement", newUser.FaithStatement);
      if (newUser.VisionStatement !== undefined && newUser.VisionStatement !== null)
        dataMap.set("VisionStatement", newUser.VisionStatement);
      if (newUser.CoreValues !== undefined && newUser.CoreValues !== null)
        dataMap.set("CoreValues", newUser.CoreValues);
      if (newUser.ObjectiveOfOrganization !== undefined && newUser.ObjectiveOfOrganization !== null)
        dataMap.set("ObjectiveOfOrganization", newUser.ObjectiveOfOrganization);
      if (newUser.MajorActivities !== undefined && newUser.MajorActivities !== null)
        dataMap.set("MajorActivities", newUser.MajorActivities);
      if (newUser.NumberOfCommiteeMember !== undefined && newUser.NumberOfCommiteeMember !== null)
        dataMap.set("NumberOfCommiteeMember", newUser.NumberOfCommiteeMember);

      let fileMap: Map<string, string> = new Map();
      if (newUser.OrganizationInfoOrBrouchefile)
        fileMap.set(
          "OrganizationInfoOrBrouchefile",
          newUser.OrganizationInfoOrBrouchefile[0]
        );
      if (newUser.OrganizationLogoFile)
        fileMap.set("OrganizationLogoFile", newUser.OrganizationLogoFile[0]);
      if (newUser.CEOPhotoFile)
        fileMap.set("CEOPhotoFile", newUser.CEOPhotoFile[0]);
      if (newUser.TrusteeorManagementProfileFile)
        fileMap.set(
          "TrusteeorManagementProfileFile",
          newUser.TrusteeorManagementProfileFile[0]
        );

      if (newUser.AuditStatement)
        fileMap.set("RecentAuditstatement", newUser.AuditStatement[0]);

      if (newUser.ActivityReport)
        fileMap.set("ActivityReport", newUser.ActivityReport[0]);
      if (newUser.OrganisationBudget)
        fileMap.set("OrganisationBudget", newUser.OrganisationBudget[0]);
      if (newUser.RefTitle1 !== undefined && newUser.RefTitle1 !== null)
        dataMap.set("RefTitle1", newUser.RefTitle1);
      if (newUser.RefTitle2 !== undefined && newUser.RefTitle2 !== null)
        dataMap.set("RefTitle2", newUser.RefTitle2);
      if (newUser.RefFirstName1 !== undefined && newUser.RefFirstName1 !== null)
        dataMap.set("RefFirstName1", newUser.RefFirstName1);
      if (newUser.RefFirstName2 !== undefined && newUser.RefFirstName2 !== null)
        dataMap.set("RefFirstName2", newUser.RefFirstName2);
      if (newUser.RefLastName1 !== undefined && newUser.RefLastName1 !== null)
        dataMap.set("RefLastName1", newUser.RefLastName1);
      if (newUser.RefLastName1 !== undefined && newUser.RefLastName1 !== null)
        dataMap.set("RefLastName1", newUser.RefLastName1);
      if (newUser.RefLastName2 !== undefined && newUser.RefLastName2 !== null)
        dataMap.set("RefLastName2", newUser.RefLastName2);
      if (newUser.RefMobile1 !== undefined && newUser.RefMobile1 !== null)
        dataMap.set("RefMobile1", newUser.RefMobile1);
      if (newUser.RefMobile2 !== undefined && newUser.RefMobile2 !== null)
        dataMap.set("RefMobile2", newUser.RefMobile2);

        if (newUser.RefEmail1 !== undefined && newUser.RefEmail1 !== null)
        dataMap.set("RefEmail1", newUser.RefEmail1);
      if (newUser.RefEmail2 !== undefined && newUser.RefEmail2 !== null)
        dataMap.set("RefEmail2", newUser.RefEmail2);

      if (newUser.RefDesignation1 !== undefined && newUser.RefDesignation1 !== null)
        dataMap.set("RefDesignation1", newUser.RefDesignation1);
      if (newUser.RefDesignation2 !== undefined && newUser.RefDesignation2 !== null)
        dataMap.set("RefDesignation2", newUser.RefDesignation2);
      if (newUser.RefOrganization1 !== undefined && newUser.RefOrganization1 !== null)
        dataMap.set("RefOrganization1", newUser.RefOrganization1);
      if (newUser.RefOrganization2 !== undefined && newUser.RefOrganization2 !== null)
        dataMap.set("RefOrganization2", newUser.RefOrganization2);
        if (newUser.ContactTitle !== undefined && newUser.ContactTitle !== null)
        dataMap.set("ContactTitle", newUser.ContactTitle);

      if (newUser.ContactFirstName !== undefined && newUser.ContactFirstName !== null)
        dataMap.set("ContactFirstName", newUser.ContactFirstName);

      if (newUser.ContactLastName !== undefined && newUser.ContactLastName !== null)
        dataMap.set("ContactLastName", newUser.ContactLastName);

      if (newUser.ContactMobile !== undefined && newUser.ContactMobile !== null)
        dataMap.set("ContactMobile", newUser.ContactMobile);

      if (newUser.ContactEmailId !== undefined && newUser.ContactEmailId !== null)
        dataMap.set("ContactEmailId", newUser.ContactEmailId);

      if (newUser.ContactDesignation !== undefined && newUser.ContactDesignation !== null)
        dataMap.set("ContactDesignation", newUser.ContactDesignation);

      // var obj1 = newUser.PartnerContactPersonJson;
      // var contactObj = JSON.stringify(obj1);
      // dataMap.set("PartnerContactPersonJson", contactObj);
      // console.log(dataMap);
      const userResponse = await agent.ODPartner.editWithFile(
        newUser.PartnerId,
        dataMap,
        fileMap
      );
      runInAction(() => {
      
        this.IsRegister = false;
        this.partnerList = [...this.partnerList, userResponse];
        if (userResponse === -1) {
          this.isRegisterComplete=false;
        }
        else{
          this.isRegisterComplete=true;
        }
        toast.info("User Edited Successfully");
        this.rootStore.commonStore.partnerId  =0;
      });
    } catch (error) {
      toast.error("Please try again after some time");
      this.rootStore.commonStore.partnerId  =0;
    }
  };
   odPartnerArray: any;
  @action getOdPartnerById = async (id: number) => {
    try {
      this.loadingOdPartnerDetail = true;
      this.odPartnerDetail = undefined;

      const odPartner = await agent.ODPartner.getById(id);
      console.log(odPartner);
      this.odPartnerArray = odPartner;
      runInAction(() => {
        this.odPartnerDetail = odPartner;
        this.loadingOdPartnerDetail = false;
       // this.odPartnerGet = odPartner;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingOdPartnerDetail = false;
      });
    }
  };

  @action getAllOdPartners = async () => {
    try {
      this.loadingOdPartnerDetail = true;
      this.odPartnerDetail = undefined;

      const odPartners = await agent.ODPartner.getAllPartners();
      console.log(odPartners);
      //this.odPartnerArray = odPartners;
      runInAction(() => {
        this.partners = odPartners;
        this.updatePartnerDropdownValues();
        this.loadingOdPartnerDetail = false;
       // this.odPartnerGet = odPartner;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingOdPartnerDetail = false;
      });
    }
  };
  
  @action updatePartnerDropdownValues() {
    let dropDownValues: any[] = [];
    let masterDropDownValues: any[] = [];
    this.partners.map((partner) => {
      let masteritem = {
        text: partner.organizationName,
        value: partner.partnerBasicDetailId,
        key: partner.partnerBasicDetailId,
        
      };
      masterDropDownValues.push(masteritem);
      let item = {
        text: partner.organizationName,
        value: partner.partnerBasicDetailId,
        key: partner.partnerBasicDetailId,
      };
      dropDownValues.push(item);
      console.log(dropDownValues);
      return partner;
    });
    this.partnerDropDownValues = dropDownValues;
    
  }


  @action upsertPartner = async (user: OdPartnerRegisterValues) => {
    if (user.PartnerId === undefined || user.PartnerId === 0)
      await this.creatOdPartner(user);
    else await this.editOdPartner(user);
  };


  @action registerationDone = async (values: IRegistrationDone ) => {
    this.loadingSendSms = true;
    console.log(values);
    try {
      const user = await agent.DoneRegister.create(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.userSendSms = user;
        this.loadingSendSms = false;
        
      });
    } catch (error) {
      this.loadingSendSms = false;
      toast.error(TryAgain);
      throw error;
    }
  };
  @action registerationAdmin = async (values: IRegistrationAdmin ) => {
    this.loadingSendSmsAdmin = true;
    console.log(values);
    try {
      const user = await agent.AdminRegister.create(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.userSendSmsAdmin = user;
        this.loadingSendSmsAdmin = false;
        
      });
    } catch (error) {
      this.loadingSendSmsAdmin = false;
      toast.error(TryAgain);
      throw error;
    }
  };


  @action odPartnerApprove = async (approve: IOdPartnerApproveOrReject) => {
    try {
      console.log(approve);
      const odPartnerApproveResponse = await agent.ODPartner.approve(approve);
     
      console.log(odPartnerApproveResponse);
      runInAction(async() => {
        this.odPartnerApproveOrReject = odPartnerApproveResponse;
        console.log(this.odPartnerApproveOrReject);
        if(this.odPartnerApproveOrReject == "Updated successfully"){
         
          // agent.RegistrationNotify.notifyUser(this.odPartnerArray["mobileContactNumber"]);
          agent.RegistrationNotify.notifyUser(`${this.odPartnerArray.mobileContactNumber}`);
          console.log(this.odPartnerArray.mobileContactNumber);
        }
        toast.info(
          "Od Partner " +
            (approve.IsApproved ? "approved" : "rejected")
        );
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action savePartnerDetails = async (partner: IOdPartnerRegisterValues) => {
    

      try {
        this.IsRegister = true;
        let dataMap: Map<string, any> = new Map();
        if (partner.OrganizationName !== undefined)
          dataMap.set("OrganizationName", partner.OrganizationName);
        if (partner.YearOfInception !== undefined)
          dataMap.set("YearOfInception", partner.YearOfInception);
        if (partner.RegisteredAddress !== undefined)
          dataMap.set("RegisteredAddress", partner.RegisteredAddress);
        if (partner.CommunicationAddress !== undefined)
          dataMap.set("CommunicationAddress", partner.CommunicationAddress);
        if (partner.Pincode !== undefined)
          dataMap.set("Pincode", partner.Pincode);
        if (partner.State !== undefined) dataMap.set("State", partner.State);
        if (partner.OfficialEmailId !== undefined)
          dataMap.set("OfficialEmailId", partner.OfficialEmailId);
        if (partner.Password !== undefined)
          dataMap.set("Password", partner.Password);
        if (partner.OfficeContactumber !== undefined) {
          dataMap.set("OfficeContactumber", partner.OfficeContactumber);
        }
        if (partner.MobileContactNumber !== undefined)
          dataMap.set("MobileContactNumber", partner.MobileContactNumber);
        if (partner.WebsiteAddress !== undefined)
          dataMap.set("WebsiteAddress", partner.WebsiteAddress);
        if (partner.SectorId !== undefined)
          dataMap.set("SectorId", partner.SectorId);
        if (partner.SubsectorId !== undefined)
          dataMap.set("SubsectorId", partner.SubsectorId);
          if (partner.FedarationBasicDetailIds !== undefined)
          dataMap.set(
            "FedarationBasicDetailIds",
            partner?.FedarationBasicDetailIds?String(partner?.FedarationBasicDetailIds): "0" ); 
        if (partner.WhatsappNumber !== undefined)
          dataMap.set("whatsappNumber", partner.WhatsappNumber);
        if (partner.FacebookID !== undefined)
          dataMap.set("FacebookID", partner.FacebookID);
        if (partner.TwitterID !== undefined)
          dataMap.set("TwitterID", partner.TwitterID);
          if (partner.LinkedInID !== undefined)
          dataMap.set("LinkedInID", partner.LinkedInID);
        if (partner.OtherMediaLink !== undefined)
          dataMap.set("OtherMediaLink", partner.OtherMediaLink);
        if (partner.FounderTitle !== undefined)
          dataMap.set("FounderTitle", partner.FounderTitle);
        if (partner.FounderFirstName !== undefined)
          dataMap.set("FounderFirstName", partner.FounderFirstName);
        if (partner.FounderLastName !== undefined)
          dataMap.set("FounderLastName", partner.FounderLastName);
        if (partner.FounderMobile !== undefined)
          dataMap.set("FounderMobile", partner.FounderMobile);
        if (partner.FounderEmailId !== undefined)
          dataMap.set("FounderEmailId", partner.FounderEmailId);
        if (partner.FounderDesignation !== undefined)
          dataMap.set("FounderDesignation", partner.FounderDesignation);
        if (partner.CEOTitle !== undefined)
          dataMap.set("CEOTitle", partner.CEOTitle);
        if (partner.CEOFirstName !== undefined)
          dataMap.set("CEOFirstName", partner.CEOFirstName);
        if (partner.CEOLastName !== undefined)
          dataMap.set("CEOLastName", partner.CEOLastName);
        if (partner.CEOMobile !== undefined)
          dataMap.set("CEOMobile", partner.CEOMobile);
        if (partner.CEOEmailId !== undefined)
          dataMap.set("CEOEmailId", partner.CEOEmailId);
        if (partner.CEODesignation !== undefined)
          dataMap.set("CEODesignation", partner.CEODesignation);
  
        if (partner.ActivityReport !== undefined)
          dataMap.set("ActivityReport", partner.ActivityReport);
        if (partner.OrganisationBudget !== undefined)
          dataMap.set("OrganisationBudget", partner.OrganisationBudget);
        if (partner.AuditStatement !== undefined)
          dataMap.set("RecentAuditstatement", partner.AuditStatement);
          if (partner.OrganizationLogoFile !== undefined)
          dataMap.set("OrganizationLogoFile", partner.OrganizationLogoFile);
        console.log(dataMap);
        if (partner.TrusteeorManagementProfileFile) {
          if (partner.TrusteeorManagementProfileFile !== undefined)
            dataMap.set(
              "TrusteeorManagementProfileFile",
              partner.TrusteeorManagementProfileFile.toString()
            );
        }
        if (partner.CEOPhotoFile) {
          if (partner.CEOPhotoFile !== undefined)
            dataMap.set("CEOPhotoFile", partner.CEOPhotoFile.toString());
        }
        if (partner.MissionStatement !== undefined)
          dataMap.set("MissionStatement", partner.MissionStatement);
        if (partner.FaithStatement !== undefined)
          dataMap.set("FaithStatement", partner.FaithStatement);
        if (partner.VisionStatement !== undefined)
          dataMap.set("VisionStatement", partner.VisionStatement);
        if (partner.CoreValues !== undefined)
          dataMap.set("CoreValues", partner.CoreValues);
        if (partner.ObjectiveOfOrganization !== undefined)
          dataMap.set("ObjectiveOfOrganization", partner.ObjectiveOfOrganization);
        if (partner.MajorActivities !== undefined)
          dataMap.set("MajorActivities", partner.MajorActivities);
        if (partner.NumberOfCommiteeMember !== undefined)
          dataMap.set("NumberOfCommiteeMember", partner.NumberOfCommiteeMember);
  
        let fileMap: Map<string, string> = new Map();
        if (partner.OrganizationInfoOrBrouchefile)
          fileMap.set(
            "OrganizationInfoOrBrouchefile",
            partner.OrganizationInfoOrBrouchefile
          );
        if (partner.OrganizationLogoFile)
          fileMap.set("OrganizationLogo", partner.OrganizationLogoFile[0]);
        if (partner.CEOPhotoFile)
          fileMap.set("CEOPhotoFile", partner.CEOPhotoFile[0]);
        if (partner.TrusteeorManagementProfileFile)
          fileMap.set(
            "TrusteeorManagementProfileFile",
            partner.TrusteeorManagementProfileFile[0]
          );
  
        if (partner.AuditStatement)
          fileMap.set("RecentAuditstatement", partner.AuditStatement[0]);
  
        if (partner.ActivityReport)
          fileMap.set("ActivityReport", partner.ActivityReport[0]);
        if (partner.OrganisationBudget)
          fileMap.set("OrganisationBudget", partner.OrganisationBudget[0]);
        if (partner.RefTitle1 !== undefined)
          dataMap.set("RefTitle1", partner.RefTitle1);
        if (partner.RefTitle2 !== undefined)
          dataMap.set("RefTitle2", partner.RefTitle2);
        if (partner.RefFirstName1 !== undefined)
          dataMap.set("RefFirstName1", partner.RefFirstName1);
        if (partner.RefFirstName2 !== undefined)
          dataMap.set("RefFirstName2", partner.RefFirstName2);
        if (partner.RefLastName1 !== undefined)
          dataMap.set("RefLastName1", partner.RefLastName1);
        if (partner.RefLastName1 !== undefined)
          dataMap.set("RefLastName1", partner.RefLastName1);
        if (partner.RefLastName2 !== undefined)
          dataMap.set("RefLastName2", partner.RefLastName2);
        if (partner.RefMobile1 !== undefined)
          dataMap.set("RefMobile1", partner.RefMobile1);
        if (partner.RefMobile2 !== undefined)
          dataMap.set("RefMobile2", partner.RefMobile2);
          if (partner.RefEmail1 !== undefined && partner.RefEmail1 !== null)
          dataMap.set("RefEmail1", partner.RefEmail1);
        if (partner.RefEmail2 !== undefined && partner.RefEmail2 !== null)
          dataMap.set("RefEmail2", partner.RefEmail2);
        if (partner.RefDesignation1 !== undefined)
          dataMap.set("RefDesignation1", partner.RefDesignation1);
        if (partner.RefDesignation2 !== undefined)
          dataMap.set("RefDesignation2", partner.RefDesignation2);
        if (partner.RefOrganization1 !== undefined)
          dataMap.set("RefOrganization1", partner.RefOrganization1);
        if (partner.RefOrganization2 !== undefined)
          dataMap.set("RefOrganization2", partner.RefOrganization2);
  
        if (partner.ContactTitle !== undefined)
          dataMap.set("ContactTitle", partner.ContactTitle);
  
        if (partner.ContactFirstName !== undefined)
          dataMap.set("ContactFirstName", partner.ContactFirstName);
  
        if (partner.ContactLastName !== undefined)
          dataMap.set("ContactLastName", partner.ContactLastName);
  
        if (partner.ContactMobile !== undefined)
          dataMap.set("ContactMobile", partner.ContactMobile);
  
        if (partner.ContactEmailId !== undefined)
          dataMap.set("ContactEmailId", partner.ContactEmailId);
  
        if (partner.ContactDesignation !== undefined)
          dataMap.set("ContactDesignation", partner.ContactDesignation);
  
        // var obj1 = newUser.PartnerContactPersonJson;
        // var contactObj = JSON.stringify(obj1);
        // dataMap.set("PartnerContactPersonJson", contactObj);
        // console.log(dataMap);
    //     const userResponse = await agent.ODPartner.createWithFile(
    //       dataMap,
    //       fileMap
    //     );
    //     runInAction(() => {
    //       this.IsRegister = false;
    //       this.partnerList = [...this.partnerList, userResponse];
    //       if (userResponse === -1) {
    //         toast.error("Email id already Registered.Try using different id");
    //       } else {
    //         toast.info("Registered Successfully.Our Admin will review and approve shortly.");
    //       }
    //     });
    //   } catch (error) {
    //     toast.error("Please try again after some time");
    //   }
    // };
  
        const saveODPartner = await agent.ODPartner.savePartner(dataMap, fileMap);
        
        runInAction(() => {
            this.savedPartner = saveODPartner;
            console.log(this.savedPartner);
            // if (saveODPartner === -1) {
            //   toast.error("Email id Already Registered");
            // }
            this.IsRegister = false;
            
        });
        return saveODPartner;
    
    }catch (ex) {
      runInAction(() => {
        
          this.IsRegister
           = false;
      });
   //   toast.error(ex?.data.errors.message);
      throw ex;
  }
  
};

  @action getSavedPartnerDetails = async (contactNumber: IOdPartnerRegisterValues ) => {

  this.loadingSavedPartnerDetails = true;
  try {
      const newBene = await agent.ODPartner.getSavedPartner(
          contactNumber
      );
      runInAction(() => {
          
          this.odPartnerDetail = newBene;
          this.loadingSavedPartnerDetails = false;

      });
  }
  catch (ex) {
      //history.push('/beneficiary');

      this.loadingSavedPartnerDetails = false;

  }
};
}