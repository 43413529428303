import { observable, computed, action, runInAction } from "mobx";
import { IChangePasswordFormValues, IForgotPasswordFormValues,  ILoginFormValues, IUserProfile, IVerifyOtpFormValues } from "../models/user";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { history } from '../..';
import { toast } from "react-toastify";
import { UserRole } from "../common/enum";

export default class AuthStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

    }

    @observable user: IUserProfile | null = null;


    @computed get isLoggedIn() { return !!this.user }

    @observable selectedUser: IUserProfile | undefined
    @observable isForgetPasswordLoading: boolean=false

   
    @action login = async (values: ILoginFormValues) => {
        try {
            const user = await agent.Auth.login(values);
            runInAction(() => {
                this.user = user;
                this.selectedUser = user;
                console.log(user)
            });
            this.rootStore.commonStore.setToken(user.token);
            this.rootStore.commonStore.setTokenExpiry(user.tokenExpires);
            this.rootStore.commonStore.setRefreshToken(user.refreshToken);
            this.rootStore.commonStore.setRegistrationKey(user.registrationKey);
            this.rootStore.commonStore.setUserName(user.userName);
            this.rootStore.commonStore.setRegistrationId(user.registrationId);
            this.rootStore.commonStore.setRole(user.role);
            this.rootStore.commonStore.setOrganisation(user.organisation);
            this.rootStore.commonStore.setEmail(user.emailId);
            this.rootStore.commonStore.setMobile(user.mobileNumber);
            this.rootStore.commonStore.setLogo(user.organisationLogo);
            this.rootStore.commonStore.setFullName(user.fullName);
            this.rootStore.commonStore.setPincode(user.pincode);
            this.rootStore.commonStore.setState(user.state);
            this.rootStore.commonStore.setSector(user.sector);
            this.rootStore.commonStore.setSubsector(user.subSector);
            
            history.push('/dod-adminDashboard');
            if (user.role === UserRole.Administrator)
                history.push('/dod-adminDashboard');
            else if (user.role === UserRole.ODPartner)
                history.push('/dod-ODDashboard');
            else if (user.role ===  UserRole.Federation)
                history.push('/dod-federationDashboard');
            else if (user.role ===  UserRole.DonorOrganisation)                            
                history.push('/dod-donorDashboard');
            else if (user.role ===  UserRole.DonorIndividual)
                history.push('/dod-donorIndDashboard');
        } 
         catch (error) {
            throw error;
        }
    };

    @action getProfile = async () => {
        try {
            const user = await agent.Auth.profile();
            runInAction(() => {
                console.log(user);
                this.user = user;


            })

        } catch (error) {

            this.logout();
            throw error;
        }
    }

    @action changePassword = async (values: IChangePasswordFormValues) => {
        try{
            const user = await agent.Auth.changePassword(values);
            runInAction(() => {
                this.user = user;
                toast.info('Password successfully updated');
                this.rootStore.modalStore.closeModal();
                
            });
            
        } catch (error) {
            throw error;
            
        } 
    }

    @action forgotPassword  = async (values:IForgotPasswordFormValues) =>{
        this.isForgetPasswordLoading=true
        try{
            const user = await agent.Auth.forgotPassword(values.mobileNumber);
            runInAction(() => {
                this.user=user
                this.isForgetPasswordLoading=false
            })
            history.push('/dod-verifyOtp');
        } catch (error) {
            this.isForgetPasswordLoading=false
            toast.error("Invalid User")
            throw error;    
        }
        
    }

    @action verifyOtp = async (values: IVerifyOtpFormValues) => {
        this.isForgetPasswordLoading=true
        try{
            const user = await agent.Auth.verifyOtp(values);
            runInAction(() => {
                this.user = user;
                this.isForgetPasswordLoading=false
                toast.info('Password successfully updated');   
            });
            history.push('/');
        } catch (error) {
            this.isForgetPasswordLoading=false
            toast.error('Password change failed!! Please check your details')
            throw error;   
        } 
    }
    @action logout = async () => {
        try {
            if (this.rootStore.commonStore.token)
                await agent.Auth.logout();
        }
        finally {
            runInAction(() => {
                this.rootStore.commonStore.setToken(null);
                this.rootStore.commonStore.setRefreshToken(null);
                this.rootStore.commonStore.setUserName(null);
                this.user = null;
                history.push('/');
            })
        }
    }

}