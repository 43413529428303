import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Field, Form as FinalForm } from 'react-final-form'
import { IAssessmentSuggesstion } from "../../../../app/models/assessment";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import TextInputTab from "../../../../app/common/form/TextInputTab";

interface IProps {
    assessmentSuggestion: IAssessmentSuggesstion,
}

const AssessmentSuggesstionEdit: React.FC<IProps> = ({ assessmentSuggestion: approveOrRejectAssessmentProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {editSuggestion} = rootStore.assessmentStore;
    const [suggestionState] = React.useState({
        suggestion: approveOrRejectAssessmentProps
      });
    return (
        <FinalForm
            initialValues={{
                suggesstion : suggestionState.suggestion.suggesstion,
                areaId : suggestionState.suggestion.areaId,
                assessmentVerifyId : suggestionState.suggestion.assessmentVerifyId,
            }}
            onSubmit={(values: IAssessmentSuggesstion) => 
                editSuggestion(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                
                <Field
                                        component={TextInputTab}
                                        name="suggesstion"
                                        placeholder="Mobile"                                     
                                        inputstyle={{ width: "349px" }}
                                      ></Field>
                                     
                                       <Field hidden = {true}
                                        component={TextInputTab}
                                        name="assessmentVerifyId"
                                        placeholder="Mobile"                                     
                                        inputstyle={{ width: "250px" }}
                                      ></Field>
                                          
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '130px'}} type = "submit"  onClick={() => {
                   
               }} loading ={submitting}> <Icon name = "edit"/> {" "} Edit </Button>
                </Form>
                 )}>
        </FinalForm>
    )
}

export default observer(AssessmentSuggesstionEdit);