import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { toast } from "react-toastify";
import {
  Button,
  Segment,
  Header,
  Icon,
  Form,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { IState } from "../../../../app/models/state";

// import SelectInput from "../../../app/common/form/SelectInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import CreateEditState from "./CreateEditState";
import DisableState from "./DisableState";

const StateList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getState,
    getStateFromId,
    stateMasterDropdownValues,
    loadingState,
  } = rootStore.stateStore;
  const { openRightPanel } = rootStore.rightPanelStore;
  const [selectedStateId, setSelectedStateId] = useState<any>(-1);
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    getState();
  }, [getState]);

  return (
    <FinalForm
      onSubmit={() => {}}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <br />
          <br />
          <Segment style={{ marginRight: "70px" }}>
            <Segment color="violet">
              <Header as="h4" icon textAlign="center">
                <Header.Content>STATE</Header.Content>
              </Header>
            </Segment>

            <Form>
             

              <Button
                color="blue"
                content="Create State"
                floated="right"
                onClick={() => {
                  openRightPanel("Create State", <CreateEditState />);
                }}
              /> 
              <Dropdown
                search
                selection
                options={stateMasterDropdownValues}
                loading={loadingState}
                placeholder="Select State"
                onChange={(event, data: DropdownProps) => {
                  if (data.value) setSelectedStateId(data.value);
                }}
              ></Dropdown>

              <br />
              <br />

              <Button.Group attached="bottom">
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    openRightPanel(
                      "Edit State",
                      <CreateEditState
                        editState={getStateFromId(selectedStateId)}
                      />
                    );
                  }}
                >
                  <Icon name="edit" />
                  View & Edit
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    if (selectedStateId === "") {
                      toast.error("Please select from dropdown");
                    } else {
                      let state: IState = getStateFromId(selectedStateId);

                      openModal(
                        `${state.isActive ? "Disable" : "Enable"} State`,
                        <DisableState disableEnableState={state} />
                      );
                    }
                  }}
                >
                  <Icon name="trash" />
                  Disable & Enable{" "}
                </Button>
              </Button.Group>
            </Form>
          </Segment>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(StateList);
