import { error } from "console";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { toast } from "react-toastify";
import {
  Button,
  Segment,
  Header,
  Icon,
  Form,
  Dropdown,
  DropdownProps,
  Table,
} from "semantic-ui-react";
import SelectInputTab from "../../../../app/common/form/SelectInputTab";
import { IAreaAllocation } from "../../../../app/models/areaOfAssessment";
import { ISectorFormValues } from "../../../../app/models/sector";
import { RootStoreContext } from "../../../../app/stores/rootStore";


const AreaAllocationList: React.FC<{
  createUser?: IAreaAllocation;
}> = ({ createUser }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getSectorFromId,
    getSector,
    loadingSectors,
    sectorDropdownValues,
    sectorMasterDropdownValues,
  } = rootStore.sectorStore;
  const [selectedSectorId, setSelectedSectorId] = useState<any>(-1);
  const { openRightPanel } = rootStore.rightPanelStore;
  const {
    subSectorDropdownValues,
    getSubSectorFromSectorId, getActiveSubSector
  } = rootStore.subSectorStore;
  let { areaDropdownValues, getActiveArea, createAreaAllocation, getAreaAllocation, areaAllocation, getAreaAllocationById } = rootStore.areaOfAssessmentStore;
  const { openModal } = rootStore.modalStore;
  const [state, setState] = React.useState({
    areas: [],
  });
  const handleSectorChange = (event) => {
    getSubSectorFromSectorId(event.value);
  };
  const handleAreaChange = (event) => {
    getAreaAllocationById(1, 1);
  };
  console.log(state.areas);
  useEffect(() => {
    getSector();
    getActiveSubSector();
    getActiveArea();
    getAreaAllocation();
  }, [getSector, getActiveSubSector, getActiveArea, getAreaAllocation]);
  // var areaAllocation
  return (
    <FinalForm
      initialValues={createUser}
      onSubmit={(values: IAreaAllocation) => {
        //  values.MobileContactNumber = state.mobile;
        createAreaAllocation(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({
        handleSubmit: sectorSubmit,
        submitting,
        form,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <br />
          <br />
          <Segment style={{ width: "90%" }}>
            <Segment color="violet">
              <Header as="h4" icon textAlign="center">
                <Header.Content>AREA ALLOCATION</Header.Content>
              </Header>
            </Segment>

            <Form
              onSubmit={async (event) => {
                await sectorSubmit(event);
                form.reset();
              }}
              error
            >

              <Field
                component={SelectInputTab}
                placeholder="Select Sector"
                name="sectorId"
                search
                selections={"true"}
                options={sectorDropdownValues}
                inputstyle={{ width: "290px" }}
                onCustomChange={handleSectorChange}
              ></Field>
              <Field
                component={SelectInputTab}
                inputstyle={{ width: "290px" }}
                fluid
                placeholder="Select Sub-Sector"
                name="subsectorId"
                search
                selections={"true"}
                options={subSectorDropdownValues}
                onCustomChange={handleAreaChange}
              />

              <Field
                component={SelectInputTab}
                placeholder='Select Area' name="areaId"
                search selections={"true"} options={areaDropdownValues}
                multiple selection={"true"}
                loading={loadingSectors}
              ></Field>
              <br />
              <br />

              <Button.Group attached="bottom">
                <Button
                  basic
                  type="submit"
                  color="blue"
                  loading={submitting}

                >
                  <Icon name="edit" />
                  Area Allocate
                </Button>

              </Button.Group>
            </Form>

            <div className="table-scroll">
              <Table collapsing color="violet">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3}>Sector</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Area</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {areaAllocation &&
                    areaAllocation.map((subSector) => (
                      <Table.Row key={subSector.subSectorId}>
                        <Table.Cell>{subSector.sectorName}</Table.Cell>
                        <Table.Cell>{subSector.areaName}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </Segment>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(AreaAllocationList);
