import { DropdownItemProps } from 'semantic-ui-react';
import { IMaster, IQuestionExport, IQuestionImport, IQuestionSetupDisableFormValues } from './../models/questionSetup';
import { observable, action, runInAction } from 'mobx';
import agent from '../api/agent';
import { RootStore } from './rootStore';
import { toast } from 'react-toastify';
import { IQuestionAnswerValues } from '../models/questionSetup';
import { AnswerOptionViewModel, IAnswerOptionViewModel } from '../models/answerOption';
import { IAssessmentDetail, IAssessmentList } from '../models/assessment';

export default class QuestionSetupStore {
  rootStore: RootStore;

  @observable questionList: IQuestionAnswerValues[] = [];
  @observable questionDraftList: IQuestionExport[] = [];
  @observable assessmentList: IAssessmentList | undefined = undefined;
  @observable assessmentDetail: IAssessmentDetail[] = [];
  @observable loadingQuestionSetup = false;
  @observable answerOptions: IAnswerOptionViewModel[] = [];
  @observable questionNames: DropdownItemProps[] = [];
  @observable questionSearchCriteria: IMaster | undefined;
  @observable selectedQuestionDetail: IQuestionAnswerValues | undefined = undefined;
  @observable answerDropDownValues: DropdownItemProps[] = [];
  @observable questionHidden: Map<number, boolean> = new Map<number, boolean>();
  @observable uploadingDocument = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action getQuestionSetup = async (partnerId: number) => {
    this.loadingQuestionSetup = true;
    agent.QuestionSetup.list(partnerId).then
      (questionResponse => {
        console.log(questionResponse);
        runInAction(() => {
          this.questionList = questionResponse;
          this.updateDropdownValues();
          this.updateQuestionHiddenMap();
          this.loadingQuestionSetup = false;

        });
      })
      .finally(() => runInAction(() => this.loadingQuestionSetup = false));
  }

  @action uploadDocument = async (questionImport: IQuestionImport) => {
    this.uploadingDocument = true;
    let dataMap: Map<string, string> = new Map();

    if (questionImport.sectorId !== undefined) dataMap.set('SectorId', questionImport.sectorId.toString());
    if (questionImport.subSectorId !== undefined) dataMap.set('SubSectorId', questionImport.subSectorId.toString());
    if (questionImport.areaOfAssessmentId !== undefined) dataMap.set('AreaId', questionImport.areaOfAssessmentId.toString());
    let fileMap: Map<string, string> = new Map();
    if (questionImport.file) fileMap.set("File", questionImport.file);

    await agent.QuestionSetup.importQuestion(dataMap, fileMap);
    runInAction(() => {
      this.uploadingDocument = false;
      toast.success("Questions Uploaded Successfully")
    });

  };

  @action createQuestionSetup = async (question: IQuestionAnswerValues) => {
    question.answerOptionCommand.map(x => {
      let ansopt = new AnswerOptionViewModel();
      ansopt.optionName = x.optionName;
      ansopt.pointValue = x.pointValue;
      ansopt.actionPoints = x.actionPoints;
      this.answerOptions.push(ansopt);
      return x;
    }
    );

    const questionResponse = await agent.QuestionSetup.create(question);
    console.log(questionResponse)
    runInAction(() => {
      this.questionList = [...this.questionList, questionResponse];
      //  this.updateDropdownValues();
      // this.updateQuestionHiddenMap();
      toast.info(
        "Question successfully created"
      );

    });
  }
  @action handleAnswerSelectionChange(question: IQuestionAnswerValues, selectedValue: number | string,
    questionHidden: Map<number, boolean>, checked: boolean = true) {
    var ansvalue = "";
    question.answerOptionCommand.map(ans => {
      ans.questionsToDisplay.map(qId => {
        questionHidden[qId] = true;
        return questionHidden;
      }
      );
      return ans;
    });
    question.answerOptionCommand.map(ans => {
      if (ans.answerOptionId === selectedValue && ans.questionsToDisplay && checked) {
        if (question.question === "Is your Organisation registered?") {
          ansvalue = ans.optionName;
        }
        ans.questionsToDisplay.map(qId => {
          questionHidden[qId] = false
          return questionHidden;
        });
      }
      return ans;
    });
    return ansvalue;
  }

  @action updateQuestionHiddenMap() {
    this.questionList.map(x => {
      if (x.questionId && x.questionId > 0)
        this.questionHidden[x.questionId] = x.defaultHidden;
      return this.questionHidden;
    }
    )
  }



  @action updateDropdownValues() {
    this.questionList.map(x => {

      let dropDownValues: DropdownItemProps[] = [];
      x.answerOptionCommand = x.questionOptions;
      console.log(x.answerOptionCommand)
      x.answerOptionCommand.map(
        ansOpt => {
          if (ansOpt !== null) {
            let item = {
              text: ansOpt.optionName,
              value: ansOpt.answerOptionId,
              key: ansOpt.answerOptionId
            };
            dropDownValues.push(item);
            console.log(this.questionList);
          }
          return ansOpt;
        })
      x.answerDropDownValues = dropDownValues;
      this.answerDropDownValues = x.answerDropDownValues;
      console.log(x.answerDropDownValues);
      return x;
    })
  }

  @action master = async (masterid: IMaster) => {
    this.questionSearchCriteria = masterid;
    console.log(this.questionSearchCriteria);
  }

  @action getQuestionSetUpByMaster = async (searchCriteria: IMaster) => {
    try {
      this.questionSearchCriteria = searchCriteria;
      const questionSetup = await agent.QuestionSetup.viewByIds(searchCriteria?.sectorId, searchCriteria?.subSectorId, searchCriteria?.areaOfAssessmentId);
      runInAction(() => {
        this.questionList = questionSetup;
        console.log(this.questionList);

        this.updateDropdownValues();
        this.updateQuestionHiddenMap();
        console.log(this.questionList);
        let dropDownValues: DropdownItemProps[] = [];
        this.questionList.map(x => {
          if (x.defaultHidden && x.defaultHidden === true) {
            let questionName = {
              text: x.question,
              value: x.questionId,
              key: x.questionId
            };
            dropDownValues.push(questionName);
          }
          return x;
        })
        this.questionNames = dropDownValues;
        console.log(this.questionNames);
      })
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
      })
    }
  }

  @action enableDisableQuestionSetup = async (disableQuestionSetup: IQuestionSetupDisableFormValues) => {
    disableQuestionSetup.isActive = !disableQuestionSetup.isActive;
    console.log(disableQuestionSetup);
    await agent.QuestionSetup.disable(disableQuestionSetup);
    runInAction(() => {
      let index = this.questionList.findIndex(detail => detail.questionId === disableQuestionSetup.questionId);
      this.questionList[index].isActive = disableQuestionSetup.isActive;

      toast.info('Question Setup successfully ' + (disableQuestionSetup.isActive ? 'disabled' : 'enabled'));
      this.rootStore.modalStore.closeModal();

    })
  }

  @action editQuestionSetup = async (updatedQuestion: IQuestionAnswerValues) => {
    await agent.QuestionSetup.edit(updatedQuestion);
    runInAction(
      () => {
        let index = this.questionList.findIndex(detail => detail.questionId === updatedQuestion.questionId);
        Object.assign(this.questionList[index], updatedQuestion);

        toast.info('Question ' + updatedQuestion.question + ' successfully updated');
        this.rootStore.modalStore.closeModal();
      }
    )
  }

  @action getById = async (questionId?: number) => {
    const detail = await agent.QuestionSetup.viewById(questionId);
    console.log(detail);

    runInAction(() => {
      this.selectedQuestionDetail = detail;

    });
  };
  @action getByExcel = async (sectorid: number, subsectorid: number, areaid: number) => {
    const detail = await agent.QuestionSetup.getexport(sectorid, subsectorid, areaid);
    console.log(detail);

    runInAction(() => {
      this.questionDraftList = detail;

    });
  };



  @action upsertQuestion = async (questionSetup: IQuestionAnswerValues) => {
    if (questionSetup.questionId === undefined || questionSetup.questionId === 0) {
      console.log(questionSetup);
      await this.createQuestionSetup(questionSetup);
    }
    else {
      console.log(questionSetup);
      await this.editQuestionSetup(questionSetup);
      console.log(questionSetup);
    }
  };

}