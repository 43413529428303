


import Iframe from 'react-iframe'
import React from 'react';



export default function GoogleMap(){
    return(
        <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.163354658635!2d80.17664341482326!3d13.088831590779014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5263fa8b9b4c91%3A0x26683dd98c26d7a0!2sChristian%20Institute%20of%20Management!5e0!3m2!1sen!2sin!4v1613645334150!5m2!1sen!2sin" width="600" height="450"   aria-hidden="false" ></Iframe> 
      
    );
}  
