import React, { useContext, useEffect } from 'react'
import { Grid, GridColumn, Segment} from 'semantic-ui-react'
import SectorList from './sector/SectorList';
import SubSectorList from './subsector/SubSectorList';
import AreaList from './area/AreaList';
import StateList from './state/StateList';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import AreaAllocationList from './areaAllocation/AreaAllocationList';


const MasterDashboard =() => {
  const rootStore = useContext(RootStoreContext);
  const { getSubSector, subSectorList} = rootStore.subSectorStore;
  //const { openModal, closeModal } = rootStore.modalStore;
console.log(subSectorList)
  useEffect(() => {
      getSubSector();

  }, [getSubSector]);
  return (
    <Segment   >      
      <br/>    
     <div className = 'App-backimage'>
      <Grid columns={2}  >
        <GridColumn width={8} >
        <SectorList /> 
        <SubSectorList /> 
        </GridColumn>
        <GridColumn width={8} >
        <AreaList />
        <StateList />
        <AreaAllocationList></AreaAllocationList>
        </GridColumn>     
           
     
      </Grid>
<br/>
<br/>     
  
   </div>
      </Segment>
    
  );
}
export default observer(MasterDashboard)