import React, { useContext } from "react";
import { Segment, Item, Grid, Card } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import ODComplianceChart from "../odDashboard/odCharts/ODComplianceChart";
import ODArea from "../odDashboard/odCharts/ODArea";
import { LoadingComponent } from "../../../app/layout/LoadingComponent";
interface Props {
  classes: any;
}
interface State {
  value: number;
}
function FederationSearchODDashboard(props) {
  const rootStore = useContext(RootStoreContext);
  const { partnerdashboard, loadingDashboard } =
    rootStore.partnerdashboardStore;
  const { odPartnerDetail, loadingOdPartnerDetail } = rootStore.partnerStore;
  return (
    <div className="App-background">
      {loadingDashboard && loadingOdPartnerDetail && (
        <LoadingComponent content="Loading PartnerView Dashboard" />
      )}
      {odPartnerDetail && partnerdashboard && (
        <Segment>
          <Card fluid>
            <Card.Content>
              <Grid columns={2}>
                <Grid.Column>
                  <Item.Group link>
                    <Item>
                      <Item.Image
                        height="92px"
                        size="tiny"
                        src={odPartnerDetail?.organizationLogo}
                      />
                      <Item.Content>
                        <Item.Header>
                          {odPartnerDetail?.organizationName}
                        </Item.Header>
                        <Item.Meta>
                          CEO: {odPartnerDetail?.ceoFirstName}{" "}
                          {odPartnerDetail?.ceoLastName}
                        </Item.Meta>
                        <Item.Description>
                          Email: {odPartnerDetail?.officialEmailId}{" "}
                        </Item.Description>
                        <Item.Extra>
                          {" "}
                          Mobile No: {odPartnerDetail?.mobileContactNumber}
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Grid.Column>
                <Grid.Column>
                  <Item>
                    <br />
                    <Item.Content>
                      <Item.Header>State: {odPartnerDetail?.state}</Item.Header>
                      <Item.Meta>
                        Postal code: {odPartnerDetail?.pincode}
                      </Item.Meta>
                      <Item.Description>
                        Sector: {odPartnerDetail?.sectorName}
                      </Item.Description>
                      <Item.Extra>
                        Sub-Sector: {odPartnerDetail?.subSectorName}
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                </Grid.Column>
              </Grid>
            </Card.Content>
          </Card>

          <Grid>
            <Grid.Column width={9}>
              <br />
              <br />
              <br />
              <br />
              {partnerdashboard !== undefined ? (
                <ODComplianceChart data={partnerdashboard?.pointPercentage} />
              ) : (
                <div></div>
              )}{" "}
              <br />
              <br />
              <br />
            </Grid.Column>
            <Grid.Column width={11}>
              <br />
              {partnerdashboard !== undefined ? (
                <ODArea areagraph={JSON.stringify(partnerdashboard)} />
              ) : (
                <div></div>
              )}
              <br />
              <br />
            </Grid.Column>
          </Grid>
          <Card fluid color="blue">
            <br />
            <Item>
              <h5
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontStyle: "Italic",
                }}
              >
                About {odPartnerDetail?.organizationName}{" "}
              </h5>
              <Item.Content style={{ paddingLeft: "100px" }}>
                <Item.Header className="dash-card-header">
                  <b>Vision statement:</b> {odPartnerDetail?.visionStatement}
                </Item.Header>
                <br />
                <Item.Header className="dash-card-header">
                  <b>Mission statement:</b> {odPartnerDetail?.missionStatement}
                </Item.Header>
                <br />
                <Item.Header className="dash-card-header">
                  <b>Faith statement:</b> {odPartnerDetail?.faithStatement}
                </Item.Header>
                <br />
                <Item.Header className="dash-card-header">
                  <b>Core Values:</b> {odPartnerDetail?.coreValues}
                </Item.Header>
                <br />
                <Item.Header className="dash-card-header">
                  <b>Major Activities:</b> {odPartnerDetail?.majorActivities}
                </Item.Header>
                <br />
                <Item.Header className="dash-card-header">
                  <b>Objective of Organization:</b>{" "}
                  {odPartnerDetail?.objectiveOfOrganization}
                </Item.Header>
              </Item.Content>
              <br />
            </Item>
          </Card>

          <br />
          <br />
          <Card fluid color="blue">
            <br />
            <Item>
              <h5
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontStyle: "Italic",
                }}
              >
                About {odPartnerDetail?.organizationName} Leadership{" "}
              </h5>
              <Item.Content>
                <Grid>
                  <Grid.Column width={8}>
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      Founder Name- {odPartnerDetail?.founderTitle}{" "}
                      {odPartnerDetail?.founderFirstName}{" "}
                      {odPartnerDetail?.founderLastName}{" "}
                    </Item.Header>
                    <br />
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      Email ID - {odPartnerDetail?.founderEmailId}
                    </Item.Header>
                    <br />
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      Mobile No. - {odPartnerDetail?.founderMobile}
                    </Item.Header>
                    <br />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      CEO Name - {odPartnerDetail?.ceoTitle}{" "}
                      {odPartnerDetail?.ceoFirstName}{" "}
                      {odPartnerDetail?.ceoLastName}{" "}
                    </Item.Header>
                    <br />
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      Email ID - {odPartnerDetail?.ceoEmailId}
                    </Item.Header>
                    <br />
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      Mobile No. - {odPartnerDetail?.ceoMobile}
                    </Item.Header>
                    <br />
                    <Item.Header
                      style={{
                        marginLeft: "150px",
                        fontSize: 16,
                        fontStyle: "Italic",
                      }}
                    >
                      No. Of Board Of Trustee -{" "}
                      {odPartnerDetail?.numberOfCommiteeMember}
                    </Item.Header>
                  </Grid.Column>
                </Grid>
                <br />
              </Item.Content>
            </Item>
          </Card>
          <br />
          <br />
          <br />
          <br />
        </Segment>
      )}
    </div>
  );
}
export default observer(FederationSearchODDashboard);
