import React from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useContext } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { IAreaOfAssessmentValues } from "../../../../app/models/areaOfAssessment";

interface IProps {
  disableEnableArea: IAreaOfAssessmentValues;
}

const DisableArea: React.FC<IProps> = ({ disableEnableArea }) => {
  const rootStore = useContext(RootStoreContext);
  const { disableArea } = rootStore.areaOfAssessmentStore;
  return (
    <FinalForm
      initialValues={disableEnableArea}
      onSubmit={(values: IAreaOfAssessmentValues) => {
        disableArea(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <Form onSubmit={handleSubmit}>
    
            Do you really want to{" "}
            {disableEnableArea.isActive ? "disable" : "enable"} 
           <b> {disableEnableArea.areaName}?</b>
         
          <br />
          <br />
          {submitError && <ErrorMessage error={submitError} />}

         
            <Button
              loading={submitting}
              color='blue' style={{ marginLeft: '130px'}}
            >
              <Icon name={disableEnableArea.isActive ? "delete" : "check"} />{" "}
              {disableEnableArea.isActive ? "Disable" : "Enable"}
            </Button>
          
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(DisableArea);
