import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IState } from "../models/state";
import { AlreadyExist } from "../common/constant";

export default class StateStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable stateList: IState[] = [];
  @observable loadingState = false;
  @observable stateMasterDropdownValues: DropdownItemProps[] = [];
  @observable stateDropdownValues: DropdownItemProps[] = [];

  @action getState = async () => {
    this.loadingState = true;
    try {
      const topicsResponse = await agent.State.list();
      console.log("state", topicsResponse);
      runInAction(() => {
        this.stateList = topicsResponse;
        this.updateDropdownValues();
        this.loadingState = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingState = false;
      });
      console.log(error);
    }
  };
  @action getActiveState = async () => {
    this.loadingState = true;
    try {
      const topicsResponse = await agent.State.listActive();
      console.log("state", topicsResponse);
      runInAction(() => {
        this.stateList = topicsResponse;
        this.updateDropdownValue();
        this.loadingState = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingState = false;
      });
      console.log(error);
    }
  };
  @action updateDropdownValue() {
    let dropDownValues: DropdownItemProps[] = [];
    let masterDropdownValues: DropdownItemProps[] = [];
    this.stateList.map((state) => {
      if (state.isActive === true) {
        let item = {
          text: state.stateName,
          value: state.stateId,
          key: state.stateId,
        };
        dropDownValues.push(item);
      }
      let masterItem = {
        text: state.stateName,
        value: state.stateId,
        key: state.stateId,
       
      };
      masterDropdownValues.push(masterItem);

      return state;
    });
      this.stateDropdownValues = dropDownValues;
    console.log("statedropDownValues", this.stateDropdownValues);
    this.stateMasterDropdownValues = masterDropdownValues;
  }
  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    let masterDropdownValues: DropdownItemProps[] = [];
    this.stateList.map((state) => {
      if (state.isActive === true) {
        let item = {
          text: state.stateName,
          value: state.stateId,
          key: state.stateId,
        };
        dropDownValues.push(item);
      }
      let masterItem = {
        text: state.stateName,
        value: state.stateId,
        key: state.stateId,
        icon: state.isActive ? "check" : "close",
      };
      masterDropdownValues.push(masterItem);

      return state;
    });

    this.stateDropdownValues = dropDownValues;
    console.log("statedropDownValues", this.stateDropdownValues);
    this.stateMasterDropdownValues = masterDropdownValues;
  }

  @action getStateFromId = (stateId: number): IState => {
    console.log(this.stateList);
    let index = this.stateList.findIndex((b) => b.stateId === stateId);
    console.log(index);
    return this.stateList[index];
  };

  @action createState = async (state: IState) => {
    try {
      this.loadingState = true;
      const topicResponse = await agent.State.create(state);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("State " + state.stateName + " already exists");
          this.loadingState = false;
        } else {
          state.stateId = Number(topicResponse);
          state.isActive = true;
          this.stateList.push(state);
          this.updateDropdownValues();
          toast.info("State " + state.stateName + " successfully created");
          this.loadingState = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {}
  };

  @action editState = async (updatedState: IState) => {
    try {
      this.loadingState = true;
      const topicResponse = await agent.State.update(updatedState);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("State " + updatedState.stateName + " already exists");
          this.loadingState = false;
        } else {
          let index = this.stateList.findIndex(
            (state) => state.stateId === updatedState.stateId
          );
          Object.assign(this.stateList[index], updatedState);
          this.updateDropdownValues();
          toast.info(
            "State " + updatedState.stateName + " successfully Updated"
          );
          this.loadingState = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action disableState = async (selectedState: IState) => {
    if (selectedState.stateId) {
      let disableState: IState = {
        stateId: selectedState.stateId,
        stateName: selectedState.stateName,
        stateDescription: selectedState.stateDescription,
        isActive: !selectedState.isActive,
      };
      const response = await agent.State.disable(disableState);
      console.log("response", response);
      console.log("Api data", disableState);
      runInAction(() => {
        let index = this.stateList.findIndex(
          (a) => a.stateId === disableState.stateId
        );
        this.stateList[index].isActive = disableState.isActive;

        toast.info(
          "State  \t" +
            selectedState.stateName +
            " \t successfully " +
            (disableState.isActive ? "enabled" : "disabled")
        );
        this.rootStore.modalStore.closeModal();
      });
    }
  };

  @action upsertState = async (state: IState) => {
    if (state.stateId === undefined || state.stateId === 0)
      await this.createState(state);
    else await this.editState(state);
  };
}
