import React, { useContext, useEffect } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { history } from '../../../..';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { PendingOrApprove } from '../../../../app/models/dashboard';

const AssessmentPending: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { assessmentDetail, getPendingAssessments } = rootStore.dashboardStore;
    const {  getSuggestion  } = rootStore.assessmentStore; 
    useEffect(() => {
        let pendingReq = new PendingOrApprove();
        pendingReq.isPending = true;
        getPendingAssessments(pendingReq); 
    }, [getPendingAssessments, getSuggestion]);
    return (
         
            <div>
                <h3 style={{ textAlign: 'center' }}> List of Assessments - Pending</h3>
                <br />
                <Table>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                            <Table.HeaderCell>Assessment Date</Table.HeaderCell>
                            <Table.HeaderCell>Score</Table.HeaderCell>
                            <Table.HeaderCell>View</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {assessmentDetail && assessmentDetail.map(
                            (assessment) => (
                                <Table.Row key={assessment.assessmentId}>
                                    <Table.Cell>{assessment.organisationName}</Table.Cell>
                                    <Table.Cell>{moment(assessment.assessmentDate).format('DD MMM YYYY')}</Table.Cell>
                                    <Table.Cell>{assessment.score}</Table.Cell>

                                    <Table.Cell>
                                        <Button circular color='twitter' icon="angle double right" type="submit"
                                            onClick={() => { 
                                                rootStore.commonStore.setPartnerId(assessment.partnerId); 
                                                rootStore.commonStore.setAssessmentId(assessment.assessmentId);
                                                history.push('/dod-assessmentDetail');
                                            }}
                                        >
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        )}
                    </Table.Body>
                </Table>
            </div>
       
    );
}

export default observer(AssessmentPending)