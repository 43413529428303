import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { IAssessmentSuggesstion } from "../../../../app/models/assessment";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
    assessmentSuggestion: IAssessmentSuggesstion,
}

const AssessmentSuggesstionDelete: React.FC<IProps> = ({ assessmentSuggestion }) => {

    const rootStore = useContext(RootStoreContext);
    const {deleteSuggestion} = rootStore.assessmentStore;
 
    return (
        <FinalForm
            initialValues={assessmentSuggestion}
            onSubmit={(values: IAssessmentSuggesstion) =>  {
                values.isActive = false;            
                deleteSuggestion(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))                
                }
                }
              
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit} error> 
            Do you really want to{" "}
            delete
           <b> {assessmentSuggestion.suggesstion}?</b>
        
                                          
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '130px'}} 
                 onClick={() => {

               }}
               type = "submit" loading ={submitting}> <Icon name = "delete"/> {" "} Delete </Button>
                </Form>
                 )}>
        </FinalForm>
    )
}

export default observer(AssessmentSuggesstionDelete);