import React, { useContext, useEffect } from 'react';
import { Container, Segment, Item, Grid, Button, Form, Label, Image, Card, Loader } from 'semantic-ui-react';
import ODComplianceChart from '../odDashboard/odCharts/ODComplianceChart';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';
import ODMembershipEndorse from './ODMembershipEndorse';
import CreateMessage from '../../mailBox/CreateMessage';
import { UserRole, VerificationStatus } from '../../../app/common/enum';
import { IPartnerEndorse } from '../../../app/models/federation';
import SelectInput from '../../../app/common/form/SelectInput';
import { Form as FinalForm, Field } from 'react-final-form'
import ODArea from '../odDashboard/odCharts/ODArea';
import TextInput from '../../../app/common/form/TextInput';
import { Link } from "react-router-dom";



interface Props {
  classes: any;
}

interface State {
  value: number;
}
function FederationDashboard(props) {
  const [state] = React.useState({
    fedId: 0,
    isChart: false
  });
  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { federationdashboard, getFederationDashboard, loadingDashboard } = rootStore.federationdashboardStore
  const { getWaitingPartnerEndorse, partnerDetails, partnerEndorsedList,loadingFederationDetail,loadingFederation } = rootStore.federationStore;
  const { getPartnerDashboard, partnerdashboard } = rootStore.partnerdashboardStore;
  const { odPartnerDetail, getOdPartnerById,partnerDropDownValues,getAllOdPartners } = rootStore.partnerStore;
  const { registrationId, role } = rootStore.commonStore;

  state.fedId = rootStore.commonStore.registrationId === undefined ? props.location.state.Id : rootStore.commonStore.registrationId;

  useEffect(() => {
    getAllOdPartners();
    getWaitingPartnerEndorse(registrationId, VerificationStatus.Accepted?.toString());
    if (role === UserRole.Administrator) {
      getFederationDashboard(props.location.state.Id);
    }
    else {
      getFederationDashboard(registrationId);
    }
  }, [props, getFederationDashboard, registrationId, getWaitingPartnerEndorse, role,getAllOdPartners]);

  console.log(partnerdashboard);

  const [searchState] = React.useState({
    partnerId: 0,
    partnerKey: ""
  });


  const handlePartnerIdChange = (event, selectedIndex) => {
    searchState.partnerId = event.value;
    searchState.partnerId = event.options.find(o => o.value === searchState.partnerId).key
  };
  const endorsedList: any[] = []
   
  partnerEndorsedList.map((partner)=> {
    
  

    endorsedList.push({
      text: partner.partnerName,
      value: partner.partnerId,
      key: partner.partnerId,
    })

  }
  
    );
  


  const handlePartnerKeyChange = (event) => {
    searchState.partnerKey = event.value;
  };
  return (

    <Container>
      <div className='App-fedbackground'>
        {
          loadingDashboard && <LoadingComponent content="Loading FederationDashboard" />
        }
        <Segment>
          <Grid columns={3}>
            <Grid.Column>
              <Item.Group link>
                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={federationdashboard?.federationCEO.companyLogo} />
                    <Item.Content>
                      <Item.Header>{federationdashboard?.federationCEO.organisationName}</Item.Header>
                      <Item.Meta>{federationdashboard?.federationCEO.ceoName}</Item.Meta>
                      <Item.Description> {federationdashboard?.federationCEO.ceoEmail} </Item.Description>
                      <Item.Extra> {federationdashboard?.federationCEO.ceoMobile}</Item.Extra>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={rootStore.commonStore.logo} />
                    <Item.Content>
                      <Item.Header>{rootStore.commonStore.organisation}</Item.Header>
                      <Item.Meta>{rootStore.commonStore.fullName}</Item.Meta>
                      <Item.Description>  {rootStore.commonStore.email}  </Item.Description>
                      <Item.Extra> {rootStore.commonStore.mobile}</Item.Extra>
                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            <Grid.Column>
              <Item.Group link>

                {rootStore.commonStore.role === 'Administrator' ? (
                  <Item>

                    <Item.Content>
                      <br />
                      <Item.Meta style={{ marginLeft: '100px' }}>State:  {federationdashboard?.federationCEO.state}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {federationdashboard?.federationCEO.pincode}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Sector:   {federationdashboard?.federationCEO.sector} </Item.Meta>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>

                    <Item.Content>
                      <br />
                      <Item.Meta style={{ marginLeft: '100px' }}>State:  {rootStore.commonStore.state}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {rootStore.commonStore.pincode}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Sector:   {rootStore.commonStore.sector}  </Item.Meta>
                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            {rootStore.commonStore.role === UserRole.Federation &&
              <Grid.Column >

                <Label style={{ marginLeft: "320px" }} size="mini" color='red' pointing='below'>{federationdashboard?.pendingEndorsedCount}</Label>
                <Button title='WaitngPartnerEndorse' style={{ marginLeft: '200px' }} color='blue'
                  onClick={() => {
                    openRightPanel('Waiting for endorse partners list', <ODMembershipEndorse id={state.fedId} Email={rootStore.commonStore.email} Name={rootStore.commonStore.organisation} Key={rootStore.commonStore.registrationKey} />)
                  }} > Membership Approval
                                 </Button>
                <br />
                <br />
                <br />
                <Button style={{ marginLeft: '280px' }} color='blue' icon='mail'
                  onClick={() => {
                    openRightPanel('Contact Admin', <CreateMessage />)
                  }} />
              </Grid.Column>
            }
          </Grid>
        </Segment>
        <Grid>

          {federationdashboard?.endorsedODLogo.map((x) =>
            <Grid.Column>
              <Image src={x} size={'mini'} />
            </Grid.Column>
          )}

        </Grid>
        <br />
        {rootStore.commonStore.role === UserRole.Federation &&
          <FinalForm
            onSubmit={(values: IPartnerEndorse) => {
              getPartnerDashboard(searchState.partnerId);
            }
            }
            render={({ handleSubmit, submitting, submitError }) => (
              <Form onSubmit={handleSubmit} error>
                <Segment>
                  <label> <h6 style={{ fontStyle: 'Italic' }}> Enter OD Partner ID or Select Org to view member dashboard</h6></label>
                  <Grid>
                    <Grid.Column width={6} style={{ marginTop: "12px" }}>
                      <Field name={'partnerKey'} component={TextInput} placeholder="Organisation ID"
                        onCustomChange={handlePartnerKeyChange} format={key => key || []} />
                    </Grid.Column>
                    <Grid.Column width={6} >
                      <Field component={SelectInput} placeholder='Organisation Name' name={'partnerKey'}
                        onCustomChange={handlePartnerIdChange} search selection={"true"} options={endorsedList} loading = {loadingFederation} />
                    </Grid.Column>
                    <Grid.Column width={2} style={{ marginTop: "12px" }}>
                      <Button color='blue' type='submit' as={Link} to='/dod-federationSearchODDashboard'
                        onClick={() => {
                          getPartnerDashboard(searchState.partnerId);
                          getOdPartnerById(searchState.partnerId);
                        }}
                      >SEARCH</Button>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <br />
              </Form>
            )}>
          </FinalForm>



        }
        <FinalForm
          onSubmit={(values: IPartnerEndorse) => {
            //getPartnerDashboard(searchState.partnerId);
          }
          }
          render={({ handleSubmit, submitting, submitError }) => (
            <Form error>
              <Segment>
                <label> <h6 style={{ marginLeft: "400px", fontStyle: 'Italic' }}><b>List of Member Organisation</b></h6></label>
                <Grid>
                  {
                loadingFederationDetail && 
                <Loader active inline='centered' size='small'  />
                  }
                  {
                    partnerEndorsedList && partnerEndorsedList.map((x) =>
                      <Grid.Row key={x.partnerId} style={{ marginLeft: "300px" }}>
                        <Grid.Column width={5} >
                          {x.partnerName}<br />
                          {x.ceoName}<br />
                          {x.state}
                        </Grid.Column> 
                        <Grid.Column width={11} >
                          <Button color='blue' type='button'
                            onClick={() => {
                              state.isChart = true
                              getPartnerDashboard(x.partnerId);
                              getOdPartnerById(x.partnerId)
                            }}
                          >View Dashboard </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )  
                  }
                </Grid>
              </Segment>
            </Form>
          )}>
        </FinalForm>
        {
          state.isChart === true &&
          <Segment>
            <Grid columns={2} >
              <Grid.Column >
                <Item.Group link>
                  <Item>
                    <Item.Image height='92px' size='tiny' src={odPartnerDetail?.organizationLogo} />
                    <Item.Content>
                      <Item.Header>{odPartnerDetail?.organizationName}</Item.Header>
                      <Item.Meta>CEO: {odPartnerDetail?.ceoFirstName} {odPartnerDetail?.ceoLastName}</Item.Meta>
                      <Item.Description>Email: {odPartnerDetail?.officialEmailId} </Item.Description>
                      <Item.Extra> Mobile No: {odPartnerDetail?.mobileContactNumber}</Item.Extra>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
              <Grid.Column  >
                <Item>
                  <br />
                  <Item.Content>
                    <Item.Header>State: {odPartnerDetail?.state}</Item.Header>
                    <Item.Meta>Postal code: {odPartnerDetail?.pincode}</Item.Meta>
                    <Item.Description>Sector: {odPartnerDetail?.sectorName}</Item.Description>
                  </Item.Content>
                </Item>
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Column width={9}>
                <br />
                <br />
                <br />
                <br />
                {partnerdashboard !== undefined ? (
                  <ODComplianceChart data={partnerdashboard?.pointPercentage} />
                ) : (<div></div>)}   <br />
                <br />
                <br />
              </Grid.Column>
              <Grid.Column width={11}>
                <br />
                {/* <h5 style={{textAlign: 'center',fontSize: 20 , fontWeight: 'bold', fontStyle: 'Italic'}}>Area</h5> */}
                {partnerdashboard !== undefined ? (
                  <ODArea areagraph={JSON.stringify(partnerdashboard)} />
                ) : (<div></div>)}
                <br />
                <br />
              </Grid.Column>
            </Grid>
            <Card fluid color='blue'>
              <br />
              <Item>
                <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>About {odPartnerDetail?.organizationName} </h5>
                <Item.Content>
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Vision statement-  {odPartnerDetail?.visionStatement}</Item.Header>
                  <br />
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Mission Statement -  {odPartnerDetail?.missionStatement}</Item.Header>
                  <br />
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Faith Statement -  {odPartnerDetail?.faithStatement}</Item.Header>
                  <br />
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Core Values -  {odPartnerDetail?.coreValues}</Item.Header>
                  <br />
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Major Activities -  {odPartnerDetail?.majorActivities}</Item.Header>
                  <br />
                  <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Objective Of Organization -  {odPartnerDetail?.objectiveOfOrganization}</Item.Header>
                  <br />
                </Item.Content>
              </Item>
            </Card>

            <br />
            <br />
            <Card fluid color='blue'>
              <br />
              <Item>
                <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>About {odPartnerDetail?.organizationName} Leadership </h5>
                <Item.Content>
                  <Grid>
                    <Grid.Column width={8}>
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Founder Name-  {odPartnerDetail?.founderTitle} {odPartnerDetail?.founderFirstName} {odPartnerDetail?.founderLastName} </Item.Header>
                      <br />
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Email ID -  {odPartnerDetail?.founderEmailId}</Item.Header>
                      <br />
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Mobile No. -  {odPartnerDetail?.founderMobile}</Item.Header>
                      <br />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>CEO Name -  {odPartnerDetail?.ceoTitle} {odPartnerDetail?.ceoFirstName} {odPartnerDetail?.ceoLastName} </Item.Header>
                      <br />
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Email ID -  {odPartnerDetail?.ceoEmailId}</Item.Header>
                      <br />
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Mobile No. -  {odPartnerDetail?.ceoMobile}</Item.Header>
                      <br />
                      <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>No. Of Board Of Trustee -  {odPartnerDetail?.numberOfCommiteeMember}</Item.Header>
                    </Grid.Column>
                  </Grid>
                </Item.Content>
              </Item>
            </Card>
            <br />
            <br />
            <br />
            <br />

          </Segment>
        }
      </div>
    </Container>
  );
}
export default observer(FederationDashboard);
