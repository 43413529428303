import React, { Fragment } from 'react'
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Grid, GridColumn } from 'semantic-ui-react';
import { DateTimePicker } from 'react-widgets';

interface IProps
  extends FieldRenderProps<Date, HTMLElement>,
  FormFieldProps { }

const DateInput: React.FC<IProps> = ({
  input,
  width,
  placeholder,
  label,
  date = false,
  time = false,
  hidden = false,
  readOnly = false,
  allowFutureDate = false,
  meta: { touched, error },
  id,
  ...rest
}) => {
  let updatedDate: Date | undefined = undefined;

  if (input.value) {
    let dateString = input.value as any as string;
    try {
      let index = dateString.indexOf('T');
      if (index > 0)
        updatedDate = new Date(dateString.substring(0, index))
    }
    catch (ex) { }
  }
  return (

    <Fragment>
      <Grid columns={label ? 2 : 1} verticalAlign='middle'>
        {label &&
          <GridColumn width={5}>
            <Form.Field hidden={hidden}>
              <label className='fieldLabel'>{label}</label>
            </Form.Field>
          </GridColumn>
        }

        <GridColumn width={label ? 11 : 8} >
          <Form.Field error={touched && !!error} width={width} hidden={hidden}>
            <DateTimePicker
              placeholder={placeholder}
              value={updatedDate ? updatedDate : input.value || null}
              onChange={input.onChange}
              onBlur={input.onBlur}
              onKeyDown={(e) => e.preventDefault()}
              date={date}
              time={time}
              format='dd-MM-yyyy'
              id={id !== undefined ? id.toString() : undefined}
              {...rest}
              max={allowFutureDate ? undefined : new Date()}
              readOnly={readOnly}
            />
          </Form.Field>

          {touched && error && (
            <Label basic color='red'>
              {error}
            </Label>
          )}
        </GridColumn>
      </Grid>
    </Fragment >
  )
}

export default DateInput