export enum VerificationStatus {
    Pending = "Pending", Accepted = "Accepted", Rejected = "Rejected"
}
export enum UserRole {
    ODPartner="ODPartner", Federation="Federation", DonorOrganisation="DonorOrganisation", DonorIndividual="DonorIndividual", Administrator="Administrator"
}
export enum MessageType
{
PreLogin   = 1,
PartnerMessage=2,
EndorsedMessage=3,
DonorInterestmessage=4,
}