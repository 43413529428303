export interface IFederationRegisterValues {
  OrganizationName: string;
  YearOfInception: string;
  RegisteredAddress: string;
  CommunicationAddress: string;
  State: string;
  Pincode: string;
  OfficialEmailId: string;
  Password: string;
  OfficeContactumber: string;
  MobileContactNumber: string;
  WebsiteAddress: string;
  SectorId: string;
  SectorOther: string;
  WhatsappNumber: string;
  FacebookID: string;
  TwitterID: string;
  OtherMediaLink: string;
  OrganizationLogoFile: string;
  ContactId: number;
  ContactTitle: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactMobile: string;
  ContactEmailId: string;
  ContactDesignation: string;
  CEOTitle: string;
  CEOFirstName: string;
  CEOLastName: string;
  CEOMobile: string;
  CEOEmailId: string;
  CEODesignation: string;
  CEOPhoto: string;
  TrusteeorManagementProfile: string;
  FederationContactJson: FederationContactJson[];
}

export class FederationRegisterValues implements IFederationRegisterValues {
  FederationId: number = 0;
  OrganizationName: string = "";
  YearOfInception: string = "";
  RegisteredAddress: string = "";
  CommunicationAddress: string = "";
  CEOPhoto: string = "";
  ContactId: number = 0;
  ContactTitle: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactMobile: string = "";
  ContactEmailId: string = "";
  ContactDesignation: string = "";
  State: string = "";
  Pincode: string = "";
  OfficialEmailId: string = "";
  Password: string = "";
  OfficeContactumber: string = "";
  MobileContactNumber: string = "";
  WebsiteAddress: string = "";
  SectorId: string = "";
  SectorOther: string = "";
  WhatsappNumber: string = "";
  FacebookID: string = "";
  TwitterID: string = "";
  OtherMediaLink: string = "";
  OrganizationLogoFile: string = "";
  CEOTitle: string = "";
  CEOFirstName: string = "";
  CEOLastName: string = "";
  CEOMobile: string = "";
  CEOEmailId: string = "";
  CEODesignation: string = "";
  TrusteeorManagementProfile: string = "";
  FederationContactJson: FederationContactJson[] = [];
  constructor(init?: FederationRegisterValues) {
    Object.assign(this, init);
  }
}

export interface IFederationContactDetail {
  ContactTitle: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactMobile: string;
  ContactEmailId: string;
  ContactDesignation: string;
  // Organization: string;
}

export class FederationContactJson implements IFederationContactDetail {
  ContactTitle: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactMobile: string = "";
  ContactEmailId: string = "";
  ContactDesignation: string = "";
}

export interface IFederationRegister {
  fedarationBasicDetailId: number;
  organizationName: string;
  yearOfInception: string;
  registeredAddress: string;
  communicationAddress: string;
  partnerKey: string;
  otherMediaLink: string;
  organizationLogoFile: string;
  ceoPhoto: string;
  trusteeorManagementProfile: string;
  sectorName: string;
  pincode: string;
  state: string;
  officialEmailId: string;
  password: string;
  sectorOther: string;
  whatsappNumber: string;
  facebookID: string;
  twitterID: string;
  ceoTitle: string;
  ceoFirstName: string;
  contactId: number;
  contactTitle: string;
  contactFirstName: string;
  contactLastName: string;
  contactMobile: string;
  contactEmailId: string;
  contactDesignation: string;
  ceoLastName: string;
  ceoMobile: string;
  ceoEmailId: string;
  ceoDesignation: string;
  verificationStatus: string;
}

export interface IFederationApproveOrReject {
  FedarationBasicDetailId?: number | undefined;
  IsApproved: boolean;
  VerifiedBy: string;
  VerifiedComment: string;
}

export class FederationApproveOrReject implements IFederationApproveOrReject {
  FedarationBasicDetailId?: number = undefined;
  IsApproved: boolean = false;
  VerifiedBy: string = "";
  VerifiedComment: string = "";

  constructor(init?: IFederationApproveOrReject) {
    Object.assign(this, init);
  }
}
export interface IPartnerEndorse {
  partnerId: number;
  partnerName: string;
  partnerKey: string;
  ceoName: string;
  state: string;
}
export interface IPartnerEndorsed {
  partnerId: number;
  partnerName: string;
  partnerKey: string;
  ceoName: string;
  state: string;
}
export interface IAcceptPartnerEndorse {
  partnerId: number;
  partnerName: string; //for toast message
  federationId: number;
  federationName: string; // for toast message
  isAccept: boolean;
  reasonForReject: string;
  contactId?: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
  organisationName?: string;
  date: string;
  senderId?: number;
  sender?: string;
  receiverId?: number;
  receiver?: string;
  messageType: number;
  isRead: boolean;
}

export interface IFederationName {
  fedarationBasicDetailId: number;
  federationKey: string;
  organizationName: string;
}
