import React, { Fragment, useContext, useState } from 'react';
import {  Button, Form, Table,Image, Container, Label } from 'semantic-ui-react';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { LoadingComponent } from '../../../../app/layout/LoadingComponent';
import FedApproveOrReject from './FedApproveOrReject';
import { FederationApproveOrReject } from '../../../../app/models/federation';
import { observer } from 'mobx-react-lite';
import TextAreaInput from '../../../../app/common/form/TextAreaInput';
import { Field, Form as FinalForm } from 'react-final-form'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

const FederationRegistrationApproval: React.FC<{ hideSearch?: boolean }> = ({ hideSearch }) => {
  const rootStore = useContext(RootStoreContext);
  const {  federationDetail, loadingFederationDetail,federationApprove } = rootStore.federationStore;
  const { isPending} = rootStore.dashboardStore;
  const [ selectedValue,setComment] = useState(" ");
  const { openModal } = rootStore.modalStore;
  const [state] = React.useState({  
    open : false
 });
  
    const open= state;
    const close = !state;

    let approve = new FederationApproveOrReject()
    approve.FedarationBasicDetailId = federationDetail?.fedarationBasicDetailId;
    approve.VerifiedComment = selectedValue;
    approve.IsApproved = true;

    let reject = new FederationApproveOrReject()
    reject.FedarationBasicDetailId = federationDetail?.fedarationBasicDetailId;
    reject.VerifiedComment = selectedValue;
    reject.IsApproved = false;


    return (
         <Container>
           <Table celled> 
           {
                    loadingFederationDetail &&
                    <LoadingComponent content="Loading Basic Information and Leadership Team Profile" />
           }
          <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic' }}> Federation</Table.HeaderCell>
                             {
                               federationDetail?.verificationStatus !== "Pending" &&
                               <Label as='a' color='blue' ribbon= 'right'> 
                               {federationDetail?.verificationStatus === 'Accepted' ? 'Approved' : 'Rejected'}
                                </Label>                          
                             } 
                        </Table.Row>
          </Table.Header>
          <Table.Body>
                  <Table.Row>
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Basic Information</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                      <Table.Cell  style={{  margin: '10px'}} textAlign="left">Organisation Name </Table.Cell>
                      <Table.Cell  style={{ margin: '10px' }}>{federationDetail?.organizationName}</Table.Cell>
                      <Table.Cell  style={{ margin: '10px' }} textAlign="left">Year of Inception</Table.Cell>
                      <Table.Cell  style={{ margin: '10px' }}>{federationDetail?.yearOfInception}</Table.Cell>
                 </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Registered Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.registeredAddress}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Communication Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.communicationAddress}</Table.Cell>
                 </Table.Row>
                
                 <Table.Row>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> State </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.state}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Pincode</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.pincode}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Official Email ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.officialEmailId}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Office Contact Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.officeContactumber}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mobile Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.mobileContactNumber}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Website Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.websiteAddress}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Whatsapp Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.whatsappNumber}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Facebook ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.facebookID}</Table.Cell>
                  </Table.Row >
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Twitter ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.twitterID}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Other Media Link</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.otherMediaLink}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Sector</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.sectorName}</Table.Cell> 
                      
                  </Table.Row>
                  
                  {
                    federationDetail?.sectorName === "Others" ?
                    (<Table.Row >
                    <Table.Cell style={{ margin: '10px' }} textAlign="left"> Others</Table.Cell>
                    <Table.Cell style={{ margin: '10px' }}>{federationDetail?.sectorOther}</Table.Cell>
                    </Table.Row >) : ("")
                  }
               

                </Table.Body>
                </Table>
                <Table>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organisation Logo</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}> <Image src={federationDetail?.organizationLogoFile} style={{ width: '23vh' }} /> </Table.Cell>
                  </Table.Row>
                  </Table.Body>
                </Table>
                <Table celled>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> CEO /Executive Director / Managing Trustee</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Title</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoTitle}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO First Name</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoFirstName}</Table.Cell>
                 </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Last Name</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoLastName}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">CEO Mobile</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoMobile}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO EmailId</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoEmailId}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Designation</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{federationDetail?.ceoDesignation}</Table.Cell>
                  </Table.Row>
                </Table.Body>
                </Table>
                
                <Table>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Photo</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}> <Image src={federationDetail?.ceoPhoto} style={{ width: '23vh' }} /> </Table.Cell>
                  </Table.Row>
                  </Table.Body>
                </Table>
                

                <Table celled>
                    <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Trustee or Management Profile</Table.Cell>
                      {
                      <Table.Cell style={{ margin: '10px' }}>
                        {
                                        federationDetail?.trusteeorManagementProfile ?
                                        <a href={window.location.origin+"/" + federationDetail?.trusteeorManagementProfile} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")
                                    }
                      </Table.Cell>
                     }
                  </Table.Row>
                  </Table.Body>
                  </Table>
                <Table celled>
                <Table.Body >
                  <Table.Row >
                      <Table.Cell colspan='6' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Contact person for Communication </Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell> Contact Title </Table.Cell>
                      <Table.Cell> Contact First Name </Table.Cell>
                      <Table.Cell> Contact Last Name </Table.Cell>
                      <Table.Cell> Contact Mobile </Table.Cell>
                      <Table.Cell> Contact EmailId </Table.Cell>
                      <Table.Cell> Contact Designation </Table.Cell>
                  </Table.Row>
                  {
                      federationDetail?.federationContactViewModel && federationDetail?.federationContactViewModel.map(
                          (fc) => 
                          <Table.Row key = {fc.federationContactPersonId}> 
                              <Table.Cell>{fc.contactTitle}</Table.Cell>
                              <Table.Cell>{fc.contactFirstName}</Table.Cell>
                              <Table.Cell>{fc.contactLastName}</Table.Cell>
                              <Table.Cell>{fc.contactMobile}</Table.Cell>
                              <Table.Cell>{fc.contactEmailId}</Table.Cell>
                              <Table.Cell>{fc.contactDesignation}</Table.Cell>
                          </Table.Row>      
                      )
                  } 

          </Table.Body> 
          </Table> 
          
          {
              isPending ? (
                  <Fragment>
        <FinalForm
             onSubmit={(values: FederationApproveOrReject) => 
                federationApprove(values)
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Field
                                        name='VerifiedComment'
                                        placeholder="Enter Comment"
                                        rows={3}
                                        width={7}
                                        component={TextAreaInput}
                                        onChange={e => setComment(e.target.value)
                                        }
                                    />
                </Form>
                 )}>
        </FinalForm>  
          <br/>
          <br/>
          
          <Button style={{ marginLeft: '450px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Federation',<FedApproveOrReject approveOrRejectFederation={approve} 
                        />)
                      }}
                    >Approve</Button>
                     <Button style={{ marginLeft: '30px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Federation',<FedApproveOrReject approveOrRejectFederation={reject} 
                        />)
                      }}
                    >Reject</Button>

        {/* <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Approve</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectFederation={approve}   
                    />
                }
        </Segment>
      </TransitionablePortal>

 <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Reject</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectFederation={reject}   
                    />
                }
        </Segment>
      </TransitionablePortal> */}
         </Fragment>
              ) : null
          }              
          
</Container>
         
    );
}

export default observer(FederationRegistrationApproval);