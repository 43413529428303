import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IPlanFormValues, IFeature, IPlanDisable } from "../models/plan"; 

export default class PlanStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable planList: IPlanFormValues[] = [];
  @observable loadingPlan = false;
  @observable selectedPlanDetail: IPlanFormValues | undefined = undefined;
  @observable Features: IFeature[] = [];
  // @observable areaDropdownValues: DropdownItemProps[] = [];
  //@observable areaMasterDropdownValues: DropdownItemProps[] = [];

  @action getAllPlans = async () => {
    this.loadingPlan = true;
    try {
      const planList = await agent.Plan.list();
      console.log(planList);
      runInAction(() => {
        
        this.planList = planList;
        console.log(this.planList);
        this.loadingPlan = false;
      });
    } catch {
      runInAction(() => {
        this.loadingPlan = false;
      });
    }
  };
  @action getById = async (planId: number)  => {
    const detail = await agent.Plan.getById(planId);
    console.log(detail);

    runInAction(() => {
      this.selectedPlanDetail = detail;
      this.selectedPlanDetail.planFeatureViewModel=detail.planFeatureViewModel;
      console.log(this.selectedPlanDetail.organisationType);
      console.log(this.selectedPlanDetail);
    });
  };
 
  @action getPlanFromId = (planId: number): IPlanFormValues =>{
    console.log(this.planList);
    let index = this.planList.findIndex(b => b.planId === planId);
    console.log(index)
    return this.planList[index];
}
  @action createPlan = async (plan: IPlanFormValues) => {
    console.log(plan);
    plan.planFeatureViewModel = this.Features;
    const topicResponse = await agent.Plan.create(plan);
    console.log(topicResponse);
    runInAction(() => {   
       plan.planId = topicResponse;
      plan.isActive = true;
      this.planList.push(plan);
      console.log(this.planList); 
      toast.info("Plan " + plan.organisationType + " successfully created");
      this.rootStore.modalStore.closeModal();
    });
  };
  @action addFeature = (values: IFeature) => {
    if (values.feature) this.Features = [...this.Features, values];
    console.log(values);
    console.log(this.Features);
  };

  @action upsertPlan = async (plan: IPlanFormValues) => {
    if (plan.planId === undefined || plan.planId === 0)
      await this.createPlan(plan);
    else await this.editPlans(plan);
  };

  @action editPlans = async (updatedPlan: IPlanFormValues) => {
    await agent.Plan.update(updatedPlan);
    runInAction(() => {
      let index = this.planList.findIndex(
        (plan) => plan.planId === updatedPlan.planId
      );
      
      Object.assign(this.planList[index], updatedPlan);

      
      toast.info(
        "Plan " + updatedPlan.organisationType + " successfully updated"
      );
      this.rootStore.modalStore.closeModal();
    });
  };
  @action DisablePlan = async (selectedPlan: IPlanFormValues) => {
    console.log(selectedPlan.isActive);
    if (selectedPlan.planId) {
      let disablePlan: IPlanDisable = {
        planId: selectedPlan.planId,
        isActive: !selectedPlan.isActive,
      };
      console.log(disablePlan.isActive);
      await agent.Plan.disable(disablePlan);
      runInAction(() => {
        let index = this.planList.findIndex(
          (b) => b.planId === selectedPlan.planId
        );
        //  Object.assign(this.planList[index], selectedPlan);
        this.planList[index].isActive = disablePlan.isActive;

        // this.departments[index].isDisabled = disableDepartment.isDisabled;

        if (selectedPlan.isActive === true) {
          toast.info(
            "Plan " + selectedPlan.planType + " successfully Disabled"
          );
        } else {
          toast.info("Plan " + selectedPlan.planType + " successfully Enabled");
        }

        this.rootStore.modalStore.closeModal();
      });
    }
  };

  /*  @action inactivePlan = async (selectedPlan: IPlanFormValues) => {
      console.log(selectedPlan.isActive);
      if(selectedPlan.isActive===false){
        selectedPlan.isActive = true;
      }else{
        selectedPlan.isActive=false;
      }
      console.log(selectedPlan.isActive)
      
      await agent.Plan.update(selectedPlan);   
      console.log(selectedPlan.isActive)
      runInAction(() => {
         let index = this.planList.findIndex(
          (b) => b.planId === selectedPlan.planId
        );
       
        Object.assign(this.planList[index], selectedPlan);
        
       
        toast.info("Plan with planId" + selectedPlan.planId + " had successfully "+selectedPlan.isActive?'Enabled':'Disabled');
        this.rootStore.modalStore.closeModal();
      });
     
    };

 */
}
