import React, { useContext } from 'react'
import { Form, Grid, Header, Image, Message, Button } from 'semantic-ui-react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Form as FinalForm, Field } from 'react-final-form'
import { RootStoreContext } from '../../app/stores/rootStore'
import { ILoginFormValues } from '../../app/models/user'
import { FORM_ERROR } from 'final-form'
import { combineValidators, isRequired, } from 'revalidate'
import ErrorMessage from '../../app/common/form/ErrorMessage'
import TextInput from '../../app/common/form/TextInput'
import Logo from './../../assets/images/cimlogoweb.png'
import { Fragment } from 'react'
import HeaderThree from '../../component/header/HeaderThree'

const validate = combineValidators({
  userName: isRequired('User Name'),
  password: isRequired('Password')
})


const LoginForm: React.FC<RouteComponentProps> = ({ history }) => {
  const rootStore = useContext(RootStoreContext);
  const { login } = rootStore.authStore;


  return (
    <Fragment>
      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />

      <Grid textAlign='center' style={{ height: '80vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='blue' textAlign='center' style={{ fontStyle: 'italic' }}>
            <Image src={Logo} style={{ width: '23vh' }} />
            <br />
            Accountability & Management Standards

        </Header>
          <FinalForm
            onSubmit={(values: ILoginFormValues) =>
              login(values).catch(error => ({
                [FORM_ERROR]: error
              }))
            }
            validate={validate}
            render={({
              handleSubmit,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit
            }) => (
              <Form onSubmit={handleSubmit} error>
                <Field name='userName'
                  component={TextInput}
                  placeholder='Email Id' />
                <Field
                  name='password'
                  component={TextInput}
                  placeholder='Password'
                  type='password'
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage
                    error={submitError}
                    frontEndError={submitError}
                  />
                )}
                <Button
                  //as={Link} to='/pendingdash'
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={submitting}
                  color='facebook'
                  content='Login'
                  fluid
                />
              </Form>
            )}
          />
          <Message>
            Forgot Password?
          <Link to='/dod-forgotPassword' style={{ textDecoration: 'underline', color: 'blue' }}> Click Here</Link>
          </Message>
        </Grid.Column>
      </Grid >
    </Fragment>
  )
}
export default LoginForm
