import React, {useEffect, useState} from "react";
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Grid, GridColumn, Segment } from 'semantic-ui-react';


interface IProps
  extends FieldRenderProps<File, HTMLElement>,
  FormFieldProps { }

const FileUpload: React.FC<FieldRenderProps<string, HTMLElement>> = ({
  input,
  width,
  type,
  placeholder,
  label,
  hidden,
  errorstyle,
  uploadedFile,
  style,
  readOnly = false,
  required = false,
  onChange = undefined,
  meta: { touched, error }
}) => {

  
  const [stateError] = React.useState({
    touched: false,
  });
  const validation = () => {
    stateError.touched = true;
  }

  

  
  //console.log(file);
  async function createFile(){


    let response = await fetch(uploadedFile);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };

    let trimming = uploadedFile.toString();
    let stringTrim= trimming.split("-")
    let file = new File([data], stringTrim[5]
      , metadata);

    console.log(stringTrim[5]);
    return file;
    
  }

    useEffect (() => {
          if(uploadedFile){
          let savedFile = createFile().then((file1) => 
           {
             input.onChange(file1)
             });

        }
    }, [])
    
  
    console.log(input.onChange.name)
  return (
    <Grid columns={2} verticalAlign='middle'>
      {label &&
        <GridColumn width={8}>
          <Form.Field hidden={hidden}>
            <label 
            //className='fieldLabel'
            >{label}
              {required === true && <span className="mandatory-field">*</span>}
            </label>
          </Form.Field>
        </GridColumn>
      }
      <GridColumn width={label ? 8 : 16} >
        <Form.Field 
        //error={touched && !!error} 
        type={type} width={width} hidden={hidden} >
          <Segment style={style}>
            <input
              // {...input}
              placeholder={placeholder}
              readOnly={readOnly}
              type="file"
              //value = {}
              
              
              //onBlur = {validation}
              
              onChange={
                (event) => {
                  //if (event?.target?.files)
                    
                    {event?.target?.files && 
                    input.onChange(event?.target?.files[0])
                   // {

                      event?.target?.files &&
                      console.log(event?.target?.files[0])

                   // }
                   // state.storedFile[0] = event?.target?.files
                  if (onChange)
                    onChange(event)
                }}
              }
            />
            {
              uploadedFile && (
                <a href={uploadedFile} target="_blank" rel="noopener noreferrer"> {uploadedFile.split("/").pop().split("-").pop()}</a>
              )
            }


            {touched && error && (
              <Label basic color='red'>
                {error}
              </Label>
            )}
          </Segment>
          

        </Form.Field>
      </GridColumn>
    </Grid>
  );
};

export default FileUpload;