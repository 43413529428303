import React, { useContext, Fragment } from "react";

import { Button, Form, Icon } from "semantic-ui-react";
import { Form as FinalForm } from 'react-final-form'
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IPlanFormValues } from "../../../app/models/plan";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";

interface IProps {
  disableEnablePlan: IPlanFormValues,
}
const DisablePlan: React.FC<IProps> = ({ disableEnablePlan }) => {
  const rootStore = useContext(RootStoreContext);
  const { DisablePlan } = rootStore.planStore;

  return (

    <Fragment>
      <FinalForm
        initialValues={disableEnablePlan}
        onSubmit={(values: IPlanFormValues) =>
          DisablePlan(values)
            .catch(error => ({ [FORM_ERROR]: error }))
        }
        render={({ handleSubmit, submitting, submitError }) => (
          <Form onSubmit={handleSubmit} error>
            Are you sure want to {disableEnablePlan?.isActive ? 'disable' : 'enable'}
            Plan {disableEnablePlan?.planType}??
            <br />
            <br />
            {submitError &&
              (
                <ErrorMessage error={submitError} />
              )}
            <Button
              loading={submitting}
              color='blue' style={{ marginLeft: '130px' }}
            >
              <Icon name={disableEnablePlan.isActive ? 'check' : 'delete'} />
              {disableEnablePlan?.isActive ? 'Disable' : 'Enable'}
            </Button>

            <br /></Form>

        )} />
    </Fragment>


  );
}

export default observer(DisablePlan);
