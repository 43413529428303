import React, { Fragment, useContext } from 'react';
import {  Button, Container, Form, Label, Table } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { LoadingComponent } from '../../../../app/layout/LoadingComponent';
import DonorIndiApproveOrReject from './DonorIndiApproveOrReject';
import { DonorIndApproveOrReject } from '../../../../app/models/donorIndividual';
import TextAreaInput from '../../../../app/common/form/TextAreaInput';
import { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import moment from 'moment';

const DonorIndRegistrationApproval: React.FC<{ hideSearch?: boolean }> = ({ hideSearch }) => {
  const rootStore = useContext(RootStoreContext);
  const {  loadingDonorIndividualDetail,donorIndividualDetail,donorIndApproveOrReject } = rootStore.donorIndividualStore;
  const { openModal } = rootStore.modalStore;   
  const [ selectedValue,setComment] = useState(" ");
  const { isPending} = rootStore.dashboardStore;

  const [state] = React.useState({  
    open : false
 });
   
    const open= state;
    const close = !state;

    let approve = new DonorIndApproveOrReject()
    approve.DonorIndividualBasicDetailId = donorIndividualDetail?.donorIndividualBasicDetailId;
    approve.VerifiedComment = selectedValue;
    approve.IsApproved = true;

    let reject = new DonorIndApproveOrReject()
    reject.DonorIndividualBasicDetailId = donorIndividualDetail?.donorIndividualBasicDetailId;
    reject.VerifiedComment= selectedValue;
    reject.IsApproved = false;
    
    return (
        <div>
        <Container>
          {
                    loadingDonorIndividualDetail &&
                    <LoadingComponent content="Loading Basic Information Preference and Reference" />
          }
          <Table celled >
          <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}>Donor Individual </Table.HeaderCell>
                            {
                               donorIndividualDetail?.verificationStatus !== "Pending" &&
                               <Label as='a' color='blue' ribbon= 'right'> 
                               {donorIndividualDetail?.verificationStatus === 'Accepted' ? 'Approved' : 'Rejected'}
                                </Label>                           }
                        
                        </Table.Row>
          </Table.Header>
          <Table.Body>
                 <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Basic Information</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Name </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.name}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Date of Birth</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{moment(donorIndividualDetail?.dateOfBirth).format('DD MMM YYYY')}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Gender</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.gender}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Email </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.emailId}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mobile Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.mobileNo}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.address}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> State</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.state}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Pincode</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.pincode}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organization</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.organisation}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Nationality</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.nationality}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Website Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.websiteAddress}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Whatsapp Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.whatsappNumber}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Facebook ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.facebookID}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Twitter ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.twitterID}</Table.Cell>
                  </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Other Media Link</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.otherMediaLink}</Table.Cell>
                  </Table.Row>
                </Table.Body>
                </Table>
                
                <Table celled>
                    <Table.Body>
                  <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Preference</Table.Cell>
                  </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">My Giving Preference </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myGivingPreference}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Mode of Giving Preference</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.modeOfGivingPreference}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Giving Frequency</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.givingFrequence}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Church Domination</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedChurchDomination}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred State </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedState}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred District </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedDistrict}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Community </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedCommunity}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Tribe </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedTribe}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Language </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedLanguage}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">My Preferred Pincode </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.preferedPincode}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> I am Representing </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.iamPresenting}</Table.Cell>                      
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred work</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.myPreferedWork}</Table.Cell>
                      
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Track Record </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.givingTrackRecord ?
                                        <a href={window.location.origin+"/" + donorIndividualDetail?.givingTrackRecord} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Proof of total contribution </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{
                              donorIndividualDetail?.proofofTotalContribution ?
                                        <a href={window.location.origin+"/" + donorIndividualDetail?.proofofTotalContribution} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")
                                }</Table.Cell>
                   </Table.Row>
                   {
                       donorIndividualDetail?.myPreferedWork === "Others" ?
                  (<Table.Row >   
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Others</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorIndividualDetail?.others}</Table.Cell>
                  </Table.Row>) : ("")
                  }
                  </Table.Body>
                  </Table>

                  <Table celled>
                    <Table.Body>
                  <Table.Row >
                      <Table.Cell colspan='7' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Reference </Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell> Title </Table.Cell>
                      <Table.Cell> First Name </Table.Cell>
                      <Table.Cell> Last Name </Table.Cell>
                      <Table.Cell> Mobile </Table.Cell>
                      <Table.Cell> EmailId </Table.Cell>
                      <Table.Cell> Designation </Table.Cell>
                      <Table.Cell>Organization</Table.Cell>
                  </Table.Row>
                  
                     
                          <Table.Row key={donorIndividualDetail?.donorIndividualReferenceId}> 
                              <Table.Cell>{donorIndividualDetail?.refTitle2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refFirstName2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refLastName2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refMobile2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refEmail2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refDesignation2}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refOrganization2}</Table.Cell>
                          </Table.Row>      
                          <Table.Row key={donorIndividualDetail?.donorIndividualReferenceId}> 
                              <Table.Cell>{donorIndividualDetail?.refTitle1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refFirstName1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refLastName1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refMobile1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refEmail1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refDesignation1}</Table.Cell>
                              <Table.Cell>{donorIndividualDetail?.refOrganization1}</Table.Cell>
                          </Table.Row>      
                      
          </Table.Body>   
          </Table>
          {
              isPending ? (
                <Fragment>
                        <FinalForm
             onSubmit={(values: DonorIndApproveOrReject) => 
                donorIndApproveOrReject(values)
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Field
                                        name='VerifiedComment'
                                        placeholder="Enter Comment"
                                        rows={3}
                                        width={7}
                                        component={TextAreaInput}
                                        onChange={e => setComment(e.target.value)
                                        }
                                    />
                </Form>
                 )}>
        </FinalForm>  

          <br/>
          <br/>
          <Button style={{ marginLeft: '450px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Donor Individual',<DonorIndiApproveOrReject approveOrRejectDonorInd={approve}     
                        />)
                      }}
                    >Approve</Button>
                     <Button style={{ marginLeft: '30px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Donor Individual',<DonorIndiApproveOrReject approveOrRejectDonorInd={reject}   
                        />)
                      }}
                    >Reject</Button>
{/* <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Approve</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectDonorInd={approve}   
                    />
                }
        </Segment>
      </TransitionablePortal>

 <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Reject</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectDonorInd={reject}   
                    />
                }
        </Segment>
      </TransitionablePortal> */}
      


                </Fragment>
              ) : null
          }               
          
          <br/>
          <br/>
         
          </Container>
          </div>
    );
}

export default observer(DonorIndRegistrationApproval);