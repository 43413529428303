import { RootStore } from "./rootStore";
import { observable, action, reaction } from "mobx";

export default class CommonStore {
                 rootStore: RootStore;

                 constructor(rootStore: RootStore) {
                   this.rootStore = rootStore;

                   reaction(
                     () => this.token,
                     (token) => {
                       if (token) {
                         window.localStorage.setItem("jwt", token);
                       } else {
                         window.localStorage.removeItem("jwt");
                       }
                     }
                   );
                   reaction(
                     () => this.tokenExpiry,
                     (tokenExpiry) => {
                       if (tokenExpiry) {
                         window.localStorage.setItem("jwtExpiry", tokenExpiry);
                       } else {
                         window.localStorage.removeItem("jwtExpiry");
                       }
                     }
                   );

                   reaction(
                     () => this.refreshToken,
                     (refreshToken) => {
                       if (refreshToken) {
                         window.localStorage.setItem(
                           "refreshToken",
                           refreshToken
                         );
                       } else {
                         window.localStorage.removeItem("refreshToken");
                       }
                     }
                   );

                   reaction(
                     () => this.userName,
                     (userName) => {
                       if (userName) {
                         window.localStorage.setItem("userName", userName);
                       } else {
                         window.localStorage.removeItem("userName");
                       }
                     }
                   );
                   reaction(
                     () => this.fullName,
                     (fullName) => {
                       if (fullName) {
                         window.localStorage.setItem("fullName", fullName);
                       } else {
                         window.localStorage.removeItem("fullName");
                       }
                     }
                   );
                   reaction(
                     () => this.registrationId,
                     (registrationId) => {
                       if (registrationId) {
                         window.localStorage.setItem(
                           "registrationId",
                           registrationId.toString()
                         );
                       } else {
                         window.localStorage.removeItem("registrationId");
                       }
                     }
                   );
                   reaction(
                     () => this.registrationKey,

                     (registrationKey) => {
                       if (registrationKey) {
                         window.localStorage.setItem(
                           "registrationKey",
                           registrationKey
                         );
                       } else {
                         window.localStorage.removeItem("registrationKey");
                       }
                     }
                   );
                   reaction(
                     () => this.role,
                     (role) => {
                       if (role) {
                         window.localStorage.setItem("role", role.toString());
                       } else {
                         window.localStorage.removeItem("role");
                       }
                     }
                   );
                   reaction(
                     () => this.organisation,
                     (organisation) => {
                       if (organisation) {
                         window.localStorage.setItem(
                           "organisation",
                           organisation.toString()
                         );
                       } else {
                         window.localStorage.removeItem("organisation");
                       }
                     }
                   );
                   reaction(
                     () => this.email,
                     (email) => {
                       if (email) {
                         window.localStorage.setItem("email", email.toString());
                       } else {
                         window.localStorage.removeItem("email");
                       }
                     }
                   );
                   reaction(
                     () => this.mobile,
                     (mobile) => {
                       if (mobile) {
                         window.localStorage.setItem(
                           "mobile",
                           mobile.toString()
                         );
                       } else {
                         window.localStorage.removeItem("mobile");
                       }
                     }
                   );
                   reaction(
                     () => this.logo,
                     (logo) => {
                       if (logo) {
                         window.localStorage.setItem("logo", logo);
                       } else {
                         window.localStorage.removeItem("logo");
                       }
                     }
                   );
                   reaction(
                     () => this.state,
                     (state) => {
                       if (state) {
                         window.localStorage.setItem("state", state);
                       } else {
                         window.localStorage.removeItem("state");
                       }
                     }
                   );
                   reaction(
                     () => this.sector,
                     (sector) => {
                       if (sector) {
                         window.localStorage.setItem("sector", sector);
                       } else {
                         window.localStorage.removeItem("sector");
                       }
                     }
                   );
                   reaction(
                     () => this.subSector,
                     (subSector) => {
                       if (subSector) {
                         window.localStorage.setItem("subSector", subSector);
                       } else {
                         window.localStorage.removeItem("subSector");
                       }
                     }
                   );
                   reaction(
                     () => this.pincode,
                     (pincode) => {
                       if (pincode) {
                         window.localStorage.setItem("pincode", pincode);
                       } else {
                         window.localStorage.removeItem("pincode");
                       }
                     }
                   );
                   reaction(
                     () => this.partnerId,
                     (partnerId) => {
                       if (partnerId) {
                         window.localStorage.setItem(
                           "partnerId",
                           partnerId.toString()
                         );
                       } else {
                         window.localStorage.removeItem("partnerId");
                       }
                     }
                   );
                   reaction(
                     () => this.federationId,
                     (federationId) => {
                       if (federationId) {
                         window.localStorage.setItem(
                           "federationId",
                           federationId.toString()
                         );
                       } else {
                         window.localStorage.removeItem("federationId");
                       }
                     }
                   );

                   reaction(
                     () => this.assessmentId,
                     (assessmentId) => {
                       if (assessmentId) {
                         window.localStorage.setItem(
                           "assessmentId",
                           assessmentId.toString()
                         );
                       } else {
                         window.localStorage.removeItem("assessmentId");
                       }
                     }
                   );
                 }

                 @observable token: string | null = window.localStorage.getItem(
                   "jwt"
                 );
                 @observable tokenExpiry:
                   | string
                   | null = window.localStorage.getItem("jwtExpiry");
                 @observable refreshToken:
                   | string
                   | null = window.localStorage.getItem("refreshToken");
                 @observable userName:
                   | string
                   | null = window.localStorage.getItem("userName");
                 @observable registrationId: number | 0 = Number(
                   window.localStorage.getItem("registrationId")
                 );
                 @observable registrationKey:
                   | string
                   | null = window.localStorage.getItem("registrationKey");
                 @observable role: string | null = window.localStorage.getItem(
                   "role"
                 );
                 @observable organisation:
                   | string
                   | null = window.localStorage.getItem("organisation");
                 @observable email: string | null = window.localStorage.getItem(
                   "email"
                 );
                 @observable mobile:
                   | string
                   | null = window.localStorage.getItem("mobile");
                 @observable logo: string | null = window.localStorage.getItem(
                   "logo"
                 );
                 @observable fullName:
                   | string
                   | null = window.localStorage.getItem("fullName");

                 @observable state: string | null = window.localStorage.getItem(
                   "state"
                 );
                 @observable sector:
                   | string
                   | null = window.localStorage.getItem("sector");
                 @observable subSector:
                   | string
                   | null = window.localStorage.getItem("subSector");
                 @observable pincode:
                   | string
                   | null = window.localStorage.getItem("pincode");
                 @observable partnerId: number | 0 = Number(
                   window.localStorage.getItem("partnerId")
                 );
                 @observable federationId: number | 0 = Number(
                   window.localStorage.getItem("partnerId")
                 );

                 @observable assessmentId: number | 0 = Number(
                   window.localStorage.getItem("assessmentId")
                 );
                 @observable appLoaded = false;
                 @observable chartLoaded = true;
                 // @observable partnerId = 0;
                 //  @observable assessmentId = 0;

                 //for odpartner report
                 @observable chart1:
                   | string
                   | null = window.localStorage.getItem("chart1");
                 @observable chart2:
                   | string
                   | null = window.localStorage.getItem("chart2");
                 @observable chart3:
                   | string
                   | null = window.localStorage.getItem("chart3");

                @observable emailRegExp = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")

                @observable passwordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")

                 @action setToken = (token: string | null) => {
                   this.token = token;
                 };

                 @action setTokenExpiry = (tokenExpiry: string | null) => {
                   this.tokenExpiry = tokenExpiry;
                 };

                 @action setRefreshToken = (refreshToken: string | null) => {
                   this.refreshToken = refreshToken;
                 };

                 @action setUserName = (userName: string | null) => {
                   this.userName = userName;
                 };
                 @action setRegistrationId = (registrationId: number) => {
                   this.registrationId = registrationId;
                 };
                 @action setRegistrationKey = (registrationKey: string) => {
                   this.registrationKey = registrationKey;
                 };
                 @action setRole = (role: string) => {
                   this.role = role;
                 };
                 @action setOrganisation = (organisation: string) => {
                   this.organisation = organisation;
                 };
                 @action setEmail = (email: string) => {
                   this.email = email;
                 };
                 @action setMobile = (mobile: string) => {
                   this.mobile = mobile;
                 };
                 @action setLogo = (logo: string) => {
                   this.logo = logo;
                 };
                 @action setFullName = (fullName: string) => {
                   this.fullName = fullName;
                 };
                 @action setState = (state: string) => {
                   this.state = state;
                 };
                 @action setSector = (sector: string) => {
                   this.sector = sector;
                 };
                 @action setSubsector = (subSector: string) => {
                   this.subSector = subSector;
                 };
                 @action setPincode = (pincode: string) => {
                   this.pincode = pincode;
                 };

                 @action setChart1 = async (chart1: string) => {
                   await (this.chart1 = chart1);
                 };

                 @action setChart2 = async (chart2: string) => {
                   await (this.chart2 = chart2);
                 };

                 @action setChart3 = async (chart3: string) => {
                   await (this.chart3 = chart3);
                 };

                 @action setAppLoaded = () => {
                   this.appLoaded = true;
                 };

                 @action setChartLoaded = async () => {
                   await (this.chartLoaded = false);
                 };
                 @action unsetChartLoaded = async () => {
                   await (this.chartLoaded = true);
                 };

                 @action setPartnerId = async (partnerId: number) => {
                   await (this.partnerId = partnerId);
                 };
                 @action setAssessmentId = async (id: number) => {
                   await (this.assessmentId = id);
                 };
               }