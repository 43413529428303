import React from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useContext } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";
import { ISubSectorFormValues } from "../../../../app/models/subSector";
import { observer } from "mobx-react-lite";

interface IProps {
  disableEnableSubSector: ISubSectorFormValues;
}

const DisableSubSector: React.FC<IProps> = ({ disableEnableSubSector }) => {
  const rootStore = useContext(RootStoreContext);
  const { disableSubSector } = rootStore.subSectorStore;
  return (
    <FinalForm
      initialValues={disableEnableSubSector}
      onSubmit={(values: ISubSectorFormValues) =>
        disableSubSector(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      render={({ handleSubmit, submitting, submitError }) => (
        <Form onSubmit={handleSubmit}>
       
            Do you really want to{" "}
            {disableEnableSubSector.isActive ? "disable" : "enable"} 
         <b>   {disableEnableSubSector.subSectorName}?{" "}</b>
         
          <br />
          <br />
          {submitError && <ErrorMessage error={submitError} />}

      
            <Button
              loading={submitting}
              color='blue' style={{ marginLeft: '130px'}}
            >
              <Icon
                name={disableEnableSubSector.isActive ? "delete" : "check"}
              />{" "}
              {disableEnableSubSector.isActive ? "Disable" : "Enable"}
            </Button>
 
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(DisableSubSector);
