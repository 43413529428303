import React, { Fragment, useContext, useState } from 'react';
import {  Button, Form, Table,Image, Container, Label} from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { LoadingComponent } from '../../../../app/layout/LoadingComponent'; 
import TextAreaInput from '../../../../app/common/form/TextAreaInput';
import { Field, Form as FinalForm } from 'react-final-form'
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import DonorOrgsApproveOrReject from './DonorOrgsApproveOrReject';
import { DonorOrgApproveOrReject } from '../../../../app/models/donorOrg';

const DonorOrgRegistrationApproval: React.FC<{ hideSearch?: boolean }> = ({hideSearch}) => {
  const rootStore = useContext(RootStoreContext);
  const {  loadingDonorDetail,donorDetail,donorOrgApproveOrReject } = rootStore.donorOrgStore;
  const { isPending} = rootStore.dashboardStore;
  const [ selectedValue,setComment] = useState(" ");
  const { openModal } = rootStore.modalStore; 

  const [state] = React.useState({  
    open : false
 });
  
    const open= state;
    const close = !state;

    let approve = new DonorOrgApproveOrReject();
                    approve.DonorOrganisationBasicDetailId = donorDetail?.donorOrgBasicDetailId;
                    approve.VerifiedComment = selectedValue;
                    approve.IsApproved = true;

    let reject = new DonorOrgApproveOrReject();
    reject.DonorOrganisationBasicDetailId = donorDetail?.donorOrgBasicDetailId;
    reject.VerifiedComment = selectedValue;
    reject.IsApproved = false;
    return (
        <div>
        <Container>
          {
                    loadingDonorDetail &&
                    <LoadingComponent content="Loading Basic Information Preference and Reference" />
          }
          <Table celled >
          <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}>Donor Organisation</Table.HeaderCell>
                            {
                               donorDetail?.verificationStatus !== "Pending" &&
                               <Label as='a' color='blue' ribbon= 'right'> 
                               {donorDetail?.verificationStatus === 'Accepted' ? 'Approved' : 'Rejected'}
                                </Label>                           }
                        </Table.Row>
          </Table.Header>
          <Table.Body>
                <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Basic Information</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Organisation Name </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.organizationName}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Year of Inception</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.yearOfInception}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">Registered Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.registeredAddress}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Communication Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.communicationAddress}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> State </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.state}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Pincode</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.pincode}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Official Email ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.officialEmailId}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Office Contact Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.officeContactumber}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mobile Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.mobileContactNumber}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Website Address</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.websiteAddress}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Whatsapp Number</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.whatsappNumber}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Facebook ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.facebookID}</Table.Cell>
                  </Table.Row>
                
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Twitter ID</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.twitterID}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Other Media Link</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.otherMediaLink}</Table.Cell>
                  </Table.Row>
                  </Table.Body>
                  </Table>

                 <Table celled>
                     <Table.Body>
                  <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Organisational Profile</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Vision Statement</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.visionStatement}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mission Statement</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.missionStatement}</Table.Cell>
                  </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Faith Statement</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.faithStatement}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Core Values</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.coreValues}</Table.Cell>
                  </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Objective Of Organization</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.objectiveOfOrganization}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Major Activities</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.majorActivities}</Table.Cell>
                  </Table.Row>
                  </Table.Body>
                  </Table>
                 
                 <Table celled>
                     <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organisation Brochure</Table.Cell>
                      {
                      <Table.Cell style={{ margin: '10px' }}>
                          {
                              donorDetail?.organizationInfoOrBrouche ?
                                        <a href={window.location.origin+"/" + donorDetail?.organizationInfoOrBrouche} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")
                          }
                      
                      </Table.Cell>
                     }
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organisation Logo</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>
                      <Image src={donorDetail?.organizationLogo} style={{ width: '23vh' }} />
                     </Table.Cell>
                  </Table.Row>
                </Table.Body>
                </Table>
               
                <Table celled>
                  <Table.Body>
                  <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Leadership Team Profile</Table.Cell>
                  </Table.Row>  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Title</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoTitle}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO First Name</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoFirstName}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Last Name</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoLastName}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">CEO Mobile</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoMobile}</Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO EmailId</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoEmailId}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Designation</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.ceoDesignation}</Table.Cell>
                  </Table.Row>
                  </Table.Body>
                </Table>
               
                <Table celled>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Photo</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}> <Image src={donorDetail?.ceoPhoto} style={{ width: '23vh' }} /></Table.Cell>
                  </Table.Row>
                  </Table.Body>
                </Table>

                <Table celled>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Management Profile</Table.Cell>
                     
                          {
                               <Table.Cell style={{ margin: '10px' }}>
                                   {
                              donorDetail?.trusteeorManagementProfile ?
                                        <a href={window.location.origin+"/" + donorDetail?.trusteeorManagementProfile} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")
                                }
                              </Table.Cell>
                          }
                      
                     
                  </Table.Row> 
                </Table.Body>
                </Table>

                <Table celled>
                  <Table.Body>
                  <Table.Row >
                      <Table.Cell colspan='6' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Contact person for Communication </Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell> Contact Title </Table.Cell>
                      <Table.Cell> Contact First Name </Table.Cell>
                      <Table.Cell> Contact Last Name </Table.Cell>
                      <Table.Cell> Contact Mobile </Table.Cell>
                      <Table.Cell> Contact EmailId </Table.Cell>
                      <Table.Cell> Contact Designation </Table.Cell>

                  </Table.Row>
                  {
                      donorDetail?.donorOrgContactViewModel && donorDetail?.donorOrgContactViewModel.map(
                          (odp) => 
                          <Table.Row key={odp.donorOrgContactPersonId}> 
                              <Table.Cell>{odp.contactTitle}</Table.Cell>
                              <Table.Cell>{odp.contactFirstName}</Table.Cell>
                              <Table.Cell>{odp.contactLastName}</Table.Cell>
                              <Table.Cell>{odp.contactMobile}</Table.Cell>
                              <Table.Cell>{odp.contactEmailId}</Table.Cell>
                              <Table.Cell>{odp.contactDesignation}</Table.Cell>
                          </Table.Row>      
                      )
                  }
                  </Table.Body>
                  </Table>
                 
                  <Table celled>
                  <Table.Body>
                  <Table.Row >
                      <Table.Cell colSpan='4' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Preference</Table.Cell>
                  </Table.Row>
                 
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left">My Giving Preference </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myGivingPreference}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Giving Frequency </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.givingFrequence}</Table.Cell>
                  </Table.Row>
                 
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Church Domination</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedChurchDomination}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred State </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedState}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred District </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedDistrict}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Community </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedCommunity}</Table.Cell>
                  </Table.Row>
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Tribe </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedTribe}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Language </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedLanguage}</Table.Cell>
                  </Table.Row>
                  
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred Pincode </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.preferedPincode}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> I am Representing </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.iamPresenting}</Table.Cell>
                  </Table.Row>

                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> My Preferred work</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.myPreferedWork}</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Track Record </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.givingTrackRecord ?
                                        <a href={window.location.origin+"/" + donorDetail?.givingTrackRecord} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")}</Table.Cell>
                   </Table.Row>  
                 
                  <Table.Row >
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Proof of total contribution </Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>
                          {
                              donorDetail?.proofofTotalContribution ?
                                        <a href={window.location.origin+"/" + donorDetail?.proofofTotalContribution} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            : ("")
                                }
                          </Table.Cell>
                  </Table.Row>


                    {
                        donorDetail?.myPreferedWork === "Others" ?
                   (<Table.Row>
                      <Table.Cell style={{ margin: '10px' }} textAlign="left"> Others</Table.Cell>
                      <Table.Cell style={{ margin: '10px' }}>{donorDetail?.others}</Table.Cell>
                  </Table.Row>) : ("")
                     }
            </Table.Body>
            </Table>
            
            <Table celled>
                <Table.Body>
                  <Table.Row >
                      <Table.Cell colspan='6' style={{textAlign: 'center',fontSize: 16 , fontWeight: 'bold', fontStyle: 'Italic'}}> Reference </Table.Cell>
                  </Table.Row>
                  <Table.Row >
                      <Table.Cell> Title </Table.Cell>
                      <Table.Cell> First Name </Table.Cell>
                      <Table.Cell> Last Name </Table.Cell>
                      <Table.Cell> Mobile </Table.Cell>
                      <Table.Cell> EmailId </Table.Cell>
                      <Table.Cell> Designation </Table.Cell>
                  </Table.Row>
                  <Table.Row key={donorDetail?.donorOrgReferenceId}> 
                              <Table.Cell>{donorDetail?.refTitle2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refFirstName2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refLastName2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refMobile2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refEmail2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refDesignation2}</Table.Cell>
                              <Table.Cell>{donorDetail?.refOrganization2}</Table.Cell>
                          </Table.Row>      
                          <Table.Row key={donorDetail?.donorOrgReferenceId}> 
                              <Table.Cell>{donorDetail?.refTitle1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refFirstName1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refLastName1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refMobile1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refEmail1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refDesignation1}</Table.Cell>
                              <Table.Cell>{donorDetail?.refOrganization1}</Table.Cell>
                          </Table.Row>      

          </Table.Body>  
          </Table> 
           
          {
              isPending ? (
                  <Fragment>
 <FinalForm
             onSubmit={(values: DonorOrgApproveOrReject) => 
                donorOrgApproveOrReject(values)
            }
            render={({ handleSubmit }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Field
                                        name='VerifiedComment'
                                        placeholder="Enter Comment"
                                        rows={3}
                                        width={7}
                                        component={TextAreaInput}
                                        onChange={e => setComment(e.target.value)
                                        }
                                    />
                </Form>
                 )}>
        </FinalForm>  

          <br/>
          <br/>

          <Button style={{ marginLeft: '450px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Donor org',<DonorOrgsApproveOrReject approveOrRejectDonorOrg={approve}  
                        />)
                      }}
                    >Approve</Button>
                     <Button style={{ marginLeft: '30px', backgroundColor: '#2185d0' }}
                            negative={!open}
                            positive={!close}              
                      onClick={() => {
                        openModal('Donor org',<DonorOrgsApproveOrReject approveOrRejectDonorOrg={reject}  
                        />)
                      }}
                    >Reject</Button>
{/* <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Approve</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <DonorApproveOrReject approveOrRejectDonorOrg={approve}   
                    />
                }
        </Segment>
      </TransitionablePortal>

 <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Reject</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <DonorApproveOrReject approveOrRejectDonorOrg={reject}   
                    />
                }
        </Segment>
      </TransitionablePortal> */}

     </Fragment>
     ) : null
          }              
         
          <br/>
          <br/>
        </Container>
        </div>
    );
}

export default observer(DonorOrgRegistrationApproval);