export interface IDonorIndividualRegisterValues{
    Name: string;
    ProfilePicture:string;
    DateOfBirth: string;
    Gender: string;
    EmailId: string;
    Password: string;
    MobileContactNumber: string;
    Address: string;
    Pincode:string;
    Country: string;
    State:string;
    Nationality: string;
    Organisation: string;

    Designation: string;
    MyGivingPreference: string;
    GivingTrackRecord: string;

    WebsiteAddress: string;
    WhatsappNumber: string;
    FacebookID: string;
    TwitterID: string;

    MyPreferedWork: string;
    Others: string;

    ModeOfGivingPreference: string;
    MyPreferedChurchDomination: string;
    GivingFrequence: string;
    MyPreferedState: string;
    
    MyPreferedDistrict: string;
    MyPreferedCommunity: string;
    MyPreferedTribe: string;
    MyPreferedLanguage: string;
    OtherMediaLink: string;
    PreferedPincode: string;
    IamPresenting: string;
    ProofofTotalContribution: string;
    refTitle1: string;
  refFirstName1: string;
  refLastName1: string;
  refMobile1: string;
  refEmail1: string;
  refDesignation1: string;
  refOrganization1: string;
  refTitle2: string;
  refFirstName2: string;
  refLastName2: string;
  refMobile2: string;
  refEmail2: string;
  fedarationBasicDetailIds:number[];
  refDesignation2: string;
  refOrganization2: string;

    //DonorindReferenceDetailJson: DonorIndReferenceDetail[];
}

export class DonorIndividualRegisterValues implements IDonorIndividualRegisterValues {
    Name: string = "";
    ProfilePicture: string = "";
    DateOfBirth: string = "";
    Gender: string = "";
    EmailId: string = "";
    Password: string = "";
    MobileContactNumber: string = "";
    Address: string = "";
    Pincode: string = "";
    Country: string = "";
    State: string = "";
    Nationality: string = "";
    Organisation: string = "";
    Designation: string = "";
    MyGivingPreference: string = "";
    GivingTrackRecord: string = "";
    WebsiteAddress: string = "";
    WhatsappNumber: string = "";
    FacebookID: string = "";
    TwitterID: string = "";
    MyPreferedWork: string = "";
    Others: string = "";
    ModeOfGivingPreference: string = "";
    MyPreferedChurchDomination: string = "";
    GivingFrequence: string = "";
    MyPreferedState: string = "";
    MyPreferedDistrict: string = "";
    MyPreferedCommunity: string = "";
    MyPreferedTribe: string = "";
    MyPreferedLanguage: string = "";
    OtherMediaLink: string = "";
    PreferedPincode: string = "";
    IamPresenting: string = "";
    ProofofTotalContribution: string = "";
    refTitle1: string = "";
    refFirstName1: string = "";
    refLastName1: string = "";
    refMobile1: string = "";
    refEmail1: string = "";
    refDesignation1: string = "";
    refOrganization1: string = "";
    refTitle2: string = "";
    refFirstName2: string = "";
    refLastName2: string = "";
    refMobile2: string = "";
    refEmail2: string = "";
    fedarationBasicDetailIds: number[] = [];
    refDesignation2: string = "";
    refOrganization2: string = "";
}

export interface IDonorIndReferenceDetail{
    Title: string;
    FirstName: string;
    LastName: string;
    Mobile: string;
    Email: string;
    Designation: string;
    Organization: string;

}

export class  DonorIndReferenceDetail implements IDonorIndReferenceDetail{
    Title: string = '';
    FirstName: string = '';
    LastName: string = '';
    Mobile: string = '';
    Email: string = '';
    Designation: string = '';
    Organization: string = '';
}

export interface IDonorIndApproveOrReject{
    DonorIndividualBasicDetailId?: number | undefined;
    IsApproved: boolean;
    VerifiedBy: string;
    VerifiedComment: string;
}

export class DonorIndApproveOrReject implements IDonorIndApproveOrReject{
    DonorIndividualBasicDetailId?: number = undefined;
    IsApproved: boolean = false;
    VerifiedBy: string = "";
    VerifiedComment: string = "";

    constructor(init?: IDonorIndApproveOrReject) {
        Object.assign(this, init);
    }
}

export interface IDonorIndividualRegister{
    donorIndividualBasicDetailId: number,
    name: string;
    dateOfBirth: string;
    gender: string;
    emailId: string;
    password: string;
    mobileNo: string;
    address: string;
    pincode:string;
    state:string;
    nationality: string;
    organisation: string;
    designation: string;
    myGivingPreference: string;
    givingTrackRecord: Blob;
    websiteAddress: string;
    whatsappNumber: string;
    facebookID: string;
    twitterID: string;
    myPreferedWork: string;
    others: string;
    modeOfGivingPreference: string;
    myPreferedChurchDomination: string;
    givingFrequence: string;
    myPreferedState: string;
    myPreferedDistrict: string;
    myPreferedCommunity: string;
    myPreferedTribe: string;
    myPreferedLanguage: string;
    otherMediaLink: string;
    preferedPincode: string;
    iamPresenting: string;
    refTitle1: string;
  refFirstName1: string;
  refLastName1: string;
  refMobile1: string;
  refEmail1: string;
  refDesignation1: string;
  refOrganization1: string;
  refTitle2: string;
  refFirstName2: string;
  refLastName2: string;
  refMobile2: string;
  refEmail2: string;
  fedarationBasicDetailIds:number[];
  refDesignation2: string;
  refOrganization2: string;
    proofofTotalContribution: string;
    verificationStatus:string;
    donorIndividualReferenceId:number;
   
}