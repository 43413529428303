import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import {
  IAssessment,
  IAssessmentApproveOrReject,
  IAssessmentAreaVerify,
  IAssessmentByArea,
  IAssessmentByAreaVerify,
  IAssessmentList,
  IAssessmentSuggesstion,
  ISuggestion,
} from "../models/assessment";
import { TryAgain } from "../common/constant";
import { IAssessmentDone } from "../models/assessmentDone";

import { IActionPoints } from "../models/assessment";
import { history } from "../..";
export default class AssessmentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable assessmentList: IAssessmentList[] = [];
  @observable uploadingDocument = false;
  @observable assessment: IAssessment | undefined;
  @observable loadingAssessments = false;
  @observable isSaved = false;
  @observable isSubmittedAssements = false;
  @observable isFileUpload = false;
  @observable assessmentValues: IAssessmentList[] = [];
  @observable assessmentAreaVerify:
    | IAssessmentAreaVerify
    | undefined = undefined;
  @observable selectedAssessment: IAssessmentList | undefined = undefined;
  @observable loadingAssessmentDetail: boolean = false;
  @observable assessmentDetail?: IAssessment | undefined;
  @observable assessmentByArea: IAssessmentByArea[] = [];
  @observable assessmentByAreaVerify: IAssessmentByAreaVerify[] = [];
  @observable assessmentApproveOrReject: IAssessmentApproveOrReject | any;
  @observable assessmentFile: IAssessmentList[] = [];
  @observable actionPoints: IActionPoints[] = [];
  @observable loadingAssessmentAreaDetail: boolean = false;
  // @observable assessmentId = 0;
  @observable suggesstion: IAssessmentSuggesstion | undefined;
  @observable suggestionGet: ISuggestion[] = [];
  @observable isAssessmentsug: boolean = false;
  @observable suggestionLoading: boolean = false;
  @observable index: number = 1;
  @observable loadingSendSms: boolean = false;
  @observable userSendSms: string = "";

  @action submitAssessmentData = async (assessmentValues: IAssessmentList) => {
    //assessmentValues.partnerId = 1;
    this.isSubmittedAssements = true;
    await agent.Assessment.submit(assessmentValues);
    runInAction(() => {
      this.assessmentList.push(assessmentValues);
      this.isSubmittedAssements = false;
      toast.info("Assessment successfully submitted");
      history.push({
        pathname: "/dod-odDashboard",
        state: { Id: assessmentValues.partnerId },
      });
    });
  };

  @action saveAssessment = async (assessments: IAssessmentList) => {
    this.isSaved = true;
    if (assessments.optionValue !== "No") {
      await agent.Assessment.save(assessments);
    }
    runInAction(() => {
      if (assessments.optionValue === "No") {
        toast.error(
          "Since your Organisation is not Registered, Kindly contact admin for further assistance"
        );
        history.push({
          pathname: "/dod-odDashboard",
          state: { Id: assessments.partnerId },
        });
      } else {
        this.assessmentList.push(assessments);
      }
      this.isSaved = false;
    });
  };

  @action assessmentDone = async (values: string) => {
    this.loadingSendSms = true;
    console.log(values);
    try {
      const user = await agent.DoneAssessment.create(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.userSendSms = user;
        this.loadingSendSms = false;
        toast.info("Assesment Submitted");
      });
    } catch (error) {
      this.loadingSendSms = false;
      toast.error(TryAgain);
      throw error;
    }
  };

  // @action getAssessmentById = async (id: number) => {
  //   try {
  //     this.loadingAssessmentDetail = true;
  //     this.assessmentDetail = undefined;

  //     const assessment = await agent.Assessment.getById(id);
  //     console.log(assessment);
  //     runInAction(() => {
  //       this.assessmentDetail = assessment;
  //     })

  //   } catch (error) {
  //     throw error;
  //   } finally {
  //     runInAction(() => {
  //       this.loadingAssessmentDetail = false;
  //     })
  //   }
  // }

  @action getFullDetailsByAssessmentId = async (assessmentId: number) => {
    try {
      this.loadingAssessmentDetail = true;

      const assessment = await agent.Assessment.getFullDetailsByAssessmentId(
        assessmentId
      );
      console.log("assess", assessment);
      runInAction(() => {
        this.assessmentByArea = assessment;
        // this.assessmentId = id;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action getLastAssessment = async (id: number, status: boolean) => {
    try {
      this.loadingAssessmentDetail = true;

      const assessment = await agent.Assessment.getlastAssessmentByArea(
        id,
        status
      );
      console.log(assessment);
      runInAction(() => {
        this.assessmentByArea = assessment;
        // this.assessmentId = id;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action getActionPoints = async (id: number) => {
    try {
      this.loadingAssessmentDetail = true;

      const actionpoint = await agent.Assessment.getActionPoints(id);
      runInAction(() => {
        this.actionPoints = actionpoint;
        if (actionpoint[0] && actionpoint[0].assessmentId >0) {
          this.getAssessmentbyid(actionpoint[0].assessmentId);
        }
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action getActionPointsByAssessmentId = async (assessmentId: number) => {
    try {
      this.isAssessmentsug=true;

      const actionpoint = await agent.Assessment.getActionPointsByAssessmentId(
        assessmentId
      );
      console.log(actionpoint);
      runInAction(() => {
        this.actionPoints = actionpoint;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.isAssessmentsug=false;
      });
    }
  };
  @action getAssessmentAreaById = async (id: number) => {
    try {
      this.loadingAssessmentAreaDetail = true;
      const assessment = await agent.Assessment.getAssessmentByArea(id);
      console.log(assessment);
      runInAction(() => {
        this.assessmentByAreaVerify = assessment;
        this.loadingAssessmentAreaDetail = false;

        //  this.assessmentId = id;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action getAssessmentAreaVerify = async (id: number) => {
    try {
      this.loadingAssessmentDetail = true;
      const assessment = await agent.Assessment.getAssessmentAreaVerify(id);
      console.log(assessment);
      runInAction(() => {
        this.assessmentAreaVerify = assessment;
        //  this.assessmentId = id;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action assessmentApprove = async (approve: IAssessmentApproveOrReject) => {
    try {
      console.log(approve);
      const assessmentApproveResponse = await agent.Assessment.approve(approve);

      runInAction(() => {
        this.assessmentApproveOrReject = assessmentApproveResponse;
        if (approve.IsApproved === true) {
          toast.info("Assessment Approved");
          agent.AssesmentNotify.notifyUser(
            this.rootStore.partnerStore.odPartnerDetail.mobileContactNumber
          );
          console.log(
            this.rootStore.partnerStore.odPartnerDetail.mobileContactNumber
          );
        } else {
          toast.info("Assessment Rejected");
        }
        this.rootStore.modalStore.closeModal();
        history.push("/dod-adminDashboard");
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action createAssessmentFile = async (assessment: IAssessmentList) => {
    this.isFileUpload = true;
    console.log(assessment);
    let dataMap: Map<string, string> = new Map();
    dataMap.set("PartnerId", String(assessment.partnerId));

    var obj = assessment.questionIdList;
    var referenceObj = JSON.stringify(obj);
    dataMap.set("QuestionIdList", referenceObj);
    //dataMap.set('File', assessment.file[0]);
    let fileMap: Map<string, Array<string>> = new Map<string, Array<string>>();
    let arrayList: Array<string> = new Array<string>();
    for (let i = 0; i < assessment.file.length; i++) {
      arrayList.push(assessment.file[i][0]);
    }
    fileMap.set("File", arrayList);
    const assessmentFileResponse = await agent.Assessment.createFile(
      dataMap,
      fileMap
    );
    console.log(assessmentFileResponse);
    runInAction(() => {
      this.isFileUpload = false;
      this.assessmentFile = assessmentFileResponse;
    });
  };

  @action assessmentSuggesstion = async (approve: IAssessmentSuggesstion) => {
    try {
      this.isAssessmentsug = true;
      console.log(approve);
      const assessmentSave = await agent.Assessment.saveSuggesstion(approve);
      console.log(assessmentSave);
      runInAction(() => {
        this.suggesstion = assessmentSave;
        console.log(this.suggesstion);
        this.isAssessmentsug = false;
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action getSuggestion = async (assessmentId: number) => {
    try {
      this.suggestionLoading = true;
      const actionpoint = await agent.Assessment.getSuggestions(assessmentId);
      console.log("acctpt", actionpoint);
      runInAction(() => {
        this.suggestionGet = actionpoint;
        this.suggestionLoading = false;
      });
    } catch (error) {
      throw error;
    }
  };

  @action editSuggestion = async (
    assessmentVerifyId: IAssessmentSuggesstion
  ) => {
    try {
      this.loadingAssessmentDetail = true;
      const actionpoint = await agent.Assessment.editSuggesstion(
        assessmentVerifyId
      );
      console.log(actionpoint);
      runInAction(() => {
        toast.info("Suggestion Edited Successfully");
        this.loadingAssessmentDetail = false;
        window.location.reload();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };
  @action getAssessmentbyid = async (assessmentId: number) => {
    try {
      this.loadingAssessmentDetail = true;

      const assessment = await agent.Assessment.getByAssessmentId(assessmentId);
      console.log("assess", assessment);
      runInAction(() => {
        this.assessment = assessment;
        // this.assessmentId = id;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action uploadDocument = async (
    assessmentId: number,

    document: string
  ) => {
    this.uploadingDocument = true;
    let dataMap: Map<string, string> = new Map();
    dataMap.set("AssessmentId", assessmentId.toString());

    let fileMap: Map<string, string> = new Map();
    fileMap.set("reportDoc", document);
    try {
      await agent.Assessment.uploadDocument(dataMap, fileMap);
      runInAction(() => {
        this.uploadingDocument = false;
        toast.success("Assessment Report uploaded Successfully");
      });
    } catch (error) {
      toast.error("Problem uploading document");
      runInAction(() => {
        this.uploadingDocument = false;
      });
    }
  };
  @action deleteSuggestion = async (
    assessmentVerifyId: IAssessmentSuggesstion
  ) => {
    try {
      this.loadingAssessmentDetail = true;
      const actionpoint = await agent.Assessment.disableSuggestion(
        assessmentVerifyId
      );
      console.log(actionpoint);
      runInAction(() => {
        toast.info("Suggestion Deleted Successfully");
        this.loadingAssessmentDetail = false;
        window.location.reload();
      });
    } catch (error) {
      throw error;
    }
  };
}
