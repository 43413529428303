import React, { useEffect } from 'react'
import { Grid, Table} from 'semantic-ui-react';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore'; 


function OrgInbox() {
    const rootStore = useContext(RootStoreContext);
    const { getContactList, contactList,readContact} = rootStore.contactStore;
    const {registrationKey} = rootStore.commonStore; 
console.log(contactList)
    useEffect(() => {
        getContactList(String(registrationKey));
        readContact(String(registrationKey));
    }, [getContactList,registrationKey,readContact]);
    return (
    <div style = {{marginLeft: '50px'}}>
         <Grid >
         <Grid.Row >
          
        <Table  >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                         <Table.Body >
                            {contactList && contactList.map(
                                (contact) => (
                                    (contact.receiver  === rootStore.commonStore.registrationKey &&
                                    <Table.Row key={contact.contactId}>
                                        <Table.Cell>{contact.date}</Table.Cell>
                                        <Table.Cell>{contact.message}</Table.Cell>
                                                                            
                                    </Table.Row>
                                    )
                                )
                            )}
                        </Table.Body> 
                    </Table>
         </Grid.Row>
         </Grid>

         
    </div>
    
    );
}

export default observer(OrgInbox);