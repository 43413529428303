import { IProfile } from './../models/profile';
import { IPartnerDetail } from './../models/donorIndDashboard';
import { ISectorFormValues } from './../models/sector';
import { IState } from './../models/state';
import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { IDonorDashboard } from "../models/donorIndDashboard";
import { RootStore } from "./rootStore";
import { DropdownItemProps } from 'semantic-ui-react';
import { history } from '../..';
import { IApproval } from '../models/approval';


export default class DonorIndividualDashboardStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable donorIndDashboard?: IDonorDashboard | undefined;
    @observable loadingDonorInd: boolean = false;
    @observable state: IState[] =[];
    @observable sector: ISectorFormValues[] = [];
    @observable partnerName : IPartnerDetail[] = []; 
    @observable stateDropdownValues: DropdownItemProps[] =[];
    @observable sectorDropdownValues: DropdownItemProps[] = [];
    @observable partnerNameDropdownValues : DropdownItemProps[] = []; 
    @observable partnerDetailDropdownValues : DropdownItemProps[] = []; 
    @observable profile?: IProfile | undefined;
    @observable approval?: IApproval[] | undefined;

    @action getDonorIndividualDashboard = async (id: number) => {
        try {
          this.loadingDonorInd = true;  
          const dashboard = await agent.DonorIndDashboard.getById(id);
          console.log(dashboard);
          runInAction(() => {
            this.donorIndDashboard = dashboard;
            this.updateStateDropdownValues();
            this.updateSectorDropdownValues();
            this.updatePartnerNameDropdownValues();
            console.log(this.donorIndDashboard);
          })
    
        } catch (error) {
          throw error;
        } finally {
          runInAction(() => {
            this.loadingDonorInd = false;
          })
        }
        
      };
      @action updateStateDropdownValues() {
        let stateDropDownValues: DropdownItemProps[] = [];

        this.donorIndDashboard?.state.map((s) => {
            let item = {
                text: s.stateName,
                value: s.stateId,
                key: s.stateId,
            };
            stateDropDownValues.push(item);
            return s;
        })

        this.stateDropdownValues = stateDropDownValues;
      }

      @action updateSectorDropdownValues() {
        let sectorDropDownValues: DropdownItemProps[] = [];

        this.donorIndDashboard?.sector.map((sec) => {
            let item = {
                text: sec.sectorName,
                value: sec.sectorId,
                key: sec.sectorId,
            };
            sectorDropDownValues.push(item);
            return sec;
        })

        this.sectorDropdownValues = sectorDropDownValues;
    }

    @action updatePartnerNameDropdownValues() {
      let partnerNameDropDownValues: DropdownItemProps[] = [];

      this.donorIndDashboard?.partner.map((p) => {
          let item = {
              text: p.partnerName,
              value: p.partnerId,
              key: p.partnerKey,
          };
          partnerNameDropDownValues.push(item);
          return p;
      })

      this.partnerDetailDropdownValues = partnerNameDropDownValues;
    }
    

    @action searchPartner = async (stateId : number,sectorId: number, partnerId : number) => {
      try { 
        if(stateId === undefined) stateId =0;
        if(sectorId === undefined) sectorId =0;
        if(partnerId === undefined) partnerId =0;
        const dashboard = await agent.DonorIndDashboard.searchPartner(stateId,sectorId,partnerId);
        console.log(dashboard);
        runInAction(() => {
          this.partnerName = dashboard;
          this.updatePartnerDetailDropdownValues();
          if(partnerId !== 0)
          {
            history.push({pathname:'/dod-donorSearchODDashboard',state: {Id: partnerId}})
          }
          console.log(this.partnerName);
        })
    
      } catch (error) {
        throw error;
      } finally {
        runInAction(() => {       
        })
      }     
    };

    @action updatePartnerDetailDropdownValues() {
      let partnerDropDownValues: DropdownItemProps[] = [];

      this.partnerName.map((p) => {
          let item = {
              text: p.partnerName,
              value: p.partnerId,
              key: p.partnerKey,
          };
          partnerDropDownValues.push(item);
          return p;
      })

      this.partnerDetailDropdownValues = partnerDropDownValues;
    }

    @action getProfile = async ( id: number) => {
      try {
        const profile = await agent.DonorIndDashboard.getProfile(id);
        console.log(profile);
        runInAction(() => {
          this.profile = profile;
        })
  
      } catch (error) {
        throw error;
      } finally {
        runInAction(() => {
        })
      }
    }

    @action getApproval = async ( id: number) => {
      try {
        const approval = await agent.DonorIndDashboard.getApproval(id);
        console.log(approval);
        runInAction(() => {
          this.approval = approval;
        })
  
      } catch (error) {
        throw error;
      } finally {
        runInAction(() => {
        })
      }
    }
    
}