import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import ReactExport from "react-data-export";
import { Icon, Label, Table } from "semantic-ui-react";

import { RootStoreContext } from "../../../app/stores/rootStore";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn; 

function ExportQuestion(props) {
    const rootStore = useContext(RootStoreContext);
    const { questionDraftList, getByExcel } = rootStore.questionSetupStore
    useEffect(() => {
        getByExcel(props.sector, props.subsector, props.area);
    }, [props,getByExcel]);

    if (questionDraftList)
        return (
            <Table><Table.Row><Table.Cell>

                <ExcelFile style ={{marginLeft : '141px'}} element={<Icon name='download' size='big' color='green'></Icon>} >
                    <ExcelSheet data={questionDraftList} name="DOD Questions">                    
                        <ExcelColumn label={"SubQuestion?"} value={"isSubQuestion"} />
                        <ExcelColumn label={"Question"} value={"question"} />
                        <ExcelColumn label={"AnswerType"} value={"answerType"} />
                        <ExcelColumn label={"MainQuestion"} value={"mainQuestion"} />
                        <ExcelColumn label={"MainOption"} value={"mainOption"} />
                        <ExcelColumn label={"IsMandatory"} value={"isMandatory"} />

                       
                        <ExcelColumn label={"OptionName1"} value={"optionName1"} />
                        <ExcelColumn label={"PointValue1"} value={"ponintValue1"} />
                        <ExcelColumn label={"ActionResult1"} value={"actionPoints1"} />
                        <ExcelColumn label={"ReportText1"} value={"reportText1"} />

                      
                        <ExcelColumn label={"OptionName2"} value={"optionName2"} />
                        <ExcelColumn label={"PointValue2"} value={"ponintValue2"} />
                        <ExcelColumn label={"ActionResult2"} value={"actionPoints2"} />
                        <ExcelColumn label={"ReportText2"} value={"reportText2"} />

                        
                        <ExcelColumn label={"OptionName3"} value={"optionName3"} />
                        <ExcelColumn label={"PointValue3"} value={"ponintValue3"} />
                        <ExcelColumn label={"ActionResult3"} value={"actionPoints3"} />
                        <ExcelColumn label={"ReportText3"} value={"reportText3"} />

                        
                        <ExcelColumn label={"OptionName4"} value={"optionName4"} />
                        <ExcelColumn label={"PointValue4"} value={"ponintValue4"} />
                        <ExcelColumn label={"ActionResult4"} value={"actionPoints4"} />
                        <ExcelColumn label={"ReportText4"} value={"reportText4"} />

                         
                        <ExcelColumn label={"OptionName5"} value={"optionName5"} />
                        <ExcelColumn label={"PointValue5"} value={"ponintValue5"} />
                        <ExcelColumn label={"ActionResult5"} value={"actionPoints5"} />                      
                        <ExcelColumn label={"ReportText5"} value={"reportText5"} />

                        <ExcelColumn label={"MaxScore"} value={"maxScore"} />                      
                        <ExcelColumn label={"MaxTextLimit"} value={"maxTextLimit"} />


                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />
                        <ExcelColumn label={""} value={""} />

                        <ExcelColumn label={"AnswerOptionId1"} value={"answerOptionId1"} />
                        <ExcelColumn label={"AnswerOptionId2"} value={"answerOptionId2"} />
                        <ExcelColumn label={"AnswerOptionId3"} value={"answerOptionId3"} />
                        <ExcelColumn label={"AnswerOptionId4"} value={"answerOptionId4"} />
                        <ExcelColumn label={"AnswerOptionId5"} value={"answerOptionId5"} />

                       
                        <ExcelColumn label={""} value={""} />

                        <ExcelColumn label={"QuestionId"} value={"questionId"} visible={false} />

                    </ExcelSheet>

                </ExcelFile >Download</Table.Cell></Table.Row></Table>
        )
    else
        return (<Label>Loading data for Excel export...</Label>)


}
export default observer(ExportQuestion);
