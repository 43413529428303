import React, { Fragment } from "react";
import LoginForm from "../../features/auth/LoginForm";
//import { Container } from 'semantic-ui-react';
import { Route, withRouter, Switch } from "react-router-dom";
import ODDashboard from "../../features/dashboard/odDashboard/ODDashboard";
import { observer } from "mobx-react-lite";
import ModalContainer from "../common/modals/ModalContainer";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import { IdleTimerComponent } from "./IdleTimerComponent";
import UserList from "../../features/admin/user/UserList";
import HomePage from "../../features/homePage/Home";
import Plan from "../../features/admin/plan/Plan";
import QuestionDashboard from "../../features/admin/questionSetup/QuestionDashboard";
import Assessment from "../../features/dashboard/odDashboard/Assessment";
import AdminDashboard from "../../features/dashboard/adminDashboard/AdminDashboard";
import RegistrationPending from "../../features/dashboard/adminDashboard/regApproval/RegistrationPending";
import RegistrationEvaluation from "../../features/dashboard/adminDashboard/regApproval/RegistrationEvaluation";
import FederationRegistrationApproval from "../../features/dashboard/adminDashboard/regApproval/FederationRegistrationApproval";
import MiniVariant from "../../features/menu/MiniVariant";
import ODRegistration from "../../features/registration/OD/BeneficiaryRegistration";
import DonorRegistration from "../../features/registration/donor/DonorRegistration";
import FederationRegistration from "../../features/registration/federation/FederationRegistration";
import MasterDashboard from "../../features/admin/masters/MasterDashboard";
import DonorIndividual from "../../features/registration/donor/DonorIndividual";
import FederationDashboard from "../../features/dashboard/federationDashboard/FederationDashboard";
import DonorDashboard from "../../features/dashboard/donorDashboard/DonorDashboard";
import CreateUser from "../../features/admin/user/CreateUser";
import CreatePlan from "../../features/admin/plan/CreatePlan";
import ManageUser from "../../features/admin/user/ManageUser";
import DisableUser from "../../features/admin/user/DisableUser";
import EditPlan from "../../features/admin/plan/EditPlan";
import DisablePlan from "../../features/admin/plan/DisablePlan";
import DisableQuestion from "../../features/admin/questionSetup/DisableQuestion";
import DonorIndRegistrationApproval from "../../features/dashboard/adminDashboard/regApproval/DonorIndRegistrationApproval";
import DonorOrgRegistrationApproval from "../../features/dashboard/adminDashboard/regApproval/DonorOrgRegistrationApproval";
import ODRegistrationApproval from "../../features/dashboard/adminDashboard/regApproval/ODRegistrationApproval";
import About from "../../features/homePage/About";
import Contact from "../../features/homePage/Contact";
import AssessmentDetail from "../../features/dashboard/adminDashboard/assessmentReview/AssessmentDetail";
import AdminInbox from "../../features/mailBox/AdminInbox";
import RightPanelContainer from "../common/modals/RightPanelContainer";
import ChangePassword from "../../features/auth/ChangePassword";
import ForgotPassword from "../../features/auth/ForgotPassword";
import VerifyOtp from "../../features/user/VerifyOtp";
import OrgInbox from "../../features/mailBox/OrgInbox";
import FederationSearchODDashboard from "../../features/dashboard/federationDashboard/FederationSearchODDashboard";
import DonorIndDashboard from "../../features/dashboard/donorDashboard/DonorIndDashboard";
import DonorSearchODDashboard from "../../features/dashboard/donorDashboard/DonorSearchODDashboard";
import AssessmentPending from "../../features/dashboard/adminDashboard/assessmentReview/AssessmentPending";
import AssessmentAssessed from "../../features/dashboard/adminDashboard/assessmentReview/AssessmentAssessed";
import ViewRegistration from "../../features/dashboard/odDashboard/ViewRegistration";
import ThankYouPage from "../../features/registration/OD/ThankYouPage";
import ScrollToTop from "./ScrollToTop";
//import ParagraphFormatView from "../../features/dashboard/adminDashboard/assessmentReview/ReportPreview";
import ThankYouPageFed from "../../features/registration/federation/ThankYouPage";
import ThankYouPageDonor from "../../features/registration/donor/ThankYouPageDonor";
import ThankYouPageDonorind from "../../features/registration/donor/ThankYouPageDonorind";
//
/* 
function usePageViews(){
let location = useLocation();
useEffect(()=>{

  ReactGA.initialize("G-58SH8E5YRP");
ReactGA.set({page : location.pathname});
ReactGA.pageview(location.pathname);
}, [location] 
);
}
 */

function App() {
  //usePageViews();
  /* 
    const rootStore = useContext(RootStoreContext);
    const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
    const { getProfile } = rootStore.authStore;
  
    useEffect(() => {
      if (token) {
        getProfile().finally(() => setAppLoaded())
      } else {
        setAppLoaded();
      }
    }, [getProfile, setAppLoaded, token])
  
    if (!appLoaded) return <LoadingComponent content='Loading app...' />
   */
  return (
    <Fragment>
      <ScrollToTop>
        <IdleTimerComponent>
          <ToastContainer position="bottom-right" />
          {/* background-image: url('./../../assets/images/lightblue.jpg'); */}

        <div
          style={{
            paddingTop: "100px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Route exact path="/" component={HomePage} />
          <Route path="/home" component={HomePage} />
          <Route path="/homepageAbout" component={About} />
          <Route path="/homepageContact" component={Contact} />
          <Route path="/login" component={LoginForm} />
          <Route path="/beneficiary" component={ODRegistration} />
          <Route path="/thankyou" component={ThankYouPage} />
          <Route path="/federation" component={FederationRegistration} />
          <Route path="/donor" component={DonorRegistration} />
          <Route path="/thankyouorg" component={ThankYouPageDonor} />
          <Route path="/donorind" component={DonorIndividual} />
          <Route path="/thankyouind" component={ThankYouPageDonorind} />
          <Route path="/thankyoufed" component={ThankYouPageFed} />

            <Route
              path={"/dod-(.+)"}
              render={() => (
                <div style={{ paddingLeft: "80px" }}>
                  <MiniVariant />
                  <ModalContainer />
                  <RightPanelContainer>
                    <Switch>
                      <Route path="/dod-odDashboard" component={ODDashboard} />
                      <Route path="/dod-users" component={UserList} />
                      <Route path="/dod-plan" component={Plan} />
                      <Route
                        path="/dod-questionDashboard"
                        component={QuestionDashboard}
                      />
                      <Route path="/dod-assessment" component={Assessment} />
                      <Route
                        path="/dod-adminDashboard"
                        component={AdminDashboard}
                      />
                      <Route
                        path="/dod-registrationPending"
                        component={RegistrationPending}
                      />
                      <Route
                        path="/dod-registrationEvaluation"
                        component={RegistrationEvaluation}
                      />
                      <Route
                        path="/dod-fedRegApproval"
                        component={FederationRegistrationApproval}
                      />
                      <Route path="/dod-master" component={MasterDashboard} />
                      <Route path="/dod-donorind" component={DonorIndividual} />
                      <Route
                        path="/dod-federationdashboard"
                        component={FederationDashboard}
                      />
                      <Route
                        path="/dod-donorDashboard"
                        component={DonorDashboard}
                      />
                      <Route path="/dod-createuser" component={CreateUser} />
                      <Route path="/dod-createplan" component={CreatePlan} />
                      <Route path="/dod-manageUser" component={ManageUser} />
                      <Route path="/dod-userdisable" component={DisableUser} />
                      <Route path="/dod-editplan" component={EditPlan} />
                      <Route path="/dod-disableplan" component={DisablePlan} />
                      <Route
                        path="/dod-disableQuestion"
                        component={DisableQuestion}
                      />
                      <Route
                        path="/dod-donorIndRegApproval"
                        component={DonorIndRegistrationApproval}
                      />
                      {/* <Route path="/dod-edit" component={ParagraphFormatView} /> */}
                      <Route
                        path="/dod-donorOrgRegApproval"
                        component={DonorOrgRegistrationApproval}
                      />
                      <Route
                        path="/dod-odRegApproval"
                        component={ODRegistrationApproval}
                      />
                      <Route
                        path="/dod-assessmentPending"
                        component={AssessmentPending}
                      />
                      <Route
                        path="/dod-assessmentDetail"
                        component={AssessmentDetail}
                      />
                      <Route
                        path="/dod-assessmentAssessed"
                        component={AssessmentAssessed}
                      />
                      <Route path="/dod-adminInbox" component={AdminInbox} />
                      <Route
                        path="/dod-changePassword"
                        component={ChangePassword}
                      />
                      <Route
                        path="/dod-forgotPassword"
                        component={ForgotPassword}
                      />
                      <Route path="/dod-verifyOtp" component={VerifyOtp} />
                      <Route path="/dod-inbox" component={OrgInbox} />
                      <Route
                        path="/dod-federationSearchODDashboard"
                        component={FederationSearchODDashboard}
                      />
                      <Route
                        path="/dod-donorIndDashboard"
                        component={DonorIndDashboard}
                      />
                      <Route
                        path="/dod-donorSearchODDashboard"
                        component={DonorSearchODDashboard}
                      />
                      <Route path="/dod-odView" component={ViewRegistration} />
                      <Route component={NotFound} />
                    </Switch>
                  </RightPanelContainer>
                </div>
              )}
            />
          </div>
        </IdleTimerComponent>
      </ScrollToTop>
    </Fragment>
  );
}

export default withRouter(observer(App));
