import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Radio, CheckboxProps, DropdownItemProps, Grid, Label, GridColumn } from 'semantic-ui-react';

interface IProps
    extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {
}
const RadioInput: React.FC<IProps> = ({
    input,
    label,
    options,
    possibleValues,
    hidden,
    readOnly,
    onChange,
    required = false,
    
    
    meta: { touched, error }
}) => {

    const [selected, setSelected] = useState<any>(input.value);

    const handleChange = (event: any, data: CheckboxProps) => {
        setSelected(data.value);

        input.onChange(data.value);
        if (onChange) {
            onChange(data.value)
        }
    }


    if (options === undefined && possibleValues && possibleValues.length > 0) {
        options = [];
        let index = 1;
        possibleValues.forEach((element: string) => {
            let item = {
                text: element,
                value: element,
                key: index
            };
            index++;
            options.push(item);

        });
    }

    useEffect (() => {

        if(input.value != null){
         if (onChange) {
           onChange(input.value);
         }
        }
        
      }, [])
      var conversion = parseInt(input.value);
      
      var mandatory = conversion  ? true : false
      
      
      console.log(required);
    return (
        <Fragment>
            <Grid columns={4} verticalAlign='middle'>
                <GridColumn width={8} >
                    <Form.Field hidden={hidden}>
                        {label && <label className='fieldLabel'>{label}
                                {required === true  && <span className ="mandatory-field">*</span>}
                           

                            <br/>
                            
                        </label>
                        

                        }

                    </Form.Field>
                </GridColumn>
                <GridColumn width={8}>
                    <Form.Field error={touched && !!error} hidden={hidden}>

                        {
                            options && options.map((item: DropdownItemProps) => (
                                <Fragment key={item.key}>
                                    <Radio
                                        label={item.text}
                                        value={typeof (item.value) === "number" ? item.value as number : item.value as string}
                                        checked={selected === item.value}
                                        onChange={handleChange}
                                        readOnly={readOnly}
                                       // required={requiredField ? "Mandatory" : "NotMandatory"}
                                        error={error}
                                        

                                    />
                                    &nbsp;&nbsp;&nbsp;
                                </Fragment>

                            ))

                        }
                    </Form.Field>
                    {touched && error && (
                        <Label basic color='red'>
                            {error}
                        </Label>)}
                </GridColumn>
            </Grid>
        </Fragment >
    )
}

export default RadioInput

