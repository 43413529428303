import React from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormFieldProps,
  Form,
  Label,
  Select,
  DropdownItemProps,
} from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
  FormFieldProps { }

const SelectInputTab: React.FC<IProps> = ({
  input,
  width,
  options,
  placeholder,
  selection,
  label,
  mandate,
  labelstyle,
  inputstyle,
  errorstyle,
  search,
  hidden,
  loading,
  possibleValues,
  onChange,
  onCustomChange,
  name,
  select,
  readOnly = false, 
  error,
}) => {
  let dropDownValues: DropdownItemProps[] = [];
  if (possibleValues && possibleValues.length > 0) {
    let index = 1;
    possibleValues.forEach((element: string | number) => {
      let item = {
        text: element,
        value: element,
        key: index,
      };
      index++;
      dropDownValues.push(item);
    });
  }

  let displayValue;
  if (readOnly && options) {
    let optionValues: DropdownItemProps[] = options as DropdownItemProps[];
    optionValues.map((option) => {
      console.log(input.value);
      if (Array.isArray(input.value)) {
        console.log("input.value " + input.value);
        console.log("option " + option.value);
        (input.value as Array<number | string>).map((arrayItem) => {
          if (option.value === arrayItem) {
            if (displayValue === "") displayValue = option.text;
            else displayValue = displayValue + ", " + option.text;
          }
          return arrayItem;
        });
      } else if (option.value === input.value) {
        displayValue = option.text;
        console.log("selectedValue");
        console.log(option.text);
      }
      return option;
    });
  }
  const [stateError] = React.useState({
    touched: false,
  }); 
  const validation = (e) => {
    stateError.touched = true;
  }
  return (
    <Form.Field inline>
      {label && (
        <label style={labelstyle} className="fieldLabel">
          {label}{" "}
          {mandate && (
            <span
              style={{ color: "red", fontSize: 18, fontWeight: "bold" }}
              className="fieldLabel"
            >
              {mandate}
            </span>
          )}
        </label>
      )}{" "}
      <Select
        autoComplete="new-password"
        value={input.value.toString() === "0" ? "" : input.value}
        style={inputstyle}
        onChange={(e, data) => {
          input.onChange(data.value);
          if (onChange) {
            onChange(data);
            validation(e);
          }
          if (onCustomChange) {
            onCustomChange(data);
            validation(e);
          }
        } }
        placeholder={placeholder}
        options={options ? options : dropDownValues}
        selections={selection}
        search={search}
        multiple={input.multiple}
        loading={loading}
       // onBlur={validation}
        select = {select}
        
      />
      {readOnly && (
        <input
          value={displayValue ? displayValue : input.value}
          placeholder={placeholder}
          readOnly
          width = {width}
        />
      )}
      {stateError.touched && error && (
        <Label basic color="red" style={errorstyle}>
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default SelectInputTab;
