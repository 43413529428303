import React, { Fragment, useContext } from "react";
import { Button, Grid, Form } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import HeaderThree from "../../component/header/HeaderThree";
import { IContactValues } from "../../app/models/contact";
import { RootStoreContext } from "../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import { MessageType } from "../../app/common/enum";
import {
  combineValidators,
  composeValidators,
  hasLengthBetween,
  isNumeric,
  isRequired,
  matchesField,
  matchesPattern,
} from "revalidate";
import { useState } from "react";
import TextInput from "../../app/common/form/TextInput";
import TextAreaInput from "../../app/common/form/TextAreaInput";
interface IProps {
  newContact?: IContactValues;
}

const Contact: React.FC<IProps> = ({ newContact }) => {
  const rootStore = useContext(RootStoreContext);
  const { createContact } = rootStore.contactStore;

  return (
    <Fragment>
      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />
      <br />
      <br />
      <br />
      <div className="App-contact-overlap-group1">
        <div
          className="App-contact-overlap-group3"
          style={{ marginLeft: "100px" }}
        >
          <div>
            <h1 className="App-contact-contact-us App-contact-montserrat-black-white-40px">
              Contact Us
            </h1>
          </div>
          <br />
          <FinalForm
            onSubmit={(values: IContactValues) => {
              console.log("submit method called");
              values.sender = "PreLogin";
              values.receiver = "Admin";
              values.organisationName = "";
              values.messageType = MessageType.PreLogin;
              createContact(values).catch((error) => ({
                [FORM_ERROR]: error,
              }));
            }}
            //validate = {}
            render={({ handleSubmit, form, submitting }) => (
              <Form
                onSubmit={() => {
                  handleSubmit();
                  form.reset();
                }}
              >
                <Grid columns={2}>
                  <Grid.Column>
                    <label
                      className="App-contact-regular-normal-white-30px"
                      style={{ color: "white" }}
                    >
                      First Name
                    </label>
                    <Field
                      component={TextInput}
                      name="firstName"
                      placeholder="FirstName"
                      // validate={isRequired = true}
                    />

                    <label
                      className="App-contact-regular-normal-white-30px"
                      style={{ color: "white" }}
                    >
                      Mobile
                    </label>
                    <Field
                      component={TextInput}
                      name="phone"
                      placeholder="Mobile"
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <label
                      className="App-contact-regular-normal-white-30px"
                      style={{ color: "white" }}
                    >
                      Last Name
                    </label>
                    <Field
                      component={TextInput}
                      name="lastName"
                      placeholder="Last Name"
                    />

                    <label
                      className="App-contact-regular-normal-white-30px"
                      style={{ color: "white" }}
                    >
                      Email ID
                    </label>
                    <Field
                      component={TextInput}
                      name="email"
                      placeholder="EmailID"
                    />
                  </Grid.Column>
                </Grid>
                <br />

                <label
                  className="App-contact-regular-normal-white-30px"
                  style={{ color: "white" }}
                >
                  Message
                </label>
                <br />
                <Field
                  component={TextAreaInput}
                  name="message"
                  placeholder="Tell us more about..."
                />

                <Button
                  className="actionButton"
                  style={{ marginLeft: "250px", marginTop: "10px" }}
                  onClick={() => {
                    console.log("Submitted");
                  }}
                  content="Submit"
                  loading={submitting}
                  color="orange"
                  type="submit"
                ></Button>
              </Form>
            )}
          />
        </div>
        <div className="App-contact-auto-flex">
          <div className="App-contact-follow-us App-contact-montserrat-black-eerie-black-30px">
            Follow Us
          </div>
          <div className="social-icons">
            <Button
              circular
              size="large"
              color="blue"
              icon="facebook"
              a
              href="https://www.facebook.com/ChristianInstituteOfManagement/"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Button
              circular
              size="large"
              color="red"
              icon="youtube"
              a
              href="https://www.youtube.com/channel/UC-8RcJS0H5zsWgmajIPOIuQ"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Button
              circular
              size="large"
              color="blue"
              icon="linkedin"
              a
              href="https://www.linkedin.com/in/cim-executive-director-61a07215a"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Button
              circular
              size="large"
              color="blue"
              icon="twitter"
              a
              href="https://www.twitter.com/cim_india"
              target="_blank"
              rel="noopener noreferrer"
            />
            <Button
              circular
              size="large"
              color="orange"
              icon="instagram"
              a
              href="https://www.instagram.com/cim_news/"
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(Contact);
