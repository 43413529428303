import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Form as FinalForm, Field } from 'react-final-form';
import { Button, Segment, Form, Grid, Icon } from "semantic-ui-react";
import SelectInput from "../../app/common/form/SelectInput";
import TextInput from "../../app/common/form/TextInput";
import { IContactValues } from "../../app/models/contact";
import { RootStoreContext } from "../../app/stores/rootStore";
import { UserRole ,MessageType} from '../../app/common/enum';

interface IProps {
  editContact?: IContactValues,
}

const CreateMessage: React.FC<IProps> = ({ editContact }) => {

  const rootStore = useContext(RootStoreContext);
  const { orgDropdownValues } = rootStore.dashboardStore;
  const { createContact } = rootStore.contactStore;
  const [state] = React.useState({
    organisationId: "",
    organisationName: ""
  });
  const handleOrgNameChange = (event) => {
    state.organisationId = event.value;
    state.organisationName = event.options?.find(o => o.value === state.organisationId).text;

  };
  return (
    // <Segment >
    <FinalForm
      onSubmit={(values: IContactValues) => {
        if (rootStore.commonStore.role === UserRole.Administrator) {
          values.sender = "Admin";
          values.organisationName = state.organisationName;
          values.receiver = state.organisationId;
        }
        else {
          values.receiver = "Admin";
          values.email = String(rootStore.commonStore.email);
          values.firstName = String(rootStore.commonStore.userName);
          values.phone = String(rootStore.commonStore.mobile);
          values.senderId = rootStore.commonStore.registrationId;
          values.sender = String(rootStore.commonStore.registrationKey);
          values.organisationName = String(rootStore.commonStore.organisation);
          values.messageType = MessageType.PartnerMessage
        }
        createContact(values)
          .catch(error => ({
            [FORM_ERROR]: error
          }))
      }
      }
      render={({ handleSubmit: contactSubmit, form, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (

        <Segment style={{ marginLeft: '50px' }}>
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <div>
              <div style={{ textAlign: 'center' }}> SELECT ORGANISATION NAME</div><br />
            </div>
          ) : (<div>
            <div style={{ textAlign: 'center' }}> MESSAGE</div><br />
          </div>)}
          <Form onSubmit={async event => {
            await contactSubmit(event)
            form.reset()
          }} error>
            {rootStore.commonStore.role === UserRole.Administrator ? (

              <Field component={SelectInput} fluid placeholder='Organisation List' name={'organisationName'}
                search selection={"true"} options={orgDropdownValues} onCustomChange={handleOrgNameChange} format={key => key || []} />
            ) : (<div></div>)}
            <Field component={TextInput} fluid placeholder='Enter Message' name="message" />

            <Grid textAlign='center' style={{ margin: '4px' }}>
              <Button disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                loading={submitting} primary><Icon name='check' /> Submit </Button>

            </Grid>
          </Form>
        </Segment>
      )}>

    </FinalForm >
    // </Segment>
  )
}
export default observer(CreateMessage);

