import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { observer } from 'mobx-react-lite';

function HighestODScore(props) {
  const admindashboard = JSON.parse(props.beneficiaryScore)
  const [state] = React.useState({
    series: [{
      name: 'score',
      data: admindashboard && admindashboard.map(
        (x) => {
          return x.sumValuebymonth
        }
      )
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'year',
        categories: admindashboard && admindashboard.map(
          (x) => {
            return x.year
          }
        )
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}
export default observer(HighestODScore);