import React, { useContext, useEffect } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { history } from '../../../..';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { PendingOrApprove } from '../../../../app/models/dashboard';

const AssessmentAssessed: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { assessmentDetail ,getPendingAssessments} = rootStore.dashboardStore;
    const { getAssessmentAreaById } = rootStore.assessmentStore;
   
    useEffect(() => {
        let pendingReq = new PendingOrApprove();
        pendingReq.isPending = false;
        getPendingAssessments(pendingReq);
    }, [getPendingAssessments]);
    return (
        <div>
            <h3 style={{ textAlign: 'center' }}> List of Assessments - Approved</h3>
            <br />
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                        <Table.HeaderCell>Assessment Date</Table.HeaderCell>
                        <Table.HeaderCell>Score</Table.HeaderCell>
                        <Table.HeaderCell>Verified By CIM</Table.HeaderCell>
                        <Table.HeaderCell>View</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {assessmentDetail && assessmentDetail.map(
                        (assessment) => (
                            <Table.Row key={assessment.assessmentId}>
                                <Table.Cell>{assessment.organisationName}</Table.Cell>
                                <Table.Cell>{moment(assessment.assessmentDate).format('DD MMM YYYY')}</Table.Cell>
                                <Table.Cell>{assessment.score}</Table.Cell>
                                <Table.Cell>
                                    {
                                        assessment.verificationStatus === "Accepted" ?
                                            <Icon name='check' /> : " "
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                <Button circular color='twitter' icon="angle double right"
                                        onClick={() => {
                                            getAssessmentAreaById(assessment.assessmentId)
                                            history.push('/dod-assessmentDetail')
                                        }}
                                    >
                                       </Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

export default observer(AssessmentAssessed)