import React from 'react';
import { AxiosResponse } from 'axios';
import { Message } from 'semantic-ui-react';

interface IProps {
  error: AxiosResponse;
  frontEndError?: Error;
  text?: string;
}

const ErrorMessage: React.FC<IProps> = ({ error, text, frontEndError }) => {
  return (
    <Message error>
      <Message.Header>
        {
          `${error.statusText ||
          (frontEndError && frontEndError.message && frontEndError.message.substring(0, frontEndError.message.indexOf('-')))}`
        }</Message.Header>

      {error.data && Object.keys(error.data.errors).length > 0 && (
        <Message.List>
          {Object.values(error.data.errors).flat().map((err: any, i) => (
            <Message.Item key={i}>{err}</Message.Item>
          ))}
        </Message.List>
      )}
      {text && <Message.Content content={text} />}

      {frontEndError && frontEndError.message &&
        <Message.Content content={frontEndError.message.substring(frontEndError.message.indexOf('-') + 1)} />}
    </Message>
  );
};

export default ErrorMessage;
