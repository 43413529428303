import React, { useContext, useEffect } from 'react';
import { Segment, Item, Grid, Card, Button, Form } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import ODComplianceChart from '../odDashboard/odCharts/ODComplianceChart';
import { Field, Form as FinalForm } from "react-final-form";
import ODArea from '../odDashboard/odCharts/ODArea';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';
import { IAcceptRejectPartner } from '../../../app/models/donorOrg';
import { MessageType, UserRole } from '../../../app/common/enum';
import SelectInputTab from '../../../app/common/form/SelectInputTab';
import TextInputTab from '../../../app/common/form/TextInputTab';
interface Props {
  classes: any;
}

interface State {
  value: number;
}

function DonorSearchODDashboard(props) {
  const rootStore = useContext(RootStoreContext);
  const { partnerdashboard, getPartnerDashboard, loadingDashboard } = rootStore.partnerdashboardStore;
  const { odPartnerDetail, getOdPartnerById } = rootStore.partnerStore;
  const { donorOrgPartnerApprove } = rootStore.donorOrgStore;
  const { donorIndPartnerApprove } = rootStore.donorIndividualStore;
  const { fedEndoresedContact } = rootStore.contactStore;
  console.log(partnerdashboard);
  useEffect(() => {
    getPartnerDashboard(props.location.state.Id);
    getOdPartnerById(props.location.state.Id);
  }, [props, getPartnerDashboard, getOdPartnerById]);
  const [searchState] = React.useState({
    partnerId: 0,
    partnerKey: "",
    isInterest: true
  });

  const Giving = [
    { text: "Monthly", value: "Monthly", key: 1 },
    { text: "Quarterly", value: "Quarterly", key: 2 },
    { text: "Half-Yearly", value: "Half-Yearly", key: 3 },
    { text: "Annual", value: "Annual", key: 4 },
  ]

  const Mode = [
    { text: "Cash", value: "Cash", key: 1 },
    { text: "Cheque", value: "Cheque", key: 2 },
    { text: "CreditCard", value: "Cheque", key: 3 },
    { text: "GooglePay", value: "GooglePay", key: 4 },
    { text: "OnlineTransaction", value: "Online", key: 4 },
  ]
  const [Yesstate, setYesState] = React.useState({
    hiddenYes: true
  });
  const [Nostate, setNoState] = React.useState({
    hiddenNo: true
  });

  const [Checkstate] = React.useState({
    disabled: true,
    reason: "",
  });

  const [Hiddenstate, setHiddenState] = React.useState({
    reasonHidden: true
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Checkstate.reason = event.target.value;
    setHiddenState({ reasonHidden: true });
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Checkstate.reason = event.target.value;
    setHiddenState({ reasonHidden: false });
  };
  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHiddenState({ reasonHidden: false });
  };


  return (
    <div className='App-background'>
      {
        loadingDashboard && <LoadingComponent content="Loading PartnerView Dashboard" />
      }

      <Segment>
        <Grid columns={2}>
          <Grid.Column>
            <Item.Group link>
              <Item>
                <Item.Image height='92px' size='tiny' src={odPartnerDetail?.organizationLogo} />
                <Item.Content>
                  <Item.Header style={{ marginLeft: '50px', fontSize: 16, fontStyle: 'Italic' }}>{odPartnerDetail?.organizationName} </Item.Header>
                  <br />
                  <Item.Meta style={{ marginLeft: '50px', fontSize: 14, fontStyle: 'Italic' }}>State: {odPartnerDetail?.state}</Item.Meta>
                  <Item.Meta style={{ marginLeft: '50px', fontSize: 14, fontStyle: 'Italic' }}>Postal code: {odPartnerDetail?.pincode}</Item.Meta>
                  <br />
                </Item.Content>
              </Item>
            </Item.Group>
          </Grid.Column>
          <Grid.Column>
            <Item>
              <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>About Leadership Team</h5>
              <br />
              <Item.Content>
                <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>Founder Name: {odPartnerDetail?.founderTitle} {odPartnerDetail?.founderFirstName} {odPartnerDetail?.founderLastName} </Item.Header>
                <br />
                <Item.Header style={{ marginLeft: '150px', fontSize: 16, fontStyle: 'Italic' }}>CEO Name:  {odPartnerDetail?.ceoTitle} {odPartnerDetail?.ceoFirstName} {odPartnerDetail?.ceoLastName} </Item.Header>
                <br />
              </Item.Content>
            </Item>
          </Grid.Column>
        </Grid>

        <Card fluid color='blue'>
          <br />
          <Item>
            <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>About {odPartnerDetail?.organizationName} </h5>
            <Item.Content>
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Vision statement-  {odPartnerDetail?.visionStatement}</Item.Header>
              <br />
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Mission Statement -  {odPartnerDetail?.missionStatement}</Item.Header>
              <br />
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Faith Statement -  {odPartnerDetail?.faithStatement}</Item.Header>
              <br />
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Core Values -  {odPartnerDetail?.coreValues}</Item.Header>
              <br />
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Major Activities -  {odPartnerDetail?.majorActivities}</Item.Header>
              <br />
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Objective Of Organization -  {odPartnerDetail?.objectiveOfOrganization}</Item.Header>
              <br />
              {odPartnerDetail?.organizationInfoOrBrouche !== undefined}
              <Item.Header style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>Organization Broucher -  <a style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}
                href={odPartnerDetail?.organizationInfoOrBrouche} target="_blank" rel="noopener noreferrer"> click here</a>   </Item.Header>

              <br />
            </Item.Content>


          </Item>
        </Card>

        <br />
        <br />

        <Grid>
        
            <br />
            <br />
            <br />
            <br />
            <br />
          <Grid.Column width={9}   >
            <br />
            <br />
            <br />
            <br />
            {partnerdashboard !== undefined ? (
              <ODComplianceChart data={partnerdashboard?.pointPercentage} />
            ) : (<div></div>)}   <br />
            <br />
            <br />
          </Grid.Column>
          <Grid.Column width={11} >
            <br />
            {/* <h5 style={{textAlign: 'center',fontSize: 20 , fontWeight: 'bold', fontStyle: 'Italic'}}>Area</h5> */}
            {partnerdashboard !== undefined ? (
              <ODArea areagraph={JSON.stringify(partnerdashboard)} />
            ) : (<div></div>)}
            <br />
            <br />
          </Grid.Column>
        </Grid>
        {rootStore.commonStore.role !== UserRole.Administrator &&
          <Card fluid color='blue'>
            <div className='App-support'>
              <br />
              <Item>
                <h5 style={{ textAlign: 'center', color: 'white', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>Are you interested to support this ministry</h5>

                <Button type="button" onClick={() => {
                  setYesState({ hiddenYes: false }); setNoState({ hiddenNo: true })
                }}>YES</Button>

                <Button type="button" onClick={() => { setYesState({ hiddenYes: true }); setNoState({ hiddenNo: false }) }} style={{ marginLeft: '25px' }} >NO</Button>

              </Item>
              <br />
            </div>
          </Card>
        }
        <FinalForm initialValues={null}
          onSubmit={async (values: IAcceptRejectPartner) => {
            values.partnerId = odPartnerDetail.partnerBasicDetailId;
            values.isInterest = searchState.isInterest;
            values.rejectReason = Checkstate.reason;
            const donorOrgName = String(rootStore.commonStore.organisation);
            if (rootStore.commonStore.registrationKey?.startsWith("ODDO")) {
              values.donorOrgId = rootStore.commonStore.registrationId;
              values.donorOrgName = donorOrgName;
              values.partnerName = odPartnerDetail.organizationName;
              donorOrgPartnerApprove(values);
              if (Yesstate.hiddenYes === false) {
                Yesstate.hiddenYes = true;
              }
              if (Nostate.hiddenNo === false) {
                Nostate.hiddenNo = true;
              }
            }
            if (rootStore.commonStore.registrationKey?.startsWith("ODDI")) {
              values.donorIndividualId = rootStore.commonStore.registrationId;
              values.donorIndividualName = String(rootStore.commonStore.fullName);
              donorIndPartnerApprove(values);
              if (Yesstate.hiddenYes === false) {
                Yesstate.hiddenYes = true;
              }
              if (Nostate.hiddenNo === false) {
                Nostate.hiddenNo = true;
              }
            }

            //contact save

            values.organisationName = donorOrgName;
            values.receiver = "Admin";
            values.isRead = false;
            values.sender = String(rootStore.commonStore.registrationKey);
            values.email = String(rootStore.commonStore.email);
            values.messageType = MessageType.DonorInterestmessage;
            
            if (searchState.isInterest === false)
              values.message = donorOrgName + " was not interested to support the " + odPartnerDetail.organizationName + " for the reason " + Checkstate.reason;
            else
              values.message = donorOrgName + " has been interested to support the  " + odPartnerDetail.organizationName;
            fedEndoresedContact(values)

            const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
            await sleep(300);

            // values.organisationName = donorOrgName;
            // values.receiver = odPartnerDetail.partnerKey;
            // values.sender =String( rootStore.commonStore.registrationKey);
            // if (searchState.isInterest === false)
            //   values.message =donorOrgName + " has been rejected the membership approval with the reason is " + Checkstate.reason;
            // else
            //   values.message =donorOrgName + " has been accepted the membership approval ";
            // fedEndoresedContact(values)
          }
          }
          render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, form }) => (
            <Form onSubmit={async event => {
              await handleSubmit(event);
              form.reset();


            }}>
              <Grid columns={3} padded>
                <Grid.Column width={3}>

                </Grid.Column>
                <Grid.Column width={10} hidden={Yesstate.hiddenYes ? true : false}>
                  <Card fluid color='blue'>
                    <br />
                    <Item>
                      <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>I am interested </h5>
                      <Item.Content>

                        <Field label="Preferred Amount :" labelstyle={{ marginLeft: '100px', fontSize: 16, fontStyle: 'Italic' }}
                          inputstyle={{ marginLeft: '93px', width: '192pxx ' }} component={TextInputTab} name="preferredAmount"></Field>



                        <Field label="Preferred Frequency :" labelstyle={{ marginLeft: '100px', fontSize: 16, fontStyle: 'Italic' }}
                          component={SelectInputTab} placeholder="Frequency" inputstyle={{ marginLeft: '69px', width: '25pxx ' }} options={Giving} name="preferredFrequency"></Field>

                        <Field label="Preferred Mode of Payment :" labelstyle={{ marginLeft: '100px', fontSize: 16, fontStyle: 'Italic' }}
                          component={SelectInputTab} placeholder="Mode of Payment" inputstyle={{ marginLeft: '18px', width: '25pxx ' }} options={Mode} name="modeOfPayment"></Field>


                        <Field label="Preferred Project :" labelstyle={{ marginLeft: '100px', fontSize: 16, fontStyle: 'Italic' }}
                          component={TextInputTab} inputstyle={{ marginLeft: '101px', width: '192px' }} name="preferredProject"></Field>

                        <br />
                        <Item.Header style={{ marginLeft: '100px', fontSize: 13, fontStyle: 'Italic' }}>

                          <a href={odPartnerDetail?.organizationInfoOrBrouche} rel="noopener noreferrer" target="_blank">
                            click here for more info about the project</a>
                        </Item.Header>
                        <br />

                        <Button type="submit" style={{ marginLeft: '500px' }} color='blue'
                          onClick={() => {
                            searchState.isInterest = true;

                          }}  >
                          GO
                  </Button>

                        <br />
                        <br />
                      </Item.Content>
                    </Item>
                  </Card>
                </Grid.Column>
                <Grid.Column width={10} hidden={Nostate.hiddenNo ? true : false}>
                  <Card fluid color='blue'>
                    <br />
                    <Item>
                      <h5 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>I am not interested</h5>
                      <Item.Content>
                        <label style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>
                          <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Not an  Inspiring Mission/Vision '
                            name="actionPoint" onChange={handleChange} />
                    &nbsp;&nbsp;Not an Inspiring Mission/Vision
                </label>

                        <br />
                        <label style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>
                          <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Not an  Inspiring Project/Program '
                            name="actionPoint" onChange={handleChange} />
                     &nbsp;&nbsp;Not an Inspiring Project/Program
                </label>
                        <br />
                        <label style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>
                          <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Objective was not clear '
                            name="actionPoint" onChange={handleChange} />
                        &nbsp;&nbsp;Objective was not clear
                </label>
                        <br />

                        <label style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>
                          <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Overall rating was low'
                            name="actionPoint" onChange={handleChange} />
                        &nbsp;&nbsp;Overall rating was low
                </label>
                        <br />
                        <label style={{ marginLeft: '25px', fontSize: 16, fontStyle: 'Italic' }}>
                          <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }}
                            value='My concern was not listed -' name="actionPoint" onChange={handleOtherChange} />
                         &nbsp;&nbsp;My concern was not listed -
                </label>
                        <br />
                        <br />

                        <label style={{ marginLeft: '60px', fontSize: 15, fontStyle: 'Italic' }} hidden={Hiddenstate.reasonHidden ? true : false}>Specify other reason :</label>
                        <br></br>

                        <input style={{ marginLeft: '101px', width: '192px' }} name="Other" onChange={handleTextChange} hidden={Hiddenstate.reasonHidden ? true : false} ></input>
                        <Button.Group style={{ marginLeft: '148' }} color='blue' >


                          <Button type="submit" style={{ marginLeft: '345px' }} color='blue'
                            onClick={() => {
                              searchState.isInterest = false;
                            }}  > GO </Button>
                        </Button.Group>
                        <br />
                        <br />

                      </Item.Content>
                    </Item>
                  </Card>
                </Grid.Column>
              </Grid>   </Form>
          )}

        ></FinalForm>

      </Segment>
    </div>
  );
}
export default observer(DonorSearchODDashboard);


