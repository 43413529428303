import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { IAreaOfAssessmentValues } from "../../../../app/models/areaOfAssessment";
import TextInput from "../../../../app/common/form/TextInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
  editArea?: IAreaOfAssessmentValues;
}

const CreateEditArea: React.FC<IProps> = ({ editArea }) => {
  const rootStore = useContext(RootStoreContext);
  const { upsertArea, loadingArea } = rootStore.areaOfAssessmentStore;

  return (
    <FinalForm
      initialValues={editArea}
      onSubmit={(values: IAreaOfAssessmentValues) =>
        upsertArea(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      render={({
        handleSubmit: areaSubmit,
        form,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <div style={{ marginLeft: "50px" }}>
            <Form
              onSubmit={async (event) => {
                await areaSubmit(event);
                if (editArea === undefined) {
                  form.reset();
                }
              }}
            >
              <Field
                component={TextInput}
                fluid
                placeholder="Enter Area Name"
                name="areaName"
              />
              <Field
                component={TextInput}
                fluid
                placeholder="Enter Area Icon"
                name="areaIcon"
              />
              <Grid textAlign="center" style={{ margin: "4px" }}>
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={loadingArea}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </div>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(CreateEditArea);
