import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Grid, Icon } from "semantic-ui-react";

import TextInput from "../../../../app/common/form/TextInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { IState } from "../../../../app/models/state";

interface IProps {
  editState?: IState;
}

const CreateEditState: React.FC<IProps> = ({ editState }) => {
  const rootStore = useContext(RootStoreContext);
  const { upsertState, loadingState } = rootStore.stateStore;

  return (
    <FinalForm
      initialValues={editState}
      onSubmit={(values: IState) => {
        upsertState(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({
        handleSubmit: areaSubmit,
        form,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <div style={{ marginLeft: "50px" }}>
            <Form
              onSubmit={async (event) => {
                await areaSubmit(event);
                if (editState === undefined) {
                  form.reset();
                }
              }}
            >
              <Field
                component={TextInput}
                fluid
                placeholder="Enter State"
                name="stateName"
              />

              <Grid textAlign="center" style={{ margin: "4px" }}>
                <br />
                <br />
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={loadingState}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </div>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(CreateEditState);
