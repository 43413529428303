import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { toast } from "react-toastify";
import {
  Button,
  Segment,
  Header,
  Icon,
  Form,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { ISectorFormValues } from "../../../../app/models/sector";
// import SelectInput from "../../../app/common/form/SelectInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import CreateEditSector from "./CreateEditSector";
import CreateSector from "./CreateEditSector";
import DisableSector from "./DisableSector";

const SectorList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getSectorFromId,
    getSector,
    loadingSectors,
    sectorMasterDropdownValues,
  } = rootStore.sectorStore;
  const [selectedSectorId, setSelectedSectorId] = useState<any>(-1);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    getSector();
  }, [getSector]);

  return (
    <FinalForm
      onSubmit={() => {}}
      render={({
        handleSubmit: sectorSubmit,
        submitting,
        form,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <br />
          <br />
          <Segment style={{ marginLeft: "75px" }}>
            <Segment color="violet">
              <Header as="h4" icon textAlign="center">
                <Header.Content>SECTOR</Header.Content>
              </Header>
            </Segment>

            <Form
              onSubmit={async (event) => {
                await sectorSubmit(event);
                form.reset();
              }}
              error
            >
              <Button
                color="blue"
                content="Create Sector"
                floated="right"
                onClick={() => {
                  openRightPanel("Create Sector", <CreateEditSector />);
                }}
              />
              <Dropdown
                search
                selection
                options={sectorMasterDropdownValues}
                loading={loadingSectors}
                placeholder="Select Sector"
                onChange={(event, data: DropdownProps) => {
                  if (data.value) setSelectedSectorId(data.value);
                }}
              ></Dropdown>

              <br />
              <br />

              <Button.Group attached="bottom">
                <Button
                  basic
                  color="blue"
                  loading={submitting}
                  onClick={() => {
                    openRightPanel(
                      "Edit Sector",
                      <CreateSector
                        editSector={getSectorFromId(selectedSectorId)}
                      />
                    );
                  }}
                >
                  <Icon name="edit" />
                  View & Edit
                </Button>
                <Button
                  basic
                  color="blue"
                  loading={submitting}
                  onClick={() => {
                    if (selectedSectorId === "") {
                      toast.error("Please select from dropdown");
                    } else {
                      let sector: ISectorFormValues = getSectorFromId(
                        selectedSectorId
                      );

                      openModal(
                        `${sector.isActive ? "Disable" : "Enable"} Sector`,
                        <DisableSector disableEnableSector={sector} />
                      );
                    }
                  }}
                >
                  <Icon name="trash" />
                  Disable & Enable{" "}
                </Button>
              </Button.Group>
            </Form>
          </Segment>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(SectorList);
