import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { history } from "../../";
// import CategoryIcon from '@material-ui/icons/Category';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
// import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Button, Icon, Image, Menu, Label } from 'semantic-ui-react';
// import { findAllByTestId } from '@testing-library/react';
import Logo from './../../assets/images/cimlogoweb.png'
import { RootStoreContext } from '../../app/stores/rootStore';
import ChangePassword from '../auth/ChangePassword';
import { UserRole } from '../../app/common/enum';
import OrgInbox from '../mailBox/OrgInbox';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },

}));

export default function MiniVariant() {

  const classes = useStyles();
  const theme = useTheme();
  const rootStore = useContext(RootStoreContext);
  const { role, registrationKey } = rootStore.commonStore;
  const { getContactCount, contactCount } = rootStore.contactStore;
  useEffect(() => {
    if (role === "Administrator") {
      getContactCount("0");
    }
    else {
      getContactCount(String(registrationKey));
    }
  }, [getContactCount, role, registrationKey]);
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { openRightPanel } = rootStore.rightPanelStore;


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(open);
  };

  const redirectHome = () => {
    if (rootStore.commonStore.role === UserRole.Administrator)
      window.location.href = '/dod-adminDashboard';
    else if (rootStore.commonStore.role === UserRole.ODPartner)
      window.location.href = '/dod-odDashboard';
    else if (rootStore.commonStore.role === UserRole.Federation)
      window.location.href = '/dod-federationDashboard';
    else if (rootStore.commonStore.role === UserRole.DonorOrganisation)
      window.location.href = '/dod-donorDashboard';
    else if (rootStore.commonStore.role === UserRole.DonorIndividual)
      window.location.href = '/dod-donorIndDashboard';
  };
  return (

    <div className={classes.root}>

      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Image src={Logo} style={{ height: '10vh', padding: '5px' }} />
          <div >
            <h6 style={{ color: 'white', fontSize: '12px', fontStyle: 'italic', marginLeft: '350px', fontWeight: 'normal' }}> Therefore encourage one another and build each other up, just as in fact you are doing. </h6>
          </div>
          {/* <Typography variant="h6" noWrap style={{ fontSize: '2em', fontStyle: 'italic', padding: '20px'}}>
               Digital Organizational Development
          </Typography>  */}
          <Menu.Menu position='right'>
            <Menu.Item>
              <Button style={{ marginLeft: '50px' }} onClick={() => {
                 rootStore.commonStore.registrationId = 0;
                 history.push("/");
                }}>Logout
              
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />

        <List>

          <ListItem button onClick={redirectHome} >
            <ListItemIcon><Icon name="home" style={{ fontSize: 'large' }} color="blue" /></ListItemIcon>
            <ListItemText primary="Home "></ListItemText>
          </ListItem>
          {/* {rootStore.commonStore.role === 'ODPartner' ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding >
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-beneficiarydashboard'}>
                  <ListItemIcon>
                    <GridOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="OD Partner" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)} */}
          {/* {rootStore.commonStore.role === 'Federation' || rootStore.commonStore.role === 'Administrator' ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-federationdashboard'}>
                  <ListItemIcon>
                    <GridOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Federation" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)} */}
          {/* {rootStore.commonStore.role === 'DonorOrganisation' || rootStore.commonStore.role === 'Administrator' ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-donordashboard'}>
                  <ListItemIcon>
                    <GridOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Donor" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)} */}
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <ListItem button onClick={handleClick} >

              <ListItemIcon  > <Icon name="user" style={{ fontSize: 'large' }} color="blue" /></ListItemIcon>
              <ListItemText primary="Admin"></ListItemText>
              {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
          ) : (<div></div>)}
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-manageUser'}>
                  <ListItemIcon>
                    <Icon name="user plus" style={{ fontSize: 'large' }} color="blue" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Users" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)}
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-master'}>
                  <ListItemIcon>
                    <Icon name="setting" style={{ fontSize: 'large' }} color="blue" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Master" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)}
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-plan'}>
                  <ListItemIcon>
                    <Icon name="vcard" style={{ fontSize: 'large' }} color="blue" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Plan" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)}
          {rootStore.commonStore.role === UserRole.Administrator ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-questionDashboard'}>
                  <ListItemIcon>
                    <Icon name="clipboard list" style={{ fontSize: 'large' }} color="blue" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Question" />
                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)}

          {rootStore.commonStore.role === UserRole.Administrator ? (
            <Collapse in={open} timeout="auto" >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={event => window.location.href = '/dod-adminInbox'}>
                  <ListItemIcon>

                    <Icon name="mail" color="blue" style={{ fontSize: 'large' }} />

                    {contactCount?.count !== 0 ? (
                      <Label size="mini" color="blue" circular> {contactCount?.count} </Label>
                    )
                      : (
                        <div></div>)}
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />

                </ListItem>
              </List>
            </Collapse>
          ) : (<div></div>)}
          {rootStore.commonStore.role !== UserRole.Administrator ? (
            <ListItem button onClick={event => window.location.href = '/dod-plan'} >
              <ListItemIcon  >  <Icon name="shopping cart" style={{ fontSize: 'large' }} color="blue"> </Icon></ListItemIcon>
              <ListItemText primary="Plan"></ListItemText>
            </ListItem>
          ) : (<div></div>)}

          {rootStore.commonStore.role === UserRole.ODPartner ? (
            <div>
              <ListItem button onClick={event => window.location.href = '/dod-assessment'}>
                <ListItemIcon  ><Icon name="clipboard" style={{ fontSize: 'large' }} color="blue" /></ListItemIcon>
                <ListItemText primary="Assessment"></ListItemText>
              </ListItem>

              <ListItem button onClick={() => {
                 rootStore.registrationOTPStore.index = 2;
                 history.push("/dod-odView");
                }
              }
              >
                <ListItemIcon  >  <Icon name="user circle" style={{ fontSize: 'large' }} color="blue"> </Icon></ListItemIcon>
                <ListItemText primary="View Profile"></ListItemText>
              </ListItem>
            </div>
          ) : (<div></div>)}

          <ListItem button onClick={() => {
            openRightPanel('Change Password', <ChangePassword />)
          }}>
            <ListItemIcon  >
              <Icon name="key" style={{ fontSize: 'large' }} color="blue" /></ListItemIcon>
            <ListItemText primary="Change Password"></ListItemText>
          </ListItem>
          )
          {/* {rootStore.commonStore.role !== 'Administrator' ? (
            <ListItem button onClick={event => window.location.href = '/dod-iplan'} >
              <ListItemIcon  ><AttachMoneyIcon /></ListItemIcon>
              <ListItemText primary="Plan"></ListItemText>
            </ListItem>
          ) : (<div></div>)} */}
          {rootStore.commonStore.role === UserRole.ODPartner ? (
            <ListItem button onClick={() => {
              openRightPanel('Inbox', <OrgInbox />)
            }}>

              <ListItemIcon >
                <Icon name="mail" style={{ fontSize: 'large' }} onClick={getContactCount(String(rootStore.commonStore.registrationKey))} color="blue" />
                {contactCount?.count !== 0 ? (
                  <Label size="mini" color="blue" circular> {contactCount?.count} </Label>
                )
                  : (
                    <div></div>)}
              </ListItemIcon>


              <ListItemText primary="Inbox"></ListItemText>

            </ListItem>

          ) : (<div></div>)}
          {(rootStore.commonStore.role === UserRole.Federation || rootStore.commonStore.role === UserRole.DonorOrganisation ||
            rootStore.commonStore.role === UserRole.DonorIndividual) ? (
            <ListItem button onClick={() => {
              openRightPanel('Inbox', <OrgInbox />)
            }}>
              <ListItemIcon  > <Icon name="mail" style={{ fontSize: 'large' }} color="blue" /></ListItemIcon>
              <ListItemText primary="Inbox"></ListItemText>
            </ListItem>
          ) : (<div></div>)}



        </List>
        <Divider />

      </Drawer>


    </div>
  );

}

