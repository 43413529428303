import React, { Fragment, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { RootStoreContext } from '../stores/rootStore'
import { toast } from 'react-toastify';

export const IdleTimerComponent: React.FC = ({ children }) => {
    const rootStore = useContext(RootStoreContext);
    const { logout, isLoggedIn } = rootStore.authStore;


    const handleOnIdle = (event: any) => {
        console.log(getLastActiveTime());
        let remaining = getRemainingTime();
        if (isLoggedIn) {
            if (remaining === 0) {
                toast.error('Your session has been expired. You have been logged out.')
                logout();
            }
            else {
                //this line is not called
                toast.info('You have idle for sometime. You will be logged out in ' + remaining + ' minutes')
            }
        }
    }

    const handleOnActive = (event: any) => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
    }


    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 20,
        onIdle: handleOnIdle,
        onActive: handleOnActive,

        debounce: 500
    })

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}