
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { observer } from 'mobx-react-lite';

function SectorwiseRegistration(props) {
  const admindashboard = JSON.parse(props.org);
  const sectorsum = admindashboard && admindashboard.map(a => a.sectorSum)
    .reduce((prev, current) => prev.concat(current), [])
    .map((column, index) => {
      return column
    });
  const sectornamesum = Object.values(sectorsum.reduce((acc, item) => {
    if (!acc[item.sectorName]) acc[item.sectorName] = {
      name: item.sectorName,
      data: []
    };
    acc[item.sectorName].data.push(item.count);
    return acc;
  }, {}))

  const [state] = React.useState({
    series: sectornamesum,
    options: {
      chart: {
        type: 'bar',
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
        },
        textAnchor: 'start',

        formatter: function (val, opt) {
          return opt.w.globals.seriesNames[opt.seriesIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: admindashboard && admindashboard.map(
          (x) => {
            return x.year
          }
        ),
      },
    },


  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={430} />
      </div>
      <div id="html-dist"></div>
    </div>
  );

} export default observer(SectorwiseRegistration);