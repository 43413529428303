export interface IDonorOrgRegisterValues {
  OrganizationName: string;
  YearOfInception: string;
  RegisteredAddress: string;
  CommunicationAddress: string;
  Country: string;
  State: string;
  Pincode: string;
  OfficialEmailId: string;
  Password: string;
  OfficeContactumber: string;
  MobileContactNumber: string;
  WebsiteAddress: string;
  WhatsappNumber: string;
  FacebookID: string;
  TwitterID: string;
  OtherMediaLink: string;
  CEOTitle: string;
  CEOFirstName: string;
  CEOLastName: string;
  CEOMobile: string;
  CEOEmailId: string;
  CEODesignation: string;
  CEOPhoto: string;
  TrusteeorManagementProfile: string;
  MissionStatement: string;
  FaithStatement: string;
  VisionStatement: string;
  CoreValues: string;
  ObjectiveOfOrganization: string;
  MajorActivities: string;
  OrganizationInfoOrBrouche: string;
  OrganizationLogo: string;
  MyPreferedWork: string;
  Others: string;
  MyGivingPreference: string;
  GivingFrequence: string;
  MyPreferedChurchDomination: string;
  MyPreferedState: string;
  MyPreferedDistrict: string;
  MyPreferedCommunity: string;
  MyPreferedTribe: string;
  MyPreferedLanguage: string;
  PreferedPincode: string;
  IamPresenting: string;
  ProofofTotalContribution: string;
  GivingTrackRecord: string;
  refTitle1: string;
  refFirstName1: string;
  refLastName1: string;
  refMobile1: string;
  refEmail1: string;
  refDesignation1: string;
  refOrganization1: string;
  refTitle2: string;
  refFirstName2: string;
  refLastName2: string;
  refMobile2: string;
  refEmail2: string;
  fedarationBasicDetailIds: number[];
  refDesignation2: string;
  refOrganization2: string;
  // DonorOrgReferenceDetailJson: DonorOrgReferenceDetailJson[];
  DonorOrgContactPersonJson: DonorOrgContactPersonJson[];
}

export class DonorOrgRegisterValues implements IDonorOrgRegisterValues {
         OrganizationName: string = "";
         YearOfInception: string = "";
         RegisteredAddress: string = "";
         CommunicationAddress: string = "";
         Country: string = "";
         State: string = "";
         Pincode: string = "";
         OfficialEmailId: string = "";
         Password: string = "";
         OfficeContactumber: string = "";
         MobileContactNumber: string = "";
         WebsiteAddress: string = "";
         WhatsappNumber: string = "";
         FacebookID: string = "";
         TwitterID: string = "";
         OtherMediaLink: string = "";
         CEOTitle: string = "";
         CEOFirstName: string = "";
         CEOLastName: string = "";
         CEOMobile: string = "";
         CEOEmailId: string = "";
         CEODesignation: string = "";
         CEOPhoto: string = "";
         TrusteeorManagementProfile: string = "";
         MissionStatement: string = "";
         FaithStatement: string = "";
         VisionStatement: string = "";
         CoreValues: string = "";
         ObjectiveOfOrganization: string = "";
         MajorActivities: string = "";
         OrganizationInfoOrBrouche: string = "";
         OrganizationLogo: string = "";
         MyPreferedWork: string = "";
         Others: string = "";
         MyGivingPreference: string = "";
         GivingFrequence: string = "";
         MyPreferedChurchDomination: string = "";
         MyPreferedState: string = "";
         MyPreferedDistrict: string = "";
         MyPreferedCommunity: string = "";
         MyPreferedTribe: string = "";
         MyPreferedLanguage: string = "";
         PreferedPincode: string = "";
         IamPresenting: string = "";
         ProofofTotalContribution: string = "";
         GivingTrackRecord: string = "";
         refTitle1: string = "";
         refFirstName1: string = "";
         refLastName1: string = "";
         refMobile1: string = "";
         refEmail1: string = "";
         refDesignation1: string = "";
         refOrganization1: string = "";
         refTitle2: string = "";
         refFirstName2: string = "";
         refLastName2: string = "";
         refMobile2: string = "";
         refEmail2: string = "";
         fedarationBasicDetailIds: number[] = [];
         refDesignation2: string = "";
         refOrganization2: string = "";
         DonorOrgContactPersonJson: DonorOrgContactPersonJson[] = [];
         constructor(init?: DonorOrgRegisterValues) {
           Object.assign(this, init);
         }
       }
export interface IDonorOrgReferenceDetailJson {
  Title: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
  Email: string;
  Designation: string;
  Organization: string;
}

export class DonorOrgReferenceDetailJson
  implements IDonorOrgReferenceDetailJson {
  Title: string = "";
  FirstName: string = "";
  LastName: string = "";
  Mobile: string = "";
  Email: string = "";
  Designation: string = "";
  Organization: string = "";
}

export interface IDonorOrgContactPersonJson {
  ContactTitle: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactMobile: string;
  ContactEmailId: string;
  ContactDesignation: string;
  // Organization: string;
}

export class DonorOrgContactPersonJson implements IDonorOrgContactPersonJson {
  ContactTitle: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactMobile: string = "";
  ContactEmailId: string = "";
  ContactDesignation: string = "";
}

export interface IDonorOrgApproveOrReject {
  DonorOrganisationBasicDetailId?: number | undefined;
  IsApproved: boolean;
  VerifiedBy: string;
  VerifiedComment: string;
}

export class DonorOrgApproveOrReject implements IDonorOrgApproveOrReject {
  DonorOrganisationBasicDetailId?: number = undefined;
  IsApproved: boolean = false;
  VerifiedBy: string = "";
  VerifiedComment: string = "";

  constructor(init?: IDonorOrgApproveOrReject) {
    Object.assign(this, init);
  }
}

export interface IDonorOrgRegister {
  donorOrgBasicDetailId: number;
  organizationName: string;
  yearOfInception: string;
  registeredAddress: string;
  communicationAddress: string;
  state: string;
  pincode: string;
  officialEmailId: string;
  password: string;
  officeContactumber: string;
  mobileContactNumber: string;
  websiteAddress: string;
  whatsappNumber: string;
  facebookID: string;
  twitterID: string;
  otherMediaLink: string;
  ceoTitle: string;
  ceoFirstName: string;
  ceoLastName: string;
  ceoMobile: string;
  ceoEmailId: string;
  ceoDesignation: string;
  ceoPhoto: string;
  trusteeorManagementProfile: string;
  missionStatement: string;
  faithStatement: string;
  visionStatement: string;
  coreValues: string;
  objectiveOfOrganization: string;
  majorActivities: string;
  organizationInfoOrBrouche: string;
  organizationLogo: string;
  myPreferedWork: string;
  others: string;
  myGivingPreference: string;
  givingFrequence: string;
  myPreferedChurchDomination: string;
  myPreferedState: string;
  myPreferedDistrict: string;
  myPreferedCommunity: string;
  myPreferedTribe: string;
  myPreferedLanguage: string;
  preferedPincode: string;
  iamPresenting: string;
  proofofTotalContribution: string;
  givingTrackRecord: string;
  verificationStatus: string;
  refTitle1: string;
  refFirstName1: string;
  refLastName1: string;
  refMobile1: string;
  refEmail1: string;
  refDesignation1: string;
  refOrganization1: string;
  refTitle2: string;
  refFirstName2: string;
  refLastName2: string;
  refMobile2: string;
  refEmail2: string;
  refDesignation2: string;
  refOrganization2: string;
  donorOrgReferenceId: number;
  donorOrgContactViewModel: [
    {
      donorOrgContactPersonId: number;
      contactTitle: string;
      contactFirstName: string;
      contactLastName: string;
      contactMobile: string;
      contactEmailId: string;
      contactDesignation: string;
    }
  ];
}
export interface IAcceptRejectPartner {
  donorOrgId: number;
  donorOrgName: string; // for toast msg
  donorIndividualId: number;
  donorIndividualName: string; //for toast msg
  partnerId: number;
  partnerName: string; // for toast msg
  isInterest: boolean;
  preferredAmount: string;
  preferredFrequency: string;
  modeOfPayment: string;
  preferredProject: string;
  rejectReason: string;
  contactId?: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
  organisationName?: string;
  date: string;
  senderId?: number;
  sender?: string;
  receiverId?: number;
  receiver?: string;
  messageType: number;
  isRead: boolean;
}
export interface IApprovedParterProfile {
  donorId: number;
  donorIndividualId: number;
  partnerId: number;
  partnerName: string;
  partnerLogo: string;
  isInterest: boolean;
  preferredAmount: string;
  preferredFrequency: string;
  modeOfPayment: string;
  preferredProject: string;
  rejectReason: string;
}
