import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { IDonorOrgApproveOrReject } from "../../../../app/models/donorOrg";

interface IProps {
    approveOrRejectDonorOrg: IDonorOrgApproveOrReject,
}

const DonorOrgsApproveOrReject: React.FC<IProps> = ({ approveOrRejectDonorOrg: approveOrRejectDonorOrgProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {donorOrgApprove} = rootStore.donorOrgStore;

    return (
        <FinalForm
            initialValues={approveOrRejectDonorOrgProps}
            onSubmit={(values: IDonorOrgApproveOrReject) => 
                donorOrgApprove(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Segment>
                {/* <Header color={approveOrRejectDonorOrgProps.IsApproved ? 'green' : 'red'} style={{ height: '6vh' }}>{approveOrRejectDonorOrgProps.IsApproved ? 'Approve' : 'Reject'} !</Header> */}
                Kindly confirm donor organisation can be {approveOrRejectDonorOrgProps.IsApproved ? 'Approved' : 'Rejected'}
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '150px'}} loading ={submitting}>{approveOrRejectDonorOrgProps.IsApproved ? 'Approve' : 'Reject'}</Button>
                </Segment>
                </Form>
                 )}>
        </FinalForm>        
    )
}

export default observer(DonorOrgsApproveOrReject);