import React, { useContext } from 'react'
import logo from '../../../src/assets/images/logo/logo-symbol-dark.png'
import { Form, Grid, Header, Image, Button } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { RootStoreContext } from '../../app/stores/rootStore'
import {  IVerifyOtpFormValues} from '../../app/models/user'
import { FORM_ERROR } from 'final-form'
import { combineValidators,isRequired,isNumeric ,matchesPattern ,matchesField,composeValidators} from 'revalidate'
import ErrorMessage from '../../app/common/form/ErrorMessage'
import TextInput from '../../app/common/form/TextInput'
import { observer } from 'mobx-react-lite'

function VerifyOtp() {
  const rootStore = useContext(RootStoreContext);
  const { verifyOtp , isForgetPasswordLoading} = rootStore.authStore;

  const validate = combineValidators({
    otp: isRequired('OTP'),
    confirmPassword: composeValidators(
      isRequired({ message: "Please enter the Confirm Password" }),
      matchesField("newPassword", "Enter New Password")({
        message: "Password and Confirm Password must be same",
      })
    )(),
    newPassword: composeValidators(
      isRequired({ message: "Please enter the Password" }),
      matchesPattern(
        rootStore.commonStore.passwordRegExp
      )({
        message:
          "Password must contain at least 8 characters, including uppercase & lowercase letters, special character and numbers",
      })
    )(),
  
})


  return (
    <Grid textAlign='center' style={{ height: '80vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center' style={{ color: '#3f66b5',fontStyle: 'italic' }}>
          <Image src={logo} style={{ width: '23vh' }} />
          <br />
           SET NEW PASSWORD
        </Header>
        <FinalForm
          onSubmit={(values: IVerifyOtpFormValues) => 
            {
            values.mobileNumber = window.localStorage.getItem('mobile') as string ; 
            verifyOtp(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
                  }
        }

          validate={validate}
          render={({
            handleSubmit,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit
          }) => (
              <Form onSubmit={handleSubmit} error>
                {/* <Field name='mobileNumber' component={TextInput} placeholder='Enter Mobile Number' /> */}
                
                <Field name='otp' component={TextInput} placeholder='Enter OTP' />
                <Field name='newPassword' component={TextInput} placeholder='Enter New Password' type='password' />
                <Field name='confirmPassword' component={TextInput} placeholder='Confirm Password' type='password' />
                
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage
                    error={submitError}
                    frontEndError={submitError}
                  />
                )}
                <Button
                  disabled={invalid || rootStore.authStore.isForgetPasswordLoading}
                  loading={isForgetPasswordLoading}
                  positive
                  content='Verify OTP/Set Password'
                  fluid
                />
              </Form>
            )}
        />
        
      </Grid.Column>
    </Grid >
  )
}
export default observer(VerifyOtp)

