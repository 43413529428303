import { observable, action, runInAction } from "mobx";
import { IBeneficiaryType, ICustomFields } from "../models/beneficiaryType";
import agent from "../api/agent";
import { SyntheticEvent } from "react";
import { RootStore } from "./rootStore";



export default class BeneficiaryTypeStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

  }
  @observable beneficiaryTypeRegistry = new Map();
  @observable beneficiaryTypes: IBeneficiaryType[] = [];
  @observable customFieldList: ICustomFields[] = [];
  @observable beneficiaryType: IBeneficiaryType | undefined;
  @observable loadingInitial = false;
  @observable editMode = false;
  @observable submitting = false;
  @observable target = '';

  @action loadBeneficiaryTypes = async () => {
    this.loadingInitial = true;
    try {
      const beneficiaryTypes = await agent.BeneficiaryType.list();
      runInAction('loading', () => {
        beneficiaryTypes.forEach((beneficiaryType) => {
          this.beneficiaryTypes.push(beneficiaryType);

        });
        this.loadingInitial = false;
      })
    } catch (error) {
      runInAction('load error', () => {
        this.loadingInitial = false;
      })
      console.log(error);


    }
  };


  @action createBeneficiaryType = async (beneficiaryType: IBeneficiaryType) => {
    this.submitting = true;
    try {
      console.log(beneficiaryType);
      await agent.BeneficiaryType.create(beneficiaryType);

      runInAction('create beneficiaryType', () => {
        this.beneficiaryTypes.push(beneficiaryType);

        this.editMode = false;
        this.submitting = false;
      })

    } catch (error) {
      runInAction('create beneficiaryType error', () => {
        this.submitting = false;
      })

      console.log(error);
    }
  };

  @action editBeneficiaryType = async (beneficiaryType: IBeneficiaryType) => {
    this.submitting = false;
    try {
      await agent.BeneficiaryType.update(beneficiaryType);
      runInAction('editing beneficiaryType', () => {
        this.beneficiaryTypes.push(beneficiaryType);
        this.beneficiaryType = beneficiaryType;
        this.editMode = false;
        this.submitting = false;
      })

    } catch (error) {
      runInAction('editing beneficiaryType error', () => {
        this.submitting = false;
      })

      console.log(error);
    }
  };

  @action loadBeneficiaryType = async (id: string) => {
    let beneficiaryType = this.getBeneficiaryType(id);
    if (beneficiaryType) {
      this.beneficiaryType = beneficiaryType;
    } else {
      this.loadingInitial = true;
      try {
        beneficiaryType = await agent.BeneficiaryType.view(id);
        runInAction('getting beneficiaryType', () => {
          this.beneficiaryType = beneficiaryType;
          this.loadingInitial = false;
        })
      } catch (error) {
        runInAction('get beneficiaryType error error', () => {
          this.loadingInitial = false;
        })
        console.log(error);
      }
    }
  }


  getBeneficiaryType = (id: string) => {
    return this.beneficiaryTypeRegistry.get(id);

  }



  @action disableBeneficiaryType = async (event: SyntheticEvent<HTMLButtonElement>, id: string) => {
    this.editMode = true;
    this.target = event.currentTarget.name;
    try {
      await agent.BeneficiaryType.disable(id);
      runInAction('disabling beneficiaryType', () => {
        this.beneficiaryTypes.filter(a => a.beneficiaryTypeId !== id);
        this.submitting = false;
        this.target = '';

      })

    } catch (error) {
      runInAction('disabling beneficiaryType error ', () => {
        this.submitting = false;
        this.target = '';

      })

      console.log(error);
    }


  };



  @action openEditForm = (id: string) => {
    this.beneficiaryTypes.find(
      (a) => a.beneficiaryTypeId === id
    );
    this.editMode = true;
  };

  @action cancelSelectedBeneficiaryType = () => {
    this.beneficiaryType = undefined;
  };

  @action cancelFormOpen = () => {
    this.editMode = false;
  };

  @action openCreateForm = () => {
    this.editMode = true;
    this.beneficiaryType = undefined;
  };

  @action selectBeneficiaryType = (id: string) => {
    this.beneficiaryType = this.beneficiaryTypes.find(
      (a) => a.beneficiaryTypeId === id
    );
    this.editMode = false;
  };

}

