import {
  Form,
  Button,
  Step,
  Segment,
  Header,
  Grid,
  Icon,
} from "semantic-ui-react";
import React, { Fragment, useContext, useState } from "react";
import Helmet from "../../../component/common/Helmet";
import HeaderThree from "../../../component/header/HeaderThree";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";
import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
} from "../../../app/models/registrationOTP";
import { FederationRegisterValues } from "../../../app/models/federation";
import { useEffect } from "react";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
  matchesField,
  matchesPattern,
} from "revalidate";
import TextInputTab from "../../../app/common/form/TextInputTab";
import SelectInputTab from "../../../app/common/form/SelectInputTab";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { observer } from "mobx-react-lite";
import FileUpload from "../../../app/common/form/FileUpload";
import TextAreaTab from "../../../app/common/form/TextAreaTab";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const FederationRegistration: React.FC<{
  createUser?: FederationRegisterValues;
}> = ({ createUser }) => {
  const rootStore = useContext(RootStoreContext);
  const [save, saveData] = useState(false);
  const {
    federationDetail,
    saveandnextFederationDetails,
    savedandnextFederation,
    IsRegister,
    getSavedAndNextFederationDetails,
    upsertFederation,
    fedregisterationDone,
    fedregisterationAdmin,
  } = rootStore.federationStore;
  const [formData, setFormData] = useState(federationDetail);

  const { createFederation, IsFedRegister } = rootStore.federationStore;
  const {
    sendOTP,
    verifyOtp,
    loadingSendOtp,
    loadingVerifyOtp,
    getVerifyByMobileOrEmail,verifyByMobile,
  } = rootStore.registrationOTPStore;
  const { getSector, sectorDropdownValues } = rootStore.sectorStore;
  const { getState, stateDropdownValues } = rootStore.stateStore;
  const [state] = React.useState({
    mobile: "",
  });
  const registrationId =
    rootStore.commonStore.registrationId !== undefined
      ? rootStore.commonStore.registrationId
      : 0;

  let phone = "";
  //const Sectors =[sectorDropdownValues]
  useEffect(() => {
    getSector();
    getState();
    getVerifyByMobileOrEmail();
  }, [getSector, getState,getVerifyByMobileOrEmail]);

  const validateleadership = combineValidators({
    CEOFirstName: isRequired("First Name"),
    CEOTitle: isRequired("Title"),
    CEOLastName: isRequired("Last Name"),
    CEOMobile: composeValidators(
      isRequired({ message: "CEO Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(new RegExp("^[0-9]{10}$"))({
        message: "Accepts 10 digits only",
      })
    )(),
    CEOEmailId: composeValidators(
      isRequired("CEO EmailID"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid EmailId",
      })
    )(),

    CEODesignation: isRequired("Please enter Designation"),

    TrusteeorManagementProfile: isRequired("Please upload your Logo"),
  });
  const validatecom = combineValidators({});
  const validate = combineValidators({
    OrganizationName: isRequired("Organisation Name"),

    OfficeContactumber: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(new RegExp("^[0-9]{10}$"))({
        message: "Accepts 10 digits only",
      })
    )(),

    CommunicationAddress: isRequired("Communication Address"),

    YearOfInception: composeValidators(
      isRequired({ message: "Please enter the Year of Inception" }),
      isNumeric("Year of Inception")
    )(),
    Pincode: composeValidators(
      isRequired({ message: "Please enter Pincode" }),
      isNumeric("Pincode")
    )(),

    OrganizationLogoFile: isRequired("Please upload your Logo"),

    WebsiteAddress: isRequired({ message: "Please enter Pincode" }),

    SectorId: isRequired("Please select Sector"),

    State: isRequired("Please select State"),

    Password: composeValidators(
      isRequired({ message: "Please enter the Password" }),
      matchesPattern(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        )
      )({
        message:
          "Password must contain at least six characters, including uppercase & lowercase letters, special character and numbers",
      })
    )(),
    ConfirmPassword: composeValidators(
      isRequired({ message: "Please enter the Confirm Password" }),
      matchesField(
        "Password",
        "Preferred Password"
      )({
        message: "Password and Confirm Password must be same",
      })
    )(),
    OfficialEmailId: composeValidators(
      isRequired("Official EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Official EmailId",
      })
    )(),
  });
  const steps = [
    {
      key: "verification",
      icon: "mobile alternate",
      active: rootStore.registrationOTPStore.index === 1,
      title: "Verification",
      description: "",
    },
    {
      key: "basic",
      icon: "info",
      active: rootStore.registrationOTPStore.index === 2,
      disabled: rootStore.registrationOTPStore.index < 2,
      title: "Basic Information",
      description: "Your Basic Info",
    },

    {
      key: "leadership",
      active: rootStore.registrationOTPStore.index === 3,
      disabled: rootStore.registrationOTPStore.index < 3,
      icon: "user",
      title: "Leadership Team Profile",
      description: "Your Leadership Team Details",
    },
  ];
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const [checkbox1, setCheckbox] = useState(false);
  const [address] = React.useState({
    regval: "",
    comval: "",
  });
  function handleonchange() {
    setCheckbox(!checkbox1);
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  let isMobile=verifyByMobile;

  return (
    <Fragment>
      <div>
        <Helmet pageTitle="CIM - Organisation Development" />
        <HeaderThree
          homeLink="https://www.cimindia.in/"
          logo="symbol-dark"
          color="color-black"
        />
      </div>

      <div className="App-paragraph">
        <Header as="h4" icon textAlign="center" style={{ paddingTop: "10px" }}>
          <Header.Content>Federation Organisations</Header.Content>
        </Header>
      </div>

      <br />
      <Segment>
        <Step.Group
          attached
          items={steps}
          style={{
            width: "80%",
          }}
        />
        <br />
        <br />
        <FinalForm
          onSubmit={async (values: IRegistrationVerifyOTP) => {
            values.MobileContactNumber =
              window.localStorage.getItem("mobile") + "";
            let phoneNumber = new FederationRegisterValues();

            phoneNumber.MobileContactNumber = values.MobileContactNumber.substring(
              3
            );
            console.log(phoneNumber);
            getSavedAndNextFederationDetails(phoneNumber);

            await verifyOtp(values).catch((error) => ({
              [FORM_ERROR]: error,
            }));
          }}
          render={({
            handleSubmit,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form onSubmit={handleSubmit} error>
              {rootStore.registrationOTPStore.index === 1 && (
                <Fragment>
                  {/* <Grid style={{ marginLeft: "200px" }} columns={1}>
                    <Grid.Column> */}
                  <FinalForm
                    onSubmit={(values: IRegistrationSendOTP) => {
                      state.mobile = values.MobileContactNumber;
                      sendOTP(values).catch((error) => ({
                        [FORM_ERROR]: error,
                      }));
                      window.localStorage.setItem(
                        "mobile",
                        values.MobileContactNumber
                      );
                    }}
                    render={({
                      handleSubmit: federationSubmit,
                      form,
                      submitting,
                      submitError,
                      invalid,
                      pristine,
                      dirtySinceLastSubmit,
                      error,
                    }) => (
                      <Form
                        onSubmit={async (event) => {
                          await federationSubmit(event);
                        }}
                        error
                      >
                        <Form.Field inline>
                          <Grid>
                            <Grid.Column width={5}>
                              <label
                                className="label-field"
                                style={{ marginLeft: "130px" }}
                              >
                                {isMobile === true ? "Registered Mobile No" : "Registered Email ID"}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={isMobile === true ? 5 : 4}>
                              <Field
                                component={isMobile === true ? PhoneNumberInput : TextInputTab} 
                                name="MobileContactNumber" 
                                style={{ width: "190px" }}
                                placeholder={isMobile === true ? "Mobile No" : "Email ID"}
                                onFocus={(event: any) => {
                                  if (event?.target.value.length === 10)
                                    phone = event?.target.value;
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Button
                                className="actionButton"
                                content="Send OTP"
                                color="blue"
                                loading={loadingSendOtp}
                              ></Button>
                            </Grid.Column>
                          </Grid>
                        </Form.Field>
                      </Form>
                    )}
                  />

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "130px" }}
                        >
                          Enter OTP
                        </label>
                        <span className="mandatory-field"> * </span>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          component="input"
                          name="Otp"
                          style={isMobile == true ? {marginLeft: "35px"} :{ marginLeft: "0px" }}
                          placeholder="OTP"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}></Grid.Column>
                    </Grid>
                  </Form.Field>

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}></Grid.Column>

                      <Button
                        // disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                        className="actionButton"
                        style={{ marginLeft: "51px", marginTop: "10px" }}
                        content="Verify"
                        color="blue"
                        loading={loadingVerifyOtp}
                      ></Button>
                    </Grid>
                  </Form.Field>

                  <br />
                  <br />
                  <Segment
                    inverted
                    color="blue"
                    style={{ width: "100%", height: "80px", borderRadius: 25 }}
                  >
                    <label
                      color="blue"
                      style={{ marginLeft: "500px", fontFamily: "verdana" }}
                    >
                      {" "}
                      Welcome to Christian Institute of Management!{" "}
                    </label>
                    <label
                      color="blue"
                      style={{ marginLeft: "300px", fontFamily: "verdana" }}
                    >
                      {" "}
                      To complete registration process: You need to provide
                      brief details of your organisation in just 15 minutes{" "}
                    </label>
                  </Segment>
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
        <FinalForm
          initialValues={{
            PartnerId: federationDetail?.federationBasicDetailId,
            OrganizationName: federationDetail?.organizationName,
            YearOfInception: federationDetail?.yearOfInception,
            RegisteredAddress: federationDetail?.registeredAddress,
            CommunicationAddress: federationDetail?.communicationAddress,
            Pincode: federationDetail?.pincode,
            State: federationDetail?.stateId,
            OfficialEmailId: federationDetail?.officialEmailId,
            OfficeContactumber: federationDetail?.officeContactumber,
            MobileContactNumber: federationDetail?.mobileContactNumber,
            WebsiteAddress: federationDetail?.websiteAddress,
            WhatsappNumber: federationDetail?.whatsappNumber,
            FacebookID: federationDetail?.facebookID,
            TwitterID: federationDetail?.twitterID,
            OtherMediaLink: federationDetail?.otherMediaLink,
            SectorId: federationDetail?.sectorId,
            CEOTitle: federationDetail?.ceoTitle,
            CEOFirstName: federationDetail?.ceoFirstName,
            CEOLastName: federationDetail?.ceoLastName,
            CEOMobile: federationDetail?.ceoMobile,
            CEOEmailId: federationDetail?.ceoEmailId,
            CEODesignation: federationDetail?.ceoDesignation,
            ContactId: federationDetail?.contactId,
            ContactDesignation: federationDetail?.contactDesignation,
            ContactEmailId: federationDetail?.contactEmailId,
            ContactFirstName: federationDetail?.contactFirstName,
            ContactLastName: federationDetail?.contactLastName,
            ContactMobile: federationDetail?.contactMobile,
            ContactTitle: federationDetail?.contactTitle,
            OrganizationLogo: federationDetail?.organizationLogo,
          }}
          onSubmit={(values: FederationRegisterValues) => {
            values.MobileContactNumber = state.mobile;
            if (save) {
              saveandnextFederationDetails(values);
              saveData(false);
              setFormData(values);
            } else if (rootStore.registrationOTPStore.index < 3) {
              saveandnextFederationDetails(values);
              rootStore.registrationOTPStore.index =
                rootStore.registrationOTPStore.index + 1;
              setFormData(values);
            } else {
              createFederation(values)
                .then(() => {
                  fedregisterationDone(values);
                  fedregisterationAdmin(values);
                  setFormData(new FederationRegisterValues());
                })
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }));
            }
            // createFederation(values).catch((error) => ({
            //   [FORM_ERROR]: error,
            // }));
          }}
          validate={
            rootStore.registrationOTPStore.index === 2
              ? validate
              : rootStore.registrationOTPStore.index === 3
              ? validateleadership
              : validatecom
          }
          render={({
            handleSubmit,
            form,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form
              onSubmit={async (event) => {
                await handleSubmit(event);
              }}
            >
              {rootStore.registrationOTPStore.index === 2 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}></Grid.Column>
                      <Grid.Column width={8} textAlign="right">
                        <Button
                          className="actionButton"
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 3;
                          }}
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          loading={submitting}
                          content="Save and Next"
                          color="blue"
                          type="submit"
                          validate={validate}
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Organisation Name{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OrganizationName"
                                placeholder="Name"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Year of Inception{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="YearOfInception"
                                placeholder="Year of Inception"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            {/* {sector === "Others" && (
                        // <Form.Field inline>
                        <div>
                        <Grid.Column width={8}  style={{ paddingLeft: "150px" }}>
                          <label className="label-field"  style={{ paddingTop: "4px" }}>Others</label>
                          </Grid.Column>
                          <Grid.Column >
                          <Field
                            component="input"
                            name="SectorOther"
                            style={{  }}
                            placeholder="Others"
                          />
                          </Grid.Column>
                          </div>
                        // </Form.Field>
                      )} */}

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Office Contact Number{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OfficeContactumber"
                                placeholder="Office Contact Number"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Official Email ID{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OfficialEmailId"
                                placeholder="Official Email ID"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Preferred Password{" "}
                                <span className="mandatory-field"> * </span>
                                (min 8 digit with uppercase, lowercase, numbers
                                & special charc)
                              </label>
                            </Grid.Column>
                            <Grid.Column width={7}>
                              <Field
                                component={TextInputTab}
                                name="Password"
                                placeholder="Enter Password"
                                type={values.showPassword ? "text" : "password"}
                                onChange={handlePasswordChange("password")}
                                id="myInput"
                                value={values.password}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={1}
                              style={{ marginTop: "15px" }}
                            >
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Confirm Password{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="ConfirmPassword"
                                placeholder="Re-enter Password"
                                type="Password"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                RegisteredAddress{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              {/* <textarea  style={{ width: "250px", height: "100px" }} id="reg" name="RegisteredAddress" onChange={(event)=>{
                                      address.regval = event.target.value}} placeholder="Registered Address"/> */}
                              <Field
                                component={TextAreaTab}
                                name="RegisteredAddress"
                                id="reg"
                                inputstyle={{
                                  width: "250px",
                                  height: "10px",
                                }}
                                placeholder="Registered Address"
                                onChange={(event) => {
                                  address.regval = event.target.value;
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Same as Registered Address{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column>
                              <input
                                type="checkbox"
                                id="checkBox"
                                name="checkbox"
                                onChange={handleonchange}
                                checked={checkbox1}
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Communication Address{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              {/* <Form.Field inline> */}
                              <Field
                                component={TextAreaTab}
                                id="com"
                                name="CommunicationAddress"
                                value={
                                  checkbox1 ? address.regval : address.comval
                                }
                                defaultValue={
                                  checkbox1 ? address.regval : address.comval
                                }
                                inputstyle={{ width: "250px", height: "10px" }}
                                placeholder="Communication Address"
                                onChange={(e: any) =>
                                  (address.comval = e.target.value)
                                }
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Organisation Logo{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              {/* <Form.Field inline> */}

                              <Field
                                name="OrganizationLogoFile"
                                style={{ width: "250px" }}
                                component={FileUpload}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Sector{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select Sector"
                                name="SectorId"
                                search
                                selections={"true"}
                                options={sectorDropdownValues}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                State{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select State"
                                name="State"
                                search
                                selections={"true"}
                                options={stateDropdownValues}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Pincode{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Pincode"
                                placeholder="Pincode"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Website Address{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Form.Field inline>
                                <Field
                                  component="input"
                                  name="WebsiteAddress"
                                  style={{ width: "250px" }}
                                  placeholder="Website Address"
                                />
                              </Form.Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <Icon
                                style={{ color: "green" }}
                                size="big"
                                name="whatsapp"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="WhatsappNumber"
                                style={{ width: "250px" }}
                                placeholder="Whatsapp No"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <Icon
                                style={{ color: "darkblue" }}
                                size="big"
                                name="facebook"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="FacebookID"
                                style={{ width: "250px" }}
                                placeholder="Facebook ID"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <Icon
                                style={{ color: "lightblue" }}
                                size="big"
                                name="twitter"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="TwitterID"
                                style={{ width: "250px" }}
                                placeholder="Twitter ID"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Other Media Link{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="OtherMediaLink"
                                style={{ width: "250px" }}
                                placeholder="Other link"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid>

                  <br />
                  <br />
                </Fragment>
              )}

              {rootStore.registrationOTPStore.index === 3 && (
                <Fragment>
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column width={11}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          content="Back"
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 2;
                          }}
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>
                      <Grid.Column width={3} textAlign="right">
                        <Button
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          className="actionButton"
                          type="submit"
                          loading={IsRegister}
                          content="Save"
                          color="blue"
                          onClick={() => {
                            saveData(true);
                          }}

                          //validate = {validateReference}
                        ></Button>
                      </Grid.Column>

                      <Grid.Column width={2} textAlign="right">
                        <Button
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          className="actionButton"
                          loading={IsRegister}
                          type="submit"
                          validate={validate}
                          content="Register"
                          color="blue"
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px" }}
                        >
                          {" "}
                          CEO /Executive Director /<br />
                          Managing Trustee
                        </label>
                        <span className="mandatory-field"> * </span>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          name="CEOTitle"
                          component="select"
                          inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                        >
                          {" "}
                          <option>Title</option>
                          <option>Dr.</option>
                          <option>Rev.</option>
                          <option>Fr.</option>
                          <option>Ps.</option>
                          <option>Sis.</option>
                          <option>Mr.</option>
                          <option>Ms.</option>
                          <option>Mrs.</option>
                        </Field>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="CEOFirstName"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder=" First Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          inputstyle={{ marginLeft: "10px" }}
                          name="CEOLastName"
                          placeholder=" Last Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          inputstyle={{ marginLeft: "10px" }}
                          name="CEOMobile"
                          placeholder="Mobile No"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          inputstyle={{ marginLeft: "10px" }}
                          name="CEOEmailId"
                          placeholder="Email"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          inputstyle={{ marginLeft: "10px" }}
                          name="CEODesignation"
                          placeholder="Designation"
                        />
                      </Grid.Column>
                      {/* </Form.Field> */}
                    </Grid.Row>
                    {/* <br />
                    <Form.Field inline> */}

                    <Grid.Row>
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px" }}
                        >
                          CEO Photo
                        </label>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          name="CEOPhoto"
                          uploadedFile={federationDetail?.CEOPhoto}
                          component={FileUpload}
                          style={{ width: "300px" }}
                          addformstyle={{
                            paddingRight: "4px",
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {/* </Form.Field> */}

                    <Grid.Row>
                      <Grid.Column width={4}>
                        {/* <Form.Field inline> */}
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px" }}
                        >
                          {" "}
                          Contact person for <br />
                          Communication (Optional)
                        </label>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          name="FederationContactJson[0].ContactTitle"
                          component="select"
                          placeholder="Select Title"
                          inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                        >
                          <option>Title</option>
                          <option>Dr.</option>
                          <option>Rev.</option>
                          <option>Fr.</option>
                          <option>Ps.</option>
                          <option>Sis.</option>
                          <option>Mr.</option>
                          <option>Ms.</option>
                          <option>Mrs.</option>
                        </Field>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="FederationContactJson[0].ContactFirstName"
                          placeholder=" First Name"
                          inputstyle={{ marginLeft: "10px" }}
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="FederationContactJson[0].ContactLastName"
                          placeholder=" Last Name"
                          inputstyle={{ marginLeft: "10px" }}
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="FederationContactJson[0].ContactMobile"
                          placeholder="Mobile No"
                          inputstyle={{ marginLeft: "10px" }}
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="FederationContactJson[0].ContactEmailId"
                          placeholder="Email"
                          inputstyle={{ marginLeft: "10px" }}
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="FederationContactJson[0].ContactDesignation"
                          placeholder="Designation"
                          inputstyle={{ marginLeft: "10px" }}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {/* <br />
                    <Form.Field inline> */}
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px", marginTop: "26px" }}
                        >
                          Current Board of trustee/
                          <br />
                          Management Profile
                        </label>
                        <span className="mandatory-field"> * </span>
                        <label style={{ marginLeft: "50px", color: "red" }}>
                          {" "}
                          (Download sample template and fill the current board
                          profile){" "}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          name="TrusteeorManagementProfile"
                          style={{ width: "300px" }}
                          uploadedFile={
                            federationDetail?.trusteeorManagementProfile
                          }
                          component={FileUpload}
                          addformstyle={{
                            paddingRight: "4px",
                          }}
                        />
                      </Grid.Column>

                      {/* </Form.Field> */}
                      <Grid.Column width={8}>
                        <Button
                          color="blue"
                          style={{ marginLeft: "9px", height: "37px" }}
                          href={
                            "https://cimodcb.in/document/CurrentBoardProfile.xlsx"
                          }
                          icon
                        >
                          <Icon name="download" />
                          Download Sample
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};
export default observer(FederationRegistration);
