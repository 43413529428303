import AuthStore from './authStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import ModalStore from './modalStore';
import UserStore from './userStore';
import PartnerStore from './partnerStore';
import BeneficiaryTypeStore from './beneficiaryTypeStore';
import FederationStore from './federationStore';
import RegistrationOTPStore from './registrationOTPStore';
import DonorOrgStore from './donorOrgStore';
import DonorIndividualStore from './donorIndividualStore';
import SectorStore from './sectorStore';
import AreaOfAssessmentStore from './areaOfAssessmentStore';
import SubSectorStore from './subSectorStore';
import QuestionSetupStore from './questionSetupStore';
import PlanStore from './planStore';
import RightPanelStore from './rightPanelStore';
import ManageUserStore from './manageUserStore';
import AssessmentStore from './assessmentStore';
import DashboardStore from './dashboardStore';
import PartnerDashboardStore from './partnerDashboardStore';
import FederationDashboardStore from './federationDashboardStore';
import ContactStore from './contactStore';
import StateStore from './stateStore';
import federationDashboardStore from './federationDashboardStore';
import DonorOrgDashboardStore from './donorOrgDashboardStore';

import DonorIndividualDashboardStore from './donorIndividualDashboardStore';
import { RegistrationDone } from '../models/registrationDone';

configure({ enforceActions: 'always' });

export class RootStore {
    //[x: string]: { upsertArea: any; };
    commonStore: CommonStore;
    modalStore: ModalStore;
    rightPanelStore: RightPanelStore;
    authStore: AuthStore;
    userStore: UserStore;
    partnerStore: PartnerStore;
   // registrationDoneStore : Part;
    federationStore: FederationStore;
    beneficiaryTypeStore:BeneficiaryTypeStore;
    registrationOTPStore: RegistrationOTPStore;
    donorOrgStore: DonorOrgStore;
    donorIndividualStore: DonorIndividualStore;
    sectorStore: SectorStore;
    areaOfAssessmentStore: AreaOfAssessmentStore;
    subSectorStore: SubSectorStore;
    questionSetupStore: QuestionSetupStore;
    planStore: PlanStore;
    manageUserStore:ManageUserStore;
    assessmentStore:AssessmentStore;
    dashboardStore: DashboardStore;
    partnerdashboardStore: PartnerDashboardStore;
    federationdashboardStore : FederationDashboardStore;
    contactStore: ContactStore;
    stateStore: StateStore;
    donorOrgDashboardStore : DonorOrgDashboardStore;
    donorIndividualDashboardStore:DonorIndividualDashboardStore; 

    constructor() {

        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
        this.rightPanelStore = new RightPanelStore(this);
        this.authStore = new AuthStore(this);
        this.userStore = new UserStore(this);
        this.beneficiaryTypeStore = new BeneficiaryTypeStore(this);
        this.partnerStore= new PartnerStore(this);
        this.federationStore=new FederationStore(this);
        this.registrationOTPStore=new RegistrationOTPStore(this);
        this.donorOrgStore = new DonorOrgStore(this);
        this.donorIndividualStore = new DonorIndividualStore(this);
        this.sectorStore = new SectorStore(this);
        this.subSectorStore = new SubSectorStore(this);
        this.areaOfAssessmentStore = new AreaOfAssessmentStore(this);
        this.questionSetupStore= new QuestionSetupStore(this);
        this.planStore=new PlanStore(this);
        this.manageUserStore=new ManageUserStore(this);
        this.assessmentStore=new AssessmentStore(this);
        this.dashboardStore = new DashboardStore(this);
        this.partnerdashboardStore = new PartnerDashboardStore(this);
        this.contactStore = new ContactStore(this);
        this.stateStore = new StateStore(this);
        this.federationdashboardStore= new  federationDashboardStore(this);
       this.donorOrgDashboardStore =  new DonorOrgDashboardStore(this);   
       this.donorIndividualDashboardStore = new DonorIndividualDashboardStore(this);
     
       
    }
}
export const RootStoreContext = createContext(new RootStore());