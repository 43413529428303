import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Form,
  Segment,
  Header,
  Button,
  Icon,
  Grid,
  Step,
} from "semantic-ui-react";
import HeaderThree from "../../../component/header/HeaderThree";
import Helmet from "../../../component/common/Helmet";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";
import SelectInput from "../../../app/common/form/SelectInput";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
  matchesField,
  matchesPattern,
} from "revalidate";
import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
} from "../../../app/models/registrationOTP";
import { DonorIndividualRegisterValues } from "../../../app/models/donorIndividual";
import FileUpload from "../../../app/common/form/FileUpload";
import TextInputTab from "../../../app/common/form/TextInputTab";
import DateInput from "../../../app/common/form/DateInput";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import SelectInputTab from "../../../app/common/form/SelectInputTab";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { observer } from "mobx-react-lite";

const Title = [
  { text: "Dr.", value: "Dr.", key: 1 },
  { text: "Rev.", value: "Rev.", key: 2 },
  { text: "Fr.", value: "Fr.", key: 3 },
  { text: "Ps.", value: "Ps.", key: 4 },
  { text: "Sis.", value: "Sis.", key: 5 },
  { text: "Mr.", value: "Mr.", key: 6 },
  { text: "Ms.", value: "Ms.", key: 7 },
  { text: "Mrs.", value: "Mrs.", key: 8 },
];

export const DonorIndividual: React.FC<{
  createUser?: DonorIndividualRegisterValues;
}> = ({ createUser }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    registerationDone,
    saveDonorIndividualDetails,
    getSavedDonorIndividualDetails,
    donorIndividualDetail,
    
  } = rootStore.donorIndividualStore;
  const [state] = React.useState({
    mobile: "",
  });

  const [formData, setFormData] = useState(donorIndividualDetail);
  const [save, saveData] = useState(false);
  const GinvingPreference = combineValidators({
    GivingFrequence: isRequired("Giving Frequency"),
    ModeOfGivingPreference: isRequired("Mode Of Giving Preference"),
    MyGivingPreference: isRequired("Giving Preference"),
    MyPreferedState: isRequired("State"),
    MyPreferedDistrict: isRequired("District"),
    MyPreferedLanguage: isRequired("Language"),
    PreferedPincode: isRequired("Pincode"),
  });
  const validateReference = combineValidators({
    refTitle1: isRequired("Title"),
    refTitle2: isRequired("Title"),
    refFirstName1: isRequired("First Name"),
    refFirstName2: isRequired("First Name"),
    refLastName1: isRequired("Last Name"),
    refLastName2: isRequired("Last Name"),
    refDesignation1: isRequired("Designation"),
    refDesignation2: isRequired("Designation"),
    refMobile1: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),

      matchesPattern(new RegExp("^[0-9]{10}$"))({
        message: "Accepts 10 digits only",
      })
    )(),
    refMobile2: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(new RegExp("^[0-9]{10}$"))({
        message: "Accepts 10 digits only",
      })
    )(),
    refOrganization2: isRequired("Organisation"),
    refOrganization1: isRequired("Organisation"),
    refEmail2: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),
    refEmail1: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),
  });
  const validate = combineValidators({
    Name: isRequired("Organisation Name"),
    Organisation: isRequired("Organisation"),
    Gender: isRequired("Gender"),
    Address: isRequired("Address"),
    State: isRequired("State"),
    Country: isRequired("Country"),
    WebsiteAddress: isRequired("Website Address"),
    Pincode: isRequired("Pincode"),
    Nationality: isRequired("Nationality"),
    Designation: isRequired("Designation"),
    ProfilePicture: isRequired("Profile Picture"),
    DateOfBirth: isRequired("Date Of Birth"),
    //Name: isRequired("Name"),
    Password: composeValidators(
      isRequired({ message: "Please enter the Password" }),
      matchesPattern(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        )
      )({
        message:
          "Password must contain at least six characters, including uppercase & lowercase letters, special character and numbers",
      })
    )(),
    ConfirmPassword: composeValidators(
      isRequired({ message: "Please enter the Confirm Password" }),
      matchesField(
        "Password",
        "Preferred Password"
      )({
        message: "Password and Confirm Password must be same",
      })
    )(),
    EmailId: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid EmailId",
      })
    )(),
  });

  const {
    createDonorIndividual,
    submitDonorInd,
  } = rootStore.donorIndividualStore;
  const {
    sendOTP,
    verifyOtp,
    loadingSendOtp,
    loadingVerifyOtp,
    getVerifyByMobileOrEmail,verifyByMobile,
  } = rootStore.registrationOTPStore;
  const { getState, stateDropdownValues } = rootStore.stateStore;

  useEffect(() => {
    getState();
    getVerifyByMobileOrEmail();
  }, [getState,getVerifyByMobileOrEmail]);
  const [preferredWork, setPreferredWork] = useState("Pastrol Work");
  const PreferredWorks = [
    { text: "Pastrol Work", value: "Pastrol Work", key: 1 },
    { text: "Evangelism", value: "Evangelism", key: 2 },
    {
      text: "Mission to unreached pincode",
      value: "Mission to unreached pincode",
      key: 3,
    },
    { text: "Medical", value: "Medical", key: 4 },
    { text: "Education to the poor", value: "Education to the poor", key: 5 },
    { text: "Literacy Program", value: "Literacy Program", key: 6 },
    { text: "Orphans", value: "Orphans", key: 7 },
    {
      text: "Blind/Deaf/Physically Challenged",
      value: "Blind/Deaf/Physically Challenged",
      key: 8,
    },
    { text: "Prisoners", value: "Prisoners", key: 9 },
    { text: "Vocational Training", value: "Vocational Training", key: 10 },
    { text: "Leadership Training", value: "Leadership Training", key: 11 },
    { text: "Theological Training", value: "Theological Training", key: 12 },
    { text: "Literature Ministry", value: "Literature Ministry", key: 13 },
    { text: "Outreach to Students", value: "Outreach to Students", key: 14 },
    {
      text: "Church construction program",
      value: "Church construction program",
      key: 15,
    },
    { text: "Mass Media", value: "Mass Media", key: 16 },
    {
      text: "Pioneer Bible Translation to unknown Language",
      value: "Pioneer Bible Translation to unknown Language",
      key: 17,
    },
    { text: "Others", value: "Others", key: 18 },
  ];
  const [represent, setRepresent] = useState("None");
  const Representing = [
    { text: "None", value: "None", key: 1 },
    { text: "Church", value: "Church", key: 2 },
    { text: "Foundation", value: "Foundation", key: 3 },
    { text: "Charity", value: "Charity", key: 4 },
    { text: "Business/Company", value: "Business/Company", key: 5 },
  ];

  const Gender = [
    { text: "Male", value: "Male", key: 1 },
    { text: "Female", value: "Female", key: 2 },
  ];

  const Nationality = [
    { text: "Indian", value: "Indian", key: 1 },
    { text: "NRI", value: "NRI", key: 2 },
    { text: "PIO", value: "PIO", key: 3 },
    { text: "Non-Indian", value: "", key: 4 },
  ];
  const Giving = [
    { text: "1000-5000", value: "1000-5000", key: 1 },
    { text: "5000-20000", value: "5000-20000", key: 2 },
    { text: "20000-50000", value: "20000-50000", key: 3 },
    { text: "50000-100000", value: "50000-100000", key: 4 },
    { text: "Above 100000", value: "Above 100000", key: 5 },
  ];

  const Frequency = [
    { text: "Monthly", value: "Monthly", key: 1 },
    { text: "Quarterly", value: "Quarterly", key: 2 },
    { text: "Half-Yearly", value: "Half-Yearly", key: 3 },
    { text: "Annual", value: "Annual", key: 4 },
  ];

  const Denomination = [
    { text: "Ecumenical", value: "Ecumenical", key: 1 },
    { text: "Evangelical", value: "Evangelical", key: 2 },
    { text: "Pentecostal", value: "Pentecostal", key: 3 },
    { text: "Orthodox", value: "Orthodox", key: 4 },
  ];

  const GivingPreference = [
    { text: "Cash", value: "Cash", key: 1 },
    { text: "DD/Cheque", value: "DD/Cheque", key: 2 },
    { text: "Online", value: "Online", key: 3 },
  ];

  const steps = [
    {
      key: "verification",
      icon: "mobile alternate",
      active: rootStore.registrationOTPStore.index === 1,
      title: "Verification",
      description: "",
    },
    {
      key: "basic",
      icon: "info",
      active: rootStore.registrationOTPStore.index === 2,
      disabled: rootStore.registrationOTPStore.index < 2,
      title: "Basic Information",
      description: "Your Basic Info",
    },

    {
      key: "preference",
      active: rootStore.registrationOTPStore.index === 3,
      disabled: rootStore.registrationOTPStore.index < 3,
      icon: "rendact",
      title: "My Giving Preference",
      description: "Your Giving",
    },
    {
      key: "reference",
      active: rootStore.registrationOTPStore.index === 4,
      disabled: rootStore.registrationOTPStore.index < 4,
      icon: "rendact",
      title: "Reference",
      description: "CIM is referred by",
    },
  ];
  console.log(rootStore.registrationOTPStore.index);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  let isMobile=verifyByMobile;

  //const handleChange = event => setValue({value: event.target.value});
  return (
    <Fragment>
      <Helmet pageTitle="CIM - Organisation Development" />

      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />

      <br />
      <br />
      <br />
      <br />
      <div className="App-paragraph">
        <Header as="h4" icon textAlign="center">
          <Header.Content>Donor - Individual</Header.Content>
        </Header>
      </div>

      <br />

      <Segment>
        <Step.Group
          attached
          items={steps}
          style={{
            width: "80%",
          }}
        />
        <br />
        <br />
        <FinalForm
          onSubmit={async (values: IRegistrationVerifyOTP) => {
            values.MobileContactNumber =
              window.localStorage.getItem("mobile") + "";
            let phoneNumber = new DonorIndividualRegisterValues();

            phoneNumber.MobileContactNumber = values.MobileContactNumber.substring(
              3
            );
            getSavedDonorIndividualDetails(phoneNumber);
            await verifyOtp(values).catch((error) => ({
              [FORM_ERROR]: error,
            }));
          }}
          render={({
            handleSubmit,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form onSubmit={handleSubmit} error>
              {rootStore.registrationOTPStore.index === 1 && (
                <Fragment>
                  <FinalForm
                    onSubmit={(values: IRegistrationSendOTP) => {
                      state.mobile = values.MobileContactNumber;
                      console.log(state.mobile);
                      sendOTP(values).catch((error) => ({
                        [FORM_ERROR]: error,
                      }));
                      window.localStorage.setItem(
                        "mobile",
                        values.MobileContactNumber
                      );
                    }}
                    render={({
                      handleSubmit: DonorIndSubmit,
                      form,
                      submitting,
                      submitError,
                      invalid,
                      pristine,
                      dirtySinceLastSubmit,
                      error,
                    }) => (
                      <Form
                        onSubmit={async (event) => {
                          await DonorIndSubmit(event);
                        }}
                        error
                      >
                        <Form.Field inline>
                          <Grid>
                            <Grid.Column width={5}>
                              <label
                                className="label-field"
                                style={{ marginLeft: "130px" }}
                              >
                                {" "}
                                <b>{isMobile === true ? "Registered Mobile No" : "Registered Email ID"}</b>
                              </label>
                            </Grid.Column>
                            <Grid.Column width={isMobile === true ? 5 : 4}>
                              <Field
                                component={isMobile === true ? PhoneNumberInput : TextInputTab}
                                name="MobileContactNumber"
                                placeholder={isMobile === true ? "Mobile No" : "Email ID"}
                                style={{ width: "190px" }}
                              />
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Button
                                content="Send OTP"
                                color="blue"
                                loading={loadingSendOtp}
                              ></Button>
                            </Grid.Column>
                          </Grid>
                        </Form.Field>
                      </Form>
                    )}
                  />

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "130px" }}
                        >
                          Enter OTP
                        </label>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          component="input"
                          name="Otp"
                          style={isMobile == true ? {marginLeft: "35px"} :{ marginLeft: "0px" }}
                          placeholder="OTP"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}></Grid.Column>
                    </Grid>
                  </Form.Field>

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}></Grid.Column>
                      <Button
                        className="actionButton"
                        style={{ marginLeft: "51px", marginTop: "10px" }}
                        content="Verify"
                        loading={loadingVerifyOtp}
                        color="blue"
                      ></Button>
                    </Grid>
                  </Form.Field>
                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
        <FinalForm
          //copy paste
          initialValues={createUser}
          onSubmit={(values: DonorIndividualRegisterValues) => {
            values.MobileContactNumber = state.mobile;
            if (save) {
              
              saveDonorIndividualDetails(values);
              
              rootStore.registrationOTPStore.index =
                rootStore.registrationOTPStore.index + 1;
              saveData(false);
              setFormData(values);
            } else if (rootStore.registrationOTPStore.index < 4) {
              
              saveDonorIndividualDetails(values);
              rootStore.registrationOTPStore.index =
                rootStore.registrationOTPStore.index + 1;
              setFormData(values);
            } else {
              
              createDonorIndividual(values)
                .then(() => {
                  registerationDone(values);
                  setFormData(new DonorIndividualRegisterValues());
                })
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }));
            }

            // createDonorIndividual(values).catch((error) => ({
            //   [FORM_ERROR]: error,
            // }));
          }}
          validate={
            rootStore.registrationOTPStore.index === 2
              ? validate
              : rootStore.registrationOTPStore.index === 3
              ? GinvingPreference
              : validateReference
          }
          render={({
            handleSubmit,
            form,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form
              onSubmit={async (event) => {
                await handleSubmit(event);
                //form.reset();
              }}
            >
              {rootStore.registrationOTPStore.index === 2 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}></Grid.Column>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "815px" }}
                          onClick={() => {
                            saveData(true);
                          }}
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          loading={submitting}
                          content="Save and Next"
                          color="blue"
                          type="submit"
                          //validate={validate}
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Name{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Name"
                                placeholder="Name"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Date of Birth{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={DateInput}
                                name="DateOfBirth"
                                style={{ width: "250px" }}
                                placeholder="Date of Birth"
                                date
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Gender{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>

                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="I am"
                                name="Gender"
                                search
                                selections={"true"}
                                options={Gender}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Email ID{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="EmailId"
                                placeholder="Email ID"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Preferred Password{" "}
                                <span className="mandatory-field">
                                  {" "}
                                  *{" "}
                                </span>{" "}
                                <br />
                                (min 8 digit with uppercase, lowercase, numbers
                                & special charc)
                              </label>
                            </Grid.Column>
                            <Grid.Column width={7}>
                              <Field
                                component={TextInputTab}
                                name="Password"
                                placeholder="Password"
                                type={values.showPassword ? "text" : "password"}
                                onChange={handlePasswordChange("password")}
                                value={values.password}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={1}
                              style={{ marginTop: "15px" }}
                            >
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Confirm Password{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>

                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="ConfirmPassword"
                                placeholder="Confirm Password"
                                type="Password"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Address{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>

                            <Grid.Column width={8}>
                              <Form.Field inline>
                                <Field
                                  component="textarea"
                                  name="Address"
                                  style={{
                                    width: "250px",
                                    height: "5px",
                                  }}
                                  placeholder="Address"
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Country{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Country"
                                placeholder="Country"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                State{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select State"
                                name="State"
                                search
                                selections={"true"}
                                options={stateDropdownValues}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label className="label-field">Profile </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="ProfilePicture"
                                style={{ width: "250px" }}
                                component={FileUpload}
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Pincode{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Pincode"
                                placeholder="Pincode"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Organisation{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Organisation"
                                inputstyle={{ width: "250px" }}
                                placeholder="Working at"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Designation{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Designation"
                                inputstyle={{ width: "250px" }}
                                placeholder="Working as"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Nationality{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Natioanality"
                                name="Nationality"
                                search
                                selections={"true"}
                                options={Nationality}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Website Address{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="WebsiteAddress"
                                inputstyle={{ width: "250px" }}
                                placeholder="Website Address"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <Icon size="big" name="whatsapp" />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="WhatsappNumber"
                                inputstyle={{ width: "250px" }}
                                placeholder="Whatsapp No"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <Icon size="big" name="facebook" />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="FacebookID"
                                inputstyle={{ width: "250px" }}
                                placeholder="Facebook ID"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}

                              <Icon size="big" name="twitter" />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="TwitterID"
                                inputstyle={{ width: "250px" }}
                                placeholder="Twitter ID"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "150px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label className="label-field">
                                {" "}
                                Other Media Link{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OtherMediaLink"
                                inputstyle={{ width: "250px" }}
                                placeholder="Other link"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
              {rootStore.registrationOTPStore.index === 3 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 2;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "815px" }}
                          // onClick={() => {
                          //   rootStore.registrationOTPStore.index = 4;
                          // }}
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          content="Next"
                          color="blue"
                          type="submit"
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                My Preferred Work / Ministry
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInput}
                                placeholder="MyPreferedWork"
                                name="MyPreferedWork"
                                search
                                selections={"true"}
                                onChange={(data: any) => setPreferredWork(data)}
                                options={PreferredWorks}
                                inputstyle={{ width: "300px" }}
                              />
                            </Grid.Column>

                            {preferredWork === "Others" && (
                              <Grid.Row>
                                <Grid.Column
                                  width={8}
                                  style={{ paddingLeft: "113px" }}
                                >
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Others{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="Others"
                                    inputstyle={{
                                      marginLeft: "13px",
                                      width: "220px",
                                    }}
                                    placeholder="Others"
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            )}
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Giving Preference{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="My Giving"
                                name="MyGivingPreference"
                                search
                                selections={"true"}
                                options={Giving}
                                inputstyle={{ width: "220px" }}
                                // errorstyle={{ marginLeft: "383px", marginTop: "8px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Mode of Giving Preference{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Field
                                name="ModeOfGivingPreference"
                                component={SelectInputTab}
                                placeholder="Select Mode"
                                inputstyle={{ width: "220px" }}
                                options={GivingPreference}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Giving Frequency{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Giving Frequency"
                                name="GivingFrequence"
                                search
                                selections={"true"}
                                options={Frequency}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Church{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="My Preferred Church"
                                name="MyPreferedChurchDomination"
                                search
                                selections={"true"}
                                options={Denomination}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Prefered State{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select State"
                                name="MyPreferedState"
                                search
                                selections={"true"}
                                options={stateDropdownValues}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                Giving Track Record / Total personal financial
                                <br />
                                contribution to Indian ministries (in last 5yr)
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="GivingTrackRecord"
                                style={{ width: "220px" }}
                                component={FileUpload}
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>

                            {/* <Form.Field inline> */}
                          </Grid>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred District{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedDistrict"
                                placeholder="District"
                                inputstyle={{ width: "220px" }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Community{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedCommunity"
                                inputstyle={{ width: "220px" }}
                                placeholder="Community"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Tribe{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedTribe"
                                inputstyle={{ width: "220px" }}
                                placeholder="Tribe"
                              />
                            </Grid.Column>

                            {/* <Form.Field inline> */}
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Language
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedLanguage"
                                inputstyle={{ width: "220px" }}
                                placeholder="Language"
                              />
                            </Grid.Column>
                            {/* </Form.Field>
                      <Form.Field inline> */}
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Preferred Pincode{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="PreferedPincode"
                                inputstyle={{ width: "220px" }}
                                placeholder="Pincode"
                              />
                            </Grid.Column>
                            {/* </Form.Field> */}

                            {/* <Form.Field inline> */}
                            <Grid.Column
                              width={8}
                              style={{ paddingLeft: "100px" }}
                            >
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                I am Representing{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="IamPresenting"
                                component={SelectInputTab}
                                inputstyle={{ width: "220px" }}
                                onChange={(data: any) => setRepresent(data)}
                                placeholder="representing"
                                options={Representing}
                              ></Field>
                            </Grid.Column>
                            {/* </Form.Field> */}

                            {represent !== "None" && (
                              <Grid.Row>
                                <Grid.Column
                                  width={8}
                                  style={{ paddingLeft: "100px" }}
                                >
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    If representing, proof of total <br />{" "}
                                    contribution (in last 5yr){" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    name="ProofofTotalContribution"
                                    style={{
                                      marginLeft: "13px",
                                      width: "220px",
                                    }}
                                    component={FileUpload}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            )}

                            {represent !== "None" &&
                              preferredWork === "Others" && (
                                <div style={{ width: "1px" }}> </div>
                              )}
                            {/* <Form.Field inline> */}
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid>
                  <br />
                  <br />

                  <br />
                </Fragment>
              )}

              {rootStore.registrationOTPStore.index === 4 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 3;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          className="actionButton"
                          loading={submitDonorInd}
                          type="submit"
                          style={{ marginLeft: "815px" }}
                          content="Register"
                          color="blue"
                          validate={validateReference}
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column width={2}>
                        {/* <Form.Field inline> */}
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px" }}
                        >
                          {" "}
                          Reference 1
                        </label>
                        <span className="mandatory-field"> * </span>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          name="refTitle1"
                          component={SelectInputTab}
                          inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                          placeholder="Title"
                          options={Title}
                          select="Mr."
                        ></Field>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refFirstName1"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder=" First Name"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refLastName1"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder=" Last Name"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refMobile1"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Mobile No"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refEmail1"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Email"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refDesignation1"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Designation"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refOrganization1"
                          style={{ marginLeft: "10px" }}
                          placeholder="Organisation"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      {/* <Form.Field inline> */}
                      <Grid.Column width={2}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "50px" }}
                        >
                          {" "}
                          Reference 2
                        </label>
                        <span className="mandatory-field"> * </span>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          name="refTitle2"
                          component={SelectInputTab}
                          inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                          placeholder="Title"
                          options={Title}
                        ></Field>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refFirstName2"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder=" First Name"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refLastName2"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder=" Last Name"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refMobile2"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Mobile No"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refEmail2"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Email"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refDesignation2"
                          inputstyle={{ marginLeft: "10px" }}
                          placeholder="Designation"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Field
                          component={TextInputTab}
                          name="refOrganization2"
                          placeholder="Organisation"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />

                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};
export default observer(DonorIndividual);
