import React from 'react'
import { Button, Grid, Form } from 'semantic-ui-react'
import { useContext } from 'react';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm } from 'react-final-form'
import { observer } from 'mobx-react-lite';
import { IQuestionSetupDisableFormValues, IQuestionAnswerValues } from '../../../app/models/questionSetup';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { RootStoreContext } from '../../../app/stores/rootStore';


interface IProps {
    disableQuestion: IQuestionAnswerValues,
}

const DisableQuestion: React.FC<IProps> = ({ disableQuestion: disableQuestionSetup }) => {

    const rootStore = useContext(RootStoreContext);
    const {enableDisableQuestionSetup,selectedQuestionDetail} = rootStore.questionSetupStore;

    return (

        <FinalForm
            initialValues={selectedQuestionDetail}
            onSubmit={(values: IQuestionSetupDisableFormValues) => 
                enableDisableQuestionSetup(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting,submitError }) => (

            <Form onSubmit={handleSubmit}   >
                  <Grid textAlign="center" >  Do you really want to {selectedQuestionDetail?.isActive ?   'disable' : 'enable'  }? </Grid>
                <br/>
                {submitError &&
                        (
                            <ErrorMessage error={submitError}  />
                        )}
                    
                <br/>
                <Grid textAlign='center' style={{ margin: '4px' }}>
                    {/* <Button color={disableQuestionSetup.isActive ? 'green' : 'red'} floated='right' loading={submitting}>
                        <Icon name={disableQuestionSetup.isActive ? 'check' : 'delete'} /> {disableQuestionSetup.isActive ? 'Disable' : 'Enable'}
                    </Button> */}
                    <Button
                content={selectedQuestionDetail?.isActive?   'Disable':'Enable'  }
                className='actionButton'
                loading={submitting} />
                </Grid>
          
                </Form>
                 )}>
                </FinalForm>        
    )
}


export default observer(DisableQuestion);