import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { observer } from 'mobx-react-lite';

function StatewiseRegistration(props) {
  const admindashboard = JSON.parse(props.state);
  console.log(admindashboard)


  const [state] = React.useState({
    series: [{
      name: "Score",
      data: admindashboard && admindashboard.map(
        (x) => {
          return x.stateCount
        }
      )
    }],
    options: {
      chart: {
        id: "basic-bar",
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
        '#f48024', '#69d2e7'
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: admindashboard && admindashboard.map(
          (x) => {
            return x.stateName
          }
        )
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      title: {
        text: '',
        align: 'center',
        floating: true,
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'Italic'

      },
      subtitle: {
        text: '',
        align: 'center',
      },
      tooltip: {
        theme: 'light',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
    },
  });


  return (
    <div>
      <div id="ch">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={380} />
      </div>
      <div id="html-dist"></div>

    </div>
  );
}
export default observer(StatewiseRegistration);