import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { IOdPartnerApproveOrReject } from "../../../../app/models/partner";

interface IProps {
    approveOrRejectOdPartner: IOdPartnerApproveOrReject,
}

const ODApproveOrReject: React.FC<IProps> = ({ approveOrRejectOdPartner: approveOrRejectOdPartnerProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {odPartnerApprove} = rootStore.partnerStore;

    return (
        <FinalForm
            initialValues={approveOrRejectOdPartnerProps}
            onSubmit={(values: IOdPartnerApproveOrReject) => 
                odPartnerApprove(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Segment>
                {/* <Header color={approveOrRejectOdPartnerProps.IsApproved ? 'green' : 'red'} style={{ height: '6vh' }}> {approveOrRejectOdPartnerProps.IsApproved ? 'Approve' : 'Reject'}!</Header> */}
                Kindly confirm od partner can be {approveOrRejectOdPartnerProps.IsApproved ? 'Approved' : 'Rejected'} 
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '150px'}} loading ={submitting}>{approveOrRejectOdPartnerProps.IsApproved ? 'Approve' : 'Reject'}</Button>
                </Segment>
                </Form>
                 )}>
        </FinalForm>        
    )
}

export default observer(ODApproveOrReject);