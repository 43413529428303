import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Grid, GridColumn } from 'semantic-ui-react';

interface IProps
  extends FieldRenderProps<any, HTMLElement>,
  FormFieldProps { }

const TextInput: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  label,
  hidden,
  readOnly = false,
  onChange = undefined,
  allowAge = false,
  minlength = false,
  required = false,
  maxTextLimit = undefined,
  meta: { touched, error }
}) => {
  return (
    <Grid columns={2} verticalAlign='middle'>
      <br />
      {label &&
        <GridColumn width={8}>
          <Form.Field hidden={hidden}>
            <label className='fieldLabel'>{label}
              {required === true && <span className="mandatory-field">*</span>}
              {<span className ="mandatory-field">{error}</span>}
            </label>
          </Form.Field>
        </GridColumn>
      }
      <GridColumn width={label ? 8 : 16} >
        <Form.Field error={touched && !!error} type={type} hidden={hidden} >

          <input
            {...input}
            placeholder={placeholder}
            readOnly={readOnly}
            // multiple={input.multiple}
            onChange={
              (event) => {
                input.onChange(event)
                if (onChange)
                  onChange(event)
              }}
            min={allowAge ? 60 : undefined}
            maxLength={maxTextLimit&&maxTextLimit>0?maxTextLimit:undefined} 
          />
          {touched && error && (
            <Label basic color='red'>
              {error}
            </Label>
          )}
        </Form.Field>
      </GridColumn>
    </Grid>
  );
};

export default TextInput;