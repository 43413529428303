import React, { useContext } from 'react'
import { Sidebar, Segment, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Heading } from '../../layout/Heading';
import { rightPanelStyle } from '../../layout/styles';

const RightPanelContainer: React.FC = ({ children }) => {
    const rootStore = useContext(RootStoreContext);
    const { rightPanel: { open, title, body }, closeRightPanel } = rootStore.rightPanelStore;

    return (
       
        <Sidebar.Pushable
            style={{
                minHeight: '160vh',
            }}
        >
            <Sidebar
           
                direction='right'
                animation='overlay'
                visible={open}
                 style={rightPanelStyle}
                width='very wide'

            >
                <Heading  
                 
                 title={title} >
                    <Button icon='close'  
                        onClick={() => {
                            closeRightPanel();
                        }} />

                </Heading>
                <br />
                {body}

            </Sidebar>
            <Sidebar.Pusher>
                <Segment basic
                    style={{
                        minHeight: '160vh',
                    }}
                >
                    {children}
                </Segment>
            </Sidebar.Pusher>
        </Sidebar.Pushable >
     

    )
}


export default observer(RightPanelContainer);

