import React, { Fragment } from 'react';
import HeaderThree from '../../component/header/HeaderThree';
import Helmet from "../../component/common/Helmet";
import { Button, Icon } from 'semantic-ui-react';
import od from "../../assets/images/od.png"
import stage from "../../assets/images/stage.png"
import GridContainer from '../../component/Grid/GridContainer';
import GridItem from '../../component/Grid/GridItem';
import GoogleMap from "../../features/homePage/GoogleMap";



const PortfolioLanding = () => {
    let title = 'ABOUT ORGANISATIONAL DEVELOPMENT',
    description = 'CIM Organisational Development is an exercise used for bringing change in the entire aspect of the organisation rather than focusing its attention on individuals so that change happens in every area the OD intervention helps make such the desired changes and enablesthem to function to attain bibilical level of Management Excellence';
    return (
       <Fragment>
             <Helmet pageTitle="CIM - Organisation Development" />
             
            <HeaderThree
                homeLink="https://www.cimindia.in/"
                logo="symbol-dark"
                color="color-black"
            /> 
          
            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={od} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                             <h2 className="title">{title}</h2>
                                            <h6 className="description">{description}</h6> 
                                            <h3 className="title">Steps of OD</h3>
                                            <header>1. Leadership initative</header>
                                            <header>2. Top-to-Bottom Diagnosis</header>
                                            <header>3. Stratergic Recommendations</header>
                                            <header>4. OD Interventions</header>
                                            <header>5. Change Management</header>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}

            {/* Start Service  */}
<div className= "App-about-overlap-group5 " >
    <br/>
    <br/>
<div className=" App-about-montserrat-black-white-65px" style={{ textAlign: 'center' }} >AREAS OF OD INTERVENTION</div>
<br/>
<GridContainer>
          <GridItem xs={12} sm={10} md={6}>
            <h5 className="App-about-text-20" style={{ marginLeft: '300px'}}>Governance & <br/> Accountability</h5>
            <div className="App-about-hind-regular-normal-white-40px" style={{ marginLeft: '300px'}}>• Statutory Compliance<br/>• HR System Audit<br/>• Financial Conformity<br/>• Property Management<br/>• Manual & Policies<br/>• Documentation Systems </div>
         </GridItem>
          <GridItem xs={12} sm={10} md={6}>
            <h5 className="App-about-text-20" style={{ marginLeft:'150px', }}>Organisation <br/>Restructuring</h5>
            <div className=" App-about-hind-regular-normal-white-40px" style={{ marginLeft:'150px', }}>• Leadership Development<br/>• Succession Planning<br/>• Stratergy Development<br/>• Resource Mobilisation<br/>• Information Technology<br/>• Enterprise Risk Management </div>
          </GridItem>
        </GridContainer>
{/* <Grid>
    <Grid.Row>
      <Grid.Column width={7}>
      <div className="App-about-text-20" >Governance <br/>& Accountability</div>
              <div className="App-about-text-18 App-about-hind-regular-normal-white-40px">• Statutory Compliance<br/>• HR System Audit<br/>• Financial Conformity<br/>• Property Management<br/>• Manual & Policies<br/>• Documentation Systems </div>
      </Grid.Column>
      <Grid.Column width={7}>
      <div className="App-about-text-20">Organisation<br/>Restructuring</div>
              <div className="App-about-text-19  App-about-hind-regular-normal-white-40px">• Leadership Development<br/>• Succession Planning<br/>• Stratergy Development<br/>• Resource Mobilisation<br/>• Information Technology<br/>• Enterprise Risk Management </div>
      </Grid.Column>
    </Grid.Row>
  </Grid> */}
</div>   
    {/* End Service */}
     {/* Start 3 Stages of OD  */}
             
                    <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                               <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title" style = {{marginLeft: "100px"}} >3 STAGES OF<br/>ORGANISATION<br/>DEVELOPMENT</h2>
                                       {/*  <Button style = {{marginLeft: "100px"}} as={Link} to='/' primary>KNOW MORE</Button> */}
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative" style = {{marginLeft: "100px"}}>
                                <img className="w-100" src={stage} alt="About Images"/>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* End 3 Stages of OD  */}
            {/* Start Strategic Services */}
            <div className="counterup-area pb--80 pt--40 bg_image  theme-text-white" data-black-overlay="10">
                    <div className="container" >
                    <h2 className="title" style={{color:'white' ,marginLeft: "100px"}}> SERVICES</h2>
            <GridContainer>
          <GridItem xs={12} sm={10} md={6}>
          <div className="App-about-text-21" style ={{marginLeft: "100px"}}>SECTOR SPECIALISED SERVICES</div>
              <div className="App-about-text-18 App-about-hind-regular-normal-white-41px" style ={{marginLeft: "100px"}}>• Churches and Mission Organisations<br/>• Educational Institutions/Bible College<br/>• Medical Institutions<br/>• Children Home and Hostel<br/>• Social Service(NGOs and NPOs)<br/>
             {/*  <Button as={Link} to='/' primary>KNOW MORE</Button> */}
             </div> </GridItem>
          <GridItem xs={12} sm={10} md={6}>
          <div className="App-about-text-21" style ={{marginLeft: "100px"}}>OTHER OD SERVICES</div>
              <div className="App-about-text-19  App-about-hind-regular-normal-white-41px" style ={{marginLeft: "100px"}}>• Consultancy Service<br/>• Mediation Service<br/>• Referral Service<br/>• Organisational Climate Check-up<br/>
              <br/>
             {/*  <Button as={Link} to='/' primary>KNOW MORE</Button> */}
               </div>
                </GridItem>
        </GridContainer> </div>
                </div>
                {/* End Strategic Services */}

            {/* Start Contact section as={Link} */}
            <div className="App-footer">
                
        <div className="App-auto-flex1">
       
          <div className="App-contact-1 App-montserrat-black-eerie-black-65px">CONTACT</div>
          <div className="App-christian--cimindiain App-hind-normal-eerie-black-24px">
            <span className="App-span"><h4>Christian Institute of Management</h4></span>
            <span className="App-span-1">#1405,</span>
            <span className="App-span-1">4th</span>
            <span className="App-span-1">Street, Golden Colony, <br/>Anna Nagar West Extn., <br/>Chennai 600050, India</span>
            <br/>
            <br/>
            <span className="App-span"><Icon  size ='small' color='blue' name='phone' /> </span>
            <span className="App-span-1">+91 44 2625 1530</span>
            <br/>
            <span className="App-span"><Icon  size ='small' color='blue' name='mail outline' /> </span>
            <span className="App-span-1">od@cimindia.in</span>
            <br/>
            <span className="App-span"><Icon  size ='small' color='green' name='whatsapp' /> </span>
            <span className="App-span-1">9444058796</span>
            <div className="social-icons">
          <br/>
            <Button circular size ='large' color='blue'  icon='facebook'  a  href ='https://www.facebook.com/ChristianInstituteOfManagement/' target="_blank" rel="noopener noreferrer" />
            <Button circular size ='large' color='red' icon='youtube' a  href ='https://www.youtube.com/channel/UC-8RcJS0H5zsWgmajIPOIuQ' target="_blank" rel="noopener noreferrer" />
            <Button circular size ='large' color='blue' icon='linkedin'  a  href ='https://www.linkedin.com/in/cim-executive-director-61a07215a' target="_blank" rel="noopener noreferrer"/>
            <Button circular size ='large' color='blue' icon='twitter' a  href ='https://www.twitter.com/cim_india' target="_blank" rel="noopener noreferrer" />
            <Button circular size ='large' color='orange' icon='instagram' a  href ='https://www.instagram.com/cim_news/' target="_blank" rel="noopener noreferrer" />
            </div>
          </div>
        </div>
        <div className="App-auto-flex2">
        <GoogleMap/>
        </div>  
      </div>
            {/* End Contact section */}
           
        </Fragment>
    )
}

export default PortfolioLanding;
 

