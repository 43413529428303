import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import PhoneInput, {  isPossiblePhoneNumber } from 'react-phone-number-input';
import { FormFieldProps, Form, Label, Grid, GridColumn } from 'semantic-ui-react';
import 'react-phone-number-input/style.css'

interface IProps
  extends FieldRenderProps<any, HTMLElement>,
  FormFieldProps { }

const PhoneNumberInput: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  label,
  hidden,
  readOnly = false,
  onChange = undefined,
  meta: { touched, error }
}) => {
  return (
    <Grid columns={2} verticalAlign='middle'>
      {label &&
        <GridColumn width={3}>
          <Form.Field hidden={hidden}>
            <label style={{marginLeft:'130px'}}>{label}</label>
          </Form.Field>
        </GridColumn>
      }
      <GridColumn width={label ? 11 : 14}  >
        <Form.Field error={touched && !!error} type={type} width={width} hidden={hidden} >

       {/*    <input
            {...input}
            placeholder={placeholder}
            readOnly={readOnly}
            
           // multiple={input.multiple}
            onChange={
              (event) => {
                input.onChange(event)
                if (onChange)
                  onChange(event)
              }}
              minLength={minlength?10:undefined} */}
             <PhoneInput
           placeholder={placeholder}
           readOnly={readOnly}
          value={input.value}
          defaultCountry="IN" 
         error={input.value? (isPossiblePhoneNumber(input.value) ? undefined : 
         'Invalid phone number') : 'Phone number required'}
          onChange={
            (event) => {
              input.onChange(event)
              if (onChange)
                onChange(event)
            }} />
          {touched && error && (
            <Label basic color='black'>
              {error}
            </Label>
          )}
        </Form.Field>
      </GridColumn>
    </Grid>
  );
};

export default PhoneNumberInput;