import { action, observable, runInAction } from 'mobx';
import { DropdownItemProps } from 'semantic-ui-react';
import agent from '../api/agent';
import { IDonorOrgDashboard, IPartnerName } from '../models/donorOrgDashboard';
import { RootStore } from './rootStore'; 
import { history } from '../..';
import { IProfile } from '../models/profile';


export default class DonorOrgDashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable loadingDashboard: boolean = false;  
  @observable donororgdashboard?: IDonorOrgDashboard | undefined;
  @observable loadingFederation: boolean = false;
  @observable stateDropdownValues: DropdownItemProps[] = [];
  @observable sectorDropdownValues: DropdownItemProps[] = [];
  @observable partnerDropdownValues: DropdownItemProps[] = [];
  @observable partnerName: IPartnerName[] = [];
  @observable profile?: IProfile | undefined;


  @action getDonorOrgDashboard = async (id: number) => {
    try { 
      this.loadingDashboard = true; 
      const dashboard = await agent.DonorOrgDashboard.getById(id);
      console.log(dashboard);
      runInAction(() => {
        this.loadingDashboard = false;
        this.donororgdashboard = dashboard;
        this.updateDropdownValues();
        console.log(this.donororgdashboard);
      })

    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {       
      })
    }     
  };
  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    this.donororgdashboard?.state.map(
        org => {
            if (org != null) {
                let item = {
                    text: org.stateName,
                    value: org.stateId,
                    
                };
                dropDownValues.push(item);
            }
            return org;
        })
    this.stateDropdownValues = dropDownValues;

    let sectordropDownValues: DropdownItemProps[] = [];
    this.donororgdashboard?.sector.map(
        org => {
            if (org != null) {
                let item = {
                    text: org.sectorName,
                    value: org.sectorId,
                    
                };
                sectordropDownValues.push(item);
            }
            return org;
        })
    this.sectorDropdownValues = sectordropDownValues;

    let partnerdropDownValues: DropdownItemProps[] = [];
    this.donororgdashboard?.partnerName.map(
        org => {
            if (org != null) {
                let item = {
                    text: org.partnerName,
                    value: org.partnerId,
                    key: org.partnerKey
                    
                };
                partnerdropDownValues.push(item);
            }
            return org;
        })
    this.partnerDropdownValues = partnerdropDownValues;
}

@action searchPartner = async (sectorId: number, stateId : number,partnerId : number) => {
  try {  
    if(sectorId === undefined) sectorId =0;
    if(stateId === undefined) stateId =0;
    if(partnerId === undefined) partnerId =0;
    const dashboard = await agent.DonorOrgDashboard.searchPartner(sectorId,stateId,partnerId);
    console.log(dashboard);
    runInAction(() => {
      this.partnerName = dashboard;
      this.updateDropdownValue(); 
      if(partnerId !== 0)
      {
        history.push({pathname:'/dod-donorSearchODDashboard',state: {Id: partnerId}})
      }
      console.log(this.partnerName);
    })

  } catch (error) {
    throw error;
  } finally {
    runInAction(() => {       
    })
  }     
};

@action updateDropdownValue() { 
  let partnerdropDownValues: DropdownItemProps[] = [];
  this.partnerName.map(
      org => {
          if (org != null) {
              let item = {
                  text: org.partnerName,
                  value: org.partnerId,
                  key: org.partnerKey
                  
              };
              partnerdropDownValues.push(item);
          }
          return org;
      })
  this.partnerDropdownValues = partnerdropDownValues;
}

@action getProfile = async ( id: number) => {
  try {
    const profile = await agent.DonorOrgDashboard.getProfile(id);
    console.log(profile);
    runInAction(() => {
      this.profile = profile;
    })

  } catch (error) {
    throw error;
  } finally {
    runInAction(() => {
    })
  }
}
}