import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import {
  IAssessmentCount,
  IDashboard,
  IPendingOrApprove,
  IRegistration,
  IOrganisation,
  ISearchOrganisation,
  IRegistrationApproval,
  IAssessmentReviewal,
  IYearAreawise,
  IYearSectorwise,
  IStatewise,
  IYearwise,
  IRegistrationCount,
  IPincode,
  IAllOrganisation,
} from "./../models/dashboard";
import { RootStore } from "./rootStore";
import { DropdownItemProps } from "semantic-ui-react";
import { history } from "../..";
import { IMonthwise } from "../models/partnerDashboard";

export default class DashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingDashboard: boolean = false;
  @observable loadingDropdown: boolean = false;
  @observable dashboard?: IDashboard | undefined;

  @observable registrationCount?: IRegistrationCount | undefined;
  @observable registrationApproval?: IRegistrationApproval | undefined;
  @observable assessmentReviewal?: IAssessmentReviewal | undefined;

  @observable yearAreawise?: IYearAreawise[] | [];
  @observable yearSectorwise?: IYearSectorwise[] | [];
  @observable statewise?: IStatewise[] | [];
  @observable monthwise?: IMonthwise[] | [];
  @observable yearwise?: IYearwise[] | [];

  @observable loadingRegistrationDetail: boolean = false;
  @observable registrationDetail?: IRegistration[] | undefined;
  @observable pendingRegistration: IPendingOrApprove | any;
  @observable loadingAssessmentDetail: boolean = false;
  @observable assessmentDetail?: IAssessmentCount[] | undefined;
  @observable isPending: boolean = false;
  @observable orgDropdownValues: DropdownItemProps[] = [];
  @observable pincodeDropdownValues: DropdownItemProps[] = [];
  @observable organisation: IOrganisation | any;
  @observable orgName: IOrganisation[] = [];
  @observable orgState: IAllOrganisation[] = [];

  @observable isRegistrationCount: boolean = false;
  @observable isRegistrationApproval: boolean = false;
  @observable isAssessmentReview: boolean = false;
  @observable pincode: IPincode[] = [];
  @action getDashboard = async () => {
    try {
      //  this.loadingDashboard = true;
      this.dashboard = undefined;
      const dashboard = await agent.Dashboard.view();
      runInAction(() => {
        this.dashboard = dashboard;
        this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //  this.loadingDashboard = false;
      });
    }
  };

  @action getRegistrationCount = async () => {
    try {
      this.isRegistrationCount = true;
      this.registrationCount = undefined;
      const dashboard = await agent.Dashboard.getRegisterCount();
      runInAction(() => {
        this.registrationCount = dashboard;
        //this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.isRegistrationCount = false;
      });
    }
  };

  @action getRegistrationApproval = async () => {
    try {
      this.isRegistrationApproval = true;
      this.registrationApproval = undefined;
      const dashboard = await agent.Dashboard.getRegistrationApproved();
      runInAction(() => {
        this.registrationApproval = dashboard;
        //this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.isRegistrationApproval = false;
      });
    }
  };

  @action getAssessmentReview = async () => {
    try {
      this.isAssessmentReview = true;
      this.assessmentReviewal = undefined;
      const dashboard = await agent.Dashboard.getAssessmentAssessed();
      runInAction(() => {
        this.assessmentReviewal = dashboard;
        //this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.isAssessmentReview = false;
      });
    }
  };

  @action getOrganisationName = async () => {
    try {
      this.loadingDropdown = true;
      // this.orgName = undefined;
      const dashboard = await agent.Dashboard.getOrgansiationName();
      runInAction(() => {
        this.orgName = dashboard;
        this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingDropdown = false;
      });
    }
  };
  @action getStatewise = async () => {
    try {
      // this.loadingDashboard = true;
      // this.statewise = undefined;
      const dashboard = await agent.Dashboard.getStatewiseRegistration();
      runInAction(() => {
        this.statewise = dashboard;
        //this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        // this.loadingDashboard = false;
      });
    }
  };
  @action getMonthwise = async () => {
    try {
      // this.loadingDashboard = true;
      this.monthwise = undefined;
      const dashboard = await agent.Dashboard.getAssessmentMonthwise();
      runInAction(() => {
        this.monthwise = dashboard;
        // this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //   this.loadingDashboard = false;
      });
    }
  };

  @action getYearwise = async () => {
    try {
      // this.loadingDashboard = true;
      this.yearwise = undefined;
      const dashboard = await agent.Dashboard.getAssessmentYearwise();
      runInAction(() => {
        this.yearwise = dashboard;
        // this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //   this.loadingDashboard = false;
      });
    }
  };

  @action getYearAreawise = async () => {
    try {
      //this.loadingDashboard = true;
      this.yearAreawise = undefined;
      const dashboard = await agent.Dashboard.getAreaYearwise();
      runInAction(() => {
        this.yearAreawise = dashboard;
        // this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //  this.loadingDashboard = false;
      });
    }
  };

  @action getSectorYearwise = async () => {
    try {
      // this.loadingDashboard = true;
      this.yearSectorwise = undefined;
      const dashboard = await agent.Dashboard.getSectorYearwiseRegistration();
      runInAction(() => {
        this.yearSectorwise = dashboard;
        // this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //   this.loadingDashboard = false;
      });
    }
  };

  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    this.orgName.map((org) => {
      if (org != null) {
        let item = {
          text: org.organisationName,
          value: org.organisationKey,
          key: org.organisationId,
        };
        dropDownValues.push(item);
      }
      return org;
    });
    this.orgDropdownValues = dropDownValues;
  }

  @action getPendingRegistration = async (pending: IPendingOrApprove) => {
    try {
      this.loadingRegistrationDetail = true;
      this.registrationDetail = undefined;
      this.isPending = pending.isPending;
      const registration = await agent.Dashboard.create(pending);
      runInAction(() => {
        this.registrationDetail = registration;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingRegistrationDetail = false;
      });
    }
  };

  @action getPendingAssessments = async (pending: IPendingOrApprove) => {
    try {
      this.loadingAssessmentDetail = true;
      this.assessmentDetail = undefined;
      this.isPending = pending.isPending;
      const assessment = await agent.Dashboard.listView(pending);
      runInAction(() => {
        this.assessmentDetail = assessment;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentDetail = false;
      });
    }
  };

  @action getOrganisation = async (req: ISearchOrganisation) => {
    try {
      this.organisation = undefined;
      const searchOrg = await agent.Dashboard.searchOrg(req.organisationKey);
      this.organisation = searchOrg;
      console.log(` organisationId being passed to odDashboard => ${ searchOrg.organisationId } `);
      runInAction(() => {
        if (this.organisation.organisationType === "ODPartner") {
          history.push({
            pathname: "/dod-odDashboard",
            state: { Id: searchOrg.organisationId },
          });
        } else if (this.organisation.organisationType === "Federation") {
          history.push({
            pathname: "/dod-federationDashboard",
            state: { Id: searchOrg.organisationId },
          });
        } else if (this.organisation.organisationType === "DonorOrganisation") {
          history.push({
            pathname: "/dod-donorDashboard",
            state: { Id: searchOrg.organisationId },
          });
        } else if (this.organisation.organisationType === "DonorIndividual") {
          history.push({
            pathname: "/dod-donorIndDashboard",
            state: { Id: searchOrg.organisationId },
          });
        }
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action getPincode = async () => {
    try {
      const pin = await agent.Dashboard.getPincode();
      runInAction(() => {
        this.pincode = pin;
        this.updatePincodeDropdown();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };
  @action updatePincodeDropdown() {
    let dropDownValues: DropdownItemProps[] = [];
    this.pincode.map((org) => {
      if (org != null) {
        let item = {
          text: org.pincode,
          value: org.pincode,
          key: org.pincode,
        };
        dropDownValues.push(item);
      }
      return org;
    });
    this.pincodeDropdownValues = dropDownValues;
  }

  @action getOrgByState = async (
    state: number,
    sector: number,
    pincode: number
  ) => {
    try {
      const org = await agent.Dashboard.searchState(state, sector, pincode);
      runInAction(() => {
        this.orgState = org;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };
}
