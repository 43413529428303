import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { IDonorIndApproveOrReject } from "../../../../app/models/donorIndividual";

interface IProps {
    approveOrRejectDonorInd: IDonorIndApproveOrReject,
}

const DonorIndiApproveOrReject: React.FC<IProps> = ({ approveOrRejectDonorInd: approveOrRejectDonorIndProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {donorIndApprove} = rootStore.donorIndividualStore;

    return (
        <FinalForm
            initialValues={approveOrRejectDonorIndProps}
            onSubmit={(values: IDonorIndApproveOrReject) => 
                donorIndApprove(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                {/* <Header color={approveOrRejectDonorIndProps.IsApproved ? 'green' : 'red'} style={{ height: '6vh' }}>{approveOrRejectDonorIndProps.IsApproved ? 'Approve' : 'Reject'} !</Header> */}
                 Kindly confirm  donor Individual can be {approveOrRejectDonorIndProps.IsApproved ? 'Approved' : 'Rejected'}
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '150px'}} loading ={submitting}>{approveOrRejectDonorIndProps.IsApproved ? 'Approve' : 'Reject'}</Button>
                </Form>
                 )}>
        </FinalForm>        
    )
}

export default observer(DonorIndiApproveOrReject);