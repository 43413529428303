import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { Button } from "semantic-ui-react";
import Logo from "./../../assets/images/logo/logo-symbol-dark.png";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    })
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() { }

  render() {
    // window.addEventListener("scroll", function() {
    //   var value = window.scrollY;
    //   if (value > 100) {
    //     document.querySelector(".header--fixed").classList.add("sticky")
    //   } else {
    //     document.querySelector(".header--fixed").classList.remove("sticky")
    //   }
    // });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        }
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src="/assets/images/logo/logo-light.png" alt="CIM - OD" />;
    } else if (logo === "dark") {
      logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="CIM - OD" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src={Logo} alt="CIM - OD" />;
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-symbol-light.png" alt="CIM - OD" />
      )
    } else {
      logoUrl = <img src="/assets/images/logo/logo.png" alt="CIM - OD" />;
    }

    return (
      <header style={{ position: "fixed", background: "white" }} className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink} >
                {logoUrl}
              </a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy style={{ marginLeft: "280px" }}
                className="mainmenu"
                items={["home", "about", "service", "contact"]}
              //currentClassName="is-current" offset = {-200} 
              >
                <li>
                  {" "}
                  <a href="/" className="home-menu">Home</a>
                </li>

                <li>
                  {" "}
                  <a href="/homepageAbout" className="home-menu">About OD</a>
                </li>

                <li>
                  {" "}
                  <a href="https://designit.cimindia.in/services/" target="_blank" rel="noopener noreferrer" className="home-menu"> Services</a>

                </li>
                <li>
                  {" "}
                  <a href="/homepageContact" className="home-menu">Contact Us</a>
                </li>
              </Scrollspy>
            </nav>

            <div className="header-right">


            <Button.Group color='blue' style={{ marginLeft: "20px" }}>
              <Button a href="/beneficiary">REGISTER</Button>
            </Button.Group>
            <div className="header-btn">
              <a className="rn-btn" href="/login">
                <span>LOGIN</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>

          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
