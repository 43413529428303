import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IRegistrationDone } from "../models/registrationDone";
import { DropdownItemProps } from "semantic-ui-react";
import {
  DonorIndividualRegisterValues,
  IDonorIndApproveOrReject,
  IDonorIndividualRegister,
  IDonorIndividualRegisterValues,
} from "../models/donorIndividual";
import { IAcceptRejectPartner } from "../models/donorOrg";
import { history } from "../..";
import { TryAgain } from "../common/constant";

export default class DonorIndividualStore {
  rootStore: RootStore;
  donorDetail: any;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable donorIndDetail?: IDonorIndividualRegister| any;
  @observable donorIndividualList: IDonorIndividualRegisterValues[] = [];
  @observable loadingUsers: boolean = false;
  @observable submitDonorInd: boolean = false;
  @observable loadingSendSms: boolean = false;
  @observable donorindSendSms: IRegistrationDone | undefined;
  @observable userDropdownValues: DropdownItemProps[] = [];
  @observable roleDropdownValues: DropdownItemProps[] = [
    {
      text: "Administrator",
      value: "Administrator",
      key: 1,
    },
  ];
  
  @observable loadingSavedDonorIndividualDetails: boolean = false;
  @observable loadingDonorIndividualDetail: boolean = false;
  @observable donorIndividualDetail: IDonorIndividualRegister | any;
  @observable donorIndApproveOrReject: IDonorIndApproveOrReject | any;
  @observable acceptRejectPartner: IAcceptRejectPartner | any;
  @observable saveDonorIndividual: IDonorIndividualRegisterValues | undefined;
  @observable IsRegister: boolean = false;
  @action createDonorIndividual = async (
    newUser: DonorIndividualRegisterValues
  ) => {
    try {
      this.submitDonorInd = true;
      console.log(newUser);
      let dataMap: Map<string, string> = new Map();
      if (newUser.Name !== undefined) dataMap.set("Name", newUser.Name);
      if (newUser.DateOfBirth !== undefined)
        dataMap.set("DateOfBirth", newUser.DateOfBirth);
      if (newUser.Gender !== undefined) dataMap.set("Gender", newUser.Gender);
      if (newUser.EmailId !== undefined)
        dataMap.set("EmailId", newUser.EmailId);
      if (newUser.Password !== undefined)
        dataMap.set("Password", newUser.Password);
      if (newUser.MobileContactNumber !== undefined)
        dataMap.set("MobileNo", newUser.MobileContactNumber);
      if (newUser.Address !== undefined)
        dataMap.set("Address", newUser.Address);
      if (newUser.Pincode !== undefined)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined) dataMap.set("State", newUser.State);
      if (newUser.Organisation !== undefined)
        dataMap.set("Organisation", newUser.Organisation);
      if (newUser.Designation !== undefined)
        dataMap.set("Designation", newUser.Designation);
      if (newUser.Nationality !== undefined)
        dataMap.set("Nationality", newUser.Nationality);
      if (newUser.MyPreferedWork !== undefined)
        dataMap.set("MyPreferedWork", newUser.MyPreferedWork);
      if (newUser.Others !== undefined) dataMap.set("Others", newUser.Others);
      if (newUser.ModeOfGivingPreference !== undefined)
        dataMap.set("ModeOfGivingPreference", newUser.ModeOfGivingPreference);
      if (newUser.MyPreferedChurchDomination !== undefined)
        dataMap.set(
          "MyPreferedChurchDomination",
          newUser.MyPreferedChurchDomination
        );
      if (newUser.GivingFrequence !== undefined)
        dataMap.set("GivingFrequence", newUser.GivingFrequence);
      if (newUser.MyPreferedState !== undefined)
        dataMap.set("MyPreferedState", newUser.MyPreferedState);
      if (newUser.MyPreferedDistrict !== undefined)
        dataMap.set("MyPreferedDistrict", newUser.MyPreferedDistrict);
      if (newUser.MyPreferedCommunity !== undefined)
        dataMap.set("MyPreferedCommunity", newUser.MyPreferedCommunity);
      if (newUser.MyPreferedTribe !== undefined)
        dataMap.set("MyPreferedTribe", newUser.MyPreferedTribe);
      if (newUser.MyPreferedLanguage !== undefined)
        dataMap.set("MyPreferedLanguage", newUser.MyPreferedLanguage);
      if (newUser.PreferedPincode !== undefined)
        dataMap.set("PreferedPincode", newUser.PreferedPincode);
      if (newUser.IamPresenting !== undefined)
        dataMap.set("IamPresenting", newUser.IamPresenting);
      if (newUser.MyGivingPreference !== undefined)
        dataMap.set("MyGivingPreference", newUser.MyGivingPreference);
      if (newUser.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (newUser.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.Country !== undefined)
        dataMap.set("Country", newUser.Country);
      if (newUser.ProfilePicture !== undefined)
        dataMap.set("ProfilePicture", newUser.ProfilePicture);
        if (newUser.refTitle1 !== undefined)
        dataMap.set("RefTitle1", newUser.refTitle1);
      if (newUser.refTitle2 !== undefined)
        dataMap.set("RefTitle2", newUser.refTitle2);
      if (newUser.refFirstName1 !== undefined)
        dataMap.set("RefFirstName1", newUser.refFirstName1);
      if (newUser.refFirstName2 !== undefined)
        dataMap.set("RefFirstName2", newUser.refFirstName2);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName2 !== undefined)
        dataMap.set("RefLastName2", newUser.refLastName2);
      if (newUser.refMobile1 !== undefined)
        dataMap.set("RefMobile1", newUser.refMobile1);
      if (newUser.refMobile2 !== undefined)
        dataMap.set("RefMobile2", newUser.refMobile2);
        if (newUser.refEmail1 !== undefined && newUser.refEmail1 !== null)
        dataMap.set("RefEmail1", newUser.refEmail1);
      if (newUser.refEmail2 !== undefined && newUser.refEmail2 !== null)
        dataMap.set("RefEmail2", newUser.refEmail2);
      if (newUser.refDesignation1 !== undefined)
        dataMap.set("RefDesignation1", newUser.refDesignation1);
      if (newUser.refDesignation2 !== undefined)
        dataMap.set("RefDesignation2", newUser.refDesignation2);
      if (newUser.refOrganization1 !== undefined)
        dataMap.set("RefOrganization1", newUser.refOrganization1);
      if (newUser.refOrganization2 !== undefined)
        dataMap.set("RefOrganization2", newUser.refOrganization2);
     
      let fileMap: Map<string, string> = new Map();
      if (newUser.ProofofTotalContribution)
        fileMap.set(
          "ProofofTotalContribution",
          newUser.ProofofTotalContribution
        );
      if (newUser.GivingTrackRecord)
        fileMap.set("GivingTrackRecord", newUser.GivingTrackRecord);
      const userResponse = await agent.DonorIndividual.createWithFile(
        dataMap,
        fileMap
      );
      runInAction(() => {
        this.submitDonorInd = false;
        this.donorIndividualList = [...this.donorIndividualList, userResponse];
        if (userResponse === -1) {
          toast.info("Email id already Registered.Try using different id");
        } else {
          toast.info("User Registered Successfully");
          history.push("/thankyouind");
        }
      });
    } catch (error) {
      throw error;
    }
  };

  @action getDonorIndividualById = async (id: number) => {
    try {
      this.loadingDonorIndividualDetail = true;
      this.donorIndividualDetail = undefined;

      const donorIndividual = await agent.DonorIndividual.getById(id);
      console.log(donorIndividual);
      runInAction(() => {
        this.donorIndividualDetail = donorIndividual;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingDonorIndividualDetail = false;
      });
    }
  };

  @action donorIndApprove = async (approve: IDonorIndApproveOrReject) => {
    try {
      console.log(approve);
      const donorIndApproveResponse = await agent.DonorIndividual.approve(
        approve
      );
      console.log(donorIndApproveResponse);
      runInAction(() => {
        this.donorIndApproveOrReject = donorIndApproveResponse;
        console.log(this.donorIndApproveOrReject);
       
        toast.info(
          "Donor Individual " +
            (approve.IsApproved ? "approved" : "rejected")
        );
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action donorIndPartnerApprove = async (approve: IAcceptRejectPartner) => {
    try {
      console.log(approve);
      const donorIndApproveResponse = await agent.DonorIndividual.sendApproval(
        approve
      );
      console.log(donorIndApproveResponse);
      runInAction(() => {
        this.acceptRejectPartner = donorIndApproveResponse;
        console.log(this.acceptRejectPartner);
        
        if (approve.isInterest === true) {
          toast.success(
            approve.donorIndividualName +
              " shows interest to support " +
              approve.organisationName
          );
        } else {
          toast.warn(
            approve.donorIndividualName +
              " shows not interest to support " +
              approve.organisationName
          );
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  
  @action registerationDone = async (values: IRegistrationDone ) => {
    this.loadingSendSms = true;
    console.log(values);
    try {
      const user = await agent.DonorIndsmsNotify.notifyUser(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.donorindSendSms = user;
        this.loadingSendSms = false;
        
      });
    } catch (error) {
      this.loadingSendSms = false;
      toast.error(TryAgain);
      throw error;
    }
  };

  @action getSavedDonorIndividualDetails = async (
    contactNumber: DonorIndividualRegisterValues
  ) => {
    this.loadingSavedDonorIndividualDetails = true;
    try {
      const newBene = await agent.DonorIndividual.getSave(
        contactNumber
      );
      runInAction(() => {
        this.donorIndDetail = newBene;
        this.loadingSavedDonorIndividualDetails = false;
      });
    } catch (ex) {
      //history.push('/beneficiary');

      this.loadingSavedDonorIndividualDetails = false;
    }
  };


  @action saveDonorIndividualDetails = async (
    donorInd: IDonorIndividualRegisterValues
  ) => {
    try {
      this.IsRegister = true;
      let dataMap: Map<string, any> = new Map();
      if (donorInd.Name !== undefined)
        dataMap.set("Name", donorInd.Name);

        if (donorInd.DateOfBirth !== undefined)
        dataMap.set("DateOfBirth", donorInd.DateOfBirth);
        if (donorInd.Gender !== undefined)
        dataMap.set("Gender", donorInd.Gender);
        if (donorInd.EmailId !== undefined)
        dataMap.set("EmailId", donorInd.EmailId);
        if (donorInd.Password !== undefined)
        dataMap.set("Password", donorInd.Password);
        if (donorInd.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", donorInd.MobileContactNumber);
      if (donorInd.Address !== undefined)
        dataMap.set("Address", donorInd.Address);
      
      if (donorInd.Pincode !== undefined)
        dataMap.set("Pincode", donorInd.Pincode);
        if (donorInd.Country !== undefined)
        dataMap.set("Country", donorInd.Country);
      if (donorInd.State !== undefined)
        dataMap.set("State", donorInd.State);
      
      
      if (donorInd.Nationality !== undefined) {
        dataMap.set("Nationality", donorInd.Nationality);
      }
      if (donorInd.Organisation !== undefined)
        dataMap.set("Organisation", donorInd.Organisation);

        if (donorInd.Designation !== undefined)
        dataMap.set("Designation", donorInd.Designation);

        if (donorInd.MyGivingPreference !== undefined)
        dataMap.set("MyGivingPreference", donorInd.MyGivingPreference);



      if (donorInd.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", donorInd.WebsiteAddress);

      if (donorInd.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", donorInd.WhatsappNumber);
      if (donorInd.FacebookID !== undefined)
        dataMap.set("FacebookID", donorInd.FacebookID);
      if (donorInd.TwitterID !== undefined)
        dataMap.set("TwitterID", donorInd.TwitterID);

        if (donorInd.MyPreferedWork !== undefined)
        dataMap.set("MyPreferedWork", donorInd.MyPreferedWork);

        if (donorInd.Others !== undefined)
        dataMap.set("Others", donorInd.Others);


      if (donorInd.ModeOfGivingPreference !== undefined)
        dataMap.set("ModeOfGivingPreference", donorInd.ModeOfGivingPreference);
       
      if (donorInd.MyPreferedChurchDomination !== undefined)
        dataMap.set("MyPreferedChurchDomination", donorInd.MyPreferedChurchDomination);

      if (donorInd.GivingFrequence !== undefined)
        dataMap.set("GivingFrequence", donorInd.GivingFrequence);
      if (donorInd.MyPreferedState !== undefined)
        dataMap.set("MyPreferedState", donorInd.MyPreferedState);
      if (donorInd.MyPreferedDistrict !== undefined)
        dataMap.set("MyPreferedDistrict", donorInd.MyPreferedDistrict);
      if (donorInd.MyPreferedCommunity !== undefined)
        dataMap.set("MyPreferedCommunity", donorInd.MyPreferedCommunity);



        if (donorInd.MyPreferedTribe !== undefined)
        dataMap.set("MyPreferedTribe", donorInd.MyPreferedTribe);

        if (donorInd.MyPreferedLanguage !== undefined)
        dataMap.set("MyPreferedLanguage", donorInd.MyPreferedLanguage);

        if (donorInd.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", donorInd.OtherMediaLink);

        if (donorInd.PreferedPincode !== undefined)
        dataMap.set("PreferedPincode", donorInd.PreferedPincode);

        if (donorInd.IamPresenting !== undefined)
        dataMap.set("IamPresenting", donorInd.IamPresenting);



        if (donorInd.ProofofTotalContribution !== undefined)
        dataMap.set("ProofofTotalContribution", donorInd.ProofofTotalContribution);


        if (donorInd.refTitle1 !== undefined)
        dataMap.set("refTitle1", donorInd.refTitle1);


        if (donorInd.refFirstName1 !== undefined)
        dataMap.set("refFirstName1", donorInd.refFirstName1);

        if (donorInd.refLastName1 !== undefined)
        dataMap.set("refLastName1", donorInd.refLastName1);

        if (donorInd.refMobile1 !== undefined)
        dataMap.set("refMobile1", donorInd.refMobile1);




        if (donorInd.refEmail1 !== undefined)
        dataMap.set("refEmail1", donorInd.refEmail1);

        if (donorInd.refDesignation1 !== undefined)
        dataMap.set("refDesignation1", donorInd.refDesignation1);

        if (donorInd.refOrganization1 !== undefined)
        dataMap.set("refOrganization1", donorInd.refOrganization1);

        if (donorInd.refTitle2 !== undefined)
        dataMap.set("refTitle2", donorInd.refTitle2);

        if (donorInd.refFirstName2 !== undefined)
        dataMap.set("refFirstName2", donorInd.refFirstName2);

        if (donorInd.refLastName2 !== undefined)
        dataMap.set("refLastName2", donorInd.refLastName2);

        

        let fileMap: Map<string, string> = new Map();
      if (donorInd.ProfilePicture)
        fileMap.set("ProfilePicture", donorInd.ProfilePicture);
      if (donorInd.GivingTrackRecord)
        fileMap.set("GivingTrackRecord", donorInd.GivingTrackRecord);
    







        if (donorInd.refMobile2 !== undefined)
        dataMap.set("refMobile2", donorInd.refMobile2);

        if (donorInd.refEmail2 !== undefined)
        dataMap.set("refEmail2", donorInd.refEmail2);

        if (donorInd.fedarationBasicDetailIds !== undefined)
        dataMap.set("fedarationBasicDetailIds", donorInd.fedarationBasicDetailIds);

        if (donorInd.refDesignation2 !== undefined)
        dataMap.set("refDesignation2", donorInd.refDesignation2);

        if (donorInd.refOrganization2 !== undefined)
        dataMap.set("refOrganization2", donorInd.refOrganization2);






      // let fileMap: Map<string, string> = new Map();
      // if (donorInd.OrganizationLogoFile)
      //   fileMap.set("OrganizationLogo", donorInd.OrganizationLogoFile);
      // if (donorInd.CEOPhoto)
      //   fileMap.set("CEOPhoto", donorInd.CEOPhoto);
      // if (donorInd.TrusteeorManagementProfile)
      //   fileMap.set(
      //     "TrusteeorManagementProfile",
      //     donorInd.TrusteeorManagementProfile
      //   );

      // if (donorInd.ContactTitle !== undefined)
      //   dataMap.set("ContactTitle", donorInd.ContactTitle);

      // if (donorInd.ContactFirstName !== undefined)
      //   dataMap.set("ContactFirstName", donorInd.ContactFirstName);

      // if (donorInd.ContactLastName !== undefined)
      //   dataMap.set("ContactLastName", donorInd.ContactLastName);

      // if (donorInd.ContactMobile !== undefined)
      //   dataMap.set("ContactMobile", donorInd.ContactMobile);

      // if (donorInd.ContactEmailId !== undefined)
      //   dataMap.set("ContactEmailId", donorInd.ContactEmailId);

      // if (donorInd.ContactDesignation !== undefined)
      //   dataMap.set("ContactDesignation", donorInd.ContactDesignation);

      // var obj1 = newUser.PartnerContactPersonJson;
      // var contactObj = JSON.stringify(obj1);
      // dataMap.set("PartnerContactPersonJson", contactObj);
      // console.log(dataMap);
      //     const userResponse = await agent.ODPartner.createWithFile(
      //       dataMap,
      //       fileMap
      //     );
      //     runInAction(() => {
      //       this.IsRegister = false;
      //       this.partnerList = [...this.partnerList, userResponse];
      //       if (userResponse === -1) {
      //         toast.error("Email id already Registered.Try using different id");
      //       } else {
      //         toast.info("Registered Successfully.Our Admin will review and approve shortly.");
      //       }
      //     });
      //   } catch (error) {
      //     toast.error("Please try again after some time");
      //   }
      // };

      const saveDonorIndividualDetails = await agent.DonorIndividual.save(
        dataMap,
        fileMap
      );

      runInAction(() => {
        this.saveDonorIndividual = saveDonorIndividualDetails;
        console.log(this.saveDonorIndividual);
        // if (saveODPartner === -1) {
        //   toast.error("Email id Already Registered");
        // }
        this.IsRegister = false;
      });
      return saveDonorIndividualDetails;
    } catch (ex) {
      runInAction(() => {
        this.IsRegister = false;
      });
      //   toast.error(ex?.data.errors.message);
      throw ex;
    }
  };
}

//}
