import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Button, Segment, Icon, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import CreateUser from "./CreateUser";
import { IManageUser } from "../../../app/models/manageUser";
import DisableUser from "./DisableUser";

const UserList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getAllUsers,
    manageUserList,
    getUserFromId,
  } = rootStore.manageUserStore;
  const { openRightPanel } = rootStore.rightPanelStore;
  const { openModal } = rootStore.modalStore;
  //const secondColumnStart = Math.floor(this.props.result.length / 2);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  return (
    /*  <FinalForm
      onSubmit={() => {}}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (  */

    <Segment>
      <Table>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>User Id</Table.HeaderCell> */}
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Full Name</Table.HeaderCell>
            <Table.HeaderCell>Mobile No</Table.HeaderCell>
            <Table.HeaderCell>Email Id</Table.HeaderCell>

            <Table.HeaderCell>Enable/Disable</Table.HeaderCell>
            <Table.HeaderCell>View</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {manageUserList &&
          manageUserList.map((user) => (
            <Table.Row key={user.userId}>
              {/* <Table.Cell>{user.userId}</Table.Cell> */}
              <Table.Cell>{user.userName}</Table.Cell>
              <Table.Cell>{user.fullName}</Table.Cell>
              <Table.Cell>{user.userMobile}</Table.Cell>
              <Table.Cell>{user.userEmail}</Table.Cell>

              <Table.Cell>
                <Button
                  onClick={() => {
                    let userdisable: IManageUser = getUserFromId(user.userId);
                    openModal(
                      `${user.isDisabled ? "Enable" : "Disable"} User`,
                      <DisableUser disableEnableUser={userdisable} />
                    );
                  }}
                >
                  {" "}
                  {user.isDisabled === true && (
                    <Icon className="close" color="blue"></Icon>
                  )}
                  {user.isDisabled === false && (
                    <Icon className="check" color="blue"></Icon>
                  )}
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button
                  onClick={() => {
                    openRightPanel("Edit User", <CreateUser editUser={user} />);
                  }}
                >
                  <Icon className="edit" color="blue"></Icon>
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table>
    </Segment>
  );
};
export default observer(UserList);
