import React, { useContext, useEffect, useState } from 'react';
import { Container, Segment, Button, Icon, Grid, Form, Table, Popup } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { Field, Form as FinalForm } from "react-final-form";
import { observer } from 'mobx-react-lite';
import SelectInput from '../../../app/common/form/SelectInput';
import { IQuestionAnswerValues, QuestionSetupFormValues } from '../../../app/models/questionSetup';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { Link } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';
import CheckInput from '../../../app/common/form/CheckInput';
import arrayMutators from 'final-form-arrays'
import EditQuestion from './EditQuestion';
import FileUpload from "../../../app/common/form/FileUpload";
import ExportQuestion from './ExportQuestion';
import SelectInputTab from '../../../app/common/form/SelectInputTab';


interface state {
    open: false
}

const QuestionDashboard = () => {

    const rootStore = useContext(RootStoreContext);
    const { questionList, getQuestionSetUpByMaster, getById } = rootStore.questionSetupStore
    let { getActiveSector, sectorDropdownValues } = rootStore.sectorStore;
    let { subSectorDropdownValues, getSubSectorFromSectorId } = rootStore.subSectorStore;
    let { areaDropdownValues, getActiveArea } = rootStore.areaOfAssessmentStore;
    const { openRightPanel } = rootStore.rightPanelStore;
    const {
        uploadDocument, uploadingDocument
    } = rootStore.questionSetupStore;
    const state = {
        showField: false,
        showRadio: false,
        showCheck: false,
        showDrop: false,
        showFile: false,
        selectedValue: "Type",
    };
    const [searchState] = React.useState({
        sectorId: 0,
        subSectorId: 0,
        areaOfAssessmentId: 0,
        question: "",
        answerType: "",
        order: 0,
        file: ""
    });


    const { questionNames, upsertQuestion } = rootStore.questionSetupStore;
    const [selectedId, setSelectedId] = useState(state);

    const checkLayers = (value: string) => {

        searchState.answerType = value;
        //const { value } = event.target.value;
        setSelectedId({
            showField: value === "TextBox",
            showRadio: value === "Radio",
            showCheck: value === "Check",
            showDrop: value === "DropDown",
            showFile: value === "File",
            selectedValue: value,

        });
        console.log(value);
    };

    const AnswerTypeValues = [
        { text: "TextBox", value: "TextBox", key: 1 },
        { text: "Radio Button", value: "Radio", key: 2 },
        { text: "Check Box", value: "Check", key: 3 },
        { text: "DropDown", value: "DropDown", key: 4 },
        { text: "File Upload", value: "File", key: 5 }
    ]
    const handleSectorChange = (event) => {
        searchState.sectorId = event.value;
        const ss = getSubSectorFromSectorId(event.value);
        console.log(ss);
    };
    const handleSubSectorChange = (event) => {
        searchState.subSectorId = event.value;
    };
    const handleAreaChange = (event) => {
        searchState.areaOfAssessmentId = event.value;
        getQuestionSetUpByMaster(searchState);
    };


    const handleFileChange = (event) => {
        searchState.file = event.target.files[0];
    };


    const searchQuestions = (event) => {
        getQuestionSetUpByMaster(searchState);
    }
    const handleUploadFile = (event) => {
        uploadDocument(searchState)//.then(() => setAddDocumentMode(false));
    };

    useEffect(() => {
        getActiveSector();
        getActiveArea();

    }, [getActiveSector, getActiveArea]);

    let initialFormVal = new QuestionSetupFormValues();
    initialFormVal.sectorId = searchState.sectorId;
    initialFormVal.subSectorId = searchState.subSectorId;
    initialFormVal.areaOfAssessmentId = searchState.areaOfAssessmentId;
    initialFormVal.question = searchState.question;
    initialFormVal.questionOrder = searchState.order;
    initialFormVal.answerType = searchState.answerType;
    return (
        <Container>
            <FinalForm
                initialValues={initialFormVal}

                onSubmit={(values: IQuestionAnswerValues) => {
                    console.log(values.file);
                    upsertQuestion(values)
                }


                }

                mutators={{
                    ...arrayMutators
                }}
                render={({ handleSubmit, form: {
                    mutators: { push, pop }
                }, submitting,
                    invalid, pristine, dirtySinceLastSubmit, submitError }) => (
                    <Form onSubmit={handleSubmit} error>
                        {submitError &&
                            (
                                <ErrorMessage error={submitError} />
                            )}
                        <Segment>
                            <div className='App-question'>
                            </div>
                        </Segment>

                        <Segment>
                            <Grid columns={4}>
                                <Grid.Column>
                                    <Field component={SelectInputTab} placeholder='Sector' name='sectorId'
                                        onCustomChange={handleSectorChange} inputstyle={{ marginLeft: '30px' }}
                                        search selections={"true"} clearable='true' options={sectorDropdownValues} dropup={"true"} />

                                </Grid.Column>
                                <Grid.Column>
                                    <Field component={SelectInputTab} style={{ marginLeft: '30px' }} placeholder='Sub Sector' name="subSectorId"
                                        onCustomChange={handleSubSectorChange} search selections={"true"} options={subSectorDropdownValues} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field component={SelectInputTab} style={{ marginLeft: '30px' }} placeholder='Area' name="areaOfAssessmentId"
                                        onCustomChange={handleAreaChange} search selections={"true"} options={areaDropdownValues} />
                                </Grid.Column>

                                <Grid.Column>
                                    <Button style={{ float: "right", marginTop: '3px', marginRight: '145px' }} onClick={searchQuestions} type="button" color="blue" content="search">
                                    </Button>
                                </Grid.Column>
                            </Grid>

                            <Grid columns={4}>
                                <Grid.Column width={4}>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <Field name="File" component={FileUpload} onChange={handleFileChange} />
                                </Grid.Column>

                                <Grid.Column width={2}>
                                    <div style={{ paddingTop: '16px' }}>
                                        {searchState.sectorId !== 0 && searchState.subSectorId !== 0 && searchState.areaOfAssessmentId !== 0 &&
                                            <Button onClick={handleUploadFile} loading={uploadingDocument} type="button" color="blue" content="import">
                                            </Button>
                                        }
                                    </div>
                                </Grid.Column>

                                <Grid.Column width={3}>
                                    <div style={{ paddingTop: '6px' }}>
                                        {searchState.sectorId !== 0 && searchState.subSectorId !== 0 && searchState.areaOfAssessmentId !== 0 &&

                                            <Popup trigger={<ExportQuestion sector={searchState.sectorId} subsector={searchState.subSectorId} area={searchState.areaOfAssessmentId} />} content='Click to export to excel'></Popup>
                                        }
                                    </div>
                                </Grid.Column>
                            </Grid>

                        </Segment>
                        <br />
                        <Container hidden={true}>
                            <Segment raised>
                                <Grid>
                                    <Grid.Row>
                                        <Field component="textarea" name="question" onBlur={(event: any) => { searchState.question = event?.target.value }}
                                            style={{ width: '1000px', marginLeft: '30px', height: '5vh' }} placeholder='Enter Question..' />
                                    </Grid.Row>
                                    <Grid.Column width={3}>

                                        <Field component="input" name="questionOrder" onBlur={(event: any) => { searchState.order = event?.target.value }} style={{ width: '200px', marginLeft: '10px' }}
                                            type="number"
                                            parse={(value: string | number) => Number(value)}
                                            placeholder='Enter Question Order' />
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Field style={{ marginLeft: '50px' }}
                                            name="defaultHidden"
                                            label="Is a Sub Question"
                                            component={CheckInput}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Field component={SelectInput} name="answerType" style={{ marginLeft: '50px' }} options={AnswerTypeValues}
                                            value={selectedId.selectedValue} onChange={checkLayers} fluid search selection={"true"} label="Select answer type ">
                                        </Field>
                                    </Grid.Column>

                                </Grid>
                                <Grid>
                                    <Grid.Column>
                                        {
                                            selectedId.showField ?
                                                (
                                                    <FieldArray name="answerOptionCommand">
                                                        {({ fields }) => (
                                                            <Grid>
                                                                {fields.map((name, index) => (
                                                                    <Grid key={name}>
                                                                        <Grid.Column width={4} style={{ marginLeft: '30px' }} >
                                                                            <Field name={`${name}.pointValue`} component="input" placeholder="Enter Points"
                                                                                type="number"
                                                                                parse={(value: string | number) => Number(value)} />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={4} >
                                                                            <Field name={`${name}.actionPoints`} component="input" placeholder="Enter Action Points"
                                                                            />
                                                                        </Grid.Column>
                                                                        {/* <Grid.Column width= {3}> */}
                                                                        <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question'
                                                                            search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                                                        {/* </Grid.Column> */}
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        )}
                                                    </FieldArray>
                                                ) : null}
                                        {
                                            selectedId.showRadio ?
                                                (
                                                    <FieldArray name="answerOptionCommand">
                                                        {({ fields }) => (
                                                            <Grid>
                                                                {fields.map((name, index) => (
                                                                    <Grid key={name}>
                                                                        <Grid.Column width={3} style={{ marginLeft: '30px' }} >
                                                                            <Field name={`${name}.optionName`} component="input" placeholder="Enter options" />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={3}  >
                                                                            <Field name={`${name}.pointValue`} component="input" placeholder="Enter Points"
                                                                                type="number"
                                                                                parse={(value: string | number) => Number(value)} />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={3}  >
                                                                            <Field name={`${name}.actionPoints`} component="input" placeholder="Enter Action Points"
                                                                            />
                                                                        </Grid.Column>
                                                                        {/* <Grid.Column width= {3}> */}
                                                                        <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question'
                                                                            search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                                                        {/* </Grid.Column> */}
                                                                        <Grid style={{ marginLeft: '150px' }}>
                                                                            <Grid.Column  >
                                                                                {/* <Button type="button" onClick={() => fields.remove(index)}> */}
                                                                                <Icon name='remove' color='red' onClick={() => fields.remove(index)} />
                                                                                {/* </Button> */}
                                                                            </Grid.Column>
                                                                            <Grid.Column >
                                                                                {/* <button
                                                                                    type="button"
                                                                                   
                                                                                > */}
                                                                                <Icon name='add' color='blue' onClick={() => push('answerOptionCommand')} />
                                                                                {/* </button> */}
                                                                            </Grid.Column>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                            </Grid>
                                                        )}
                                                    </FieldArray>
                                                ) : null
                                        }
                                        {selectedId.showCheck ? (
                                            <>
                                                <FieldArray name="answerOptionCommand">
                                                    {({ fields }) => (
                                                        <Grid>
                                                            {fields.map((name) => (
                                                                <Grid key={name}>
                                                                    <Grid.Column width={3} style={{ marginLeft: '30px' }}>
                                                                        <Field name={`${name}.optionName`} component="input" placeholder="Enter options" />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3}>
                                                                        <Field name={`${name}.pointValue`} component="input" placeholder="Enter Points"
                                                                            type="number"
                                                                            parse={(value: string | number) => Number(value)} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3}>
                                                                        <Field name={`${name}.actionPoints`} component="input" placeholder="Enter Action Points" />
                                                                    </Grid.Column>
                                                                    {/* <Grid.Column width={3}> */}
                                                                    <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question'
                                                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                                                    {/* </Grid.Column> */}
                                                                    <Grid style={{ marginLeft: '150px' }}>
                                                                        <Grid.Column>
                                                                            {/* <button type="button" > */}
                                                                            <Icon name='remove' color='red' onClick={() => pop('answerOptionCommand')} />
                                                                            {/* </button> */}
                                                                        </Grid.Column>
                                                                        <Grid.Column>
                                                                            {/* <button
                                                                                type="button"
                                                                                
                                                                            > */}
                                                                            <Icon name='add' color='blue' onClick={() => push('answerOptionCommand')} />
                                                                            {/* </button> */}
                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}

                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            </>
                                        ) : null}
                                        {selectedId.showDrop ? (
                                            <>
                                                <FieldArray name="answerOptionCommand">
                                                    {({ fields }) => (
                                                        <Grid>
                                                            {fields.map((name) => (
                                                                <Grid key={name}>
                                                                    <Grid.Column width={3} style={{ marginLeft: '30px' }}>
                                                                        <Field name={`${name}.optionName`} component="input" placeholder="Enter Text" />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3}>
                                                                        <Field name={`${name}.pointValue`} component="input" placeholder="Enter Points" type="number"
                                                                            parse={(value: string | number) => Number(value)} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3}>
                                                                        <Field name={`${name}.actionPoints`} component="input" placeholder="Enter Action Points" />

                                                                    </Grid.Column>
                                                                    {/* <Grid.Column width={3}> */}
                                                                    <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question'
                                                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                                                    {/* </Grid.Column> */}
                                                                    <Grid style={{ marginLeft: '150px' }}>
                                                                        <Grid.Column >
                                                                            {/* <button type="button" > */}
                                                                            <Icon name='remove' color='red' onClick={() => pop('answerOptionCommand')} />
                                                                            {/* </button> */}
                                                                        </Grid.Column>
                                                                        <Grid.Column>
                                                                            {/* <button
                                                                                type="button"
                                                                               
                                                                            > */}
                                                                            <Icon name='add' color='blue' onClick={() => push('answerOptionCommand')} />
                                                                            {/* </button> */}
                                                                        </Grid.Column>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}

                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            </>
                                        ) : null}
                                        {selectedId.showFile ? (
                                            <>
                                                <FieldArray name="answerOptionCommand">
                                                    {({ fields }) => (
                                                        <Grid>
                                                            {fields.map((name, index) => (
                                                                <Grid key={name}>
                                                                    <Grid.Column width={4} style={{ marginLeft: '30px' }} >
                                                                        <Field name={`${name}.pointValue`} component="input" placeholder="Enter Points"
                                                                            type="number"
                                                                            parse={(value: string | number) => Number(value)} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={4} >
                                                                        <Field name={`${name}.actionPoints`} component="input" placeholder="Enter Action Points"
                                                                        />
                                                                    </Grid.Column>
                                                                    {/* <Grid.Column width= {3}> */}
                                                                    <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question'
                                                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                                                    {/* </Grid.Column> */}
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </FieldArray>
                                            </>
                                        ) : null}
                                    </Grid.Column>
                                </Grid>
                                <br />
                                <Button style={{ marginLeft: "900px" }}
                                    disabled={(invalid && !dirtySinceLastSubmit)
                                        || pristine
                                    }
                                    loading={submitting} primary type='submit'><Icon name='check' /> Submit </Button>
                                <br />
                                <br />

                            </Segment>
                        </Container>
                    </Form>
                )}>

            </FinalForm>


            <Segment>                
                    <Table>
                        <Table.Header >
                            <Table.HeaderCell>Question Name</Table.HeaderCell>
                            {/* <Table.HeaderCell>Question Order</Table.HeaderCell> */}
                            <Table.HeaderCell>Answer Type</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Sub Question</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Edit</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Disable</Table.HeaderCell>
                        </Table.Header>

                        <Table.Body>
                            {
                                questionList &&
                                questionList.map(
                                    (question) => (
                                        <Table.Row key={question.questionId}>
                                            <Table.Cell>{question.question}</Table.Cell>
                                            {/* <Table.Cell>{question.questionOrder}</Table.Cell> */}
                                            <Table.Cell>{question.answerType}</Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                {
                                                    question.defaultHidden ? 'Yes' : 'No'
                                                }
                                            </Table.Cell>
                                            {
                                                <Table.Cell textAlign='center'>
                                                    <Icon name='edit' onClick={() => openRightPanel('Edit Question Setup',
                                                        <EditQuestion questionSetupDetail={question} />
                                                    )} ></Icon>
                                                </Table.Cell>
                                            }
                                            { <Table.Cell>
                                                <Button as={Link} to='dod-disableQuestion' onClick={() => {

                                                    getById(question.questionId);
                                                }}>
                                                    {question.isActive === false && <Icon className="close" color="blue"></Icon>}
                                                    {question.isActive === true && <Icon className="check" color="blue"></Icon>}
                                                </Button></Table.Cell>
                                            }
                                        </Table.Row>
                                    ))
                            }
                        </Table.Body>
                    </Table>
               
            </Segment>
        </Container>

    );
}

export default observer(QuestionDashboard);