import React, { useContext ,useEffect} from 'react'
import logo from '../../../src/assets/images/logo/logo-symbol-dark.png'
import { Form, Grid, Header, Image, Button } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { RootStoreContext } from '../../app/stores/rootStore'
import {  IForgotPasswordFormValues } from '../../app/models/user'
import { FORM_ERROR } from 'final-form'
import { combineValidators,composeValidators,isNumeric , isRequired,matchesPattern } from 'revalidate'
import ErrorMessage from '../../app/common/form/ErrorMessage'
import TextInput from '../../app/common/form/TextInput'
import { observer } from 'mobx-react-lite'


function ForgotPassword() {
  const rootStore = useContext(RootStoreContext);
  const { forgotPassword ,isForgetPasswordLoading} = rootStore.authStore;
  const {getVerifyByMobileOrEmail, verifyByMobile } = rootStore.registrationOTPStore;

  const validate = combineValidators({
    mobileNumber: composeValidators(
        isRequired,
        isNumeric
    )('Mobile Number')
  })
  
  const validateEmail = combineValidators({
    mobileNumber : composeValidators(
        isRequired("EmailId"),
        matchesPattern(
          rootStore.commonStore.emailRegExp
        )({
          message: "Please enter the valid EmailId",
        })
      )(),
  })
  useEffect(() => {
    getVerifyByMobileOrEmail();
  }, [getVerifyByMobileOrEmail]);

  let isMobile=verifyByMobile;

  return (
    <Grid textAlign='center' style={{ height: '80vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center' style={{ color: '#3f66b5',fontStyle: 'italic' }}>
          <Image src={logo} style={{ width: '23vh' }} />
          <br />
            FORGOT PASSWORD 
        </Header>
        <FinalForm
          onSubmit={(values: IForgotPasswordFormValues) => 
           { 
              forgotPassword(values).catch(error => ({
                [FORM_ERROR]: error
            }))
            
              window.localStorage.setItem('mobile',values.mobileNumber)
          }   
            
          }

          validate={isMobile ? validate : validateEmail}
          render={({
            handleSubmit,
            submitting,
            submitError,
            invalid,
            dirtySinceLastSubmit
          }) => (
              <Form onSubmit={handleSubmit} error>

                <Field name='mobileNumber' id='mobile' component={TextInput} placeholder={isMobile ? 'Enter Mobile Number': 'Enter EmailId'} />
                
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage
                    error={submitError}
                    frontEndError={submitError}
                  />
                )}
                
                <Button
                  disabled={invalid || rootStore.authStore.isForgetPasswordLoading}
                  loading={isForgetPasswordLoading}
                  style = {{color : '#3f66b5'}}
                  content='Send OTP'
                  fluid
                  
                />
                
              </Form>
            )}
        />
        
      </Grid.Column>
    </Grid >
  )
}
export default observer(ForgotPassword)

