import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext, Fragment } from "react";
import { Form as FinalForm, Field } from 'react-final-form';
import { Button, Form, Grid, Icon, } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IManageUser } from "../../../app/models/manageUser";
import { combineValidators, composeValidators, hasLengthBetween, isNumeric, isRequired, matchesField, matchesPattern } from "revalidate";
import TextInputTab from "../../../app/common/form/TextInputTab";
import FileUploadTab from "../../../app/common/form/FileUploadTab";


interface IProps {
  editUser?: IManageUser,
}

const CreateUser: React.FC<IProps> = ({ editUser }) => {
  const validate = combineValidators({
    userName:
      isRequired('UserName'),
    fullName:
      isRequired('FullName'),
    // role:
    //   isRequired('Role'),
    confirmPassword:
      composeValidators(
        isRequired({ message: 'Please enter the Confirm Password' }),
        matchesField('password', 'Preferred Password')({
          message: 'Password and Confirm Password must be same'
        })
      )(),
    password: composeValidators(
      isRequired({ message: 'Please enter the Password' }),
      matchesPattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"))({
        message: 'Password must contain at least six characters, including uppercase & lowercase letters, special character and numbers'
      })
    )(),
    userMobile:
      composeValidators(
        isRequired({ message: 'Please enter User mobile' }),
        isNumeric('userMobile'),
        hasLengthBetween(10, 10)({
          message: 'Mobile Must be 10 digits'
        })
      )(),
    userEmail: composeValidators(
      isRequired('Official EmailId'),
      matchesPattern(new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"))({
        message: 'Please enter the valid Official EmailId'
      })
    )(),
  })
  const rootStore = useContext(RootStoreContext);
  const { upsertUser, loadingSubmit } = rootStore.manageUserStore;

  return (

    <Fragment>
      <FinalForm
        initialValues={editUser}

        onSubmit={(values: IManageUser) => {

          values.confirmPassword = values.password;
          upsertUser(values)
            .catch(error => ({
              [FORM_ERROR]: error
            }))
        }
        }
        validate={validate}
        render={({ handleSubmit: userSubmit, form, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => {
          return (
            <Fragment>
              <Form onSubmit={async event => {
                await userSubmit(event);                
                  (editUser?.fullName === null) &&
                  
                    form.reset();
                
                form.resetFieldState('userName');
                form.resetFieldState('fullName');
                form.resetFieldState('userMobile');
                form.resetFieldState('userEmail');
                form.resetFieldState('confirmPassword');
                form.resetFieldState('password');
                // form.resetFieldState('role');
                form.resetFieldState('designation');
                form.resetFieldState('organisation');
              }}>

                <Grid >
                  <Grid.Column>
                  </Grid.Column>
                </Grid>
                <Grid >
                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      User Name
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*"
                      fluid placeholder='UserName' name="userName" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Full Name
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*" fluid placeholder='FullName'
                      name="fullName" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Mobile Number
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*" fluid placeholder='UserMobile'
                      name="userMobile" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      User Email
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*"
                      fluid placeholder='UserEmail' name="userEmail" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Password
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*" fluid placeholder='Password'
                      autoComplete="new-password" type="Password" name="password" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Confirm Password
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*" fluid placeholder='Confirm Password'
                      type="Password" name="confirmPassword" />
                  </Grid.Column>

                  {/* <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Role
                          </label>
                    <span className="mandatory-field"> * </span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} mandate="*"
                      fluid placeholder='Role' name="role" />
                  </Grid.Column> */}

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Designation
                          </label>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab} fluid placeholder='Designation' name="designation" />

                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "4px" }}>
                      Organsiation
                          </label>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={TextInputTab}
                      fluid placeholder='Organisation' name="organisation" />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <label className="label-field" style={{ paddingTop: "27px" }}>
                      Logo
                          </label>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Field component={FileUploadTab} uploadedFile={editUser?.logo} fluid placeholder='Organisation LOGO' name="logo" />
                  </Grid.Column>
                </Grid>
                <br />


                <Grid textAlign='center' style={{ margin: '4px' }}>
                  <Button
                    loading={loadingSubmit} primary><Icon name='check' /> Submit </Button>
                </Grid>
              </Form>
            </Fragment>
          );
        }}>

      </FinalForm >
    </Fragment>
  )
}
export default observer(CreateUser);

