import React, { useContext, useEffect } from 'react';
import { Container, Item, Grid, Button, Segment, Form, Card, List, } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import CreateMessage from '../../mailBox/CreateMessage';
import { ISearchPartner } from '../../../app/models/donorIndDashboard';
import { Form as FinalForm, Field } from 'react-final-form'
import SelectInput from '../../../app/common/form/SelectInput';
import { UserRole } from '../../../app/common/enum';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';

function DonorIndDashboard(props) {

  const [state] = React.useState({
    donorIndId: 0,
  });

  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { registrationId, role } = rootStore.commonStore;
  const { donorIndDashboard, getDonorIndividualDashboard, stateDropdownValues, sectorDropdownValues, partnerDetailDropdownValues,
    searchPartner, getProfile, profile, approval, getApproval,loadingDonorInd } = rootStore.donorIndividualDashboardStore;

    state.donorIndId = rootStore.commonStore.registrationId === undefined ? props.location.state.Id : rootStore.commonStore.registrationId;

  useEffect(() => {
    if (role === UserRole.Administrator) {
      getDonorIndividualDashboard(props.location.state.Id);
      getProfile(props.location.state.Id);
      getApproval(props.location.state.Id);
      
    }
    else {
      getDonorIndividualDashboard(registrationId);
      getProfile(registrationId);
      getApproval(registrationId);
      
    }
  }, [props, getDonorIndividualDashboard, getProfile, getApproval, role, registrationId]);

  const [searchState] = React.useState({
    partnerId: 0,
    stateId: 0,
    sectorId: 0
  });

  const [partnerState] = React.useState({
    partnerId: 0,
  });

  const handleStateIdChange = (event) => {
    searchState.stateId = event.value;
    searchPartner(searchState.stateId, searchState.sectorId, searchState.partnerId);
  };
  const handleSectorIdChange = (event) => {
    searchState.sectorId = event.value;
    searchPartner(searchState.stateId, searchState.sectorId, searchState.partnerId);
  };

  const handlePartnerIdChange = (event) => {
    partnerState.partnerId = event.value;
  };
  return (

    <Container>
      
      <div className='App-fedbackground'>
        {
          loadingDonorInd && <LoadingComponent content="Loading DonorDashboard" />
        }
        <Segment >
          <Grid columns={3}>
            <Grid.Column>
              <Item.Group link>
                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={donorIndDashboard?.donorCEO.logo} />
                    
                    <Item.Content>
                      <Item.Header>{donorIndDashboard?.donorCEO.ceoName}</Item.Header>
                      <Item.Meta>{donorIndDashboard?.donorCEO.organisationName}</Item.Meta>
                      <Item.Meta> {donorIndDashboard?.donorCEO.ceoEmail} </Item.Meta>
                      <Item.Meta>{donorIndDashboard?.donorCEO.ceoMobile}</Item.Meta>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={rootStore.commonStore.logo} />
                    <Item.Content>
                      <Item.Header>{rootStore.commonStore.fullName}</Item.Header>
                      <Item.Meta>{rootStore.commonStore.organisation}</Item.Meta>
                      <Item.Description>  {rootStore.commonStore.email}  </Item.Description>
                      <Item.Extra> {rootStore.commonStore.mobile}</Item.Extra>
                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            <Grid.Column  >
              <Item.Group link>
                {rootStore.commonStore.role === UserRole.Administrator ? (

                  <Item>
                    <br />
                    <Item.Content>
                      <Item.Header>State: {donorIndDashboard?.donorCEO.state}</Item.Header>
                      <br />
                      <Item.Meta>Country: {donorIndDashboard?.donorCEO.country}</Item.Meta>
                      <Item.Meta>Postal code: {donorIndDashboard?.donorCEO.pincode}</Item.Meta>
                      <Item.Description>Area: {profile?.preferredWork}</Item.Description>
                    </Item.Content>
                  </Item>
                ) :
                  (
                    <Item>

                      <Item.Content>
                        <br />
                        <Item.Meta style={{ marginLeft: '100px' }}>State:  {rootStore.commonStore.state}</Item.Meta>
                        <Item.Meta style={{ marginLeft: '100px' }}>Country:  {donorIndDashboard?.donorCEO.country}</Item.Meta>
                        <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {rootStore.commonStore.pincode}</Item.Meta>
                        <Item.Meta style={{ marginLeft: '100px' }}>Area: {profile?.preferredWork}  </Item.Meta>

                      </Item.Content>
                    </Item>
                  )
                }
              </Item.Group>
            </Grid.Column>
            {rootStore.commonStore.role === UserRole.DonorIndividual &&
              <Grid.Column >
                <Button style={{ marginLeft: '200px' }} color='blue' icon='mail'
                  onClick={() => {
                    openRightPanel('Contact Admin', <CreateMessage />)
                  }} />
              </Grid.Column>
            }
          </Grid>
        </Segment>
        {rootStore.commonStore.role === UserRole.DonorIndividual &&
          <FinalForm
            onSubmit={(values: ISearchPartner) => {
              searchPartner(values.state, values.sector, values.partner)
            }
            }
            render={({ handleSubmit, submitting, submitError }) => (
              <Form onSubmit={handleSubmit} error>
                <Segment color='blue'>
                  <label> <h6 style={{ fontStyle: 'Italic' }}> Select State / Sector / Organisation to navigate dashboard</h6></label>
                  <Grid>
                    <Grid.Column width={5} >
                      Select State
                       <Field component={SelectInput} fluid placeholder='State' name={'state'}
                        search selection={"true"} options={stateDropdownValues} onCustomChange={handleStateIdChange} />
                    </Grid.Column>
                    <Grid.Column width={5} >
                      Select Sector
                      <Field component={SelectInput} fluid placeholder='Sector' name={'sector'}
                        search selection={"true"} options={sectorDropdownValues} onCustomChange={handleSectorIdChange} />
                    </Grid.Column>
                    <Grid.Column width={5} >
                      Select Organisation
                      <Field component={SelectInput} fluid placeholder='Partner' name={'partner'}
                        search selection={"true"} options={partnerDetailDropdownValues} onCustomChange={handlePartnerIdChange} />
                    </Grid.Column>
                    <Grid.Column width={1} >
                      <Button color='blue' icon='search' type='submit' />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Form>
            )}>
          </FinalForm>
        }

        <Segment color='blue'>
          <label> <h6 style={{ fontStyle: 'Italic' }}> Recently viewed profiles...</h6></label>
          <Card fluid color='blue'>
            <br />
            <List>
              {
                approval && approval.map((x) => (
                  <List.Item>
                    <Item.Image height='92px' width='50px' size='tiny' style={{ marginLeft: '180px' }} src={x.partnerLogo} />
                    <List.Content style = {{width : '600px'}}>
                      <List.Header>{x.partnerName}</List.Header>
                      <br />

                      {
                        x.isInterest ? (

                          <List.Description>
                            <b>{rootStore.commonStore.organisation}  </b> shows interest to support <b> {x.partnerName} </b>
                            <List>
                              <List.Item>PreferredAmount: {x.preferredAmount}</List.Item>
                              <List.Item>PreferredFrequency: {x.preferredFrequency}</List.Item>
                              <List.Item>ModeOfPayment: {x.modeOfPayment}</List.Item>
                              <List.Item>PreferredProject: {x.preferredProject}</List.Item>
                            </List>

                          </List.Description>
                        ) :
                          (
                            <List.Description>
                              <List>
                                <List.Item> RejectReason: {x.rejectReason}</List.Item>
                              </List>
                            </List.Description>
                          )
                      }

                    </List.Content>
                    <Button style={{ marginLeft: '700px', width: '165px' }} color='blue'
                      onClick={() => {
                        searchPartner(0, 0, x.partnerId)
                      }}> Visit Again... </Button>
                  </List.Item>
                ))

              }
            </List>
            <br />
          </Card>
        </Segment>
      </div>

    </Container>
  );
}
export default observer(DonorIndDashboard);


