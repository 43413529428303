
import React, { useContext, useEffect } from 'react';
import { Button, Container } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Form as FinalForm } from "react-final-form"; 
import CreatePlan from './CreatePlan';
import { RootStoreContext } from "../../../app/stores/rootStore";
import PlanList from './PlanList';

const Plan = () => {
    const rootStore = useContext(RootStoreContext);
    const { openRightPanel } = rootStore.rightPanelStore;

    useEffect(() => {

    });

    return (
        <FinalForm
            onSubmit={() => { }}


            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <Container>


                    {/* Start Pricing Tbale Area  */}
                    <div className="rn-pricing-table-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        {/*  <h4 className="title" style={{ color: 'black' }}>Pricing Plan</h4> */}
                                        <div className='App-plan'>

                                            <h3 style={{ color: 'white' }}>Partnering great relationships is just a step away</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {rootStore.commonStore.role === "Administrator" ? (
                                <Button
                                    color='blue'
                                    content='CREATE PLAN'
                                    floated='right'
                                    onClick={() => {
                                        openRightPanel('Create Plan', <CreatePlan />)
                                    }} />
                            ) : (<div></div>)}
                        </div>
                    </div>
                    <PlanList />
                </Container>


            )}>

        </FinalForm>
    );
}

export default observer(Plan);