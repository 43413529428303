import { observable, action, runInAction } from "mobx";
import { IUserFormValues } from "../models/user";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import { IUsers } from "../models/userlist";

export default class UserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

    }
    @observable userList: IUsers[] = [];
    @observable loadingUsers: boolean = false;
    @observable userDropdownValues: DropdownItemProps[] = [];
    @observable roleDropdownValues: DropdownItemProps[] = [
        {
            text: 'Administrator',
            value: 'Administrator',
            key: 1
        },
    ]

    @action getUserList = async () => {
        try {
            this.loadingUsers = true;
            const responseList: IUsers[] = await agent.User.list();
            console.log(responseList);
            runInAction(() => {

                this.loadingUsers = false;
                this.userList = responseList;

                this.updateDropdownValues();
            });

        } catch (error) {
            throw error;
        } finally {

            runInAction(
                () => {
                    this.loadingUsers = false;
                }
            )

        }
    }

    @action upsertUser = async (user: IUserFormValues) => {

        if (user.id === undefined || user.id === '')
            await this.createUser(user)
        else
            await this.editUser(user);
    }

    @action createUser = async (newUser: IUserFormValues) => {
        console.log(newUser);
        const userResponse = await agent.User.create(newUser);
        runInAction(() => {

            this.userList = [...this.userList, userResponse];

            let item = {
                text: userResponse.userName,
                value: userResponse.id,
                key: userResponse.id
            };
            this.userDropdownValues = [...this.userDropdownValues, item];

            toast.info('User ' + userResponse.userName + ' successfully created');
            this.rootStore.modalStore.closeModal();
        })

    }

    @action editUser = async (updatedUser: IUserFormValues) => {
        const userResponse = await agent.User.update(updatedUser);

        runInAction(
            () => {
                let index = this.userList.findIndex(user => user.id === updatedUser.id);
                Object.assign(this.userList[index], userResponse);

                this.updateDropdownValues();
                toast.info('User ' + this.userList[index].userName + ' successfully updated');
                this.rootStore.modalStore.closeModal();
            }
        )

    }


    @action enableDisableUser = async (updateUser: IUserFormValues) => {
        if (updateUser.id) {
            await agent.User.disable(updateUser.id);
            runInAction(() => {
                let index = this.userList.findIndex(user => user.id === updateUser.id);

                this.updateDropdownValues();
                toast.info('User ' + this.userList[index].userName + ' successfully disabled');
                this.rootStore.modalStore.closeModal();
            })
        }
    }

    @action updateDropdownValues() {
        let dropDownValues: DropdownItemProps[] = [];
        this.userList.map(
            user => {
                if (!user.isDisabled) {
                    let item = {
                        text: user.userName,
                        value: user.id,
                        key: user.id
                    };
                    dropDownValues.push(item);
                }
                return user;
            })
        this.userDropdownValues = dropDownValues;
    }


}