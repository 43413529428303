import { observable, action, runInAction } from "mobx";
import { toast } from "react-toastify";
import { CheckboxProps, DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import {
  IAcceptedFederation,
  IActionPointDashboard,
  ICheckPreviousAssessments,
  ICommiteeMembers,
  IFederationEndorsement,
  INotMappedFederation,
  IODArea,
  IOrganisationLogo,
  IPartnerDashboard,
  IPoint,
  IPointPercentage,
  IPreviousAssessmentCount,
  IUpdateActionPoints,
} from "./../models/partnerDashboard";
import { RootStore } from "./rootStore";

export default class PartnerDashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  
  @observable loadingDashboard: boolean = false;
  @observable checkPrevAssess: ICheckPreviousAssessments | undefined;
  @observable partnerdashboard?: IPartnerDashboard | undefined;
  @observable commiteeMembers: ICommiteeMembers[] = [];
  @observable actionPointDashboard?: IActionPointDashboard | undefined;
  @observable point?: IPoint | undefined;
  @observable pointPercentage?: IPointPercentage | undefined;
  @observable odarea?: IODArea | undefined;
  @observable perviousAssessmentCount?: IPreviousAssessmentCount | undefined;
  @observable acceptedFederation: IAcceptedFederation[] = [];
  @observable notMappedFederation: INotMappedFederation[] = [];
  @observable notMappedDropdownValues: DropdownItemProps[] = [];
  @observable pendingCheckBoxValues: CheckboxProps[] = [];
  @observable loadingRegistrationDetail: boolean = false;
  @observable loading: boolean = false;
  @observable loadingAssessmentArea: boolean = false;
  @observable organisationLogo : IOrganisationLogo[]=[];
  @action getPartnerDashboard = async (id: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.getById(id);
      runInAction(() => {
        if(dashboard)
        this.partnerdashboard = dashboard;
        
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };
  @action getPoint = async (id: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.points(id);
      runInAction(() => {
        this.point = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action getPointByAssessmentId = async (assessmentId: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.pointsByAssessmentId(
        assessmentId
      );
      runInAction(() => {
        this.point = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action getPointPercentage = async (id: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.pointPercentage(id);
      runInAction(() => {
        this.pointPercentage = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };
  @action getAssessmentArea = async (id: number) => {
    try {
      this.loadingAssessmentArea = true;
      const dashboard = await agent.PartnerDashboard.assessmentArea(id);
      runInAction(() => {
        this.odarea = dashboard;
      });
    } catch (error) {
      this.loadingAssessmentArea = false;
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentArea = false;
      });
    }
  };

  @action getAreaByAssessmentId = async (assessmentId: number) => {
    try {
      this.loadingAssessmentArea = true;
      const dashboard = await agent.PartnerDashboard.areabyAssessmentId(
        assessmentId
      );
      runInAction(() => {
        this.odarea = dashboard;
      });
    } catch (error) {
      this.loadingAssessmentArea = false;
      throw error;
    } finally {
      runInAction(() => {
        this.loadingAssessmentArea = false;
      });
    }
  };
  @action getActionPointDashboard = async (id: number) => {
    try {
      // this.loadingDashboard = true;
      const dashboard = await agent.PartnerDashboard.actionPoints(id);
      runInAction(() => {
        this.actionPointDashboard = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        //  this.loadingDashboard = false;
      });
    }
  };

  @action getAcceptedFederation = async (id: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.acceptedFederation(id);
      runInAction(() => {
        this.acceptedFederation = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action getPreviousAssessment = async (id: number) => {
    try {
      const dashboard = await agent.PartnerDashboard.previousAssessments(id);
      runInAction(() => {
        this.perviousAssessmentCount = dashboard;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {});
    }
  };

  @action createFederationEndorse = async (endorse: IFederationEndorsement) => {
    console.log(endorse);
    this.loading = true;
    const topicResponse = await agent.ODPartner.sendFederationEndorsement(
      endorse
    );
    console.log(topicResponse);
    runInAction(() => {
      this.updateDropDown();
      toast.success("Sent for Endorse");
      this.rootStore.modalStore.closeModal();
      this.loading = false;
    });
  };
  @action updateDropDown() {
    let dropDownValues: DropdownItemProps[] = [];
    this.notMappedFederation.map((reason) => {
      let item = {
        text: reason.federationName,
        value: reason.fedarationBasicDetailId,
        key: reason.fedarationBasicDetailId,
      };

      dropDownValues.push(item);
      return reason;
    });

    this.notMappedDropdownValues = dropDownValues;
  }

  @action getNotMappedFederation = async () => {
    this.loading = true;
    try {
      const topicsResponse = await agent.PartnerDashboard.getUnMappedEndorse();
      runInAction(() => {
        this.notMappedFederation = topicsResponse;

        this.updateDropDown();
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      console.log(error);
    }
  };

  @action updateActionPoints = async (actionpint: IUpdateActionPoints) => {
    await agent.PartnerDashboard.updateActionPoints(actionpint);

    runInAction(() => {
      toast.info("ActionPoint successfully changed");
      this.rootStore.modalStore.closeModal();
    });
  };

  @action checkPreviousAssessments = async (id: number) => {
    const topicsResponse = await agent.PartnerDashboard.checkPreviousAssessment(
      id
    );
    runInAction(() => {
      this.checkPrevAssess = topicsResponse;
    });
  };
  @action getCommiteeMembers = async (id: number) => {
    const topicsResponse = await agent.PartnerDashboard.CommiteeMembers(id);
    runInAction(() => {
      this.commiteeMembers = topicsResponse;
      console.log(topicsResponse);
    });
    
  };
  @action getOrganisationLogo= async (id:number) =>{
    const logoResponse=await agent.PartnerDashboard.organisationLogo(id);
    runInAction(()=>{
      this.organisationLogo=logoResponse;
    });
  }
}
