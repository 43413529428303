import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class RightPanelStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable.shallow rightPanel = {
    open: false,
    title: "",
    body: null,
  };

  @action openRightPanel = (title: string, content: any) => {
    this.rightPanel.open = true;
    this.rightPanel.title = title;
    this.rightPanel.body = content;
 
  };

  @action closeRightPanel = () => {
    this.rightPanel.open = false;
    this.rightPanel.title = "";
    this.rightPanel.body = null;
  };
}
