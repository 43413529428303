import React, { useContext, useEffect } from 'react';
import { Container, Segment, Item, Grid, Button, Form, List, Card } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';
import CreateMessage from '../../mailBox/CreateMessage';
import { Form as FinalForm, Field } from 'react-final-form'
import { ISearchPartner } from '../../../app/models/donorOrgDashboard';
import { UserRole } from '../../../app/common/enum';
import SelectInputTab from '../../../app/common/form/SelectInputTab';

interface Props {
  classes: any;
}

interface State {
  value: number;
}
function DonorDashboard(props) {
  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { approvedPartnerProfile, getApprovedPartnerProfile } = rootStore.donorOrgStore;
  const { donororgdashboard, loadingDashboard, getProfile, profile, getDonorOrgDashboard, searchPartner, sectorDropdownValues, stateDropdownValues, partnerDropdownValues } = rootStore.donorOrgDashboardStore;
  const { role, registrationId } = rootStore.commonStore;
  useEffect(() => {
    if (role === UserRole.Administrator) {
      getDonorOrgDashboard(props.location.state.Id);
      getProfile(props.location.state.Id);
      getApprovedPartnerProfile(props.location.state.Id);
    }
    else {
      getDonorOrgDashboard(registrationId);
      getProfile(registrationId);
      getApprovedPartnerProfile(registrationId);
    }
  }, [props, getDonorOrgDashboard, getProfile, getApprovedPartnerProfile,role,registrationId]);

  const [searchState] = React.useState({
    partnerId: 0,
    stateId: 0,
    sectorId: 0
  });

  const [State] = React.useState({
    partnerId: 0,

  });
  const handleStateIdChange = (event) => {
    searchState.stateId = event.value;
    searchPartner(searchState.sectorId, searchState.stateId, searchState.partnerId);
  };
  const handleSectorIdChange = (event) => {
    searchState.sectorId = event.value;
    searchPartner(searchState.sectorId, searchState.stateId, searchState.partnerId);
  };
  const handlePartnerIdChange = (event) => {
    State.partnerId = event.value;

  };


  return (

    <Container>
      <div className='App-fedbackground'>
        {
          loadingDashboard && <LoadingComponent content="Loading DonorDashboard" />
        }
        <Segment>
          <Grid columns={3}>
            <Grid.Column>
              <Item.Group link>
                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={donororgdashboard?.donorOrgCEO.companyLogo} />
                    
                    <Item.Content>
                      <Item.Header>{donororgdashboard?.donorOrgCEO.organisationName}</Item.Header>
                      <Item.Meta>{donororgdashboard?.donorOrgCEO.ceoName}</Item.Meta>
                      <Item.Meta> {donororgdashboard?.donorOrgCEO.ceoEmail}</Item.Meta>
                      <Item.Meta> {donororgdashboard?.donorOrgCEO.ceoMobile}</Item.Meta>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={rootStore.commonStore.logo} />
                    <Item.Content>
                      <Item.Header>{rootStore.commonStore.organisation}</Item.Header>
                      <Item.Meta>{rootStore.commonStore.fullName}</Item.Meta>
                      <Item.Description>  {rootStore.commonStore.email}  </Item.Description>
                      <Item.Extra> {rootStore.commonStore.mobile}</Item.Extra>
                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            <Grid.Column>
              <Item.Group link>

                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Item>

                    <Item.Content>
                      <br />
                      <Item.Meta style={{ marginLeft: '100px' }}>State:  {donororgdashboard?.donorOrgCEO.state}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Country:  {profile?.country}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {donororgdashboard?.donorOrgCEO.pincode}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Area:  {profile?.preferredWork} </Item.Meta>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>

                    <Item.Content>
                      <br />
                      <Item.Meta style={{ marginLeft: '100px' }}>State:  {rootStore.commonStore.state}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Country:  {profile?.country}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {rootStore.commonStore.pincode}</Item.Meta>
                      <Item.Meta style={{ marginLeft: '100px' }}>Area:   {profile?.preferredWork} </Item.Meta>

                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            {rootStore.commonStore.role === UserRole.DonorOrganisation &&
              <Grid.Column >
                <br />
                <br />
                <br />
                <Button style={{ marginLeft: '280px' }} color='blue' icon='mail'
                  onClick={() => {
                    openRightPanel('Contact Admin', <CreateMessage />)
                  }} />
              </Grid.Column>
            }
          </Grid>
        </Segment>
        {rootStore.commonStore.role === UserRole.DonorOrganisation &&
          <FinalForm
            onSubmit={(values: ISearchPartner) => {
              searchPartner(values.state, values.sector, values.partner)

            }
            }
            render={({ handleSubmit, submitting, submitError }) => (
              <Form onSubmit={handleSubmit} error>
                <Segment color='blue'>
                  <label> <h6 style={{ fontStyle: 'Italic' }}> Select State / Sector / Organisation to navigate dashboard</h6></label>
                  <Grid>
                    <Grid.Column width={5} >

                      <Field component={SelectInputTab} label="Select State" fluid placeholder='State' name={'state'}
                        search selection={"true"} options={stateDropdownValues}
                        onCustomChange={handleStateIdChange} />
                    </Grid.Column>
                    <Grid.Column width={5} >

                      <Field component={SelectInputTab} label="Select Sector" fluid placeholder='Sector' name={'sector'}
                        search selection={"true"} options={sectorDropdownValues} onCustomChange={handleSectorIdChange} />
                    </Grid.Column>
                    <Grid.Column width={5} >

                      <Field component={SelectInputTab} label="Select Partner" fluid placeholder='Partner' name={'partner'}
                        search selection={"true"} options={partnerDropdownValues} onCustomChange={handlePartnerIdChange} f />
                    </Grid.Column>
                    <Grid.Column width={1} >
                      <Button color='blue' icon='search' type='submit'                    
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Form>
            )}>
          </FinalForm>
        }
        <Segment color='blue'>
          <label> <h6 style={{ fontStyle: 'Italic' }}> Recently viewed profiles...</h6></label>
          <Card fluid color='blue'>
            <br />
            {approvedPartnerProfile && approvedPartnerProfile.map(
              (profile) => (
                <div>
                  <List>
                    <List.Item>
                      <Item.Image height='92px' width='50px' size='tiny' style={{ marginLeft: '180px' }} src={profile.partnerLogo} />
                      <List.Content style = {{width : '600px'}}>
                        <List.Header >{profile.partnerName}</List.Header>
                        <br />
                        {profile.isInterest ? (
                          <List.Description>
                            <b>{rootStore.commonStore.organisation}  </b> shows interest to support <b>{profile.partnerName} </b>
                            <br />
                            <b> Preferred Amount : </b> {profile.preferredAmount}
                            <br />
                            <b>   Prefered Frequence:</b>  {profile.preferredFrequency}
                            <br />
                            <b> Mode of Payment: </b> {profile.modeOfPayment}
                            <br />
                            <b> Preffered Project : </b>  {profile.preferredProject}
                            <br />
                          </List.Description>
                        ) : (
                          <List.Description>

                            <b> Reason for Rejection:</b> {profile.rejectReason}
                          </List.Description>
                        )}


                      </List.Content>
                    </List.Item>
                  </List>
                  <Button style={{ marginLeft: '700px', width: '165px' }} color='blue' onClick={() => {
                    searchPartner(0, 0, profile.partnerId)

                  }}> Visit Again... </Button>
                  <br />
                </div>

              ))}
          </Card>
          <br />
        </Segment>
      </div>
    </Container>
  );
}
export default observer(DonorDashboard);
