// export interface IAssessmentList {
//   partnerId: number;
//   assessmentAnswer: IAssessmentDetail[];
// }

// export interface IAssessmentDetail {
//   questionId?: number;
//   answerOptionId?: number;
//   answerValue?: string;
// }

// export class AssessmentList implements IAssessmentList {
//   partnerId: number = 0;
//   assessmentAnswer: IAssessmentDetail[] = new AssessmentDetail[200];
// }
// export class AssessmentDetail implements IAssessmentDetail {
//   questionId?: number = undefined;
//   answerOptionId?: number = undefined;
//   answerValue?: string = undefined;
//   constructor(init?: IAssessmentList) {
//     Object.assign(this, init);
//   }
// }
export interface IFileList {
  partnerId: string;
  questionIdList: string[];
  file: [];
}

export interface IAssessmentList {
  partnerId: number;
  assessmentAnswer: IAssessmentDetail[];
  questionIdList: string[];
  file: string[];
  questionName: string;
  optionValue: string;
}

export class AssessmentList implements IAssessmentList {
  partnerId: number = 0;
  assessmentAnswer: IAssessmentDetail[] = [];
  questionIdList: string[] = [];
  file: string[] = [];
  questionName: string = "";
  optionValue: string = "";
}

export interface IAssessmentDetail {
  questionId?: number;
  answerOptionId?: number[];
  answerId?: number;
  answerOptionIds?: string;
  answerValue?: string;
  file?: string;
  answerType?: string;
  areaOfAssessmentId?: number;
  reportDoc? : string;
}

export class AssessmentDetail implements IAssessmentDetail {
  questionId?: number = undefined;
  answerOptionId?: number[] = [];
  answerId?: number = 0;
  answerOptionIds?: string = undefined;
  answerValue?: string = undefined;
  areaOfAssessmentId?:number = 0;
  reportDoc? : string="";
  file?: string = undefined;
  constructor(init?: IAssessmentList) {
    Object.assign(this, init);
  }
}

export interface IAssessmentFile {
  questionId: string;
  file: string;
}

export interface IAssessmentAreaVerify {
  assessmentId: number;
  partnerId: number;
  day: number;
  month: string;
  year: number;
  assessmentSuggestion: IAssessmentAreaImp[];
  reportDoc? : string;
}
export interface IAssessmentAreaImp {
  areaId: number;
  areaName: string;
  suggesstion: string;
  observation: string;
}

export interface IAssessment {
  assessmentId: number;
  partnerId: number;
  reportDoc : string;
  assessmentAnswer: [
    {
      questionName: string;
      answerOptionName: string;
      questionId: number;
      answerOptionId: number;
      answerValue: string;
    }
  ];
}

export interface IAssessmentByArea {
  assessmentId: number;
  partnerId: number;
  areaName: string;
  areaId: number;
  questionName: string;
  answerOptionName: string;
  questionId: number;
  answerOptionId: number;
  answerValue: string;
  verificationStatus: string;
  reportText: string;
  reportTextList: string[];
  approvedDate: string;
  organizationName: string;
}
export interface IAssessmentByAreaVerify {
  
  assessmentId: number;
  partnerId: number;
  areaName: string;
  areaId: number;
  questionName: string;
  answerOptionName: string;
  questionId: number;
  answerOptionId: number;
  answerValue: string;
  verificationStatus: string;
  reportText: string;
  reportTextList: string[];
  approvedDate: string;
  organizationName: string;
} 

export interface IAssessmentApproveOrReject {
  AssessmentId?: number;
  IsApproved: boolean;
  AssessmentVerify: IAssessmentVerify[];
}

export class AssessmentApproveOrReject implements IAssessmentApproveOrReject {
  AssessmentId?: number = undefined;
  IsApproved: boolean = false;
  AssessmentVerify: IAssessmentVerify[] = [];

  constructor(init?: IAssessmentApproveOrReject) {
    Object.assign(this, init);
  }
}

export interface IAssessmentVerify {
  areaId?: number;
  suggesstion?: string;
}

export class AssessmentVerify implements IAssessmentVerify {
  areaId?: number = undefined;
  suggesstion?: string = "";

  constructor(init?: IAssessmentApproveOrReject) {
    Object.assign(this, init);
  }
}

export interface IAssessmentSuggesstion {
  assessmentId?: number;
  areaId?: number;
  suggesstion?: string ;
  assessmentVerifyId? : number;
  isActive ?: boolean;
}
export interface ISuggestion {
  suggesstion ?: string ;
  areaId ?: number;
  assessmentId? : number;
  assessmentVerifyId? : number;
  isActive ?: boolean;
}

export class Suggestion implements ISuggestion {
  areaId?: number = undefined;
  suggesstion?: string = "" ;
  assessmentId ?: number = undefined;
  assessmentVerifyId ?: number = undefined;
  isAcive ?: boolean = false;

  constructor(init?: IAssessmentApproveOrReject) {
    Object.assign(this, init);
  }
}

export class AssessmentSuggesstion implements IAssessmentSuggesstion {
  assessmentId?: number = undefined;
  areaId?: number = undefined;
  suggesstion?: string = "";
  isActive ?: boolean = false;

  constructor(init?: IAssessmentSuggesstion) {
    Object.assign(this, init);
  }
}
export interface IActionPoints {
  assessmentId: number;
  areaId: number;
  areaName: string;
  actionPoint: string;
}
