import React from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormFieldProps,
  Form,
  Label,
  Checkbox,
  Grid,
  GridColumn,
} from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<boolean, HTMLElement>,
  FormFieldProps { }

const CheckInputSimple: React.FC<IProps> = ({
  input,
  width,
  checked,
  placeholder,
  label,
  radio,
  toggle,
  hidden,
  readOnly,
  onChange = false,
  meta: { touched, error },
}) => {
  return (
    <Grid columns={2} verticalAlign="middle">
      {label && (
        <GridColumn width={5}>
          <Form.Field hidden={hidden}>
            <label className="fieldLabel">{label}</label>
          </Form.Field>
        </GridColumn>
      )}
      <GridColumn width={label ? 11 : 16}>
        <Form.Field error={touched && !!error} width={width?width:10} hidden={hidden}>
          <Checkbox
            radio={radio}
            toggle={toggle}
            defaultChecked={input.value ? true : false}
            checked={checked}
            onClick={(e, data) => {
              input.onChange(data.checked);
              if (onChange) onChange(data.checked);
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            
          />
          {touched && error && (
            <Label basic color="red">
              {error}
            </Label>
          )}
        </Form.Field>
      </GridColumn>
    </Grid>
  );
};

export default CheckInputSimple;
