import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Segment, Step, Table } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm } from "react-final-form";
import { IAcceptPartnerEndorse } from '../../../app/models/federation';
import { VerificationStatus } from '../../../app/common/enum';
import { MessageType } from '../../../app/common/enum';
//import classes from '*.module.css';

function ODMembershipEndorse(props) {

  //const classes = useStyles();
  const rootStore = useContext(RootStoreContext);
  const [state] = React.useState({
    partnerId: 0,
    partnerName: "",
    partnerKey: "",
    isAccept: false,

  });
  const [Checkstate] = React.useState({
    disabled: true,
    reason: "",
  });

  const [Hiddenstate, setHiddenState] = React.useState({
    reasonHidden: true
  });
  const [Activestate] = React.useState({
    active: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Checkstate.reason = event.target.value;
    setHiddenState({ reasonHidden: true });
  };
  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHiddenState({ reasonHidden: false });
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Checkstate.reason = event.target.value;
    setHiddenState({ reasonHidden: false });
  };
  const [stepperIndex, setStepperIndex] = useState(1);
  const { getWaitingPartnerEndorse, partnerEndorseList, waitingPartnerApprove, IsAcceptOrReject } = rootStore.federationStore;
  const { fedEndoresedContact } = rootStore.contactStore;
  useEffect(() => {
    setStepperIndex(1);
    getWaitingPartnerEndorse(props.id, VerificationStatus.Pending?.toString());
  }, [props, getWaitingPartnerEndorse]);
  return (

    <FinalForm initialValues={null}
      onSubmit={async (values: IAcceptPartnerEndorse) => {
        // values.partnerId =Number(props.id);
        values.partnerId = state.partnerId;
        console.log(state.partnerId);
        values.federationId = props.id;
        values.isAccept = state.isAccept;
        values.reasonForReject = Checkstate.reason;
        values.partnerName = state.partnerName;
        values.federationName = props.Name;
      values.isRead = false;
        waitingPartnerApprove(values)

        values.organisationName = props.Name;
        values.receiver = "Admin";
        values.sender = props.Key;
        values.email = props.Email;
        values.messageType = MessageType.EndorsedMessage;
        values.isRead =false;
        if (state.isAccept === false)
          values.message = props.Name + " has been rejected the membership approval for " + state.partnerName + " with the reason is " + Checkstate.reason;
        else
          values.message = props.Name + " has been accepted the membership approval for " + state.partnerName;
        fedEndoresedContact(values)

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        await sleep(300);

        values.organisationName = props.Name;
        values.receiver = state.partnerKey;
        values.sender = props.Key;
        values.email = props.Name;
        if (state.isAccept === false)
          values.message = props.Name + " has been rejected the membership approval with the reason is " + Checkstate.reason;
        else
          values.message = props.Name + " has been accepted the membership approval ";
        fedEndoresedContact(values)
          .catch(error => ({
            [FORM_ERROR]: error
          }))
        //  getWaitingPartnerEndorse(props.id);   
      }
      }
      render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
        <div>
          <Step.Group size='tiny'>
            <Step active={Activestate.active} onClick={() => setStepperIndex(1)}>

              <Icon name='thumbs up' />
              <Step.Content>
                <Step.Title>Accept/Reject</Step.Title>
                <Step.Description>Choose your ODPartners </Step.Description>
              </Step.Content>

            </Step>
            <Step active={!Activestate.active} disabled={Activestate.active} onClick={() => setStepperIndex(2)}>
              <Icon name='thumbs down' />
              <Step.Content>
                <Step.Title>Reject Reason</Step.Title>
                <Step.Description>Enter reason for rejection</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          <Form onSubmit={handleSubmit}>
            {stepperIndex === 1 &&
              <Table>
                <Table.Header>
                  <Table.Row>

                    <Table.HeaderCell>Organisation</Table.HeaderCell>
                    <Table.HeaderCell>Accept/Reject</Table.HeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {partnerEndorseList && partnerEndorseList.map(
                    (registration) => (
                      <Table.Row key={registration.partnerId}>

                        <Table.Cell><b>{registration.partnerName}</b> <br /> {registration.ceoName}<br /> {registration.state}</Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => {
                              state.partnerId = registration.partnerId;
                              state.partnerName = registration.partnerName;
                              state.partnerKey = registration.partnerKey;
                              state.isAccept = true;
                            }}
                          >
                            <Icon name="check" color="blue"></Icon></Button>
                          <Button type='button'
                            onClick={() => {
                              state.partnerId = registration.partnerId;
                              state.partnerName = registration.partnerName;
                              state.partnerKey = registration.partnerKey;
                              state.isAccept = false;
                              Activestate.active = false;
                              setStepperIndex(stepperIndex + 1);
                            }}
                          >  <Icon name="close" color="red"></Icon></Button>
                        </Table.Cell>
                        <Table.Cell>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>

            }
            {
              stepperIndex === 2 &&
              <Segment>

                <div>
                  <label>
                    <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Not a Member' name="actionPoint" onChange={handleChange} />
                  Not a Member
                </label>
                  <br></br>
                  <label>
                    <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Not Paid Subscription'
                      name="actionPoint" onChange={handleChange} />
                  Not Paid Subscription
                </label>
                  <br>
                  </br>
                  <label>
                    <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Complience Low' name="actionPoint" onChange={handleChange} />
                Their Complience is too low
                </label>
                  <br></br>
                  <label>
                    <input type="radio" style={{ textAlign: 'center', height: 20, width: 25 }} value='Other' name="actionPoint" onChange={handleOtherChange} />
               Any Other Reason
                </label>
                  <br>
                  </br>
                  <label hidden={Hiddenstate.reasonHidden ? true : false}>Pls Specify other reason :</label>
                  <br></br>
                  <input type="textbox" name="Other" onChange={handleTextChange} hidden={Hiddenstate.reasonHidden ? true : false}></input>
                </div>
                <br></br>
                <div>
                  <Button loading={IsAcceptOrReject} negative type="submit"> Reject  </Button>
                </div>
              </Segment>
            }
          </Form>

        </div>

      )
      }
    ></FinalForm>);

}

export default observer(ODMembershipEndorse);