import React, { Fragment, useContext} from "react";
import { Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import HeaderThree from "../../component/header/HeaderThree";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import cms from "../../assets/images/cms.png";
import Helmet from "../../component/common/Helmet";
import { Button, Icon ,  Dropdown } from "semantic-ui-react";
import GoogleMap from "../../features/homePage/GoogleMap";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";




const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--17",
    category: "",
    title: "",
    description: "",
    buttonText: "GO TO ONLINE ASSESSMENT",
    buttonLink: "assessment",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--18",
    category: "",
    title: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--19",
    category: "",
    title: "",
    description: "",
    buttonText: "KNOW MORE",
    buttonLink: "goToMgmtVersion",
  },
];



const options = [
   {href: "/donor" ,key: 'DonorOrganization', text: 'Donor Organization' , target : "_blank"},
  { href: "/donorInd" , key: 'DonorIndividual', text: 'Donor Individual' , target : "_blank"}, 
]

const PortfolioLanding = () => {
  const rootStore = useContext(RootStoreContext);


  

  
  const [state, setState] = React.useState({
    open: false,
  });

  const modelClose = () => {
    setState({ open: false });
  };

  const handleOpen = (buttonLink) => {
    if (buttonLink === "assessment") {
      setState({ open: true });
    } else if (buttonLink === "goToMgmtVersion") {
      window.open(
        window.location.hostname +
          "images/033bd647-20ac-43d8-9afb-f7da209a4527-CIM%203rd%20Decade%20Management%20Vision.pdf",
        "_blank"
      );
    }
  };

  let title = "CHRISTIAN MANAGEMENT STANDARDS",
    description =
      "CIM Management services aims to facilitate an organisational renewal and pave the path to excellance ";
  return (
    <Fragment>
      <Helmet pageTitle="CIM - Organisation Development" />

      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />
      <div id="fff">
        <Modal
          open={state.open}
          close={!state.open}
          onClose={() => modelClose()}
          style={{
            color: "blue",
            left: "20%",
            right: "20%",
            position: "fixed",
            top: "35%",
            zIndex: 1000,
            height: "200px",
            bottom: "35%",
            width: "100px,",
          }}
        >
          <Modal.Header>
            <h5>
              {" "}
              <i>One step to go...</i>
            </h5>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <label>
                {" "}
                <h6 style={{ fontStyle: "Italic", marginLeft: "100px" }}>
                  {" "}
                  New User ?{" "}
                </h6>
              </label>
              <Button
                as={Link}
                to="/beneficiary"
                content="REGISTER"
                color="blue"
                style={{ marginLeft: "75px", width: "100px" }}
                onClick={()=>rootStore.registrationOTPStore.getVerifyByMobileOrEmail()}
              />

              <label>
                {" "}
                <h6 style={{ fontStyle: "Italic", marginLeft: "100px" }}>
                  {" "}
                  Existing User ?{" "}
                </h6>
              </label>
              <Button
                style={{ marginLeft: "50px", width: "100px" }}
                as={Link}
                to="/login"
                content="LOGIN"
                color="blue"
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <a href="/homepageContact"> For queries click here</a>
          </Modal.Actions>
        </Modal>
      </div>
      {/* Start Slider Area   */}
      <div className="slider-wrapper color-white">
        <div className="slider-activation slider-digital-agency">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay="1"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        className={`inner ${value.textPosition}`}
                        style={{ color: "white" }}
                      >
                        {value.title ? (
                          <h1 className="title" style={{ color: "white" }}>
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.description ? (
                          <p className="description" style={{ color: "white" }}>
                            {value.description}
                          </p>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        {value.buttonText ? (
                          <div style={{ color: "blue", marginLeft: "75px" }}>
                            <a
                              className="rn-button-style--2 btn-primary-color"                            
                              href="/#"
                              onClick={() => handleOpen(value.buttonLink)}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}
      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={cms} alt="About Images" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <h6 className="description">{description}</h6>
                      <header> We will, </header>
                      <header>
                        • Lead you on a journey toward Accountability and Good
                        Governance
                      </header>
                      <header>
                        • Be your Strategic Partner for Sustainability
                      </header>
                      <header>
                        • Provide Strategic Recommendations to enhance systems
                        and structures that align with your Vision and Mission
                      </header>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}
      {/* Start Strategic Services */}
      <div
        className="counterup-area pb--80 pt--40 bg_image  theme-text-white"
        data-black-overlay="10"
      >
        <div className="container">
          <h2 className="title" style={{ color: "white" }}>
            {" "}
            CIM STRATEGIC SERVICES
          </h2>
          <h6 style={{ color: "white" }}>
            • Providing strategic insights to the CEO and leadership team{" "}
          </h6>
          <h6 style={{ color: "white" }}>
            • Key inputs towards compliance and statutory requirements
          </h6>
          <h6 style={{ color: "white" }}>
            • Expert opinion and inputs on financial Sustainability and
            performance overview
          </h6>
          <h6 style={{ color: "white" }}>
            • Providing and guiding towards a Bibilical level of Management
            Excellence
          </h6>
        </div>
      </div>
      {/* End Strategic Services */}
      
                {/* Start Plan */}
                {  <div className="App-overlap-group1 App-RegisterationBox" >
                    <div className="App-overlap-group8" >
                        <div className="App-overlap-group6-1">
                            <div className="App-are-you-a-federation  App-montserrat-black-eerie-black-50px App-fed-donor-box-black-eerie"><h3>ARE YOU A FEDERATION</h3></div>
                                <div className="App-register-h-governance App-hind-normal-eerie-black-24px App-fed-donor-box-normal-eerie">
                                     <span className="span-1">Register here to enhance and support your member organisations toward Accountability, Transparency and Good Governance</span>
                                 </div>
                                </div>
                                <Button  a href ="/federation"to='/federation'  target="_blank"  onClick={()=>rootStore.registrationOTPStore.getVerifyByMobileOrEmail()} primary>REGISTER</Button>
                                </div>
                <div className="App-overlap-group8 " >
                     <div className="App-overlap-group5-1">
                        <div className="App-are-you-a--ganisation App-montserrat-black-eerie-black-50px App-fed-donor-box-black-eerie">
                                    <h3> ARE YOU A DONOR OR DONOR ORGANIZATION?</h3>
                         </div>
                    <div className="App-register-h--standards App-hind-normal-eerie-black-24px App-fed-donor-box-normal-eerie ">
              <span className="span-1">Register here to enhance and support your beneficiary organisations toward Accountability, Transparency and Christian Management Standards</span>
            </div>
          </div>
          {/* <Button href ="/donor" to='/donor' primary>REGISTER</Button> */}


                        <Button.Group color='blue'>
                            <Button href='/donor'  onClick={()=>rootStore.registrationOTPStore.getVerifyByMobileOrEmail()}  primary>REGISTER</Button>
                                 <Dropdown
                                  className='button icon'
                                  floating
                                  options={options}
                                  trigger={<></>}
                                />
                                
                        </Button.Group>               
          {/* <div className="ui blue buttons">
             <div className="ui button">REGISTER</div>
              <div className="ui floating dropdown icon button">
               <i className="dropdown icon"></i>
                <div className="menu">
      <div  className="item">Donor Individual</div>
      <Button href ="/donor" to='/donor' primary className="item">REGISTER</Button>
      <div className="item"> Donor Organization</div>
    </div>
  </div>
</div> */}


        </div>
      </div>    }
      
                {/* End Plan */} 
      {/* Start Contact section */}
      <div className="App-footer">
        <div className="App-auto-flex1">
          <div className="App-contact-1 App-montserrat-black-eerie-black-65px">
            CONTACT
          </div>
          <div className="App-christian--cimindiain App-hind-normal-eerie-black-24px">
            <span className="App-span">
              <h4>Christian Institute of Management</h4>
            </span>
            <span className="App-span-1">#1405,</span>
            <span className="App-span-1">4th</span>
            <span className="App-span-1">
              Street, Golden Colony, <br />
              Anna Nagar West Extn., <br />
              Chennai 600050, India
            </span>
            <br />
            <br />
            <span className="App-span">
              <Icon size="small" color="blue" name="phone" />{" "}
            </span>
            <span className="App-span-1">+91 44 2625 1530</span>
            <br />
            <span className="App-span">
              <Icon size="small" color="blue" name="mail outline" />{" "}
            </span>
            <span className="App-span-1">od@cimindia.in</span>
            <br />
            <span className="App-span">
              <Icon size="small" color="green" name="whatsapp" />{" "}
            </span>
            <span className="App-span-1">9444058796</span>
            <div className="social-icons">
              <br />
              <Button
                circular
                size="large"
                color="blue"
                icon="facebook"
                a
                href="https://www.facebook.com/ChristianInstituteOfManagement/"
                target="_blank"
                rel="noopener noreferrer"
              />
              <Button
                circular
                size="large"
                color="red"
                icon="youtube"
                a
                href="https://www.youtube.com/channel/UC-8RcJS0H5zsWgmajIPOIuQ"
                target="_blank"
                rel="noopener noreferrer"
              />
              <Button
                circular
                size="large"
                color="blue"
                icon="linkedin"
                a
                href="https://www.linkedin.com/in/cim-executive-director-61a07215a"
                target="_blank"
                rel="noopener noreferrer"
              />
              <Button
                circular
                size="large"
                color="blue"
                icon="twitter"
                a
                href="https://www.twitter.com/cim_india"
                target="_blank"
                rel="noopener noreferrer"
              />
              <Button
                circular
                size="large"
                color="orange"
                icon="instagram"
                a
                href="https://www.instagram.com/cim_news/"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
        </div>
        <div className="App-auto-flex2">
          <GoogleMap />
        </div>
      </div>

      {/* End Contact section */}
    </Fragment>
  );
};

export default observer(PortfolioLanding);
