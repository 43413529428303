import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import SelectInput from "../../../../app/common/form/SelectInput";
import TextInput from "../../../../app/common/form/TextInput";
import { ISubSectorFormValues } from "../../../../app/models/subSector";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
  editSubSector?: ISubSectorFormValues;
}

const CreateEditSubSector: React.FC<IProps> = ({ editSubSector }) => {
  const rootStore = useContext(RootStoreContext);
  const { upsertSubSector, loadingSubSectors } = rootStore.subSectorStore;
  const { getSector, sectorForSubSectorDropdownValues } = rootStore.sectorStore;

  useEffect(() => {
    getSector();
  }, [getSector]);
  const [state] = React.useState({
    sectorName: "",
  });

  const handleChange = (event) => {
    state.sectorName = event.options.find((s) => s.value === event.value).text;
  };
  return (
    <FinalForm
      initialValues={editSubSector}
      onSubmit={(values: ISubSectorFormValues) => {
        values.sectorName = state.sectorName;
        upsertSubSector(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({
        handleSubmit: subsectorSubmit,
        form,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <div style={{ marginLeft: "50px" }}>
            <Form
              onSubmit={async (event) => {
                await subsectorSubmit(event);
                if (editSubSector === undefined) {
                  form.reset();
                }
              }}
            >
              <Field
                component={TextInput}
                fluid
                placeholder="Enter Sub-Sector Name"
                name="subSectorName"
              />
              <Field
                component={SelectInput}
                fluid
                placeholder="Select Sector"
                name="sectorId"
                labelWidth="16"
                search
                selections={"true"}
                onCustomChange={handleChange}
                options={sectorForSubSectorDropdownValues}
              />

              <Grid textAlign="center" style={{ margin: "4px" }}>
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={loadingSubSectors}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </div>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(CreateEditSubSector);
