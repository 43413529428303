export interface IOdPartnerRegisterValues {
  PartnerId: number;
  OrganizationName: string;
  YearOfInception: string;
  RegisteredAddress: string;
  CommunicationAddress: string;
  Pincode: string;
  State: string;
  OfficialEmailId: string;
  Password: string;
  OfficeContactumber: string;
  MobileContactNumber: string;
  WebsiteAddress: string;
  WhatsappNumber: string;
  FacebookID: string;
  TwitterID: string;
  LinkedInID: string;
  OtherMediaLink: string;
  FounderTitle: string;
  FounderFirstName: string;
  FounderLastName: string;
  FounderMobile: string;
  FounderEmailId: string;
  FounderDesignation: string;
  CEOTitle: string;
  CEOFirstName: string;
  CEOLastName: string;
  CEOMobile: string;
  CEOEmailId: string;
  CEODesignation: string;
  CEOPhotoFile: string;
  TrusteeorManagementProfileFile: string;
  NumberOfCommiteeMember: string;
  MissionStatement: string;
  FaithStatement: string;
  VisionStatement: string;
  CoreValues: string;
  ObjectiveOfOrganization: string;
  MajorActivities: string;
  SectorId: string;
  SubsectorId: string;
  FedarationBasicDetailIds: string;
  OrganizationInfoOrBrouchefile: string;
  OrganizationLogoFile: string;
  ActivityReport: string;
  OrganisationBudget: string;
  AuditStatement: string;
  Membership: string;
  //  PartnerReferenceDetailJson: PartnerReferenceDetailJson[];
  RefId1: number;
  RefId2: number;
  RefTitle1: string;
  RefFirstName1: string;
  RefLastName1: string;
  RefMobile1: string;
  RefEmail1: string;
  RefDesignation1: string;
  RefOrganization1: string;
  RefTitle2: string;
  RefFirstName2: string;
  RefLastName2: string;
  RefMobile2: string;
  RefEmail2: string;
  RefDesignation2: string;
  RefOrganization2: string;
  // PartnerContactPersonJson: PartnerContactPersonJson[];
  ContactId: number;
  ContactTitle: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactMobile: string;
  ContactEmailId: string;
  ContactDesignation: string;
  savePartnerDetailsId: number;
  addressCheck: boolean;
}

export class OdPartnerRegisterValues implements IOdPartnerRegisterValues {
  PartnerId: number = 0;
  OrganizationName: string = "";
  YearOfInception: string = "";
  RegisteredAddress: string = "";
  CommunicationAddress: string = "";
  Pincode: string = "";
  State: string = "";
  OfficialEmailId: string = "";
  Password: string = "";
  OfficeContactumber: string = "";
  MobileContactNumber: string = "";
  WebsiteAddress: string = "";
  WhatsappNumber: string = "";
  FacebookID: string = "";
  TwitterID: string = "";
  LinkedInID: string = "";
  OtherMediaLink: string = "";
  FounderTitle: string = "";
  FounderFirstName: string = "";
  FounderLastName: string = "";
  FounderMobile: string = "";
  FounderEmailId: string = "";
  FounderDesignation: string = "";
  CEOTitle: string = "";
  CEOFirstName: string = "";
  CEOLastName: string = "";
  CEOMobile: string = "";
  CEOEmailId: string = "";
  CEODesignation: string = "";
  CEOPhotoFile: string = "";
  TrusteeorManagementProfileFile: string = "";
  NumberOfCommiteeMember: string = "";
  MissionStatement: string = "";
  FaithStatement: string = "";
  VisionStatement: string = "";
  CoreValues: string = "";
  ObjectiveOfOrganization: string = "";
  MajorActivities: string = "";
  SectorId: string = "";
  SubsectorId: string = "";
  FedarationBasicDetailIds: string = "";
  OrganizationInfoOrBrouchefile: string = "";
  OrganizationLogoFile: string = "";
  ActivityReport: string = " ";
  OrganisationBudget: string = "";
  AuditStatement: string = "";
  Membership: string = "";
  RefId1: number = 0;
  RefId2: number = 0;
  RefTitle1: string = "";
  RefFirstName1: string = "";
  RefLastName1: string = "";
  RefMobile1: string = "";
  RefEmail1: string = "";
  RefDesignation1: string = "";
  RefOrganization1: string = "";
  RefTitle2: string = "";
  RefFirstName2: string = "";
  RefLastName2: string = "";
  RefMobile2: string = "";
  RefEmail2: string = "";
  RefDesignation2: string = "";
  RefOrganization2: string = "";
  ContactId: number = 0;
  ContactTitle: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactMobile: string = "";
  ContactEmailId: string = "";
  ContactDesignation: string = "";
  savePartnerDetailsId: number = 0;
  addressCheck: boolean = false;
  // PartnerContactPersonJson: PartnerContactPersonJson[] = [];
  constructor(init?: OdPartnerRegisterValues) {
    Object.assign(this, init);
  }
}

export interface IPartnerReferenceDetail {
  Title: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
  Email: string;
  Designation: string;
  Organization: string;
}

export class PartnerReferenceDetailJson implements IPartnerReferenceDetail {
  Title: string = "";
  FirstName: string = "";
  LastName: string = "";
  Mobile: string = "";
  Email: string = "";
  Designation: string = "";
  Organization: string = "";
}

export interface IPartnerContactPerson {
  ContactTitle: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactMobile: string;
  ContactEmailId: string;
  ContactDesignation: string;
  // Organization: string;
}

export class PartnerContactPersonJson implements IPartnerContactPerson {
  ContactTitle: string = "";
  ContactFirstName: string = "";
  ContactLastName: string = "";
  ContactMobile: string = "";
  ContactEmailId: string = "";
  ContactDesignation: string = "";
}

export interface IOdPartnerApproveOrReject {
  PartnerBasicDetailId?: number | undefined;
  IsApproved: boolean;
  VerifiedBy: string;
  VerifiedComment: string;
}

export class OdPartnerApproveOrReject implements IOdPartnerApproveOrReject {
  PartnerBasicDetailId?: number = undefined;
  IsApproved: boolean = false;
  VerifiedBy: string = "";
  VerifiedComment: string = "";

  constructor(init?: IOdPartnerApproveOrReject) {
    Object.assign(this, init);
  }
}

export interface IOdPartnerRegister {
  partnerBasicDetailId: number;
  partnerKey: string;
  organizationName: string;
  yearOfInception: string;
  registeredAddress: string;
  communicationAddress: string;
  pincode: string;
  state: string;
  officialEmailId: string;
  password: string;
  officeContactumber: string;
  mobileContactNumber: string;
  websiteAddress: string;
  whatsappNumber: string;
  facebookID: string;
  twitterID: string;
  linkedInID: string;
  otherMediaLink: string;
  founderTitle: string;
  founderFirstName: string;
  founderLastName: string;
  founderMobile: string;
  founderEmailId: string;
  founderDesignation: string;
  ceoTitle: string;
  ceoFirstName: string;
  ceoLastName: string;
  ceoMobile: string;
  ceoEmailId: string;
  ceoDesignation: string;
  ceoPhoto: string;
  trusteeorManagementProfile: string;
  numberOfCommiteeMember: string;
  missionStatement: string;
  faithStatement: string;
  visionStatement: string;
  coreValues: string;
  objectiveOfOrganization: string;
  majorActivities: string;
  sectorName: string;
  fedarationBasicDetailId: number;
  subSectorName: number;
  sectorId: number;
  subSectorId: number;
  stateId: number;
  organizationInfoOrBrouche: string;
  organizationLogo: string;
  verificationStatus: string;
  refId1: number;
  refId2: number;
  refTitle1: string;
  refFirstName1: string;
  refLastName1: string;
  refMobile1: string;
  refEmail1: string;
  refDesignation1: string;
  refOrganization1: string;
  refTitle2: string;
  refFirstName2: string;
  refLastName2: string;
  refMobile2: string;
  refEmail2: string;
  fedarationBasicDetailIds: number[];
  refDesignation2: string;
  refOrganization2: string;
  contactId: number;
  contactTitle: string;
  contactFirstName: string;
  contactLastName: string;
  contactMobile: string;
  contactEmailId: string;
  contactDesignation: string;
  activityReport: string;
  organisationBudget: string;
  recentAuditstatement: string;
  savePartnerDetailsId: number;
  // partnerReferenceViewModel: [
  //   {
  //     partnerReferenceDetailId: number;
  //     title: string;
  //     firstName: string;
  //     lastName: string;
  //     mobile: string;
  //     email: string;
  //     designation: string;
  //     organization: string;
  //   }
  // ];
  // partnerContactViewModel: [
  //   {
  //     partnerContactPersonId: number;
  //     contactTitle: string;
  //     contactFirstName: string;
  //     contactLastName: string;
  //     contactMobile: string;
  //     contactEmailId: string;
  //     contactDesignation: string;
  //   }
  // ];
}
