import React from "react";
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Segment } from 'semantic-ui-react';


interface IProps
  extends FieldRenderProps<FileList, HTMLElement>,
  FormFieldProps { }

const FileUploadTab: React.FC<FieldRenderProps<string, HTMLElement>> = ({
  input,
  width,
  type,
  placeholder,
  label,
  hidden,
  labelstyle,
  addformstyle,
  inputstyle,
  errorstyle,
  uploadedFile,
  style,
  readOnly = false,
  onChange = undefined,
  meta: { touched, error }
  
}) => {
  const [stateError] = React.useState({
    touched: false,
  });
  const validation = () => {
    stateError.touched = true;
  }
  return (    
    <Form.Field inline style={{float:"right"}}> 
    <div style={addformstyle}>     
     {label &&         
            <label style = {labelstyle} className='fieldLabel'>{label}
            </label> 
      }  <Segment style={style}>
            <input              
              placeholder={placeholder}
              readOnly={readOnly}
              type="file"
              // multiple={input.multiple}
              onChange={
                (event) => {
                  if (event?.target?.files)
                    input.onChange(event?.target?.files)
                  if (onChange)
                    onChange(event)
                }}                 
                onBlur = {validation}
            /></Segment>
            {
              uploadedFile && (
                <a href={uploadedFile} target="_blank" rel="noopener noreferrer"> {uploadedFile.split("/").pop().split("-").pop()}</a>               
              )
            }
           
           {stateError.touched && error && (
              <Label basic color='red'>
                {error}
              </Label>
            )}
          </div>
        </Form.Field>
     
   
  );
};

export default FileUploadTab;