import React from 'react'
import { Menu, Header, Container } from 'semantic-ui-react';
interface IProps {
    title: String
}

export const Heading: React.FC<IProps> = ({ title, children }) => {


    return (
        <Menu stackable borderless secondary fluid>

            <Container textAlign='center' className = "panel-header" fluid >
                <Header className = "panel-label">{title}</Header>
                <Menu.Item position='right'>
                    {children}
                </Menu.Item>

            </Container>
        </Menu >
    );
}

