import React from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useContext } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";
import { ISectorFormValues } from "../../../../app/models/sector";
import { observer } from "mobx-react-lite";

interface IProps {
  disableEnableSector: ISectorFormValues;
}

const DisableSector: React.FC<IProps> = ({ disableEnableSector }) => {
  const rootStore = useContext(RootStoreContext);
  const { disableSector } = rootStore.sectorStore;
  return (
    <FinalForm
      initialValues={disableEnableSector}
      onSubmit={(values: ISectorFormValues) =>
        disableSector(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      render={({ handleSubmit, submitting, submitError }) => (
        <Form onSubmit={handleSubmit}>
          
            Do you really want to{" "}
            {disableEnableSector.isActive ? "disable" : "enable"} 
          <b>  {disableEnableSector.sectorName}?{" "}</b>
         
          <br />
          <br />
          {submitError && <ErrorMessage error={submitError} />}

          
            <Button
              loading={submitting}            
              color='blue' style={{ marginLeft: '130px'}}
            >
              <Icon name={disableEnableSector.isActive ? "delete" : "check"} />{" "}
              {disableEnableSector.isActive ? "Disable" : "Enable"}
            </Button>
         
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(DisableSector);
