import React, {  } from 'react';
import GaugeChart from 'react-gauge-chart' 
const ODComplianceChart = (props) => { 
           
        return (
                <div>
                        <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Overall Compliance Chart</h5>
                        <GaugeChart id="gauge-chart2" animate={true} textColor='grey'
                                nrOfLevels={20}
                                percent={props.data}                                
                                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                        />
                        
          
                </div>

        );
}
export default (ODComplianceChart);
