import { DropdownItemProps } from 'semantic-ui-react';
import { AnswerOptionViewModel, IAnswerOptionViewModel } from "./answerOption";

export interface IQuestionAnswerValues {
  partnerId?: number,
  questionId?: number,
  question: string,
  answerType: string,
  isMandatory: boolean,
  sectorId?: number,
  subSectorId?: number,
  areaOfAssessmentId?: number,
  defaultHidden: boolean,
  answerOptionCommand: IAnswerOptionViewModel[],
  answerDropDownValues: DropdownItemProps[],
  questionOptions: IAnswerOptionViewModel[],
  isActive: boolean,
  answerOptionId?: number[],
  answerId?:number;
  answerValue?: string,
  questionOrder?: number,
  file: string,
  maxTextLimit?: number
}

export class QuestionSetupFormValues implements IQuestionAnswerValues {
  questionId?: number = undefined;
  question: string = '';
  answerType: string = '';
  isMandatory: boolean = false;
  sectorId?: number = undefined;
  subSectorId?: number = undefined;
  areaOfAssessmentId?: number = undefined;
  answerId?:number = 0;
  defaultHidden: boolean = false;
  answerOptionCommand: IAnswerOptionViewModel[] = [
    new AnswerOptionViewModel()
  ];
  answerDropDownValues: DropdownItemProps[] = [];
  questionOptions: IAnswerOptionViewModel[] = [
    new AnswerOptionViewModel()
  ];
  isActive: boolean = false;
  answerOptionId?: number[] = [];
  answerValue?: string = undefined;
  questionOrder?: number = undefined;
  file: string = '';
  maxTextLimit?: number=undefined;
  constructor(init?: IQuestionAnswerValues) {
    Object.assign(this, init);
  }
}



export interface IQuestionName {
  questionId?: number,
  question: string,
}

export interface IMaster {
  sectorId?: number,
  subSectorId?: number,
  areaOfAssessmentId?: number,
}

export class Master implements IMaster {
  sectorId?: number = undefined;
  subSectorId?: number = undefined;
  areaOfAssessmentId?: number = undefined;
  constructor(init?: IQuestionAnswerValues) {
    Object.assign(this, init);
  }
}

export interface ISubQuestionViewModel {
  questionId: number,
  questionName: string
}


export interface IQuestionSetupDisableFormValues {
  questionId?: number,
  isActive: boolean,
}

export class QuestionSetupDisableFormValues implements IQuestionSetupDisableFormValues {
  questionId?: number = undefined;
  isActive: boolean = false;

  constructor(init?: IQuestionSetupDisableFormValues) {
    Object.assign(this, init);
  }
}

export interface IQuestionImport {
  sectorId: number;
  subSectorId: number;
  areaOfAssessmentId: number;
  file: string;
}

export interface IQuestionExport {
  questionId: number;
  isSubQuestion: boolean;
  question: string;
  answerType: string;
  mainOption: string;
  mainQuestion: string;
  isMandatory: boolean;
  answerOptionId1: number;
  answerOptionId2: number;
  answerOptionId3: number;
  answerOptionId4: number;
  answerOptionId5: number;
  optionName1: string;
  optionName2: string;
  optionName3: string;
  optionName4: string;
  optionName5: string;
  ponintValue1: string;
  ponintValue2: string;
  ponintValue3: string;
  ponintValue4: string;
  ponintValue5: string;
  actionPoints1: string;
  actionPoints2: string;
  actionPoints3: string;
  actionPoints4: string;
  actionPoints5: string;
  reportText1: string;
  reportText2: string;
  reportText3: string;
  reportText4: string;
  reportText5: string;
  maxScore:number;
  maxTextLimit: number;
}












