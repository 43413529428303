import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext, Fragment } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Button,  Form, Grid, Icon } from "semantic-ui-react";
import { IPlanFormValues } from "../../../app/models/plan";
import { RootStoreContext } from "../../../app/stores/rootStore";
import arrayMutators from 'final-form-arrays'
import { UserRole } from "../../../app/common/enum";
import SelectInputTab from "../../../app/common/form/SelectInputTab";

interface IProps {
  editPlan?: IPlanFormValues;
}
const entityType = [
  { text: "Single", value: "Single", key: 1 },
  { text: "Group", value: "Group", key: 2 },  
]

const planType = [
  { text: "Basic", value: "Basic", key: 1 },
  { text: "Advance", value: "Advance", key: 2 },  
]
const UserOrg = [
  { text:  UserRole.ODPartner, value: UserRole.ODPartner, key: 1 },
  { text:  UserRole.Federation, value:  UserRole.Federation, key: 2 },
  { text:  UserRole.DonorOrganisation, value:UserRole.DonorOrganisation, key: 3 },
  { text:  UserRole.DonorIndividual, value: UserRole.DonorIndividual, key: 4 },
]
const EditPlan: React.FC<IProps> = ({ editPlan }) => {
  const rootStore = useContext(RootStoreContext);
  const  { editPlans } = rootStore.planStore;
   
  const {  planList } = rootStore.planStore;
  console.log(planList)
  return (
    <Fragment>
      <FinalForm
        initialValues={editPlan}
        onSubmit={(values: IPlanFormValues) =>

          editPlans(values).catch((error) => ({
            [FORM_ERROR]: error,
          }))
        } mutators={{
          ...arrayMutators
        }}
        render={({
          handleSubmit,
          submitting,
          submitError,
          invalid,
          pristine,
          dirtySinceLastSubmit, form: {
            mutators: { push },
          }, }) => (
          <Fragment >

            <br />

            <Form onSubmit={handleSubmit}>
            <Form.Field inline>        
            <Field name="organisationType" component= {SelectInputTab} inputstyle={{ marginLeft: '40px' }}
                 label ="Organisation Type" labelstyle =  {{ marginLeft: '30px' }}  placeholder='OrganisationType' 
                 options = {UserOrg}                
                >                 
                </Field>
                   </Form.Field>
              <br />
              <Form.Field inline>               
                <Field
                  component= {SelectInputTab}
                  name="entityType"
                  label = "Entity Type"
                  labelstyle ={{ marginLeft: '30px' }}
                  inputstyle={{ marginLeft: "84px",width: '168px' }}
                  placeholder="Select Entity type"
                  options= {entityType}
                >                
                </Field>
              </Form.Field>
              <br />
              <Form.Field inline>   
                <Field labelstyle={{ marginLeft: '30px' }} label = "Plan Type" name="planType" component= {SelectInputTab}
                 inputstyle={{ marginLeft: '94px', width: '168px' }}
                  fluid placeholder='Plan Type' search selections={"true"} options = {planType}
                > </Field>
              </Form.Field>
              <br /> 
              <Form.Field inline>
                <label style={{ marginLeft: "30px" }}>Plan Amount  </label>
                <Field
                  component="input"
                  name="planAmount"
                  style={{ marginLeft: "77px", width : "197px" }}
                  placeholder="Enter Plan amount"
                />
              </Form.Field>
              <br />
              <Form.Field inline>
                <label style={{ marginLeft: "30px" }}>
                  Plan Feature {" "}
                </label>
              </Form.Field>

              <FieldArray name="planFeatureViewModel">
                {({ fields }) =>
                  fields.map((name: any, index: any) => (
                    <div>
                      <Field
                        component="input"
                        name={`${name}.feature`}
                        style={{ marginLeft: "95px", width: "286px" }}
                        placeholder="Enter Plan Feature" />
                      <Field
                        component="input"
                        hidden={true}
                        name={`${name}.isActive`}
                        style={{ marginLeft: "95px", width: "286px" }}
                        placeholder="Enter Plan Feature" />
                      {/* <Icon name='delete'
                        onClick={() => {
                          fields.remove(index);
                          fields.value[index].isActive = true;
                        }
                        }></Icon> */}
                        
                        </div>
                  ))
                }
              </FieldArray>
              <Button style={{ marginLeft: "20px" }} type="button" onClick={() => {push('planFeatureViewModel', undefined);
               
            }} color="blue" size="tiny" icon>
                <Icon name="add" />
              </Button>
              <br />
              <Grid textAlign="center" style={{ margin: "4px" }}>
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={submitting}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </Fragment>
        )}
      ></FinalForm>
    </Fragment>
  );
};
export default observer(EditPlan);
