import SelectInput from '../../../app/common/form/SelectInput';
import React, { useContext, useState } from "react";
import { Button, Container, Grid, Icon, Segment,Form } from "semantic-ui-react";
import CheckInput from "../../../app/common/form/CheckInput";
import { IQuestionAnswerValues } from "../../../app/models/questionSetup";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Form as FinalForm, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form';
import { observer } from 'mobx-react-lite';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays'


interface IProps {
    questionSetupDetail: IQuestionAnswerValues
}

const EditQuestion: React.FC<IProps> = ({  questionSetupDetail }) => { 
      
    const rootStore = useContext(RootStoreContext);
    const { questionNames,upsertQuestion,questionList } = rootStore.questionSetupStore;

    console.log(questionList)
    var status : string = "";
    
    const state = {
        showField: false,
        showRadio: false,
        showCheck: false,
        showDrop: false,
        showFile: false,
        selectedValue: status,
      };

    const [selectedId, setSelectedId] = useState(state);

    const checkLayers = (value: string)  => {
        console.log("hi");
        setSelectedId({
          showField: value === "TextBox",
          showRadio: value === "Radio" ,
          showCheck: value === "Check",
          showDrop: value === "DropDown",
          showFile: value === "File",
          selectedValue: status
        });  
        console.log(value);
      };

    const AnswerTypeValues = [
        { text: "TextBox", value: "TextBox", key: 1 },
        { text: "Radio Button", value: "Radio", key: 2 },
        { text: "Check Box", value: "Check", key: 3 },
        { text: "DropDown", value: "DropDown", key: 4 },
        { text: "File Upload", value: "File", key: 5}
    ]
   
    return (
    <FinalForm 
        initialValues = {questionSetupDetail}
        onSubmit={(values: IQuestionAnswerValues) => upsertQuestion(values)
            .catch(error => ({
                [FORM_ERROR]: error
            }))
        }
        // onSubmit={(values: any) => 
        //     console.log(values)
        // }
        mutators={{
            ...arrayMutators
        }}
         render={({ handleSubmit: questionSubmit, form: {
            mutators: { push, pop }
          },submitting, 
            invalid, pristine, dirtySinceLastSubmit,submitError }) => (

        <Form onSubmit={questionSubmit} error>
             {submitError &&
                            (
                                <ErrorMessage error={submitError} />
                            )}
        <Container>
        <Segment raised>
        <Grid>
            <Grid.Row>        
                <Field component="textarea"  name="question"   style={{ marginLeft: '30px' , height: '3vh'}} placeholder='Enter Question..' />
            </Grid.Row>
            <Grid.Row>        
                <Field component="input"  name="questionOrder"  style={{ marginLeft: '30px'}} 
                 type="number"
                 parse={(value: string | number) => Number(value)}
                placeholder='Enter Question Order' />
            </Grid.Row>
            <Grid.Row style={{ marginRight: '30px'}}>
            <Field component={SelectInput} name="answerType"   options={AnswerTypeValues}
             value={selectedId.selectedValue} onChange={checkLayers} fluid search selection={"true"}>
            </Field>
            </Grid.Row>
            <Grid.Row style={{ marginLeft: '100px'}}>        
                <Field textAlign='center' 
                        name="defaultHidden"
                        label="Is a Sub Question"
                        component={CheckInput}
                        />
            </Grid.Row>
        </Grid>
        <Grid>
            <Grid.Column>
            {
                selectedId.showField? 
                (
                    <FieldArray name="answerOptionCommand">
                     {({ fields }) => (
                            <Grid>
                            {fields.map((name, index) => (
                                <Grid key={name}>
                                <Grid.Row >
                                    <Field name={`${name}.pointValue`} style={{  marginLeft: '30px'}} component="input"  placeholder="Enter Points"
                                    type="number"
                                    parse={(value: string | number) => Number(value)}/>
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.actionPoints`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Action Points" 
                                    />
                                </Grid.Row>
                                <Grid.Row >
                                <Field component={SelectInput} fluid placeholder='Select Question' name={`${name}.questionsToDisplay`}
                                search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                </Grid.Row>
                                </Grid>
                            ))}
                            </Grid>
                        )}
                    </FieldArray>
                ) : null}
            {
                selectedId.showRadio? 
                (
                    <FieldArray name="answerOptionCommand">
                     {({ fields }) => (
                            <Grid>
                            {fields.map((name, index) => (
                                <Grid key={name}>
                                <Grid.Row >
                                    <Field name={`${name}.optionName`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter options" />
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.pointValue`} style={{  marginLeft: '30px'}} component="input"  placeholder="Enter Points"
                                    type="number"
                                    parse={(value: string | number) => Number(value)}/>
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.actionPoints`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Action Points" 
                                    />
                                </Grid.Row>
                                {/* <Grid.Column width= {3}> */}
                                <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question' 
                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                {/* </Grid.Column> */}
                                <Grid  style={{  marginLeft: '180px'}}>
                                <Grid.Column >
                               <button type="button" onClick={() => pop('answerOptionCommand')}>
                                <Icon name='remove' color='red' />
                                 </button>
                                                                           
                                </Grid.Column>
                                <Grid.Column>
                            <button
                                type="button" 
                                onClick={() => push('answerOptionCommand')}>
                               <Icon name='add' color='blue' /> 
                            </button>
                            </Grid.Column>
                                </Grid>
                                </Grid>
                            ))}
                           
                            </Grid>
                        )}
                    </FieldArray>
                ) : null
            }
            {selectedId.showCheck? (
                <>
               <FieldArray name="answerOptionCommand">
                     {({ fields }) => (
                            <Grid>
                            {fields.map((name) => (
                                <Grid key={name}>
                                <Grid.Row >
                                    <Field name={`${name}.optionName`} style={{  marginLeft: '30px'}}  component="input" placeholder="Enter options" />
                                </Grid.Row>
                                <Grid.Row>
                                    <Field name={`${name}.pointValue`} style={{  marginLeft: '30px'}} component="input"  placeholder="Enter Points" 
                                    type="number"
                                    parse={(value: string | number) => Number(value)}/>
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.actionPoints`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Action Points" />
                                </Grid.Row>
                                {/* <Grid.Column width={3}> */}
                                <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question' 
                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                {/* </Grid.Column> */}
                                <Grid  style={{  marginLeft: '180px'}}>
                                <Grid.Column >
                               <button type="button" onClick={() => pop('answerOptionCommand')}>
                                <Icon name='remove' color='red' />
                                 </button>
                                                                           
                                </Grid.Column>
                                <Grid.Column>
                            <button
                                type="button" 
                                onClick={() => push('answerOptionCommand')}>
                               <Icon name='add' color='blue' /> 
                            </button>
                            </Grid.Column>
                                </Grid>
                                </Grid>
                            ))}
                            
                            </Grid>
                        )}
                    </FieldArray>
                    </>
                ) : null}
            {selectedId.showDrop? (
                <>
                    <FieldArray name="answerOptionCommand">
                     {({ fields }) => (
                            <Grid>
                            {fields.map((name) => (
                                <Grid key={name}>
                                <Grid.Row >
                                    <Field name={`${name}.optionName`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Text" />
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.pointValue`} style={{  marginLeft: '30px'}} component="input"  placeholder="Enter Points"  type="number"
                                    parse={(value: string | number) => Number(value)}/>
                                </Grid.Row>
                                <Grid.Row >
                                    <Field name={`${name}.actionPoints`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Action Points" />
                                </Grid.Row>
                                {/* <Grid.Column width={3}> */}
                                <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question' 
                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                {/* </Grid.Column> */}
                                <Grid  style={{  marginLeft: '180px'}}>
                                <Grid.Column >
                               <button type="button" onClick={() => pop('answerOptionCommand')}>
                                <Icon name='remove' color='red' />
                                 </button>
                                                                           
                                </Grid.Column>
                                <Grid.Column>
                            <button
                                type="button" 
                                onClick={() => push('answerOptionCommand')}>
                               <Icon name='add' color='blue' /> 
                            </button>
                            </Grid.Column>
                                </Grid>
                                </Grid>
                            ))}
                            
                            </Grid>
                        )}
                    </FieldArray>
                    </>
                ) : null}
            {selectedId.showFile? (
                <> 
                <FieldArray name="answerOptionCommand">
                     {({ fields }) => (
                            <Grid>
                            {fields.map((name, index) => (
                                <Grid key={name}>
                                <Grid.Row >
                                    <Field name={`${name}.pointValue`} style={{  marginLeft: '30px'}} component="input"  placeholder="Enter Points"
                                    type="number"
                                    parse={(value: string | number) => Number(value)}/>
                                </Grid.Row>
                                <Grid.Row>
                                    <Field name={`${name}.actionPoints`} style={{  marginLeft: '30px'}} component="input" placeholder="Enter Action Points" 
                                    />
                                </Grid.Row>
                                <Grid.Row>
                                <Field name={`${name}.questionsToDisplay`} component={SelectInput} fluid placeholder='Select Question' 
                                        search multiple selection={"true"} options={questionNames} format={value => value || []} />
                                </Grid.Row>
                                </Grid>
                            ))}
                            </Grid>
                        )}
                    </FieldArray>
                    </>
                ) : null}
            </Grid.Column>
            </Grid>
            <br />
            <Button style={{marginLeft:"240px"}} 
            disabled={(invalid && !dirtySinceLastSubmit)
                 || pristine
                }
                loading={submitting} primary type='submit'><Icon name='check' /> Submit </Button>
            <br />

        </Segment>
    </Container>
        </Form>
    )}>

    </FinalForm>
)
}

export default observer(EditQuestion); 