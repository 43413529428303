import { observable, action, runInAction } from "mobx";

import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { ISectorDisable, ISectorFormValues } from "../models/sector";
import { AlreadyExist } from "../common/constant";

export default class SectorStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable sectorList: ISectorFormValues[] = [];
  @observable loadingSectors = false;
  @observable loadingSectorerror = false;
  @observable sectorDropdownValues: DropdownItemProps[] = [];
  @observable sectorMasterDropdownValues: DropdownItemProps[] = [];
  @observable sectorForSubSectorDropdownValues: DropdownItemProps[] = [];

  @action getSector = async () => {
    this.loadingSectors = true;
    try {
      const topicsResponse = await agent.Sector.list();
      runInAction(() => {
        this.sectorList = topicsResponse;

        this.updateDropdownValues();
        this.updateSubSectorDropDownValues();
        this.loadingSectors = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingSectors = false;
      });
      console.log(error);
    }
  };
  @action getActiveSector = async () => {
    this.loadingSectors = true;
    try {
      const topicsResponse = await agent.Sector.listactive();
      runInAction(() => {
        this.sectorList = topicsResponse;

        this.updateDropdownValues();
        this.updateSubSectorDropDownValues();
        this.loadingSectors = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingSectors = false;
      });
      console.log(error);
    }
  };

  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    let masterDropdownValues: DropdownItemProps[] = [];
    this.sectorList.map((reason) => {
      if (reason.isActive === true) {
        let item = {
          text: reason.sectorName,
          value: reason.sectorId,
          key: reason.sectorId,
        };
        dropDownValues.push(item);
      }
      // if(reason.isActive===false){
      let masterItem = {
        text: reason.sectorName,
        value: reason.sectorId,
        key: reason.sectorId,
        icon: reason.isActive ? "check" : "close",
      };
      masterDropdownValues.push(masterItem);
      // }
      return reason;
    });

    this.sectorDropdownValues = dropDownValues;
    this.sectorMasterDropdownValues = masterDropdownValues;
  }

  @observable updateSubSectorDropDownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    console.log(dropDownValues);
    this.sectorList.map((sector) => {
      // if (sector.isDisabled===false) {
      let item = {
        text: sector.sectorName,
        value: sector.sectorId,
        key: sector.sectorId,
      };
      // if(!sector.isActive)
      dropDownValues.push(item);
      // dropDownValues.push(item);

      return sector;
    });

    this.sectorForSubSectorDropdownValues = dropDownValues;
    console.log(this.sectorForSubSectorDropdownValues);
  }

  @action getSectorFromId = (sectorId: number): ISectorFormValues => {
    console.log(this.sectorList);
    let index = this.sectorList.findIndex((b) => b.sectorId === sectorId);
    console.log(index);
    return this.sectorList[index];
  };

  @action createSector = async (sector: ISectorFormValues) => {
    try {
      this.loadingSectorerror = true;
      const topicResponse = await agent.Sector.create(sector);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("Sector " + sector.sectorName + " already exists");
          this.loadingSectorerror = false;
        } else {
          sector.sectorId = Number(topicResponse);
          sector.isActive = true;
          this.sectorList.push(sector);
          this.updateDropdownValues();
          toast.info("Sector " + sector.sectorName + " successfully created");
          this.loadingSectorerror = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action editSector = async (updatedSector: ISectorFormValues) => {
    try {
      this.loadingSectorerror = true;
      const topicResponse = await agent.Sector.update(updatedSector);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("Sector " + updatedSector.sectorName + " already exists");
          this.loadingSectorerror = false;
        } else {
          let index = this.sectorList.findIndex(
            (sector) => sector.sectorId === updatedSector.sectorId
          );
          let oldTopicName = this.sectorList[index].sectorName;
          Object.assign(this.sectorList[index], updatedSector);
          updatedSector.sectorName &&
            this.rootStore.subSectorStore.updateSectorName(
              oldTopicName,
              updatedSector.sectorName
            );
          this.updateDropdownValues();
          toast.info(
            "Sector " + updatedSector.sectorName + " updated successfully"
          );
          this.loadingSectorerror = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action disableSector = async (selectedSector: ISectorFormValues) => {
    if (selectedSector.sectorId) {
      let disableSector: ISectorDisable = {
        sectorId: selectedSector.sectorId,
        isActive: !selectedSector.isActive,
      };
      await agent.Sector.disable(disableSector);
      runInAction(() => {
        let index = this.sectorList.findIndex(
          (a) => a.sectorId === disableSector.sectorId
        );
        this.sectorList[index].isActive = disableSector.isActive;
        this.updateDropdownValues();
        toast.info(
          "Sector  \t" +
            selectedSector.sectorName +
            " \t successfully " +
            (disableSector.isActive ? "enabled" : "disabled")
        );
        this.rootStore.modalStore.closeModal();
      });
    }
  };

  @action upsertSector = async (sector: ISectorFormValues) => {
    if (sector.sectorId === undefined || sector.sectorId === 0)
      await this.createSector(sector);
    else await this.editSector(sector);
    this.loadingSectors = false;
  };
}
