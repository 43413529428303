import React, { useContext, useEffect } from 'react';
import { Button, Container, Grid, Header, Item, Segment, Statistic, Form, Loader, Table } from 'semantic-ui-react';
import Card from '../../../component/Card/Card';
import CardHeader from '../../../component/Card/CardHeader';
import CardIcon from '../../../component/Card/CardIcon';
import CardFooter from '../../../component/Card/CardFooter';
import Icon from '@material-ui/core/Icon';
import GridItem from '../../../component/Grid/GridItem';
import GridContainer from '../../../component/Grid/GridContainer';
import SectorwiseRegistration from './adminCharts/SectorwiseRegistration';
import StatewiseRegistration from './adminCharts/StatewiseRegistration';
import AreawiseOverallAssessment from './adminCharts/AreawiseOverallAssessment';
import MonthwiseAssessment from './adminCharts/MonthwiseAssessment';
import HighestODScore from './adminCharts/HighestODScore';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { ISearchOrganisation } from '../../../app/models/dashboard';
import { Form as FinalForm, Field } from 'react-final-form'
import TextInput from "../../../app/common/form/TextInput";
import CreateMessage from '../../mailBox/CreateMessage';
import SelectInputTab from '../../../app/common/form/SelectInputTab';

interface Props {
  classes: any;
}

interface State {
  value: number;
}

const AdminDashboard = () => {

  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { getOrganisation, getMonthwise, monthwise, getStatewise, statewise, getYearwise,
    yearwise, yearAreawise, getYearAreawise, getSectorYearwise, yearSectorwise, getRegistrationCount, isRegistrationCount,
    isRegistrationApproval, isAssessmentReview,
    registrationCount,
    getAssessmentReview, assessmentReviewal, getRegistrationApproval, registrationApproval,
    orgDropdownValues, getOrganisationName, loadingDropdown,getPincode,pincodeDropdownValues, getOrgByState,orgState } = rootStore.dashboardStore;
  let { getActiveSector, sectorDropdownValues } = rootStore.sectorStore;
  const { getActiveState, stateMasterDropdownValues } = rootStore.stateStore;
  const [state] = React.useState({
    organisationKey: "",
    organisationName: "",
    state : 0,
    sector : 0,
    pincode : 0,
    hide : true,
  });

  const handleOrgNameChange = (event) => {
    state.organisationName = event.value;
  };
  const handleOrgKeyChange = (event) => {
    state.organisationKey = event.target.value;
  };
  const handleStateChange = (event) => {
    state.state = event.value;
   // getOrgByState(state.state,state.sector,state.pincode);
  };
  const handleSectorChange = (event) => {
    state.sector = event.value;
    //getOrgByState(state.state,state.sector,state.pincode);
  };
  const handlePincodeChange = (event) => {
    state.pincode = event.value;
  //  getOrgByState(state.state,state.sector,state.pincode);
  };

  useEffect(() => {
    getStatewise();
   getSectorYearwise();
    getMonthwise();
    getYearAreawise();
    getYearwise();
    getRegistrationCount();
    getRegistrationApproval();
    getAssessmentReview();
    getOrganisationName();
    getActiveSector();
    getActiveState();
    getPincode();
    
  }, [getStatewise, getSectorYearwise
    , getOrganisationName, getMonthwise,
    getYearAreawise, getYearwise, getRegistrationCount, getRegistrationApproval, getAssessmentReview, getActiveSector,
     getActiveState, getPincode]);


  return (
    <Container>
      {/* <MainMenu></MainMenu> */}
      {/* {
       loadingDashboard && <LoadingComponent content="Loading Admin Dashboard" />
      } */}
      <div className='App-background'>

        <Grid columns={2}>
          <Grid.Column width = "14"> 
            <Item.Group link>
              <Item>
                <Item.Image floated="right" height='92px' size='small' src={rootStore.commonStore.logo} />
                <Item.Content>
                  <Item.Header>{rootStore.commonStore.fullName}</Item.Header>
                  <Item.Meta>Admin - CIM</Item.Meta>
                  <Item.Description>  {rootStore.commonStore.email}  </Item.Description>
                  <Item.Extra> {rootStore.commonStore.mobile}</Item.Extra>
                </Item.Content>
              </Item>
            </Item.Group>
          </Grid.Column>
        
          <Grid.Column width = "2" >
              <Button color='blue' icon='mail' type='button' onClick={() => {
                      openRightPanel('Contact Partner', <CreateMessage />)
                    }}
                    />
          </Grid.Column>
        </Grid>



        <Header size='large' style={{ marginLeft: '350px', fontStyle: 'Italic' }}>No. of Total Organisation Registered :  {registrationCount?.registeredCount}
          <Statistic size='mini'>

          </Statistic></Header>

        <br />
        <br />
        <br />
        <Segment>

          <Grid columns={4} divided>
            <Grid.Row>
              <Grid.Column>
                <Header style={{ marginLeft: '50px', fontStyle: 'Italic' }}> OD Partner :{registrationCount?.odPartnerCount} <Statistic size='mini'>
                </Statistic></Header>
              </Grid.Column>
              <Grid.Column>
                <Header style={{ marginLeft: '50px', fontStyle: 'Italic' }}> Federation :{registrationCount?.federationCount} <Statistic size='mini'>
                </Statistic></Header>
              </Grid.Column>
              <Grid.Column>
                <Header style={{ marginLeft: '25px', fontStyle: 'Italic' }}> Donor Organisation :{registrationCount?.donorOrganisationCount} <Statistic size='mini'>
                </Statistic></Header>
              </Grid.Column>
              <Grid.Column>
                <Header style={{ marginLeft: '25px', fontStyle: 'Italic' }}> Donor Individual:
                  {!isRegistrationCount ? registrationCount?.donorIndividualCount :
                    <Loader active inline size='small' />} <Statistic size='mini'>
                  </Statistic></Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>


        <FinalForm
          onSubmit={(values: ISearchOrganisation) => {
            getOrganisation(values);          
            getOrgByState(state.state,state.sector,state.pincode);
            state.hide = false;        
          }
          }
          render={({ handleSubmit, submitting, submitError }) => (
            <Form onSubmit={handleSubmit} error>
              <Segment>
                <label> <h6 style={{ fontStyle: 'Italic' }}> Search Organisations</h6></label>
                <Grid>
                  <Grid.Column width={1} style={{ marginTop: "12px" }}>
                  </Grid.Column>
                  <Grid.Column width={3} style={{ marginTop: "12px" }}>
                    <Field name={'organisationKey'} component={TextInput} onChange={handleOrgKeyChange} placeholder="Organisation ID" />
                  </Grid.Column>
                  <Grid.Column width={5} style={{ marginTop: "12px" }} >

                    <Field component={SelectInputTab} placeholder='Organisation Name' name={'organisationKey'}
                      options={orgDropdownValues} search selections={"true"} inputstyle={{ marginLeft: "90px" }}
                      onCustomChange={handleOrgNameChange} loading={loadingDropdown}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "12px" }} >

                    <Field component={SelectInputTab} placeholder='Sector' name='sector'
                      inputstyle={{ marginLeft: '30px' }}
                      search selections={"true"} clearable='true' options={sectorDropdownValues} 
                       onCustomChange={handleSectorChange} dropup={"true"} loading={loadingDropdown} />
                  </Grid.Column>


                  {/* <Grid.Column width={2} style={{ marginTop: "12px" }}>
                    <Button color='blue' icon='search' type='submit' />
                  </Grid.Column>
                  <Grid.Column width={2} style={{ marginTop: "12px" }}>
                    <Button color='blue' icon='mail' type='button' onClick={() => {
                      openRightPanel('Contact Partner', <CreateMessage />)
                    }}
                    />
                  </Grid.Column> */}
                </Grid>
                <Grid>
                  <Grid.Column width={2} style={{ marginTop: "12px" }}>
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "12px" }}>

                    <Field component={SelectInputTab} placeholder='Pincode' name={'pincode'}
                      options={pincodeDropdownValues} search selections={"true"} inputstyle={{ marginLeft: "90px" }}
                      onCustomChange={handlePincodeChange} loading={loadingDropdown}
                    />
                  </Grid.Column>
                  <Grid.Column width={5} style={{ marginTop: "12px" }} >

                    <Field component={SelectInputTab} placeholder='State/City' name={'state'}
                      options={stateMasterDropdownValues} search selections={"true"} inputstyle={{ marginLeft: "90px" }}
                      onCustomChange={handleStateChange} loading={loadingDropdown}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} style={{ marginTop: "12px" }}>
                    <Button color='blue' icon='search' type='submit' />
                  </Grid.Column>
                </Grid>
              </Segment>

            </Form>
          )}>
        </FinalForm>

        <Segment  className="table-scroll" hidden = {state.hide}>
      <Table   color="blue">
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>User Id</Table.HeaderCell> */}
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Organisation </Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Sector</Table.HeaderCell>
            <Table.HeaderCell>Email Id</Table.HeaderCell>
            <Table.HeaderCell>Mobile</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {orgState &&
          orgState.map((org) => (
            <Table.Row key={org.organisationId}>
             <Table.Cell> {org.organisationKey}</Table.Cell>
              <Table.Cell>{org.organisationName}</Table.Cell>
              <Table.Cell>{org.state}</Table.Cell>
              <Table.Cell>{org.sector}</Table.Cell>
              <Table.Cell>{org.emailId}</Table.Cell>
              <Table.Cell>{org.mobile}</Table.Cell>           
             
            </Table.Row>
          ))}        
           
 
 
      </Table>
    </Segment>

        <GridContainer>

          <GridItem xs={12} sm={10} md={6}>
            <Card>
              <CardHeader color="warning" stats={true} icon={true}>
                <CardIcon color="warning" >
                  <Icon >Registration Approval</Icon>
                </CardIcon>
              </CardHeader>

              <CardFooter stats={true}>

                <Header  >Pending :
                  <a className='App-hyperlink' target="_blank" href="/dod-registrationPending" > {registrationApproval?.pendingCount} </a>

                </Header>
                <Header  > Evaluated :
                  <a className='App-hyperlink' target="_blank" href="/dod-registrationEvaluation"> {!isRegistrationApproval ?
                    registrationApproval?.approvedCount :
                    <Loader active inline='centered' size='small' />
                  } </a>

                </Header>

              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={10} md={6}>
            <Card>
              <CardHeader color="info" stats={true} icon={true}>
                <CardIcon color="info" >
                  <Icon > Assessment Review</Icon>
                </CardIcon>
              </CardHeader>
              <CardFooter stats={true}>
                <Header  >Pending :
                  <a className='App-hyperlink' target="_blank" href="/dod-assessmentPending">
                    {assessmentReviewal?.pendingAssessmentCount} </a>

                </Header>
                <Header>Assessed :
                  <a className='App-hyperlink' target="_blank" href="/dod-assessmentAssessed">
                    {!isAssessmentReview ? assessmentReviewal?.approvedAssessmentCount :
                      <Loader active inline='centered' size='small' />}
                  </a>

                </Header>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={10} md={6}>

            <br />
            <br />
            <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Registered Organisation - State</h5>
            <br />
            {
              statewise !== undefined ?
                (<StatewiseRegistration state={JSON.stringify(statewise)} />)
                : <Loader active inline='centered' size='big' />
            }
            <br />
          </GridItem>
          <GridItem xs={12} sm={10} md={6}>
            <br />
            <br />
            <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Registered Organisation - Year- Sector</h5>

            <br />
            {
              yearSectorwise !== undefined ?
                (<SectorwiseRegistration org={JSON.stringify(yearSectorwise)} />)
                : <Loader active inline='centered' size='big' />
            }
            <br />

          </GridItem>


        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
            <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Area</h5>
            {
              yearAreawise !== undefined ?
                (<AreawiseOverallAssessment area={JSON.stringify(yearAreawise)} />)
                : <Loader active inline='centered' size='big' />
            }

          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Assessment - Month</h5>
            {
              monthwise !== undefined ?
                (<MonthwiseAssessment assessmentMonth={JSON.stringify(monthwise)} />)
                : <Loader active inline='centered' size='big' />
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}> Highest Compliant Score - in a Year </h5>
            {
              yearwise !== undefined ?
                (<HighestODScore beneficiaryScore={JSON.stringify(yearwise)} />)
                : <Loader active inline='centered' size='big' />
            }
          </GridItem>
        </GridContainer>

      </div>
    </Container>
  );
}

export default observer(AdminDashboard);



