import React from "react";
import { FieldRenderProps } from "react-final-form";
import {
  FormFieldProps,
  Form,
  Label,
  Select,
  Grid,
  GridColumn,
  DropdownItemProps,
} from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
  FormFieldProps { }

const SelectInput: React.FC<IProps> = ({
  input,
  width,
  options,
  placeholder,
  selection,
  label,
  search,
  hidden,
  loading,
  possibleValues,
  onChange,
  name,
  labelWidth,
  onCustomChange,
  readOnly = false,
  style,
  required = false,
  meta: { touched, error },
}) => {
  let dropDownValues: DropdownItemProps[] = [];
  if (possibleValues && possibleValues.length > 0) {
    let index = 1;
    possibleValues.forEach((element: string) => {
      let item = {
        text: element,
        value: element,
        key: index,
      };
      index++;
      dropDownValues.push(item);
    });
  }

  let displayValue = "";
  if (readOnly && options) {
    let optionValues: DropdownItemProps[] = options as DropdownItemProps[];
    optionValues.map((option) => {
      console.log(input.value);
      if (Array.isArray(input.value)) {
        console.log("input.value " + input.value);
        console.log("option " + option.value);
        (input.value as Array<number | string>).map((arrayItem) => {
          if (option.value === arrayItem) {
            if (displayValue === "") displayValue = option.text as string;
            else displayValue = (displayValue + ", " + option.text) as string;
          }
          return arrayItem;
        });
      } else if (option.value === input.value) {
        displayValue = option.text as string;
      }
      return option;
    });
  }

  return (
    <Grid
      columns={2}
      style={
        !labelWidth
          ? {
            // marginLeft: "85px",
            paddingTop: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            paddingBottom: "0px",
          }
          : {}
      }
    >
      {label && (
        <GridColumn width={labelWidth ? 8 : 6}>
          <Form.Field hidden={hidden}>
            <label className="fieldLabel">{label}
              {required === true && <span className="mandatory-field">*</span>}
            </label>
          </Form.Field>
        </GridColumn>
      )}
      <GridColumn width={labelWidth ? labelWidth : label ? 4 : 4}>
        <Form.Field error={touched && !!error} width={width} hidden={hidden}>
          {!readOnly && (
            <Select
              value={input.value.toString() === "0" ? "" : input.value}
              onChange={(e, data) => {
                input.onChange(data.value);
                if (onChange) {
                  onChange(data.value);
                }
                if (onCustomChange) {
                  onCustomChange(data);
                }
              }}
              name={name}
              placeholder={placeholder}
              options={options ? options : dropDownValues}
              selections={selection}
              search={search}
              multiple={input.multiple}
              loading={loading}
              style={style}
            />
          )}
          {readOnly && (
            <input
              value={displayValue ? displayValue : input.value}
              placeholder={placeholder}
              readOnly
            />
          )}
        </Form.Field>
        {error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </GridColumn>
    </Grid>
  );
};

export default SelectInput;
