import React from 'react'
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Checkbox, Grid, GridColumn } from 'semantic-ui-react';

interface IProps
    extends FieldRenderProps<boolean, HTMLElement>,
    FormFieldProps { }

const CheckInput: React.FC<IProps> = ({
    input,
    width,
    placeholder,
    label,
    hidden,
    defaultChecked,
    required,
    meta: { touched, error }
}) => {
    return (
        <Grid columns={2} verticalAlign='middle' style={{ paddingTop: '0px', marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
            {label &&
                <GridColumn width={8}>
                    <Form.Field hidden={hidden}>
                        <label >{label}</label>
                    </Form.Field>
                </GridColumn>
            }
            <GridColumn width={label ? 8 : 16} >

                <Form.Field error={touched && !!error} hidden={hidden} width={width}>
                    <Checkbox
                        defaultChecked={input.value.toString() === "true"}
                        onClick={(e, data) => input.onChange(data.checked)}
                        placeholder={placeholder}
                        required={required}

                    />
                    {touched && error && (
                        <Label basic color='red'>
                            {error}
                        </Label>
                    )}
                </Form.Field>
                {touched && error && (
                    <Label basic color='red'>
                        {error}
                    </Label>
                )}
            </GridColumn>
        </Grid>)
}

export default CheckInput
