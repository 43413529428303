import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import {Loader, Segment,Statistic, Table } from "semantic-ui-react";
import { UserRole } from "../../../app/common/enum";
import { RootStoreContext } from "../../../app/stores/rootStore";


function PreviousAssessment(props) {
  
const rootStore = useContext(RootStoreContext);
const { role } = rootStore.commonStore;
 
const { getPreviousAssessment, perviousAssessmentCount } = rootStore.partnerdashboardStore
useEffect(() => {
  if (role === UserRole.ODPartner) {
  getPreviousAssessment(props.id);
  }
  else
  {
    getPreviousAssessment (props.id);
  }
}, [getPreviousAssessment,props,role]);

  return (
     <Segment >
      
       <h4 style={{  fontStyle: 'Italic' }}> Total No. of Assessments taken : {perviousAssessmentCount?.assessmentCount ? 
       perviousAssessmentCount?.assessmentCount :  <Loader active inline size='small'  /> } 
          <Statistic size='mini'>
          </Statistic></h4>
          <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Date of the Assessment</Table.HeaderCell>
        <Table.HeaderCell>Score</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
{ perviousAssessmentCount?.previousAssessments &&
perviousAssessmentCount.previousAssessments.map((x) =>  ( 
   <Table.Row>
     <Table.Cell>{x.date}</Table.Cell>
     <Table.Cell>{x.score} </Table.Cell>
   </Table.Row>
)) 
}
</Table.Body>
  </Table>
    </Segment> 
  )
}
export default observer(PreviousAssessment);

