import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";

import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
} from "../models/registrationOTP";
import { OTPVerified, TryAgain } from "../common/constant"; 
import { IRegistrationDone } from "../models/registrationDone";

export default class RegistrationOTP {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable userSendOtp: IRegistrationSendOTP | undefined;
  @observable userVerifyOtp: IRegistrationVerifyOTP | undefined;
  @observable loadingSendOtp: boolean = false;
  @observable loadingVerifyOtp: boolean = false;
  @observable verifyOtpSuccess: boolean = false;
  @observable index :number=  1;
  @observable loadingSendSms: boolean = false;
  @observable userSendSms: IRegistrationDone | undefined;
  @observable verifyByMobile: boolean =false;
  
  @action sendOTP = async (values: IRegistrationSendOTP) => {
    this.loadingSendOtp = true;
    console.log(values);
    try {
      const user = await agent.OTPVerification.create(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.userSendOtp = user;
        this.loadingSendOtp = false;
        toast.info("OTP sent and it is valid for 5 minutes.");
      });
    } catch (error) {
      this.loadingSendOtp = false;
      toast.error(TryAgain);
      throw error;
    }
  };

  @action verifyOtp = async (value: IRegistrationVerifyOTP) => {
    try {
      this.loadingVerifyOtp = true;
      const message = await agent.OTPVerification.update(value);
      runInAction(async () => {
        this.loadingVerifyOtp = false;
        if (message === OTPVerified) {
          this.index = 2;
          toast.info("OTP Verified successfully");
        } else {
         this.index = 1;
          toast.error(message);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  @action getVerifyByMobileOrEmail= async()=>{
    const response=await agent.OTPVerification.getVerifyByMobileOrEmail();
    runInAction(()=>{
      this.verifyByMobile=response.isMobile;
    })
  }
}
