import {
  Form,
  Button,
  Step,
  Segment,
  Grid,
} from "semantic-ui-react";
import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { LoadingComponent } from '../../../app/layout/LoadingComponent'
import { AssessmentDetail, AssessmentList, IAssessmentList } from "../../../app/models/assessment";
import TextInput from "../../../app/common/form/TextInput";
import RadioInput from "../../../app/common/form/RadioInput";
import SelectInput from "../../../app/common/form/SelectInput";
import FileUpload from "../../../app/common/form/FileUpload";
import arrayMutators from 'final-form-arrays'
import { FieldArray } from "react-final-form-arrays";
import CheckInputGroup from "../../../app/common/form/CheckInputGroup";
import { IQuestionAnswerValues } from "../../../app/models/questionSetup";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import { toast } from 'react-toastify';
import { values } from "mobx";


const Assessment = () => {
  const [stepperIndex, setStepperIndex] = useState(0);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const rootStore = useContext(RootStoreContext);
  const { getQuestionSetup, questionList, loadingQuestionSetup, questionHidden, handleAnswerSelectionChange } = rootStore.questionSetupStore;
  let { areaList, getArea, getAreaAllocationByPartnerId } = rootStore.areaOfAssessmentStore;
  const { submitAssessmentData, createAssessmentFile, saveAssessment, isSaved, isFileUpload, isSubmittedAssements , assessmentDone } = rootStore.assessmentStore;
  const { odPartnerDetail } = rootStore.partnerStore;
  
  let assessment = new AssessmentList();
  let assessmentAnswer = new AssessmentDetail();
  let currentans = new AssessmentList();
  const [questionListState] = React.useState({
    assessmentDetails: assessment,
    initialLoad: true
  });
  const state = {
    button: 1
  };
  const [registerState] = React.useState({
    questionName: "",
    optionValue: ""
  })

  const [currentAnswer] = React.useState({
    currentValue: assessment
  })
  console.log(areaList);
  //areaList = areaList.filter(item => item.isActive === true);
  const initializeAssessment = (): IAssessmentList => {
    if (questionList.length !== 0) {
      if (questionListState.initialLoad) {
        questionList.map(x => {
          if (x.questionId !== 0) {



            assessmentAnswer = {
              questionId: x.questionId,

              answerOptionId: x.answerValue !== null && x.answerValue !== undefined ? undefined : x.answerOptionId,
              answerId: x.answerId,
              answerValue: x.answerValue,
              answerOptionIds: undefined,
              file: undefined,
              areaOfAssessmentId: x.areaOfAssessmentId

            };
            assessment.assessmentAnswer.push(assessmentAnswer);
          }

          return assessmentAnswer;
        });
        console.log(questionList);
        questionListState.assessmentDetails = assessment;
        questionListState.initialLoad = false;
      }
      else {
        assessment = questionListState.assessmentDetails;
      }
    }
    return assessment;
  }




  const scrollTop = () => {
    document?.querySelector('body')?.scrollTo(0, 0)
  }

  useEffect(() => {

    //getQuestionSetup(rootStore.commonStore.registrationId);
    getAreaAllocationByPartnerId(rootStore.commonStore.registrationId);
  }, [getQuestionSetup, getAreaAllocationByPartnerId, rootStore.commonStore.registrationId]);



  const nameValidate = values => {
    console.log(values)

    if (values.length !== 0)
      if (values[1]?.questionId === questionList[1]?.questionId) {
        console.log("Hi222")
        let errors: any;

        if (!values.answerId)
          errors = "Please Fill Mandatory Question";

        console.log(errors);

        return toast.info(errors);
      }
  }



  return (
    <Fragment>
      <br />
      <br />
      {questionList &&
        <FinalForm
          initialValues={initializeAssessment()}
          onSubmit={
            
            (values: IAssessmentList) => {
              
              let access = true;
              let i: number;
              //questionList.for(x => {
              console.log(questionHidden);

              for (i = 0; i < questionList.length; i++) {

                let index = values.assessmentAnswer.findIndex(y => y.questionId === questionList[i].questionId);
                if (values.assessmentAnswer[index].questionId === questionList[i].questionId
                  && areaList[stepperIndex].areaOfAssessmentId === questionList[i].areaOfAssessmentId) {


                  if (questionList[i].isMandatory && questionList[i].isActive) {
                    if (questionHidden[questionList[i].questionId!] === false) {
                      let array = new AssessmentDetail();
                      array.answerOptionId = values.assessmentAnswer[index].answerOptionId;
                      if (values.assessmentAnswer[index].answerId || values.assessmentAnswer[index].answerValue) {
                        access = true;
                      }

                      else if (values.assessmentAnswer[index].answerOptionId !== undefined &&
                        array.answerOptionId && array.answerOptionId.length > 0) {


                        access = true;
                      }
                      else {
                        access = false;
                        break;
                      }
                    }

                  }
                }
              }

              //)


              if (access) {
                access = false;
                if (!backButtonClicked) {
                  values.questionIdList = [];
                  values.file = [];
                  values.assessmentAnswer.map(question => {
                    if (question && values.assessmentAnswer) {
                      currentans.partnerId = rootStore.commonStore.registrationId;
                      values.partnerId = rootStore.commonStore.registrationId;

                      const questionans = questionList.filter(id => id.questionId === question.questionId);
                      question.areaOfAssessmentId = questionans[0].areaOfAssessmentId;



                      if (question?.answerOptionId?.length !== 0) {


                        question?.answerOptionId?.map((id) => {
                          if (question.file !== undefined) {

                            values.questionIdList.push(String(question.questionId));
                            values.file.push(question.file);

                            const questionans = questionList.filter(id => id.questionId === question.questionId);

                            if ((id === 0 || id === undefined) && (question.file !== undefined) &&
                              questionans[0].answerOptionCommand[0].answerOptionId !== undefined) {
                              id = questionans[0].answerOptionCommand[0].answerOptionId;
                            }

                          }


                          if ((id === 0 && question.answerValue !== null)
                            || (id === undefined && question.answerValue !== null)) {
                            const questionans = questionList.filter(id => id.questionId === question.questionId);
                            if ((id === 0 || id === undefined) &&
                              questionans[0].answerOptionCommand[0].answerOptionId !== undefined) {
                              id = questionans[0].answerOptionCommand[0].answerOptionId;
                            }
                          }



                          if (id !== 0) {
                            currentans.questionName = registerState.questionName;
                            currentans.optionValue = registerState.optionValue;
                          }

                        })
                      }
                      else {
                        if (question.file !== undefined) {

                          values.questionIdList.push(String(question.questionId));
                          values.file.push(question.file);

                          const questionans = questionList.filter(id => id.questionId === question.questionId);

                          if ((question.answerId === 0 || question.answerId === undefined) && (question.file !== undefined) &&
                            questionans[0].answerOptionCommand[0].answerOptionId !== undefined) {
                            question.answerId = questionans[0].answerOptionCommand[0].answerOptionId;
                          }

                        }


                        if ((question.answerId === 0 && question.answerValue !== null)
                          || (question.answerId === undefined && question.answerValue !== null)) {
                          const questionans = questionList.filter(id => id.questionId === question.questionId);
                          if ((question.answerId === 0 || question.answerId === undefined)
                            && questionans[0].answerOptionCommand[0] !== undefined 
                            && questionans[0].answerOptionCommand[0].answerOptionId !== undefined
                          ) {
                            question.answerId = questionans[0].answerOptionCommand[0].answerOptionId;
                          }
                        }


                        if (question.answerId !== 0) {
                          currentans.questionName = registerState.questionName;
                          currentans.optionValue = registerState.optionValue;
                        }




                      }

                    }
                    //  if(Number(question.answerOptionId) !== 0 && question.answerOptionId !== undefined)
                    //  console.log(question.answerOptionId)
                    //   currentans.assessmentAnswer[0]?.answerOptionId?.push( Number(question.answerOptionId));

                    currentans.assessmentAnswer.push(question);

                    return currentans;

                  })
                }
                //access = false;  

                if (backButtonClicked) {
                  setStepperIndex(stepperIndex - 1);
                }
                else if (stepperIndex < areaList.length - 1 || state.button === 2) {
                  // setFormData(values)
                  createAssessmentFile(values);

                  saveAssessment(currentans);
                  questionListState.initialLoad = false;
                  if (state.button === 1 && currentans.optionValue !== "No") {
                    setStepperIndex(stepperIndex + 1);
                    currentAnswer.currentValue = currentans
                  }
                }
                else if (stepperIndex === areaList.length - 1) {
                  createAssessmentFile(values);
                  currentAnswer.currentValue = currentans
                  submitAssessmentData(currentans);

                }
                setBackButtonClicked(false);
              }

              else {
                toast.info("Please Fill Mandatory Questions");
              }
            }


          }



          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, form }) => (


            <Segment>
              <Step.Group className='responsive-tab' size="small" >
                {areaList.map((x) => (
                  <Step
                    active=
                    {stepperIndex === areaList.findIndex(a => a.areaOfAssessmentId === x.areaOfAssessmentId)

                    }
                    onClick={() => setStepperIndex(areaList.findIndex(a => a.areaOfAssessmentId === x.areaOfAssessmentId))}>
                    {/* {x.areaIcon &&
                    <Icon size='tiny' name={x.areaIcon} color={x.areaOfAssessmentId === areaList[stepperIndex].areaOfAssessmentId ? 'blue' : 'black'} />
                    } */}
                    <Step.Content>
                      {x.areaName.replace('&', ' & ').split(' ').map((area) => (
                        <Step.Title  >

                          {area}
                        </Step.Title>
                      ))}
                    </Step.Content>
                  </Step>
                ))}
              </Step.Group>

              <br />
              <br />

              <Form onSubmit={async event => {
                await handleSubmit(event);
                // {
                //   // form.reset();
                // }
              }}>

                <Segment>
                  <Grid columns={3} >
                    <Grid.Column width={2} >
                      {
                        (stepperIndex > 0) &&
                        <Button
                          className="actionButton"
                          style={{ float: "left" }}
                          onClick={() => {
                            setBackButtonClicked(true);

                          }}
                          content="Back"
                          color="blue"
                        />
                      }
                    </Grid.Column>
                    <Grid.Column width={12}>
                      {loadingQuestionSetup && (!questionList || questionList?.length === 0) &&
                        <LoadingComponent content='Loading Assessments' />}
                      <FieldArray name="assessmentAnswer"

                      //if (!values.length) return;
                      >
                        {({ fields }) =>
                        (<div>
                          {
                            fields.map((name, index) => (
                              questionList[index].areaOfAssessmentId === areaList[stepperIndex]?.areaOfAssessmentId
                              && questionHidden[questionList[index].questionId!] === false
                              &&
                              <Grid.Row style={{ display: 'block', paddingTop: '5px', paddingBottom: '5px' }} key={questionList[index].questionId}>
                                {
                                  (questionList[index].answerType === "TextBox" || questionList[index].answerType === "Textbox") &&
                                  <Field component={TextInput} name={`${name}.answerValue`}
                                    label={questionList[index].question}
                                    required={questionList[index].isMandatory}
                                    maxTextLimit={questionList[index].maxTextLimit}
                                  >
                                  </Field>
                                }
                                {
                                  (questionList[index].answerType === "File") &&
                                  <Field component={FileUpload} uploadedFile={questionList[index].answerValue} name={`${name}.file`}
                                    label={questionList[index].question}
                                    required={questionList[index].isMandatory}>
                                  </Field>
                                }

                                {
                                  (questionList[index].answerType === "RadioButton" || questionList[index].answerType === "Radio") &&
                                  <Field component={RadioInput} name={`${name}.answerId`}
                                    label={questionList[index].question}

                                    required={questionList[index].isMandatory}
                                    onChange={(value: number | string) => {
                                      questionList[index].question === "Is your Organisation registered?" &&
                                        (registerState.questionName = questionList[index].question);
                                      registerState.optionValue = handleAnswerSelectionChange(questionList[index], value, questionHidden)
                                    }
                                    }
                                    options={questionList[index].answerDropDownValues}>
                                  </Field>
                                }

                                {
                                  (questionList[index].answerType === "Check") &&
                                  <Field component={CheckInputGroup} search multiple selection={"true"}
                                    name={`${name}.answerId`}
                                    customName={`${name}.answerOptionIds`}
                                    label={questionList[index].question}
                                    required={questionList[index].isMandatory}
                                    onChange={(value: number | string, checked: boolean) => {
                                      handleAnswerSelectionChange(questionList[index], value, questionHidden, checked)
                                    }}
                                    options={questionList[index].answerDropDownValues} />
                                }

                                {
                                  (questionList[index].answerType === "Dropdown" || questionList[index].answerType === "DropDown") &&
                                  <Field labelWidth="8" component={SelectInput}
                                    name={`${name}.answerId`}
                                    required={questionList[index].isMandatory}
                                    label={questionList[index].question}
                                    placeholder="Select"
                                    onChange={(value: number | string) => {
                                      handleAnswerSelectionChange(questionList[index], value, questionHidden)
                                    }}
                                    options={questionList[index].answerDropDownValues} >
                                  </Field>
                                }

                                {
                                  (questionList[index].answerType === "MultiDropdown" || questionList[index].answerType === "MultiDropDown") &&
                                  <Field labelWidth="8" component={SelectInput}
                                    name={`${name}.answerOptionId`}
                                    required={questionList[index].isMandatory}
                                    label={questionList[index].question}
                                    placeholder="Select"
                                    multiple
                                    onChange={(value: number | string) => {
                                      handleAnswerSelectionChange(questionList[index], value, questionHidden)
                                    }}
                                    options={questionList[index].answerDropDownValues} >
                                  </Field>
                                }
                              </ Grid.Row>
                            ))
                          }
                        </div>)
                        }
                      </FieldArray>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Grid.Row style={{ paddingLeft: '30px' }}>
                        {
                          (stepperIndex < areaList.length - 1) &&
                          <Button
                            className="actionButton"
                            style={{ float: "right", marginTop: "10px" }}
                            loading={isFileUpload || isSaved}
                            content="Save & Next"
                            color="blue"
                            onClick={
                              scrollTop}
                          ></Button>
                        }
                      </Grid.Row>
                    </Grid.Column>
                    <br />
                    <br />
                    <br />

                  </Grid>
                  <Grid>
                    <Grid.Column width={6} ></Grid.Column>
                    <Grid.Column width={7} ></Grid.Column>
                    <Grid.Column width={3}>

                      <Grid.Column width={2}>
                        <Grid.Row style={{ paddingLeft: '111px' }}>
                          {
                            (stepperIndex < areaList.length - 1) &&
                            <Button
                              className="actionButton"
                              style={{ float: "right", marginTop: "10px" }}
                              loading={isFileUpload || isSaved}
                              content="Save & Next"
                              onClick={scrollTop}
                              color="blue"
                            ></Button>
                          }
                        </Grid.Row>
                      </Grid.Column>

                      {
                        (stepperIndex === areaList.length - 1) &&
                        <Grid.Row>
                          <Button
                            type="submit"
                            loading={isFileUpload || isSaved}
                            color="blue"
                            onClick={() => (state.button = 2)}
                            content="Save">
                          </Button>
                          <Button onClick={() => {
                            {
                             
                              assessmentDone(odPartnerDetail.mobileContactNumber);
                            }
                            (state.button = 3)
                           
                          }}
                            loading={isSubmittedAssements} type='submit' color="blue"
                            content="Submit" 
                            
                            />

                        </Grid.Row>
                      }</Grid.Column>
                  </Grid>
                </Segment>

              </Form>

            </Segment>

          )
          }>

        </FinalForm >
      }

    </Fragment >
  );
}
export default observer(Assessment);