import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IAreaAllocation, IAreaOfAssessmentValues } from "../models/areaOfAssessment";
import { AlreadyExist } from "../common/constant";

export default class AreaOfAssessmentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable areaList: IAreaOfAssessmentValues[] = [];
  @observable loadingArea = false;
  @observable areaDropdownValues: DropdownItemProps[] = [];
  @observable areaMasterDropdownValues: DropdownItemProps[] = [];
  @observable areaAllocation: IAreaAllocation[] = [];
  @action getArea = async () => {
    this.loadingArea = true;
    try {
      const topicsResponse = await agent.AreaOfAssessment.list();
      console.log("area", topicsResponse);
      runInAction(() => {
        this.areaList = topicsResponse;
        this.updateDropdownValues();
        this.loadingArea = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingArea = false;
      });
      console.log(error);
    }
  };
  @action getActiveArea = async () => {
    this.loadingArea = true;
    try {
      const topicsResponse = await agent.AreaOfAssessment.listactive();
      console.log("area", topicsResponse);
      runInAction(() => {
        this.areaList = topicsResponse;
        this.updateDropdownValues();
        this.loadingArea = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingArea = false;
      });
      console.log(error);
    }
  };

  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];
    let masterDropdownValues: DropdownItemProps[] = [];
    this.areaList.map((area) => {
      if (area.isActive === true) {
        let item = {
          text: area.areaName,
          value: area.areaOfAssessmentId,
          key: area.areaOfAssessmentId,
        };
        dropDownValues.push(item);
      }
      // if(reason.isActive===false){
      let masterItem = {
        text: area.areaName,
        value: area.areaOfAssessmentId,
        key: area.areaOfAssessmentId,
        icon: area.isActive ? "check" : "close",
      };
      masterDropdownValues.push(masterItem);
      // }
      // if(area.isActive)

      return area;
    });

    this.areaDropdownValues = dropDownValues;
    console.log("areadropDownValues", this.areaDropdownValues);
    this.areaMasterDropdownValues = masterDropdownValues;
  }

  @action getAreaFromId = (areaId: number): IAreaOfAssessmentValues => {
    console.log(this.areaList);
    let index = this.areaList.findIndex((b) => b.areaOfAssessmentId === areaId);
    console.log(index);
    return this.areaList[index];
  };

  @action createArea = async (area: IAreaOfAssessmentValues) => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaOfAssessment.create(area);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("Area " + area.areaName + " already exists");
          this.loadingArea = false;
        } else {
          area.areaOfAssessmentId = Number(topicResponse);
          area.isActive = true;
          this.areaList.push(area);
          this.updateDropdownValues();
          toast.info("Area " + area.areaName + " successfully created");
          this.loadingArea = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    }
  };

  @action editArea = async (updatedArea: IAreaOfAssessmentValues) => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaOfAssessment.update(updatedArea);
      runInAction(() => {
        if (topicResponse === AlreadyExist) {
          toast.error("Area " + updatedArea.areaName + " already exists");
          this.loadingArea = false;
        } else {
          let index = this.areaList.findIndex(
            (area) => area.areaOfAssessmentId === updatedArea.areaOfAssessmentId
          );
          Object.assign(this.areaList[index], updatedArea);
          this.updateDropdownValues();
          toast.info("Area " + updatedArea.areaName + " successfully updated");
          this.loadingArea = false;
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    }
  };

  @action disableArea = async (selectedArea: IAreaOfAssessmentValues) => {
    if (selectedArea.areaOfAssessmentId) {
      let disableArea: IAreaOfAssessmentValues = {
        areaOfAssessmentId: selectedArea.areaOfAssessmentId,
        areaName: selectedArea.areaName,
        areaIcon: selectedArea.areaIcon,
        isActive: !selectedArea.isActive,
      };
      const response = await agent.AreaOfAssessment.disable(disableArea);
      console.log("response", response);
      console.log("Api data", disableArea);
      runInAction(() => {
        let index = this.areaList.findIndex(
          (a) => a.areaOfAssessmentId === disableArea.areaOfAssessmentId
        );
        this.areaList[index].isActive = disableArea.isActive;
        this.updateDropdownValues();

        toast.info(
          "Area  \t" +
          selectedArea.areaName +
          " \t successfully " +
          (disableArea.isActive ? "enabled" : "disabled")
        );
        this.rootStore.modalStore.closeModal();
      });
    }
  };

  @action upsertArea = async (area: IAreaOfAssessmentValues) => {
    if (area.areaOfAssessmentId === undefined || area.areaOfAssessmentId === 0)
      await this.createArea(area);
    else await this.editArea(area);
  };

  @action createAreaAllocation = async (area: IAreaAllocation) => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaAllocation.create(area);
      runInAction(() => {
        toast.info("Area allocates succesfully");
        this.loadingArea = false;
      });
    } catch (error) {
      throw error;
    }
  };

  @action getAreaAllocation = async () => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaAllocation.list();
      runInAction(() => {
        this.areaAllocation  =topicResponse;
      
        this.loadingArea = false;
      });
    } catch (error) {
      throw error;
    }
  };

  @action getAreaAllocationById= async (sectorId : number, subSectorId : number) => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaAllocation.listById(sectorId,subSectorId);
      runInAction(() => {
        this.areaAllocation  =topicResponse;
        this.areaList = topicResponse;
       // toast.info("Area gets succesfully");
        this.loadingArea = false;
      });
    } catch (error) {
      throw error;
    }
  };

  @action getAreaAllocationByPartnerId= async (partnerId : number) => {
    try {
      this.loadingArea = true;
      const topicResponse = await agent.AreaAllocation.listByPartnerId(partnerId);
      runInAction(() => {
        //this.areaAllocation  =topicResponse;
        this.areaList = topicResponse;
       // toast.info("Area gets succesfully");
        this.loadingArea = false;
      });
    } catch (error) {
      throw error;
    }
  };
}
