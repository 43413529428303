import React, { Fragment, useContext, useState, useRef } from 'react';
import { Button, Form, Table, Image, Container, Label } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import { LoadingComponent } from '../../../../app/layout/LoadingComponent';
import ODApproveOrReject from './ODApproveOrReject';
import { OdPartnerApproveOrReject } from '../../../../app/models/partner';
import { Field, Form as FinalForm } from 'react-final-form'
import TextAreaInput from '../../../../app/common/form/TextAreaInput';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'

function ODRegistrationApproval(){
    const rootStore = useContext(RootStoreContext);
    const { odPartnerDetail, loadingOdPartnerDetail } = rootStore.partnerStore;
    const { openModal } = rootStore.modalStore;
    const { donorIndApprove } = rootStore.donorIndividualStore;
    const { isPending } = rootStore.dashboardStore;
    const [selectedValue, setComment] = useState(" ");
    const printRef = React.useRef<HTMLDivElement>(null);
    const [state] = React.useState({
        open: false
    });
    
    const name =odPartnerDetail?.organizationName
    const handleDownloadPdf = async () => {
    const element = printRef.current;
    //@ts-ignore
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    var position = 0;
    const pdf = new jsPDF();
    var pageHeight = 295; 
    const pdfWidth = pdf.internal.pageSize.getWidth();
    var imgHeight = canvas.height * pdfWidth / canvas.width;
    var heightLeft = imgHeight;
    const imgProperties = pdf.getImageProperties(data);
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
  position = heightLeft - imgHeight;
  pdf.addPage();
  pdf.addImage(data, 'PNG', 0, position, pdfWidth, imgHeight);
  heightLeft -= pageHeight;
}
    pdf.save(name);
  };
    const open = state;
    const close = !state;

    let approve = new OdPartnerApproveOrReject()
    approve.PartnerBasicDetailId = odPartnerDetail?.partnerBasicDetailId;
    approve.VerifiedComment = selectedValue;
    approve.IsApproved = true;

    let reject = new OdPartnerApproveOrReject()
    reject.PartnerBasicDetailId = odPartnerDetail?.partnerBasicDetailId;
    reject.VerifiedComment = selectedValue;
    reject.IsApproved = false;
    

    return (
        <div ref={printRef}>
            <Container>
                {
                    loadingOdPartnerDetail &&
                    <LoadingComponent content="Loading Basic Information and Leadership Team Profile" />
                } <Table celled >
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell  style={{ textAlign: 'end', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>
                                OD Partner </Table.Cell>
                            {odPartnerDetail?.verificationStatus !== "Pending" &&
                                <Label as='a' color='blue' ribbon='right'>
                                    {odPartnerDetail?.verificationStatus === 'Accepted' ? 'Approved' : 'Rejected'}
                                </Label>
                            }
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table celled color='blue' >
                    
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell colSpan='4' style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}> Basic Information</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">Organisation Name </Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.organizationName}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">Year of Inception</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.yearOfInception}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">Registered Address</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.registeredAddress}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Communication Address</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.communicationAddress}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> State </Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.state}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Pincode</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.pincode}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mobile Number</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.mobileContactNumber}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Official Email ID</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.officialEmailId}</Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Office Contact Number</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.officeContactumber}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Website Address</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.websiteAddress}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Whatsapp Number</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.whatsappNumber}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Facebook ID</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.facebookID}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Twitter ID</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.twitterID}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Other Media Link</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.otherMediaLink}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> LinkedIn ID</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.linkedInID}</Table.Cell> 
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">  </Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}> </Table.Cell>                           
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled color='blue'>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell colSpan='4' style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}> Organisational Profile</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Vision Statement</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.visionStatement}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Mission Statement</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.missionStatement}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Faith Statement</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.faithStatement}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Core Values</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.coreValues}</Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Objective Of Organization</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.objectiveOfOrganization}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Major Activities</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.majorActivities}</Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Sector</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.sectorName}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Sub Sector</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.subSectorName}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Table celled color='blue'>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organisation Brochure</Table.Cell>
                            {
                                <Table.Cell style={{ margin: '10px' }}>
                                    {
                                        odPartnerDetail?.organizationInfoOrBrouche ?
                                        <a href={odPartnerDetail?.organizationInfoOrBrouche} rel="noopener noreferrer" target="_blank">
                                        Click here</a>
                                            // (<DocViewer
                                            //     pluginRenderers={DocViewerRenderers}
                                            //     documents={[{ uri: 'https://cimdod.azurewebsites.net/' + odPartnerDetail?.organizationInfoOrBrouche }]}
                                            // />) 
                                            : ("")
                                    }
                                </Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Organisation Logo</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>
                                <Image src={odPartnerDetail?.organizationLogo} style={{ width: '23vh' }} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table celled color="blue"  >
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell colSpan='4' style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}> Leadership Team Profile</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Founder Title</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderTitle}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Founder First Name</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderFirstName}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Founder Last Name</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderLastName}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">Founder Mobile</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderMobile}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Founder EmailId</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderEmailId}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Founder Designation</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.founderDesignation}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Title</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoTitle}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO First Name</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoFirstName}</Table.Cell>
                        </Table.Row>

                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Last Name</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoLastName}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left">CEO Mobile</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoMobile}</Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO EmailId</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoEmailId}</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Designation</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.ceoDesignation}</Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> No Of Board Of Trustee</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}>{odPartnerDetail?.numberOfCommiteeMember}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table celled>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> CEO Photo</Table.Cell>
                            <Table.Cell style={{ margin: '10px' }}> <Image src={odPartnerDetail?.ceoPhoto} style={{ width: '23vh' }} /></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table celled>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell style={{ margin: '10px' }} textAlign="left"> Management Profile</Table.Cell>
                            {
                                <Table.Cell style={{ margin: '10px' }}>
                                    {
                                        odPartnerDetail?.trusteeorManagementProfile ?
                                        <a href={odPartnerDetail?.trusteeorManagementProfile} rel="noopener noreferrer" target="_blank">
                                        Click here</a> 
                                         : ("")
                                    }
                                </Table.Cell>
                            }
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Table celled color="blue">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colspan='6' style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}> Contact person for <br />Communication </Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell> Contact Title </Table.Cell>
                            <Table.Cell> Contact First Name </Table.Cell>
                            <Table.Cell> Contact Last Name </Table.Cell>
                            <Table.Cell> Contact Mobile </Table.Cell>
                            <Table.Cell> Contact EmailId </Table.Cell>
                            <Table.Cell> Contact Designation </Table.Cell>

                        </Table.Row>                        
                                    <Table.Row key={odPartnerDetail?.contactId}>
                                        <Table.Cell>{odPartnerDetail?.contactTitle}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.contactFirstName}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.contactLastName}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.contactMobile}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.contactEmailId}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.contactDesignation}</Table.Cell>
                                    </Table.Row>
                         
                    </Table.Body>
                </Table >
                <Table celled color="blue" >
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell colspan='6' style={{ textAlign: 'center', marginLeft: '00px', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}> Reference </Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell> Title </Table.Cell>
                            <Table.Cell> First Name </Table.Cell>
                            <Table.Cell> Last Name </Table.Cell>
                            <Table.Cell> Mobile </Table.Cell>
                            <Table.Cell> EmailId </Table.Cell>
                            <Table.Cell> Designation </Table.Cell>
                            <Table.Cell>Organization</Table.Cell>
                        </Table.Row>
                       
                                    <Table.Row key={odPartnerDetail?.refId1}>
                                        <Table.Cell>{odPartnerDetail?.refTitle1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refFirstName1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refLastName1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refMobile1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refEmail1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refDesignation1}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refOrganization1}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row key={odPartnerDetail?.refId2}>
                                        <Table.Cell>{odPartnerDetail?.refTitle2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refFirstName2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refLastName2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refMobile2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refEmail2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refDesignation2}</Table.Cell>
                                        <Table.Cell>{odPartnerDetail?.refOrganization2}</Table.Cell>
                                    </Table.Row>
                          
                    </Table.Body>
                </Table>
                {
                    isPending ? (
                        <Fragment>
                            <FinalForm
                                onSubmit={(values: OdPartnerApproveOrReject) =>
                                    donorIndApprove(values)
                                }
                                render={({ handleSubmit, submitting, submitError }) => (

                                    <Form onSubmit={handleSubmit} error>
                                        <Field
                                            name='VerifiedComment'
                                            placeholder="Enter Comment"
                                            rows={3}
                                            width={7}
                                            component={TextAreaInput}
                                            onChange={e => setComment(e.target.value)
                                            }
                                        />
                                    </Form>
                                )}>
                            </FinalForm>
                            <br />
                            <br />

                            <Button style={{ marginLeft: '450px', backgroundColor: '#2185d0' }}
                                negative={!open}
                                positive={!close}
                                onClick={() => {
                                    openModal('Od partner', <ODApproveOrReject approveOrRejectOdPartner={approve}
                                    />)
                                }}
                            >Approve</Button>
                            <Button style={{ marginLeft: '30px', backgroundColor: '#2185d0' }}
                                negative={!open}
                                positive={!close}
                                onClick={() => {
                                    openModal('Od partner', <ODApproveOrReject approveOrRejectOdPartner={reject}
                                    />)
                                }}
                            >Reject</Button>
                            {/* <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Approve</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectOdPartner={approve}   
                    />
                }
        </Segment>
      </TransitionablePortal>

 <TransitionablePortal 
        closeOnTriggerClick
        onOpen={handleOpen}
        onClose={handleClose}
        openOnTriggerClick
        trigger={
          
          <Button  style={{ marginLeft: '450px', backgroundColor:'#2185d0' }}  
            negative={!open}
            positive={!close}  
          >Reject</Button>
        }>  
         <Segment  
          style={{ left: '30%', position: 'fixed', top: '50%', zIndex: 1000,right:'33%' }}>
                 {
                    <ApproveOrReject approveOrRejectOdPartner={reject}   
                    />
                }
        </Segment>
      </TransitionablePortal> */}
                        </Fragment>
                    ) : null
                }

                <br />
                <br />
            </Container>
            <Button style={{ marginLeft: '611px', backgroundColor: '#2185d0', color: "white" }} type="button" onClick={handleDownloadPdf}>
        Registration Download
      </Button>
        </div>
    );
}

export default observer(ODRegistrationApproval);