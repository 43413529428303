import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import { history } from "../..";
import { IRegistrationDone } from "../models/registrationDone";
import { DonorOrgRegisterValues, IAcceptRejectPartner, IApprovedParterProfile, IDonorOrgApproveOrReject, IDonorOrgRegister, IDonorOrgRegisterValues } from "../models/donorOrg";
import { TryAgain } from "../common/constant";

export default class DonorOrgStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable donorOrgList: IDonorOrgRegisterValues[] = [];
  @observable loadingUsers: boolean = false;
  @observable submitDonor: boolean = false;
  @observable savedandnextDonorOrg: IDonorOrgRegisterValues[] = [];
  @observable loadingSendSms: boolean = false;
  @observable donororgSendSms: IRegistrationDone | undefined;
  @observable userDropdownValues: DropdownItemProps[] = [];
  @observable approvedPartnerProfile: IApprovedParterProfile[] = [];
  @observable roleDropdownValues: DropdownItemProps[] = [
    {
      text: "Administrator",
      value: "Administrator",
      key: 1,
    },
  ];

  @observable loadingDonorDetail: boolean = false;
  @observable donorDetail?: IDonorOrgRegister | any;
  @observable donorOrgApproveOrReject: IDonorOrgApproveOrReject | any;

  @action createDonorOrg = async (newUser: DonorOrgRegisterValues) => {
    try {
      this.submitDonor = true;
      console.log(newUser);
      let dataMap: Map<string, string> = new Map();
      if (newUser.OrganizationName !== undefined)
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (newUser.YearOfInception !== undefined)
        dataMap.set("YearOfInception", newUser.YearOfInception);
      if (newUser.RegisteredAddress !== undefined)
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (newUser.CommunicationAddress !== undefined)
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.Pincode !== undefined)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined) dataMap.set("State", newUser.State);
      if (newUser.OfficialEmailId !== undefined)
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined)
        dataMap.set("Password", newUser.Password);
      if (newUser.OfficeContactumber !== undefined)
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      if (newUser.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
      if (newUser.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (newUser.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.CEOTitle !== undefined)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (newUser.CEODesignation !== undefined)
        dataMap.set("CEODesignation", newUser.CEODesignation);
      if (newUser.MissionStatement !== undefined)
        dataMap.set("MissionStatement", newUser.MissionStatement);
      if (newUser.FaithStatement !== undefined)
        dataMap.set("FaithStatement", newUser.FaithStatement);
      if (newUser.VisionStatement !== undefined)
        dataMap.set("VisionStatement", newUser.VisionStatement);
      if (newUser.CoreValues !== undefined)
        dataMap.set("CoreValues", newUser.CoreValues);
      if (newUser.ObjectiveOfOrganization !== undefined)
        dataMap.set("ObjectiveOfOrganization", newUser.ObjectiveOfOrganization);
      if (newUser.MajorActivities !== undefined)
        dataMap.set("MajorActivities", newUser.MajorActivities);
      if (newUser.MyPreferedWork !== undefined)
        dataMap.set("MyPreferedWork", newUser.MyPreferedWork);
      if (newUser.Others !== undefined) dataMap.set("Others", newUser.Others);
      if (newUser.MyGivingPreference !== undefined)
        dataMap.set("MyGivingPreference", newUser.MyGivingPreference);
      if (newUser.GivingFrequence !== undefined)
        dataMap.set("GivingFrequence", newUser.GivingFrequence);
      if (newUser.MyPreferedChurchDomination !== undefined)
        dataMap.set(
          "MyPreferedChurchDomination",
          newUser.MyPreferedChurchDomination
        );
      if (newUser.MyPreferedState !== undefined)
        dataMap.set("MyPreferedState", newUser.MyPreferedState);
      if (newUser.MyPreferedDistrict !== undefined)
        dataMap.set("MyPreferedDistrict", newUser.MyPreferedDistrict);
      if (newUser.MyPreferedCommunity !== undefined)
        dataMap.set("MyPreferedCommunity", newUser.MyPreferedCommunity);
      if (newUser.MyPreferedTribe !== undefined)
        dataMap.set("MyPreferedTribe", newUser.MyPreferedTribe);
      if (newUser.MyPreferedLanguage !== undefined)
        dataMap.set("MyPreferedLanguage", newUser.MyPreferedLanguage);
      if (newUser.PreferedPincode !== undefined)
        dataMap.set("PreferedPincode", newUser.PreferedPincode);
      if (newUser.IamPresenting !== undefined)
        dataMap.set("IamPresenting", newUser.IamPresenting);
      if (newUser.Country !== undefined)
        dataMap.set("Country", newUser.Country);
      if (newUser.refTitle1 !== undefined)
        dataMap.set("RefTitle1", newUser.refTitle1);
      if (newUser.refTitle2 !== undefined)
        dataMap.set("RefTitle2", newUser.refTitle2);
      if (newUser.refFirstName1 !== undefined)
        dataMap.set("RefFirstName1", newUser.refFirstName1);
      if (newUser.refFirstName2 !== undefined)
        dataMap.set("RefFirstName2", newUser.refFirstName2);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName2 !== undefined)
        dataMap.set("RefLastName2", newUser.refLastName2);
      if (newUser.refMobile1 !== undefined)
        dataMap.set("RefMobile1", newUser.refMobile1);
      if (newUser.refMobile2 !== undefined)
        dataMap.set("RefMobile2", newUser.refMobile2);
      if (newUser.refEmail1 !== undefined && newUser.refEmail1 !== null)
        dataMap.set("RefEmail1", newUser.refEmail1);
      if (newUser.refEmail2 !== undefined && newUser.refEmail2 !== null)
        dataMap.set("RefEmail2", newUser.refEmail2);
      if (newUser.refDesignation1 !== undefined)
        dataMap.set("RefDesignation1", newUser.refDesignation1);
      if (newUser.refDesignation2 !== undefined)
        dataMap.set("RefDesignation2", newUser.refDesignation2);
      if (newUser.refOrganization1 !== undefined)
        dataMap.set("RefOrganization1", newUser.refOrganization1);
      if (newUser.refOrganization2 !== undefined)
        dataMap.set("RefOrganization2", newUser.refOrganization2);

      let fileMap: Map<string, string> = new Map();
      if (newUser.CEOPhoto !== undefined)
        fileMap.set("CEOPhoto", newUser.CEOPhoto);
      if (newUser.TrusteeorManagementProfile)
        fileMap.set(
          "TrusteeorManagementProfile",
          newUser.TrusteeorManagementProfile
        );
      if (newUser.OrganizationInfoOrBrouche )
        fileMap.set(
          "OrganizationInfoOrBrouche",
          newUser.OrganizationInfoOrBrouche
        );
      if (newUser.OrganizationLogo)
        fileMap.set("OrganizationLogo", newUser.OrganizationLogo);
      if (newUser.ProofofTotalContribution)
        fileMap.set(
          "ProofofTotalContribution",
          newUser.ProofofTotalContribution
        );
      if (newUser.GivingTrackRecord )
        fileMap.set("GivingTrackRecord", newUser.GivingTrackRecord);

      var obj1 = newUser.DonorOrgContactPersonJson;
      var contactObj = JSON.stringify(obj1);
      dataMap.set("DonorOrgContactPersonJson", contactObj);
      console.log(dataMap);

      const userResponse = await agent.DonorOrg.createWithFile(
        dataMap,
        fileMap
      );
      console.log(userResponse);
      runInAction( () => {
        this.submitDonor = false;
        this.donorOrgList = [...this.donorOrgList, userResponse];
        if (userResponse === -1) {
          toast.info("Email id already Registered.Try using different id");
        } else {
          toast.info("User Registered Successfully");
          history.push("/thankyouorg");
         
        }
      });
    } catch (error) {
      throw error;
    }
  };

  @action getDonorById = async (id: number) => {
    try {
      this.loadingDonorDetail = true;
      this.donorDetail = undefined;

      const donor = await agent.DonorOrg.getById(id);
      console.log(donor);
      runInAction(() => {
        this.donorDetail = donor;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingDonorDetail = false;
      });
    }
  };

  @action donorOrgApprove = async (approve: IDonorOrgApproveOrReject) => {
    try {
      console.log(approve);
      const donorOrgApproveResponse = await agent.DonorOrg.approve(approve);
      console.log(donorOrgApproveResponse);
      runInAction(() => {
        this.donorOrgApproveOrReject = donorOrgApproveResponse;
        console.log(this.donorOrgApproveOrReject);
       /* if(this.donorOrgApproveOrReject == "Updated Successfully"){
         
          // agent.RegistrationNotify.notifyUser(this.odPartnerArray["mobileContactNumber"]);
          agent.DonorRegsmsNotify.notifyUser(`${this.rootStore.donorOrgStore.donorDetail.mobileContactNumber}`);
          console.log(this.rootStore.donorOrgStore.donorDetail.mobileContactNumber);
        }*/
        toast.info(
          "Donor Organisation " +
            (approve.IsApproved ? "approved" : "rejected")
        );
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  

  @action donorOrgPartnerApprove = async (approve: IAcceptRejectPartner) => {
    try {
      console.log(approve);
      const donorOrgApproveResponse = await agent.DonorOrg.sendApproval(
        approve
      );
      console.log(donorOrgApproveResponse);
      runInAction(() => {
        this.donorOrgApproveOrReject = donorOrgApproveResponse;
        console.log(this.donorOrgApproveOrReject);
        if (approve.isInterest === true) {
          toast.success(
            approve.donorOrgName +
              " shows interest to support " +
              approve.partnerName
          );
        } else {
          toast.warn(
            approve.donorOrgName +
              " shows not interest to support " +
              approve.partnerName
          );
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };



  @action getApprovedPartnerProfile = async (id: number) => {
    try {
      this.loadingDonorDetail = true;
      const donor = await agent.DonorOrg.getPartnerProfile(id);
      console.log(donor);
      runInAction(() => {
        this.approvedPartnerProfile = donor;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingDonorDetail = false;
      });
    }
  };
  
  @action registerationDone = async (values: IRegistrationDone ) => {
    this.loadingSendSms = true;
    console.log(values);
    try {
      const user = await agent.DonorRegsmsNotify.notifyUser(values);
      console.log(user);
      console.log(values);
      runInAction(() => {
        this.donororgSendSms = user;
        this.loadingSendSms = false;
        
      });
    } catch (error) {
      this.loadingSendSms = false;
      toast.error(TryAgain);
      throw error;
    }
  };
  
  @action saveandnextDonorOrgDetails = async (
    newUser: IDonorOrgRegisterValues
  ) => {
    try {
      this.loadingUsers = true;
      let dataMap: Map<string, any> = new Map();
      if (newUser.OrganizationName !== undefined)
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (newUser.YearOfInception !== undefined)
        dataMap.set("YearOfInception", newUser.YearOfInception);
      if (newUser.RegisteredAddress !== undefined)
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (newUser.CommunicationAddress !== undefined)
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.Pincode !== undefined)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined) dataMap.set("State", newUser.State);
      if (newUser.OfficialEmailId !== undefined)
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined)
        dataMap.set("Password", newUser.Password);
      if (newUser.OfficeContactumber !== undefined)
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      if (newUser.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
      if (newUser.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (newUser.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.CEOTitle !== undefined)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (newUser.CEODesignation !== undefined)
        dataMap.set("CEODesignation", newUser.CEODesignation);
      if (newUser.MissionStatement !== undefined)
        dataMap.set("MissionStatement", newUser.MissionStatement);
      if (newUser.FaithStatement !== undefined)
        dataMap.set("FaithStatement", newUser.FaithStatement);
      if (newUser.VisionStatement !== undefined)
        dataMap.set("VisionStatement", newUser.VisionStatement);
      if (newUser.CoreValues !== undefined)
        dataMap.set("CoreValues", newUser.CoreValues);
      if (newUser.ObjectiveOfOrganization !== undefined)
        dataMap.set("ObjectiveOfOrganization", newUser.ObjectiveOfOrganization);
      if (newUser.MajorActivities !== undefined)
        dataMap.set("MajorActivities", newUser.MajorActivities);
      if (newUser.MyPreferedWork !== undefined)
        dataMap.set("MyPreferedWork", newUser.MyPreferedWork);
      if (newUser.Others !== undefined) dataMap.set("Others", newUser.Others);
      if (newUser.MyGivingPreference !== undefined)
        dataMap.set("MyGivingPreference", newUser.MyGivingPreference);
      if (newUser.GivingFrequence !== undefined)
        dataMap.set("GivingFrequence", newUser.GivingFrequence);
      if (newUser.MyPreferedChurchDomination !== undefined)
        dataMap.set(
          "MyPreferedChurchDomination",
          newUser.MyPreferedChurchDomination
        );
      if (newUser.MyPreferedState !== undefined)
        dataMap.set("MyPreferedState", newUser.MyPreferedState);
      if (newUser.MyPreferedDistrict !== undefined)
        dataMap.set("MyPreferedDistrict", newUser.MyPreferedDistrict);
      if (newUser.MyPreferedCommunity !== undefined)
        dataMap.set("MyPreferedCommunity", newUser.MyPreferedCommunity);
      if (newUser.MyPreferedTribe !== undefined)
        dataMap.set("MyPreferedTribe", newUser.MyPreferedTribe);
      if (newUser.MyPreferedLanguage !== undefined)
        dataMap.set("MyPreferedLanguage", newUser.MyPreferedLanguage);
      if (newUser.PreferedPincode !== undefined)
        dataMap.set("PreferedPincode", newUser.PreferedPincode);
      if (newUser.IamPresenting !== undefined)
        dataMap.set("IamPresenting", newUser.IamPresenting);
      if (newUser.Country !== undefined)
        dataMap.set("Country", newUser.Country);
      if (newUser.refTitle1 !== undefined)
        dataMap.set("RefTitle1", newUser.refTitle1);
      if (newUser.refTitle2 !== undefined)
        dataMap.set("RefTitle2", newUser.refTitle2);
      if (newUser.refFirstName1 !== undefined)
        dataMap.set("RefFirstName1", newUser.refFirstName1);
      if (newUser.refFirstName2 !== undefined)
        dataMap.set("RefFirstName2", newUser.refFirstName2);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName1 !== undefined)
        dataMap.set("RefLastName1", newUser.refLastName1);
      if (newUser.refLastName2 !== undefined)
        dataMap.set("RefLastName2", newUser.refLastName2);
      if (newUser.refMobile1 !== undefined)
        dataMap.set("RefMobile1", newUser.refMobile1);
      if (newUser.refMobile2 !== undefined)
        dataMap.set("RefMobile2", newUser.refMobile2);
      if (newUser.refEmail1 !== undefined && newUser.refEmail1 !== null)
        dataMap.set("RefEmail1", newUser.refEmail1);
      if (newUser.refEmail2 !== undefined && newUser.refEmail2 !== null)
        dataMap.set("RefEmail2", newUser.refEmail2);
      if (newUser.refDesignation1 !== undefined)
        dataMap.set("RefDesignation1", newUser.refDesignation1);
      if (newUser.refDesignation2 !== undefined)
        dataMap.set("RefDesignation2", newUser.refDesignation2);
      if (newUser.refOrganization1 !== undefined)
        dataMap.set("RefOrganization1", newUser.refOrganization1);
      if (newUser.refOrganization2 !== undefined)
        dataMap.set("RefOrganization2", newUser.refOrganization2);
      let fileMap: Map<string, string> = new Map();
      if (newUser.CEOPhoto)
        fileMap.set("CEOPhoto", newUser.CEOPhoto[0]);
      if (newUser.TrusteeorManagementProfile !== undefined)
        fileMap.set(
          "TrusteeorManagementProfile",
          newUser.TrusteeorManagementProfile[0]
        );
      if (newUser.OrganizationInfoOrBrouche)
        fileMap.set(
          "OrganizationInfoOrBrouche",
          newUser.OrganizationInfoOrBrouche[0]
        );
      if (newUser.OrganizationLogo )
        fileMap.set("OrganizationLogo", newUser.OrganizationLogo[0]);
      if (newUser.ProofofTotalContribution !== undefined)
        fileMap.set(
          "ProofofTotalContribution",
          newUser.ProofofTotalContribution[0]
        );
      if (newUser.GivingTrackRecord )
        fileMap.set("GivingTrackRecord", newUser.GivingTrackRecord[0]);

      var obj1 = newUser.DonorOrgContactPersonJson;
      var contactObj = JSON.stringify(obj1);
      dataMap.set("DonorOrgContactPersonJson", contactObj);
      console.log(dataMap);


      const saveandnextDonorOrg = await agent.DonorOrg.saveandnextDonorOrg(
        dataMap,
        fileMap
      );

      runInAction(() => {
        this.savedandnextDonorOrg = saveandnextDonorOrg;
        //console.log(this.savedandnextDonorOrg);
        // if (saveODPartner === -1) {
        //   toast.error("Email id Already Registered");
        // }
        this.loadingUsers = false;
      });
      return saveandnextDonorOrg;
    } catch (ex) {
      runInAction(() => {
        this.loadingUsers = false;
      });
      //   toast.error(ex?.data.errors.message);
      throw ex;
    }
  };
}


