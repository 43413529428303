import React from 'react';
import ReactApexChart from 'react-apexcharts';
const Areabar = (props) => {
    const [state] = React.useState({
        series: [],
        options: []
    });
    const partnerareadashboard = JSON.parse(props.areagraph);
    const partnerareasum = partnerareadashboard.assessmentArea?.map(a => a.areaSum)
        .reduce((prev, current) => prev.concat(current), [])
        .map((column, index) => {
            return column
        });
    const areanamesum = Object.values(partnerareasum.reduce((acc, item) => {
        if (!acc[item.areaName]) acc[item.areaName] = {
            name: item.areaName,
            data: []
        };
        acc[item.areaName].data.push(item.sum !=0 ? item.sum : 0.01);
         
        return acc;
    }, {}))
    state.series = areanamesum;
    state.options = {
        chart: {
            id: "basic-bar",
            type: 'bar',
            background: '#F5FCFF'

        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '100%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'bottom'
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "20px",
                fontWeight: 'bold',
            },
            textAnchor: 'start',

            formatter: function (val, opt) {
                if(val===0.01){
                    val=0
                }
                return opt.w.globals.seriesNames[opt.seriesIndex] + ":  " + val + "%"
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },

        stroke: {
            // show: true,
            // width: 2,
            // colors: ['transparent']
        },
        xaxis: {
            categories: partnerareadashboard.assessmentArea.map((x) => {
                return x.date
            }),
            title: {
                text: 'Score',
            },
        },
        yaxis: {
            title: {
                text: 'Assessments'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }
    };
    return (
        <div>
            <div id="chart">
                <h5 style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}>Individual Compliance Area Performance Chart</h5>
                <ReactApexChart options={state.options} series={state.series} type="bar" />
                {state.options.length === 0}
                {state.series.length === 0}
            </div>
            <div id="html-dist"></div>
        </div>

    );

}
export default (Areabar);