import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { toast } from "react-toastify";
import {
  Button,
  Segment,
  Header,
  Icon,
  Form,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { IAreaOfAssessmentValues } from "../../../../app/models/areaOfAssessment";
// import SelectInput from "../../../app/common/form/SelectInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import CreateEditArea from "./CreateEditArea";
import DisableArea from "./DisableArea";

const AreaList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getArea,
    areaMasterDropdownValues,
    getAreaFromId,
    loadingArea,
  } = rootStore.areaOfAssessmentStore;
  const [selectedAreaId, setSelectedAreaId] = useState<any>(-1);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { openModal } = rootStore.modalStore;
  useEffect(() => {
    getArea();
  }, [getArea]);

  return (
    <FinalForm
      onSubmit={() => {}}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <br />
          <br />
          <Segment style={{ marginRight: "70px" }}>
            <Segment color="violet">
              <Header as="h4" icon textAlign="center">
                <Header.Content>AREA OF ASSESSMENT</Header.Content>
              </Header>
            </Segment>

            <Form>
              {/* {sectorList && sectorList.map((topic) => (
             
           ))} */}

              <Button
                color="blue"
                content="Create Area"
                floated="right"
                onClick={() => {
                  openRightPanel("Create Area", <CreateEditArea />);
                }}
              />

              {/* <Field component={SelectInput} fluid placeholder='Select Sector' name="sectorId"
                           search selections={"true"}  options={sectorDropdownValues}  /> */}

              <Dropdown
                search
                selection
                options={areaMasterDropdownValues}
                loading={loadingArea}
                placeholder="Select Area"
                onChange={(event, data: DropdownProps) => {
                  if (data.value) setSelectedAreaId(data.value);
                }}
              ></Dropdown>

              <br />
              <br />

              <Button.Group attached="bottom">
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    openRightPanel(
                      "Edit Area",
                      <CreateEditArea
                        editArea={getAreaFromId(selectedAreaId)}
                      />
                    );
                  }}
                >
                  <Icon name="edit" />
                  View & Edit
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    if (selectedAreaId === "") {
                      toast.error("Please select from dropdown");
                    } else {
                      let area: IAreaOfAssessmentValues = getAreaFromId(
                        selectedAreaId
                      );

                      openModal(
                        `${area.isActive ? "Disable" : "Enable"} Area`,
                        <DisableArea disableEnableArea={area} />
                      );
                    }
                  }}
                >
                  <Icon name="trash" />
                  Disable & Enable{" "}
                </Button>
              </Button.Group>
            </Form>
          </Segment>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(AreaList);
