import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { observer } from 'mobx-react-lite';

function AreawiseOverallAssessment(props) {
  const admindashboard = JSON.parse(props.area);
  const areasum = admindashboard && admindashboard.map(a => a.areaCount)
    .reduce((prev, current) => prev.concat(current), [])
    .map((column, index) => {
      return column
    });
  const areanamesum = Object.values(areasum.reduce((acc, item) => {
    if (!acc[item.areaName]) acc[item.areaName] = {
      name: item.areaName,
      data: []
    };
    acc[item.areaName].data.push(item.count);
    return acc;
  }, {}))
  const [state] = React.useState({
    series: areanamesum,
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '75%',

          dataLabels: {
            position: 'bottom'
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
        },
        textAnchor: 'start',

        formatter: function (val, opt) {
          return opt.w.globals.seriesNames[opt.seriesIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        categories: admindashboard && admindashboard.map(
          (x) => {
            return x.year
          }
        ),

        title: {
          text: 'Count',
        },
      },
      yaxis: {
        title: {
          text: 'Year'
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      fill: {
        opacity: 1
      },

    },
  });
  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );

} export default observer(AreawiseOverallAssessment);