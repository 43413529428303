import {
  Form,
  Button,
  Step,
  Segment,
  Header,
  Grid,
  Icon,
} from "semantic-ui-react";
import React, { useState, Fragment, useContext, useEffect } from "react";
import Helmet from "../../../component/common/Helmet";
import HeaderThree from "../../../component/header/HeaderThree";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextAreaTab from "../../../app/common/form/TextAreaTab";
import { FORM_ERROR } from "final-form";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
  matchesField,
  matchesPattern,
} from "revalidate";
import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
} from "../../../app/models/registrationOTP";
import { DonorOrgRegisterValues } from "../../../app/models/donorOrg";
import FileUpload from "../../../app/common/form/FileUpload";
import TextInputTab from "../../../app/common/form/TextInputTab";
import SelectInput from "../../../app/common/form/SelectInput";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import SelectInputTab from "../../../app/common/form/SelectInputTab";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { observer } from "mobx-react-lite";
import CheckInputSimple from "../../../app/common/form/CheckInputSimple";
import Visibility from "@material-ui/icons/Visibility";

export const DonorOrgRegistration: React.FC<{
  createUser?: DonorOrgRegisterValues;
}> = ({ createUser }) => {
  const rootStore = useContext(RootStoreContext);
  const {registerationDone , saveandnextDonorOrgDetails, donorDetail} = rootStore.donorOrgStore;
  const [state] = React.useState({
    mobile: "",
  });

  const [save, saveData] = useState(false);
  const [formData, setFormData] = useState(donorDetail);
  
  const Title = [
    { text: "Dr.", value: "Dr.", key: 1 },
    { text: "Rev.", value: "Rev.", key: 2 },
    { text: "Fr.", value: "Fr.", key: 3 },
    { text: "Ps.", value: "Ps.", key: 4 },
    { text: "Sis.", value: "Sis.", key: 5 },
    { text: "Mr.", value: "Mr.", key: 6 },
    { text: "Ms.", value: "Ms.", key: 7 },
    { text: "Mrs.", value: "Mrs.", key: 8 },
  ];



  const [preferredWork, setPreferredWork] = useState("Pastrol Work");
  const PreferredWorks = [
    { text: "Pastrol Work", value: "Pastrol Work", key: 1 },
    { text: "Evangelism", value: "Evangelism", key: 2 },
    {
      text: "Mission to unreached pincode",
      value: "Mission to unreached pincode",
      key: 3,
    },
    { text: "Medical", value: "Medical", key: 4 },
    { text: "Education to the poor", value: "Education to the poor", key: 5 },
    { text: "Literacy Program", value: "Literacy Program", key: 6 },
    { text: "Orphans", value: "Orphans", key: 7 },
    {
      text: "Blind/Deaf/Physically Challenged",
      value: "Blind/Deaf/Physically Challenged",
      key: 8,
    },
    { text: "Prisoners", value: "Prisoners", key: 9 },
    { text: "Vocational Training", value: "Vocational Training", key: 10 },
    { text: "Leadership Training", value: "Leadership Training", key: 11 },
    { text: "Theological Training", value: "Theological Training", key: 12 },
    { text: "Literature Ministry", value: "Literature Ministry", key: 13 },
    { text: "Outreach to Students", value: "Outreach to Students", key: 14 },
    {
      text: "Church construction program",
      value: "Church construction program",
      key: 15,
    },
    { text: "Mass Media", value: "Mass Media", key: 16 },
    {
      text: "Pioneer Bible Translation to unknown Language",
      value: "Pioneer Bible Translation to unknown Language",
      key: 17,
    },
    { text: "Others", value: "Others", key: 18 },
  ];
  const [represent, setRepresent] = useState("None");
  const Representing = [
    { text: "None", value: "None", key: 1 },
    { text: "Church", value: "Church", key: 2 },
    { text: "Foundation", value: "Foundation", key: 3 },
    { text: "Charity", value: "Charity", key: 4 },
    { text: "Business/Company", value: "Business/Company", key: 5 },
  ];

  const Giving = [
    { text: "1000-5000", value: "1000-5000", key: 1 },
    { text: "5000-20000", value: "5000-20000", key: 2 },
    { text: "20000-50000", value: "20000-50000", key: 3 },
    { text: "50000-100000", value: "50000-100000", key: 4 },
    { text: "Above 100000", value: "Above 100000", key: 5 },
  ];

  const Frequency = [
    { text: "Monthly", value: "Monthly", key: 1 },
    { text: "Quarterly", value: "Quarterly", key: 2 },
    { text: "Half-Yearly", value: "Half-Yearly", key: 3 },
    { text: "Annual", value: "Annual", key: 4 },
  ];

  const Denomination = [
    { text: "Ecumenical", value: "Ecumenical", key: 1 },
    { text: "Evangelical", value: "Evangelical", key: 2 },
    { text: "Pentecostal", value: "Pentecostal", key: 3 },
    { text: "Orthodox", value: "Orthodox", key: 4 },
  ];

  const validateReference = combineValidators({
    refTitle1: isRequired("Title"),
    refTitle2: isRequired("Title"),
    refFirstName1: isRequired("First Name"),
    refFirstName2: isRequired("First Name"),
    refLastName1: isRequired("Last Name"),
    refLastName2: isRequired("Last Name"),
    refDesignation1: isRequired("Designation"),
    refDesignation2: isRequired("Designation"),
    refMobile1: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),

      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
    refMobile2: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })

    )(),
    refOrganization2: isRequired("Organisation"),
    refOrganization1: isRequired("Organisation"),
    refEmail2: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),
    refEmail1 : composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),
  })
  const otp = combineValidators({
    MobileContactNumber: composeValidators(
      isRequired({ message: "CEO Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
   
  })
  const GivingPreference = combineValidators({
    MyPreferedState: isRequired("Preffered State"),
    MyPreferedDistrict: isRequired("Preffered District"),
    MyPreferedLanguage: isRequired("Preffered Language"),
    GivingFrequence: isRequired("Giving Frequency"),
  })
  const validateSector = combineValidators({
    MajorActivities: isRequired("Major Activities"),
    OrganizationLogo: isRequired("Organisation Logo"),
  })
  const validateCommitee = combineValidators({
   // CEODesignation: isRequired("Designation"),
    CEOFirstName: isRequired("First Name"),
    CEOTitle: isRequired("Title"),
    CEOLastName: isRequired("Last Name"),
    NumberOfCommiteeMember: isRequired("Number of commitee Member"),
   TrusteeorManagementProfile: isRequired(
       "Trustee or Management Profile"
     ),
    CEODesignation: isRequired("Designation"),
  });

  const validate = combineValidators({
    OrganizationName: isRequired("Organisation Name"),
    CommunicationAddress: isRequired("Communication Address"),
    OfficeContactumber: isRequired("Office Contact Number"),
    WebsiteAddress: isRequired("Website Address"),
    //OrganizationLogoFile: isRequired("Organisation LOGO"),
    Country: isRequired("Country"),
    YearOfInception: composeValidators(
      isRequired({ message: "Year of Inception is required" }),
      isNumeric({ message: "Numbers only allowed" }), matchesPattern(
        new RegExp("^[0-9]{4}$")
      )({
        message: "Accepts 4 digits only",
      })
    )(),
    Pincode: composeValidators(
      isRequired({ message: "Pincode is required" }),
      isNumeric({ message: "Numbers only allowed" }),
    )(),
    Password: composeValidators(
      isRequired({ message: "Please enter the Password" }),
      matchesPattern(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        )
      )({
        message:
          "Password must contain at least six characters, including uppercase & lowercase letters, special character and numbers",
      })
    )(),
    ConfirmPassword: composeValidators(
      isRequired({ message: "Please enter the Confirm Password" }),
      matchesField("Password", "Preferred Password")({
        message: "Password and Confirm Password must be same",
      })
    )(),
    OfficialEmailId: composeValidators(
      isRequired("Official EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Official EmailId",
      })
    )(),
  });
  const { createDonorOrg, submitDonor } = rootStore.donorOrgStore;
  const {
    sendOTP,
    verifyOtp,
    loadingSendOtp,
    loadingVerifyOtp,
    getVerifyByMobileOrEmail,verifyByMobile
  } = rootStore.registrationOTPStore;
  const { getState, stateDropdownValues } = rootStore.stateStore;

  useEffect(() => {
    getState();
    getVerifyByMobileOrEmail();
  }, [getState,getVerifyByMobileOrEmail]);

  const scrollTop = () => 
  {
    document?.querySelector('body')?.scrollTo(0,0)
  }
  const steps = [
    {
      key: "verification",
      icon: "mobile alternate",
      active: rootStore.registrationOTPStore.index === 1,
      title: "Verification",
      description: "",
    },
    {
      key: "basic",
      active: rootStore.registrationOTPStore.index === 2,
      disabled: rootStore.registrationOTPStore.index < 2,
      icon: "info",
      title: "Basic Information",
      description: "Your Basic Information",
    },
    {
      key: "profile",
      active: rootStore.registrationOTPStore.index === 3,
      disabled: rootStore.registrationOTPStore.index < 3,
      icon: "opera",
      title: "Organisational Profile",
      description: "Your Organisational Profile Details",
    },
    {
      key: "leadership",
      active: rootStore.registrationOTPStore.index === 4,
      disabled: rootStore.registrationOTPStore.index < 4,
      icon: "user",
      title: "Leadership Team Profile",
      description: "Your Leadership Team Details",
    },
    {
      key: "giving",
      active: rootStore.registrationOTPStore.index === 5,
      disabled: rootStore.registrationOTPStore.index < 5,
      icon: "user",
      title: " Giving Preference",
      description: "Your Giving Preference",
    },
    {
      key: "reference",
      active: rootStore.registrationOTPStore.index === 6,
      disabled: rootStore.registrationOTPStore.index < 6,
      icon: "rendact",
      title: "Reference",
      description: "Your Reference Details",
    },
  ];
  
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }
  const[checkbox1,setCheckbox]=useState(false);
  const [address] = React.useState({
     CommunicationAddress:"",
     RegisteredAddress:""
  });
  let isMobile=verifyByMobile;

  return (
    <Fragment>
      <Helmet pageTitle="CIM - Organisation Development" />

      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />
      <br />
      <div className="App-paragraph">
        <Header as="h4" icon textAlign="center">
          <Header.Content>Donor Organisations</Header.Content>
        </Header>
      </div>

      <br />
      <Segment>
        <Step.Group
          size="tiny"
          attached
          items={steps}
          style={{
            width: "80%",
          }}
        />
        <br />
        <br />
        <FinalForm
          // onSubmit = {() =>{}}
          onSubmit={async (values: IRegistrationVerifyOTP) => {
            values.MobileContactNumber =
              window.localStorage.getItem("mobile") + "";
            let phoneNumber = new DonorOrgRegisterValues();
          
            phoneNumber.MobileContactNumber = values.MobileContactNumber.substring(3);
            console.log(phoneNumber)
            await verifyOtp(values).catch((error) => ({
              [FORM_ERROR]: error,
            }));
          }}
          render={({
            handleSubmit,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form onSubmit={handleSubmit} error>
              {rootStore.registrationOTPStore.index === 1 && (
                <Fragment>
                  <FinalForm
                    // onSubmit = {() =>{}}
                    onSubmit={(values: IRegistrationSendOTP) => {
                      state.mobile = values.MobileContactNumber;
                      sendOTP(values).catch((error) => ({
                        [FORM_ERROR]: error,
                      }));
                      window.localStorage.setItem(
                        "mobile",
                        values.MobileContactNumber
                      );
                    }}
                    render={({
                      handleSubmit: donorSubmit,
                      form,
                      submitting,
                      submitError,
                      invalid,
                      pristine,
                      dirtySinceLastSubmit,
                      error,
                    }) => (
                      <Form
                        onSubmit={async (event) => {
                          await donorSubmit(event);
                        }}
                        error
                      >
                        <Form.Field inline>
                          <Grid>
                            <Grid.Column width={5}>
                              <label
                                className="label-field"
                                style={{ marginLeft: "130px" }}
                              >
                                <b> {isMobile === true ? "Registered Mobile No" : "Registered Email ID"} </b>
                              </label>
                            </Grid.Column>
                            <Grid.Column width={isMobile === true ? 5 : 4}>
                              <Field
                                component={isMobile === true ? PhoneNumberInput : TextInputTab}
                                name="MobileContactNumber"
                                style={{ width: "190px" }}
                                placeholder={isMobile === true ? "Mobile No" : "Email ID"}
                              />
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Button
                                content="Send OTP"
                                loading={loadingSendOtp}
                                color="blue"
                              ></Button>
                            </Grid.Column>
                          </Grid>
                        </Form.Field>
                      </Form>
                    )}
                  />

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "130px" }}
                        >
                          Enter OTP
                        </label>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          component="input"
                          name="Otp"
                          style={isMobile === true ? {marginLeft: "35px"} :{ marginLeft: "0px" }}
                          placeholder="OTP"
                        />
                      </Grid.Column>
                      <Grid.Column width={2}></Grid.Column>
                    </Grid>
                  </Form.Field>

                  <Form.Field inline>
                    <Grid>
                      <Grid.Column width={5}> </Grid.Column>
                      <Button
                        // disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                        className="actionButton"
                        style={{ marginLeft: "51px", marginTop: "10px" }}
                        content="Verify"
                        validate = {otp}
                        loading={loadingVerifyOtp}
                        color="blue"
                        
                      ></Button>
                    </Grid>
                  </Form.Field>

                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
        <FinalForm
          initialValues={createUser}
          onSubmit={(values: DonorOrgRegisterValues) => {
            values.MobileContactNumber = state.mobile;
       
            if(save){
              saveandnextDonorOrgDetails(values);
              rootStore.registrationOTPStore.index =
              rootStore.registrationOTPStore.index + 1;
              saveData(false);
              setFormData(values);
            }
            else if(rootStore.registrationOTPStore.index < 3)
              {
                
                saveandnextDonorOrgDetails(values);
                rootStore.registrationOTPStore.index = (rootStore.registrationOTPStore.index + 1)
                setFormData(values);
              }
              else{

                createDonorOrg(values)

                .then(() => {
                  // registerationDone(values);
                  setFormData(new DonorOrgRegisterValues());
                  registerationDone(values);
                
                })
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }));
      }
          
          }}
          //validate={rootStore.registrationOTPStore.index === 3 ? validate : validateSector}
          validate={rootStore.registrationOTPStore.index === 2 ? validate : rootStore.registrationOTPStore.index === 3 ?
            validateSector : rootStore.registrationOTPStore.index === 4 ? validateCommitee : rootStore.registrationOTPStore.index === 5?
            GivingPreference:
              validateReference  }
          render={({
            handleSubmit,
            form,
            submitting,
            submitError,
            invalid,
            pristine,
            dirtySinceLastSubmit,
            error,
          }) => (
            <Form
              onSubmit={async (event) => {
                await handleSubmit(event);
                //form.reset();
              }}
            >
              {rootStore.registrationOTPStore.index === 2 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}></Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                          loading={submitting}
                          style={{ marginLeft: "815px" }}
                          onClick={() => {
                            saveData(true);
                          }}
                          content="Save & Next"
                          color="blue"
                         // validate={validate}
                          type="submit"
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Organisation Name{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OrganizationName"
                                placeholder="Name"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Year of Inception{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="YearOfInception"
                                placeholder="Year of Inception"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>



                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                Office Contact Number
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OfficeContactumber"
                                placeholder="Office Contact Number"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>


                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                Official Email ID{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="OfficialEmailId"
                                placeholder="Official Email ID"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Preferred Password {" "} <span className="mandatory-field"> * </span> <br/>(min 8 digit with uppercase, lowercase, numbers & special charc)
                                  </label>
                                 
                                </Grid.Column>
                                <Grid.Column width={7}>
                                  <Field
                                   component={TextInputTab}
                                    name="Password"
                                    placeholder="Password"
                                    type={values.showPassword ? "text" : "password"}
                                    onChange={handlePasswordChange("password")}
                                    value={values.password}
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                 
                                 </Grid.Column>
                                 <Grid.Column width={1} style={{marginTop:'15px'}}>
                                     <InputAdornment position="end">
                                        <IconButton
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Confirm Password{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="ConfirmPassword"
                                placeholder="Confirm Password"
                                type="Password"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Registered Address{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextAreaTab}
                                    name="RegisteredAddress"
                                    inputstyle={{
                                      width: "250px",
                                      height: "10px",
                                    }}
                                    placeholder="Registered Address"
                                    onChange={(event)=>{
                                      address.RegisteredAddress = event.target.value  
                                  } }
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                     Same as Registered Address{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                <Field
                                    component={CheckInputSimple}
                                    name="addressCheck"
                                    onChange={(value:boolean)=>{
                                    setCheckbox(value)
                                    console.log(value)
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Communication Address{" "}
                                  </label>  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextAreaTab}
                                    name="CommunicationAddress"
                                    inputstyle={{
                                      width: "250px",
                                      height: "10px",
                                    }}
                                    value={checkbox1?address.RegisteredAddress:address.CommunicationAddress}
                                    defaultValue={checkbox1?address.RegisteredAddress:address.CommunicationAddress}
                                    placeholder="Communication Address"
                                    onChange={(e:any)=>
                                address.CommunicationAddress=e.target.value
                                    }
                                  />
                                </Grid.Column>
                          </Grid>
                        </Grid.Column>

                        <Grid.Column width={8}>

                          <Grid>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                State{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select State"
                                name="State"
                                search
                                selections={"true"}
                                options={stateDropdownValues}
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                Pincode{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Pincode"
                                placeholder="Pincode"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Country
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="Country"
                                placeholder="Country"
                                inputstyle={{ width: "250px" }}
                              ></Field>
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Website Address{" "}
                              </label>
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            {/* <Form.Field inline> */}
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="WebsiteAddress"
                                style={{ width: "250px" }}
                                placeholder="Website Address"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <Icon
                                style={{ color: "green" }}
                                size="big"
                                name="whatsapp"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="WhatsappNumber"
                                style={{ width: "250px" }}
                                placeholder="Whatsapp No"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <Icon
                                style={{ color: "darkblue" }}
                                size="big"
                                name="facebook"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="FacebookID"
                                style={{ width: "250px" }}
                                placeholder="Facebook ID"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              <Icon
                                style={{ color: "lightblue" }}
                                size="big"
                                name="twitter"
                              />
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="TwitterID"
                                style={{ width: "250px" }}
                                placeholder="Twitter ID"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                              {/* <Form.Field inline> */}
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Other Media Link
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component="input"
                                name="OtherMediaLink"
                                style={{ width: "250px" }}
                                placeholder="Other link"
                              />
                              {/* </Form.Field> */}
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid>


                  <br />
                  <br />
                </Fragment>

              )}
              {rootStore.registrationOTPStore.index === 3 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 2;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                         disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                          className="actionButton"
                          style={{ marginLeft: "815px" }}
                          validate={validateSector}
                          onClick={() => {
                            saveData(true);
                          }}
                          content="Save & Next"
                          color="blue"
                          type="submit"
                          
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Row>
                      <Grid columns={2}>
                        <Grid>
                          <Grid.Row>
                            {/* Left Side Content */}
                            <Grid.Column width={8}>
                              <Grid>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>

                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Vision Statement
                                  </label>
                                </Grid.Column>
                                <br />
                                <br />
                                <Grid.Column width={8}>
                                  <Field
                                    component="textarea"
                                    name="VisionStatement"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Vision Statement"
                                  />
                                </Grid.Column>


                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Mission Statement
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  {/* <br />
                        <br /> */}
                                  <Field
                                    component="textarea"
                                    name="MissionStatement"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Mission Statement"
                                  />
                                </Grid.Column>


                                {/* </Form.Field>
                      <Form.Field inline> */}
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Faith Statement
                                  </label>
                                </Grid.Column>

                                <br />
                                <br />
                                <Grid.Column width={8}>
                                  <Field
                                    component="textarea"
                                    name="FaithStatement"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Faith Statement"
                                  />
                                </Grid.Column>



                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Organisation LOGO
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    name="OrganizationLogo"
                                    style={{ width: "305px" }}
                                    component={FileUpload}
                                  />
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Organisation Info or Brochure{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    name="OrganizationInfoOrBrouche"
                                    style={{ width: "305px" }}
                                    component={FileUpload}
                                  />
                                </Grid.Column>

                              </Grid>
                            </Grid.Column>
                            {/*End of Left Side Content */}
                            {/* Right Side Content */}
                            <Grid.Column width={8}>
                              <Grid>

                                <Grid.Column width={8}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Core Values
                                  </label>
                                </Grid.Column>
                                <br />
                                <br />
                                <Grid.Column width={8}>
                                  <Field
                                    component="textarea"
                                    name="CoreValues"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Core Values"
                                  />
                                </Grid.Column>

                                <Grid.Column width={8}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Objective of the Organisation{" "}
                                  </label>
                                </Grid.Column>
                                <br />
                                <br />
                                <Grid.Column width={8}>
                                  <Field
                                    component="textarea"
                                    name="ObjectiveOfOrganization"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Objective of the Organisation"
                                  />
                                </Grid.Column>

                                <Grid.Column width={8}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    Major Activities/Programs/Services{" "}
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <br />
                                <br />
                                <Grid.Column width={8}>
                                  <Field
                                    component="textarea"
                                    name="MajorActivities"
                                    style={{
                                      width: "450px",
                                      height: "100px",
                                    }}
                                    placeholder="Major Activities/Programs/Services"
                                  />
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                            {/*End of Right Side Content */}

                          </Grid.Row>
                        </Grid>
                      </Grid>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <br />
                </Fragment>
              )}
              {rootStore.registrationOTPStore.index === 4 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 3;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                          disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                          className="actionButton"
                          style={{ marginLeft: "815px" }}
                          //validate={validateCommitee}
                          onClick={() => {
                            saveData(true);
                          }}
                          content="Save & Next"
                          color="blue"
                          type="submit"
                          
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        {/* <Form.Field inline> */}

                        <label
                          className="label-field"
                          style={{ marginLeft: "60px" }}
                        >
                          {" "}
                          CEO /Executive Director /<br />
                          Managing Trustee
                        </label>
                        <span className="mandatory-field"> * </span>

                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          name="CEOTitle"
                          component="select"
                          placeholder="Select Sector"
                        >
                          <option>Title</option>
                          <option>Dr.</option>
                          <option>Rev.</option>
                          <option>Fr.</option>
                          <option>Ps.</option>
                          <option>Sis.</option>
                          <option>Mr.</option>
                          <option>Ms.</option>
                          <option>Mrs.</option>
                        </Field>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="CEOFirstName"
                          style={{ marginLeft: "20px" }}
                          placeholder="CEO First Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="CEOLastName"
                          style={{ marginLeft: "20px" }}
                          placeholder="CEO Last Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="CEOMobile"
                          style={{ marginLeft: "20px" }}
                          placeholder="CEO Mobile No"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="CEOEmailId"
                          style={{ marginLeft: "20px" }}
                          placeholder="CEO Email"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="CEODesignation"
                          style={{ marginLeft: "20px" }}
                          placeholder="CEO Designation"
                        />
                      </Grid.Column>
                      {/* </Form.Field> */}
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={4}>
                        {/* <Form.Field inline> */}
                        <label
                          className="label-field"
                          style={{ marginLeft: "60px" }}
                        >
                          CEO Photo
                        </label>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          name="CEOPhoto"
                          style={{ width: "250px" }}
                          component={FileUpload}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {/* <Button style = {{marginLeft: '20px'}} icon>
        <Icon name='upload'/>
    </Button> */}
                    {/* </Form.Field> */}

                    {/* {inputList.map((x, i) => {
return ( */}
                    <Grid.Row>
                      {/* <Form.Field inline> */}
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "60px" }}
                        >
                          {" "}
                          Contact person for
                          <br />
                          Communication (Option)
                        </label>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          name="DonorOrgContactPersonJson[0].ContactTitle"
                          component="select"
                          placeholder="Title"
                        >
                          <option>Title</option>
                          <option>Dr.</option>
                          <option>Rev.</option>
                          <option>Fr.</option>
                          <option>Ps.</option>
                          <option>Sis.</option>
                          <option>Mr.</option>
                          <option>Ms.</option>
                          <option>Mrs.</option>
                        </Field>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="DonorOrgContactPersonJson[0].ContactFirstName"
                          style={{ marginLeft: "20px" }}
                          /* onChange={e => handleInputChange(e, i)} */ placeholder=" First Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="DonorOrgContactPersonJson[0].ContactLastName"
                          style={{ marginLeft: "20px" }}
                          /* onChange={e => handleInputChange(e, i)} */ placeholder=" Last Name"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="DonorOrgContactPersonJson[0].ContactMobile"
                          style={{ marginLeft: "20px" }}
                          /* onChange={e => handleInputChange(e, i)} */ placeholder="Mobile No"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="DonorOrgContactPersonJson[0].ContactEmailId"
                          style={{ marginLeft: "20px" }}
                          /* onChange={e => handleInputChange(e, i)} */ placeholder="Email"
                        />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Field
                          component="input"
                          name="DonorOrgContactPersonJson[0].ContactDesignation"
                          style={{ marginLeft: "20px" }}
                          /* onChange={e => handleInputChange(e, i)} */ placeholder="Designation"
                        />
                      </Grid.Column>
                      {/* </Form.Field> */}
                    </Grid.Row>
                  </Grid>
                  <br />
                  {/* <Form.Field inline> */}
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "60px" }}
                        >
                          {" "}
                          No of Board of Trustee/
                          <br />
                          Commitee
                        </label>
                        <span className="mandatory-field"> * </span>

                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Field
                          component={TextInputTab}
                          name="NumberOfCommiteeMember"
                          inputstyle={{ width: "250px" }}
                          placeholder="No of Board of Trustee"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {/* </Form.Field> */}

                  {/* <Form.Field inline> */}
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={4}>
                        <label
                          className="label-field"
                          style={{ marginLeft: "60px" }}
                        >
                          Current Board of trustee/
                          <br />
                          Management Profile{" "}
                        </label>
                        <span className="mandatory-field"> * </span>

                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Field
                          name="TrusteeorManagementProfile"
                          style={{ width: "250px" }}
                          component={FileUpload}
                        />
                      </Grid.Column>

                      <Grid.Column width={4}>
                        <Button
                          color="blue"
                          style={{}}
                          href={
                            "https://cimdod.azurewebsites.net/images/8341b4ac-c4f6-4def-a624-b50c363f45b1-Template%20of%20Board%20Members%20List.xlsx"
                          }
                          icon
                        >
                          <Icon name="download" />
                          Sample{" "}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {/* <Grid.Column>
                    <Field
                      name="TrusteeorManagementProfileFile"
                      style={{ marginLeft: "210px", width: "250px" }}
                      component={FileUpload}
                    />
                    </Grid.Column> */}

                  <br />
                  <br />
                </Fragment>
              )}
              {rootStore.registrationOTPStore.index === 5 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 4;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Button
                        disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                          className="actionButton"
                          style={{ marginLeft: "815px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 6;
                          }}
                          content="Save & Next"
                          color="blue"
                          type="submit"
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Grid>
                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Work / Ministry{" "}
                                <span className="mandatory-field"> * </span>
                              </label>

                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="MyPreferedWork"
                                component={SelectInput}
                                onChange={(data: any) => setPreferredWork(data)}
                                options={PreferredWorks}
                                style={{ width: "220px" }}
                                placeholder="MyPreferedWork"
                              ></Field>
                            </Grid.Column>

                            {preferredWork === "Others" && (
                              <Grid.Row>

                                <Grid.Column width={8} style={{ paddingLeft: "113px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Others{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="Others"
                                    inputstyle={{ marginLeft: "13px", width: "220px" }}
                                    placeholder="Others"
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            )}

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Giving Preference{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="My Giving"
                                name="MyGivingPreference"
                                search
                                selections={"true"}
                                options={Giving}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Giving Frequency{" "}
                              </label>
                              <span className="mandatory-field"> * </span>

                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Giving Frequency"
                                name="GivingFrequence"
                                search
                                selections={"true"}
                                options={Frequency}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Church{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="My Preferred Church"
                                name="MyPreferedChurchDomination"
                                search
                                selections={"true"}
                                options={Denomination}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Prefered State{" "}
                              </label>
                              
                              <span className="mandatory-field"> * </span>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={SelectInputTab}
                                placeholder="Select State"
                                name="MyPreferedState"
                                mandate="*"
                                search
                                selections={"true"}
                                options={stateDropdownValues}
                                inputstyle={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred District{" "}
                              </label>
                              <span className="mandatory-field"> * </span>

                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedDistrict"
                                style={{ marginLeft: "96px" }}
                                placeholder="District"
                                inputstyle={{ width: "220px" }}
                              />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                Giving Track Record / Total personal financial
                                contribution to Indian ministries (in last 5yr)
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="GivingTrackRecord"
                                style={{ width: "220px" }}
                                component={FileUpload}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid>


                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Community{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedCommunity"
                                placeholder="Community"
                                inputstyle={{ width: "220px" }}
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Tribe{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedTribe"
                                inputstyle={{ width: "220px" }}
                                placeholder="Tribe"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                My Preferred Language
                              </label>
                              <span className="mandatory-field"> * </span>

                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="MyPreferedLanguage"
                                inputstyle={{ width: "220px" }}
                                placeholder="Language"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                Preferred Pincode{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                component={TextInputTab}
                                name="PreferedPincode"
                                inputstyle={{ width: "220px" }}
                                placeholder="Pincode"
                              />
                            </Grid.Column>

                            <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                              <label
                                className="label-field"
                                style={{ paddingTop: "4px" }}
                              >
                                {" "}
                                I am representing{" "}
                              </label>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Field
                                name="IamPresenting"
                                component={SelectInput}
                                onChange={(data: any) => setRepresent(data)}
                                placeholder="representing"
                                options={Representing}
                                style={{ width: "220px" }}
                              ></Field>
                            </Grid.Column>

                            {represent !== "None" && (
                              <div>
                                <Grid.Row>

                                  <Grid.Column width={8} style={{ paddingLeft: "100px" }}>
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      If representing, proof of total <br /> contribution
                                      (in last 5yr){" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="ProofofTotalContribution"
                                      style={{ width: "241px", marginLeft: "110px" }}
                                      component={FileUpload}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </div>
                            )}

                            {represent !== "None" && preferredWork === "Others" && (
                              <div style={{ width: "1px" }}> </div>
                            )}


                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
              {rootStore.registrationOTPStore.index === 6 && (
                <Fragment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <Button
                          className="actionButton"
                          style={{ marginLeft: "48px" }}
                          onClick={() => {
                            rootStore.registrationOTPStore.index = 5;
                          }}
                          content="Back"
                          color="blue"
                          type="button"
                        />
                      </Grid.Column>

                      <Grid.Column width={6}>


                        <Button
                          disabled={
                            (invalid && !dirtySinceLastSubmit) || pristine
                          }
                          className="actionButton"
                          type="submit"
                          style={{ marginLeft: "815px" }}
                          content="Register"
                          validate = {validateReference}
                          loading={submitDonor}
                          color="blue"
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          {/* <Form.Field inline> */}
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Reference 1
                          </label>
                          <span className="mandatory-field"> * </span>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            name="refTitle1"
                            component={SelectInputTab}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                            placeholder="Title"
                            options={Title}
                            select="Mr."
                          >

                          </Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refFirstName1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refLastName1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refMobile1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refEmail1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refDesignation1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refOrganization1"
                            style={{ marginLeft: "10px" }}
                            placeholder="Organisation"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        {/* <Form.Field inline> */}
                        <Grid.Column width={2}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Reference 2
                          </label>
                          <span className="mandatory-field"> * </span>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            name="refTitle2"
                            component={SelectInputTab}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                            placeholder="Title"
                            options={Title}
                          >

                          </Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refFirstName2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refLastName2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refMobile2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refEmail2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refDesignation2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="refOrganization2"

                            placeholder="Organisation"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  <br />

                  <br />
                  <br />
                  <br />
                </Fragment>
              )}
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};
export default observer(DonorOrgRegistration);
