import {
  Form,
  Button,
  Step,
  Segment,
  Header,
  Grid,
  Icon,
} from "semantic-ui-react";
import React, { Fragment, useContext, useState } from "react";
import HeaderThree from "../../../component/header/HeaderThree";
import Helmet from "../../../component/common/Helmet";
import { Form as FinalForm, Field } from "react-final-form";
import { OdPartnerRegisterValues } from "../../../app/models/partner";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { FORM_ERROR } from "final-form";
import {
  IRegistrationSendOTP,
  IRegistrationVerifyOTP,
} from "../../../app/models/registrationOTP";
import { useEffect } from "react";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
  matchesField,
  matchesPattern,
} from "revalidate";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import TextInputTab from "../../../app/common/form/TextInputTab";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import SelectInputTab from "../../../app/common/form/SelectInputTab";
import FileUpload from "../../../app/common/form/FileUpload";
import { configure } from "mobx";
import TextAreaTab from "../../../app/common/form/TextAreaTab";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import CheckInputSimple from "../../../app/common/form/CheckInputSimple";

configure({
  enforceActions: "never",
});


const ODRegistration = (props) => {


  const handlePostSms = (event) => {
    
  }
  console.log(props.registrationId);
  const [state] = React.useState({
    mobile: "",
  });

  const Title = [
    { text: "Dr.", value: "Dr.", key: 1 },
    { text: "Rev.", value: "Rev.", key: 2 },
    { text: "Fr.", value: "Fr.", key: 3 },
    { text: "Ps.", value: "Ps.", key: 4 },
    { text: "Sis.", value: "Sis.", key: 5 },
    { text: "Mr.", value: "Mr.", key: 6 },
    { text: "Ms.", value: "Ms.", key: 7 },
    { text: "Mrs.", value: "Mrs.", key: 8 },
  ];

  const rootStore = useContext(RootStoreContext);
  const validateReference = combineValidators({
    RefTitle1: isRequired("Title"),
    RefTitle2: isRequired("Title"),
    RefFirstName1: isRequired("First Name"),
    RefFirstName2: isRequired("First Name"),
    RefLastName1: isRequired("Last Name"),
    RefLastName2: isRequired("Last Name"),
    RefDesignation1: isRequired("Designation"),
    RefDesignation2: isRequired("Designation"),
    RefMobile1: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),

      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
    RefMobile2: composeValidators(
      isRequired({ message: "Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })

    )(),
    RefOrganization1: isRequired("Organisation"),
    RefOrganization2: isRequired("Organisation"),
    RefEmail1: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),
    RefEmail2: composeValidators(
      isRequired("EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Reference EmailId",
      })
    )(),

  })
  const otp = combineValidators({
    MobileContactNumber: composeValidators(
      isRequired({ message: "CEO Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
  })
  const valisateSector = combineValidators({
    SectorId: isRequired("Sector"),
    SubsectorId: isRequired("Subsector"),
    MajorActivities: isRequired("Major Activities")
  })
  const validateCommitee = combineValidators({
    NumberOfCommiteeMember: isRequired("Number of commitee Member"),
   TrusteeorManagementProfileFile: isRequired(
       "Trustee or Management Profile"
     ),
    ContactMobile: composeValidators(
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
    ContactEmailId: composeValidators(
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Contact EmailId",
      })
    )(),
    FounderMobile: composeValidators(
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
    FounderEmailId: composeValidators(
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Founder EmailId",
      })
    )(),

    CEOFirstName: isRequired("First Name"),
    CEOTitle: isRequired("Title"),
    CEOLastName: isRequired("Last Name"),
    CEOMobile: composeValidators(
      isRequired({ message: "CEO Mobile number is required" }),
      isNumeric({ message: "Numbers only allowed" }),
      matchesPattern(
        new RegExp("^[0-9]{10}$")
      )({
        message: "Accepts 10 digits only",
      })
    )(),
    CEOEmailId: composeValidators(
      isRequired("CEO EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid CEO EmailId",
      })
    )(),
    CEODesignation: isRequired("Designation"),
  });
  const validate = combineValidators({
    CommunicationAddress: isRequired("Communication Address"),
    OrganizationName: isRequired("Organisation Name"),
    YearOfInception: composeValidators(
      isRequired({ message: "Year of Inception is required" }),
      isNumeric({ message: "Numbers only allowed" }), matchesPattern(
        new RegExp("^[0-9]{4}$")
      )({
        message: "Accepts 4 digits only",
      })
    )(),



    Pincode: composeValidators(
      isRequired({ message: "Pincode is required" }),
      isNumeric({ message: "Numbers only allowed" }),
    )(),
    ConfirmPassword: composeValidators(
      isRequired({ message: "Please enter the Confirm Password" }),
      matchesField("Password", "Preferred Password")({
        message: "Password and Confirm Password must be same",
      })
    )(),
    Password: composeValidators(
      isRequired({ message: "Please enter the Password" }),
      matchesPattern(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        )
      )({
        message:
          "Password must contain at least 8 characters, including uppercase & lowercase letters, special character and numbers",
      })
    )(),

    OfficialEmailId: composeValidators(
      isRequired("Official EmailId"),
      matchesPattern(
        new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
      )({
        message: "Please enter the valid Official EmailId",
      })
    )(),

  });

  const {
    sendOTP,
    verifyOtp,
    loadingSendOtp,
    loadingVerifyOtp,
    getVerifyByMobileOrEmail,verifyByMobile
  } = rootStore.registrationOTPStore;
  const { getActiveSector, sectorDropdownValues } = rootStore.sectorStore;
  const { getState, stateDropdownValues } = rootStore.stateStore;

  const {
    subSectorDropdownValues,
    getSubSectorFromSectorId, getActiveSubSector
  } = rootStore.subSectorStore;
  const {
    getFederationName,
    federationNameDropdownValues,
  } = rootStore.federationStore;
  const { getOdPartnerById, odPartnerDetail, savePartnerDetails, savedPartner, 
    IsRegister, upsertPartner, getSavedPartnerDetails , registerationDone , registerationAdmin } = rootStore.partnerStore;
  const [save, saveData] = useState(false);
  
  console.log(savedPartner?.savePartnerDetailsId);
  //const {props.registrationId } = rootStore.commonStore
  const registrationId = props.registrationId !== undefined ? props.registrationId : 0
  useEffect(() => {
    getActiveSector();
    getState();
    getFederationName();
    getActiveSubSector();
    if(registrationId>0){
      getOdPartnerById(registrationId);
    }
    getVerifyByMobileOrEmail();
                      

  }, [getActiveSector, getState, getFederationName, getActiveSubSector, getOdPartnerById,getVerifyByMobileOrEmail]);

  const handleSectorChange = (event) => {
    getSubSectorFromSectorId(event.value);
  };

  //let phone = new OdPartnerRegisterValues();
  let phone = "";

  const scrollTop = () => 
  {
    document?.querySelector('body')?.scrollTo(0,0)
  }

  let isMobile=verifyByMobile;

  const [formData, setFormData] = useState(odPartnerDetail);
  const steps = [
    {
      key: "verification",
      icon: "mobile alternate",
      active: rootStore.registrationOTPStore.index === 1,
      title: "Verification",
      description: "",
    },
    {
      key: "basic",
      active: rootStore.registrationOTPStore.index === 2,
      disabled: rootStore.registrationOTPStore.index < 2,
      icon: "info",
      title: "Basic Info",
      description: "Your Basic Information",
    },
    {
      key: "profile",
      active: rootStore.registrationOTPStore.index === 3,
      disabled: rootStore.registrationOTPStore.index < 3,
      icon: "opera",
      title: "Organisational Profile",
      description: "Your Organisational Profile Details",
    },
    {
      key: "leadership",
      active: rootStore.registrationOTPStore.index === 4,
      disabled: rootStore.registrationOTPStore.index < 4,
      icon: "user",
      title: "Leadership Team Profile",
      description: "Your Leadership Team Details",
    },
    {
      key: "reference",
      active: rootStore.registrationOTPStore.index === 5,
      disabled: rootStore.registrationOTPStore.index < 5,
      icon: "rendact",
      title: "Reference",
      description: "Any 2 leaders",
    },
  ];
    
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }
  const[checkbox1,setCheckbox]=useState(false);
  const [address] = React.useState({
     CommunicationAddress:"",
     RegisteredAddress:""
  });

  return (
    <Fragment>
      {props.registrationId === undefined &&
        <div>
          <Helmet pageTitle="CIM - Organisation Development" />
          <HeaderThree
            homeLink="https://www.cimindia.in/"
            logo="symbol-dark"
            color="color-black"
          />
        </div>
      }
      <br />
      <div className="App-paragraph">
        <Header as="h4" icon textAlign="center">
          <Header.Content>OD Partner Organisation</Header.Content>
        </Header>
      </div>
      <br />
      <Segment>
        <div className="App-backimage">
          <Step.Group
            attached
            items={steps}
            style={{
              width: "80%",

            }}
          />
          <br />
          <br />

          <FinalForm
            onSubmit={async (values: IRegistrationVerifyOTP) => {
              values.MobileContactNumber =
                window.localStorage.getItem("mobile") + "";
                let phoneNumber = new OdPartnerRegisterValues();
                phoneNumber.MobileContactNumber = values.MobileContactNumber;
                getSavedPartnerDetails(phoneNumber)
              await verifyOtp(values).catch((error) => ({
                [FORM_ERROR]: error,
              }));
            }}
            // validate = {otp}
            render={({
              handleSubmit,
              invalid,
              pristine,
              dirtySinceLastSubmit,
            }) => (
              <Form onSubmit={handleSubmit} error>
                {rootStore.registrationOTPStore.index === 1 && (
                  <Fragment>
                    {/* <Grid style={{ marginLeft: "200px" }} columns={1}>
                      <Grid.Column> */}
                    <FinalForm
                      onSubmit={(values: IRegistrationSendOTP) => {
                        state.mobile = values.MobileContactNumber;
                        console.log(values.MobileContactNumber)
                        sendOTP(values).catch((error) => ({
                          [FORM_ERROR]: error,
                        }));
                        window.localStorage.setItem(
                          "mobile",
                          values.MobileContactNumber
                        );
                      }}
                      render={({
                        handleSubmit: beneficiarySubmit,
                        submitting,
                      }) => (
                        <Form
                          onSubmit={async (event) => {
                            await beneficiarySubmit(event);
                            
                          }}
                          error
                        >
                          <Form.Field inline>
                            <Grid>
                              <Grid.Column width={5}>
                                <label
                                  className="label-field"
                                  style={{ marginLeft: "130px" }}
                                >
                                  {" "}
                                {isMobile === true ? "Mobile No" : "Email ID"}
                                </label>
                              </Grid.Column>
                              <Grid.Column width={isMobile === true ? 5 : 4 }> 
                                <Field
                                  component={isMobile === true ? PhoneNumberInput : TextInputTab} 
                                  name="MobileContactNumber"
                                  style={{ width: "190px" }}
                                  placeholder={isMobile === true ? "Mobile No" : "Email ID"}
                                  onFocus = {(event : any) =>{
                                    if(event?.target.value.length === 10)
                                    phone = event?.target.value
                                }}
                                />
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <Button
                                  content="Send OTP"
                                  loading={loadingSendOtp}
                                  color="blue"
                                ></Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                        </Form>
                      )}
                    />

                    <Form.Field inline>
                      <Grid>
                        <Grid.Column width={5}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "130px" }}
                          >
                            Enter OTP
                          </label>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field
                            component="input"
                            name="Otp"
                            style={isMobile === true ? {marginLeft: "35px"} :{ marginLeft: "0px" }} 
                            placeholder="OTP"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                      </Grid>
                    </Form.Field>

                    <Form.Field inline>
                      <Grid>
                        <Grid.Column width={5}></Grid.Column>
                        <Button
                          // disabled={
                          //   (invalid && !dirtySinceLastSubmit) || pristine
                          // }
                          className="actionButton"
                          style={{ marginLeft: "51px", marginTop: "10px" }}
                          content="Verify"
                          color="blue"
                          validate={otp}
                          loading={loadingVerifyOtp}
                         // onClick = {() => {
                            
                            //getSavedPartnerDetails(phone)
                         // }}
                        ></Button>
                        
                        
                      </Grid>
                    </Form.Field>
                    <br />
                    <br />
                    <Segment inverted color='blue' style ={{width:"100%", height:"80px",borderRadius: 25}}>
                          <label color='blue' style ={{marginLeft:"500px" , fontFamily:"verdana"}}> Welcome to Christian Institute of Management! </label>
                          <label color='blue' style ={{marginLeft:"300px",fontFamily:"verdana"}}> To complete registration process: You need to provide brief details of your organisation in just 15 minutes </label>
                         </Segment>
                      
                    <br />
                  </Fragment>
                )}
              </Form>
            )}
          />
         
          <FinalForm
            initialValues={{

              Password: props.registrationId === undefined ? "" : "Help@12345",
              ConfirmPassword: props.registrationId === undefined ? "" : "Help@12345",
              PartnerId: odPartnerDetail?.partnerBasicDetailId,
              OrganizationName: odPartnerDetail?.organizationName,
              YearOfInception: odPartnerDetail?.yearOfInception,
              RegisteredAddress: odPartnerDetail?.registeredAddress,
              CommunicationAddress: odPartnerDetail?.communicationAddress,
              Pincode: odPartnerDetail?.pincode,
              State: odPartnerDetail?.stateId,
              OfficialEmailId: odPartnerDetail?.officialEmailId,
              OfficeContactumber: odPartnerDetail?.officeContactumber,
              MobileContactNumber: odPartnerDetail?.mobileContactNumber,
              WebsiteAddress: odPartnerDetail?.websiteAddress,
              WhatsappNumber: odPartnerDetail?.whatsappNumber,
              FacebookID: odPartnerDetail?.facebookID,
              TwitterID: odPartnerDetail?.twitterID,
              LinkedInID: odPartnerDetail?.linkedInID,
              OtherMediaLink: odPartnerDetail?.otherMediaLink,
              VisionStatement: odPartnerDetail?.visionStatement,
              MissionStatement: odPartnerDetail?.missionStatement,
              FaithStatement: odPartnerDetail?.faithStatement,
              CoreValues: odPartnerDetail?.coreValues,
              ObjectiveOfOrganization: odPartnerDetail?.objectiveOfOrganization,
              MajorActivities: odPartnerDetail?.majorActivities,
              SectorId: odPartnerDetail?.sectorId,
              SubsectorId: odPartnerDetail?.subSectorId,
              //FedrationBasicDetailId: odPartnerDetail?.federationbasicDetailId,
              FounderTitle: odPartnerDetail?.founderTitle,
              FounderFirstName: odPartnerDetail?.founderFirstName,
              FounderLastName: odPartnerDetail?.founderLastName,
              FounderMobile: odPartnerDetail?.founderMobile,
              FounderEmailId: odPartnerDetail?.founderEmailId,
              FounderDesignation: odPartnerDetail?.founderDesignation,
              CEOTitle: odPartnerDetail?.ceoTitle,
              CEOFirstName: odPartnerDetail?.ceoFirstName,
              CEOLastName: odPartnerDetail?.ceoLastName,
              CEOMobile: odPartnerDetail?.ceoMobile,
              CEOEmailId: odPartnerDetail?.ceoEmailId,
              CEODesignation: odPartnerDetail?.ceoDesignation,
              NumberOfCommiteeMember: odPartnerDetail?.numberOfCommiteeMember,
              RefId1: odPartnerDetail?.refId1,
              RefId2: odPartnerDetail?.refId2,
              RefTitle1: odPartnerDetail?.refTitle1, RefTitle2: odPartnerDetail?.refTitle2,
              RefFirstName1: odPartnerDetail?.refFirstName1, RefFirstName2: odPartnerDetail?.refFirstName2,
              RefLastName1: odPartnerDetail?.refLastName1, RefLastName2: odPartnerDetail?.refLastName2,
              RefMobile1: odPartnerDetail?.refMobile1, RefMobile2: odPartnerDetail?.refMobile2,
              RefEmail1: odPartnerDetail?.refEmail1, RefEmail2: odPartnerDetail?.refEmail2,
              RefDesignation1: odPartnerDetail?.refDesignation1, RefDesignation2: odPartnerDetail?.refDesignation2,
              RefOrganization1: odPartnerDetail?.refOrganization1, RefOrganization2: odPartnerDetail?.refOrganization2,
              ContactId: odPartnerDetail?.contactId,
              ContactDesignation: odPartnerDetail?.contactDesignation,
              ContactEmailId: odPartnerDetail?.contactEmailId,
              ContactFirstName: odPartnerDetail?.contactFirstName,
              ContactLastName: odPartnerDetail?.contactLastName,
              ContactMobile: odPartnerDetail?.contactMobile,
              ContactTitle: odPartnerDetail?.contactTitle,
              OrganizationLogo:odPartnerDetail?.organizationLogo,
              ActivityReport: odPartnerDetail?.activityReport,
              FedarationBasicDetailIds:odPartnerDetail?.fedarationBasicDetailIds,
            }}
            onSubmit={(values: OdPartnerRegisterValues) => {
              values.MobileContactNumber = state.mobile
            
              if(save){
                savePartnerDetails(values);
                saveData(false);
                setFormData(values);
              }
              else if(rootStore.registrationOTPStore.index < 5)
              {
                
                savePartnerDetails(values);
                rootStore.registrationOTPStore.index = (rootStore.registrationOTPStore.index + 1)
                setFormData(values);
              }
              else{

                      upsertPartner(values)
                      .then(() => {
                        setFormData(new OdPartnerRegisterValues());
                        if(rootStore.partnerStore.isRegisterComplete){
                          registerationDone(values);
                          registerationAdmin(values);
                        }
                      })
                      .catch((error) => ({
                        [FORM_ERROR]: error,
                      }));


              
            }
          //FUNCTION CALL          
          //rootStore.partnerStore.registerationDone(values);
          //-------------------------------------------------------------------------------------------------------- 
            }}
          

            validate={rootStore.registrationOTPStore.index === 2 ? validate : rootStore.registrationOTPStore.index === 3 ?
              valisateSector : rootStore.registrationOTPStore.index === 4 ? validateCommitee :
                validateReference}

            render={({
              handleSubmit,
              form,
              submitting,
              submitError,
              invalid,
              pristine,
              dirtySinceLastSubmit,
              error,
            }) => (
              <Form
                onSubmit={async (event) => {
                  await handleSubmit(event);
                  await handlePostSms(event);
                  if(rootStore.registrationOTPStore.index === 5 ){
                  if (props.registrationId === undefined) {
                    //form.reset();
                    form.resetFieldState('RefFirstName1');
                    form.resetFieldState('RefFirstName2');
                    form.resetFieldState('RefLastName1');
                    form.resetFieldState('RefLastName2');
                    form.resetFieldState("RefTitle1");
                    form.resetFieldState("RefTitle2");
                    form.resetFieldState('RefMobile1');
                    form.resetFieldState('RefMobile2');
                    form.resetFieldState('RefEmail1');
                    form.resetFieldState('RefEmail2');
                    form.resetFieldState('RefOrganization1');
                    form.resetFieldState('RefOrganization2');
                    form.resetFieldState('RefDesignation1');
                    form.resetFieldState('RefDesignation2');
                    form.resumeValidation();
                  }
                }
                }
              }
              >
                {rootStore.registrationOTPStore.index === 2 && (
                  <div>
                    <Fragment>       
                      <Grid columns={2}>
                        <Grid.Row>
                          <Grid.Column width={8}>             
                          </Grid.Column>             
                          <Grid.Column width={8} textAlign='right'>

                          
                            <Button
                              className="actionButton"
                              
                              //style={{ marginLeft: "757px" }}
                              // onClick={() => {
                              //   
                              //   rootStore.registrationOTPStore.index = 3;
                                
                              // }}
                              disabled={props.registrationId === undefined ?
                                (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit

                              }
                              loading={IsRegister}
                              content="Save and Next"
                              color="blue"
                              type="submit"
                              validate={validate}
                              onClick={scrollTop}
                            ></Button>
                            
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <br />
                      <br />
                      <Grid columns={2}>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Grid>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Organisation Name{" "}
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="OrganizationName"
                                  
                                    placeholder="Name"
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Year of Inception{" "}
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>

                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="YearOfInception"
                                    placeholder="Year of Inception"
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Office Contact Number{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    inputProps={{ tabIndex: "3" }}
                                    component={TextInputTab}
                                    name="OfficeContactumber"
                                    placeholder="Office Contact Number"
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Official Email ID{" "}
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="OfficialEmailId"
                                    placeholder="Official Email ID"
                                    inputstyle={{ width: "250px" }}
                                    
                                  ></Field>
                                </Grid.Column>
                                 
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Preferred Password {" "} <span className="mandatory-field"> * </span> <br/>(min 8 digit with uppercase, lowercase, numbers & special charc)
                                  </label>
                             
                                </Grid.Column>
                                <Grid.Column width={7}>
                                  <Field
                                   component={TextInputTab}
                                    name="Password"
                                    placeholder="Password"
                                    type={values.showPassword ? "text" : "password"}
                                    onChange={handlePasswordChange("password")}
                                    value={values.password}
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                 
                                 </Grid.Column>
                                 <Grid.Column width={1} style={{marginTop:'15px'}}>
                                     <InputAdornment position="end">
                                        <IconButton
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                </Grid.Column>
                               
                                {props.registrationId === undefined?
                                  <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Confirm Password{" "}
                                    </label>
                                    <span className="mandatory-field"> * </span>
                                  </Grid.Column>
                                  :
                                  <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Mobile Number{" "}
                                    </label>
                                    <span className="mandatory-field"> * </span>
                                  </Grid.Column>
                                }
                                {props.registrationId === undefined?
                                 ( 
                                 <Grid.Column width={7}>
                                    <Field
                                      component={TextInputTab}
                                      name="ConfirmPassword"
                                      placeholder="Confirm Password  *"
                                      type={values.showPassword ? "text" : "password"}
                                      onChange={handlePasswordChange("password")}
                                      value={values.password}
                                      inputstyle={{ width: "250px" }}
                                    ></Field>
                                  </Grid.Column>
                                
                                  ) :
                                 ( <Grid>
                                    <Grid.Column width={14}>
                                      <Field
                                        component={TextInputTab}
                                        name="MobileContactNumber"
                                        placeholder="Mobile"
                                        readOnly="readonly"
                                        inputstyle={{ width: "250px" }}
                                      ></Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                      <Icon
                                        style={{ color: "darkblue" }}
                                        size="big"
                                        name="edit"
                                        onClick={() => { rootStore.registrationOTPStore.index = 1; }
                                        }
                                      />
                                    </Grid.Column>
                                  </Grid>
                                 )
                                }

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Registered Address{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextAreaTab}
                                    name="RegisteredAddress"
                                    inputstyle={{
                                      width: "250px",
                                      height: "10px",
                                    }}
                                    placeholder="Registered Address"
                                    onChange={(event)=>{
                                      address.RegisteredAddress = event.target.value  
                                  } }
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                     Same as Registered Address{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                <Field
                                    component={CheckInputSimple}
                                    name="addressCheck"
                                    onChange={(value:boolean)=>{
                                    setCheckbox(value)
                                    console.log(value)
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Communication Address{" "}
                                  </label>  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextAreaTab}
                                    name="CommunicationAddress"
                                    inputstyle={{
                                      width: "250px",
                                      height: "10px",
                                    }}
                                    value={checkbox1?address.RegisteredAddress:address.CommunicationAddress}
                                    defaultValue={checkbox1?address.RegisteredAddress:address.CommunicationAddress}
                                    placeholder="Communication Address"
                                    onChange={(e:any)=>
                                address.CommunicationAddress=e.target.value
                                    }
                                  />
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Grid>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    State
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={SelectInputTab}
                                    placeholder="Select State"
                                    name="State"
                                    search
                                    selections={"true"}
                                    options={stateDropdownValues}
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Pincode{" "}
                                  </label>
                                  <span className="mandatory-field"> * </span>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component={TextInputTab}
                                    name="Pincode"
                                    placeholder="Pincode"
                                    inputstyle={{ width: "250px" }}
                                  ></Field>
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Website Address
                                  </label>
                                </Grid.Column>
                                {/* <Form.Field inline> */}
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="WebsiteAddress"
                                    style={{ width: "250px" }}
                                    placeholder="Website Address"
                                  />
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <Icon
                                    style={{ color: "green" }}
                                    size="big"
                                    name="whatsapp"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="WhatsappNumber"
                                    style={{ width: "250px" }}
                                    placeholder="Whatsapp No"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <Icon
                                    style={{ color: "darkblue" }}
                                    size="big"
                                    name="facebook"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="FacebookID"
                                    style={{ width: "250px" }}
                                    placeholder="Facebook ID"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <Icon
                                    style={{ color: "lightblue" }}
                                    size="big"
                                    name="twitter"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="TwitterID"
                                    style={{ width: "250px" }}
                                    placeholder="Twitter ID"
                                  />
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <Icon
                                    style={{ color: "#0A66C2" }}
                                    size="big"
                                    name="linkedin"
                                  />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="LinkedInID"
                                    style={{ width: "250px" }}
                                    placeholder="LinkedIn Id"
                                  />
                                </Grid.Column>

                                <Grid.Column width={8} style={{ paddingLeft: "150px" }}>
                                  <label
                                    className="label-field"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    {" "}
                                    Other Media Link{" "}
                                  </label>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                  <Field
                                    component="input"
                                    name="OtherMediaLink"
                                    style={{ width: "250px" }}
                                    placeholder="Other link"
                                  />
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={8}></Grid.Column>

                          <Grid.Column width={8} textAlign='right'>
                          <Button
                              className="actionButton"
                              //style={{ marginLeft: "757px" }}
                              // onClick={() => {
                              //   
                              //   rootStore.registrationOTPStore.index = 3;
                                
                              // }}
                              disabled={props.registrationId === undefined ?
                                (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit

                              }
                              loading={IsRegister}
                              content="Save and Next"
                              color="blue"
                              type="submit"
                              validate={validate}
                              onClick={scrollTop}
                            ></Button>
                            
                          
                            
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <br />
                      <br />
                      {submitError && !dirtySinceLastSubmit && (
                        <ErrorMessage
                          error={submitError}
                          frontEndError={submitError}
                        />
                      )}
                    </Fragment>
                  </div>
                )}
                {rootStore.registrationOTPStore.index === 3 && (
                  <Fragment>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Button
                            className="actionButton"
                            style={{ marginLeft: "48px" }}
                            onClick={() => {
                              rootStore.registrationOTPStore.index = 2;
                            }}
                            content="Back"
                            color="blue"
                            type="button"
                          />
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                          <Button
                             
                            disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                              (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)

                            }
                            className="actionButton"
                            //style={{ marginLeft: "815px" }}
                            // onClick={() => {
                            //   rootStore.registrationOTPStore.index = 4;
                            // }}
                            content="Save and Next"
                            loading={IsRegister}
                            color="blue"
                            type="submit"
                            validate={valisateSector}
                          ></Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                    <Grid>
                      <Grid.Row>
                        <Grid columns={2}>
                          <Grid>
                            <Grid.Row>
                              {/* Left Side Content */}
                              <Grid.Column width={8}>
                                <Grid>
                                  <Grid.Column width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Vision Statement{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component="textarea"
                                      name="VisionStatement"
                                      style={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Vision Statement"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      Mission Statement{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component="textarea"
                                      name="MissionStatement"
                                      style={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Mission Statement"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Faith Statement{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component="textarea"
                                      name="FaithStatement"
                                      style={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Faith Statement"
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Sector{" "}
                                    </label>
                                    <span className="mandatory-field"> * </span>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component={SelectInputTab}
                                      placeholder="Select Sector"
                                      name="SectorId"
                                      search
                                      selections={"true"}
                                      options={sectorDropdownValues}
                                      inputstyle={{ width: "290px" }}
                                      onCustomChange={handleSectorChange}
                                    ></Field>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Sub-Sector{" "}
                                    </label>
                                    <span className="mandatory-field"> * </span>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component={SelectInputTab}
                                      inputstyle={{ width: "290px" }}
                                      fluid
                                      placeholder="Select Sub-Sector"
                                      name="SubsectorId"
                                      search
                                      selections={"true"}
                                      options={subSectorDropdownValues}
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Membership{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component={SelectInputTab}
                                      placeholder="Member of"
                                      name="FedarationBasicDetailIds"
                                      search
                                      selections={"true"}
                                      multiple
                                      options={federationNameDropdownValues}
                                      inputstyle={{ width: "290px" }}
                                    ></Field>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Organisation LOGO{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="OrganizationLogoFile"
                                      style={{ width: "290px" }}
                                      uploadedFile={odPartnerDetail?.organizationLogoFile}
                                      addformstyle={{
                                        paddingRight: "15px",
                                      }}
                                      component={FileUpload}
                                      //parse={(value: string | number) => String(value)}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Grid.Column>
                              {/*End of Left Side Content */}
                              {/* Right Side Content */}
                              <Grid.Column width={8}>
                                <Grid>

                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Core Values{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component="textarea"
                                      name="CoreValues"
                                      style={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Core Values"
                                    />
                                  </Grid.Column>


                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Objective of the Organisation{" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component="textarea"
                                      name="ObjectiveOfOrganization"
                                      style={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Objective of the Organisation"
                                    />
                                  </Grid.Column>


                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Major Activities/Programs/<br/>Services   <span className="mandatory-field"> * </span>
                                    </label>

                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      component={TextAreaTab}
                                      name="MajorActivities"
                                      inputstyle={{
                                        width: "290px",
                                        height: "100px",
                                      }}
                                      placeholder="Major Activities/Programs/Services"
                                    />
                                  </Grid.Column>



                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Organisation Info or Brochure
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="OrganizationInfoOrBrouchefile"
                                      uploadedFile={odPartnerDetail?.organizationInfoOrBrouche}
                                      style={{ width: "290px" }}
                                      addformstyle={{
                                        paddingRight: "15px",
                                      }}
                                      component={FileUpload}
                                    />
                                  </Grid.Column>



                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Annual Activity Report (Optional){" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="ActivityReport"
                                      uploadedFile={odPartnerDetail?.activityReport}
                                      style={{ width: "290px" }}
                                      addformstyle={{
                                        paddingRight: "15px",
                                      }}
                                      component={FileUpload}
                                    />
                                  </Grid.Column>

                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Annual Organisation Budget (Optional){" "}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="OrganisationBudget"

                                      uploadedFile={odPartnerDetail?.organisationBudget}
                                      style={{ width: "290px" }}
                                      addformstyle={{
                                        paddingRight: "15px",
                                      }}
                                      component={FileUpload}
                                    />
                                  </Grid.Column>


                                  <Grid.Column
                                    width={8}
                                    style={{ paddingLeft: "150px" }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{ paddingTop: "4px" }}
                                    >
                                      {" "}
                                      Recent Audit statement
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={8}>
                                    <Field
                                      name="AuditStatement"

                                      uploadedFile={odPartnerDetail?.recentAuditstatement}
                                      style={{ width: "290px" }}
                                      addformstyle={{
                                        paddingRight: "15px",
                                      }}
                                      component={FileUpload}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Grid.Column>
                              {/*End of Right Side Content */}

                            </Grid.Row>
                          </Grid>
                        </Grid>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Button
                            className="actionButton"
                            style={{ marginLeft: "48px" }}
                            onClick={() => {
                              rootStore.registrationOTPStore.index = 2;
                            }}
                            content="Back"
                            color="blue"
                            type="button"
                          />
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                          <Button
                          disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                            (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)


                            }
                            className="actionButton"
                            //style={{ marginLeft: "815px" }}
                            // onClick={() => {
                            //   rootStore.registrationOTPStore.index = 4;
                            // }}
                            content=" Save and Next"
                            color="blue"
                            type="submit"
                            validate={valisateSector}
                            onClick={scrollTop}
                          ></Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                  </Fragment>
                )}
                {rootStore.registrationOTPStore.index === 4 && (
                  <Fragment>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Button
                            className="actionButton"
                            style={{ marginLeft: "48px" }}
                            onClick={() => {
                              rootStore.registrationOTPStore.index = 3;
                            }}
                            content="Back"
                            color="blue"
                            type="button"
                          />
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                          <Button
                          disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                            (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)

                            }
                            className="actionButton"
                            //style={{ marginLeft: "815px" }}
                            // onClick={() => {
                            //   rootStore.registrationOTPStore.index = 5;
                            // }}
                            content="Save and Next"
                            color="blue"
                            validate={validateCommitee}
                            type="submit"
                            onClick={scrollTop}
                          ></Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Founder
                          </label>
                        </Grid.Column>
                        <Grid.Column width={2}>


                          <Field
                            component={SelectInputTab}
                            placeholder="Title"
                            name="FounderTitle"
                            search
                            selections={"true"}
                            options={Title}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                          ></Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="FounderFirstName"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="FounderLastName"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        {/* </Form.Field> */}
                        {/* <Form.Field inline> */}
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="FounderMobile"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        {/* </Form.Field>
                        <Form.Field inline> */}
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="FounderEmailId"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        {/* </Form.Field> */}
                        <Grid.Column width={2}>
                          {/* <Form.Field inline> */}
                          <Field
                            component={TextInputTab}
                            name="FounderDesignation"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                          {/* </Form.Field> */}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        {/* <Form.Field inline> */}
                        <Grid.Column width={4}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            CEO /Executive Director /
                            <span className="mandatory-field"> * </span>
                            <br />
                            Managing Trustee
                          </label>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          {/* <Field
                            name="CEOTitle"
                            component= "select"
                           // inputstyle={{ marginLeft: "2px", minWidth : "161px" }}
                            options  = {title}
                            placeholder="Title"
                          > */}
                          <Field
                            component={SelectInputTab}
                            placeholder="Title"
                            name="CEOTitle"
                            search
                            selections={"true"}
                            options={Title}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                          ></Field>


                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="CEOFirstName"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="CEOLastName"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="CEOMobile"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="CEOEmailId"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="CEODesignation"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={4}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            CEO Photo
                          </label>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field
                            name="CEOPhotoFile"
                            uploadedFile={odPartnerDetail?.CEOPhotoFile}
                            style={{ width: "300px" }}
                            addformstyle={{
                              paddingRight: "4px",
                            }}
                            component={FileUpload}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        {/* <Form.Field inline> */}
                        <Grid.Column width={4}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Contact person for
                            <br />
                            Communication (Optional)
                          </label>
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            name="ContactTitle"
                            style={{}}
                            component={SelectInputTab}
                            options={Title}
                            placeholder="Title"
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                          >

                          </Field>


                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="ContactFirstName"
                            inputstyle={{ marginLeft: "10px" }}
                            /* onChange={e => handleInputChange(e, i)} */ placeholder=" First Name"
                          />
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="ContactLastName"
                            inputstyle={{ marginLeft: "10px" }}
                            /* onChange={e => handleInputChange(e, i)} */ placeholder=" Last Name"
                          />
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="ContactMobile"
                            inputstyle={{ marginLeft: "10px" }}
                            /* onChange={e => handleInputChange(e, i)}*/ placeholder="Mobile No"
                          />
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="ContactEmailId"
                            inputstyle={{ marginLeft: "10px" }}
                            /* onChange={e => handleInputChange(e, i)} */ placeholder="Email"
                          />
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="ContactDesignation"
                            inputstyle={{ marginLeft: "10px" }}
                            /* onChange={e => handleInputChange(e, i)} */ placeholder="Designation"
                          />
                          {/* </Form.Field> */}
                        </Grid.Column>
                      </Grid.Row>

                      <br />
                      <Grid.Row>
                        <br />
                        <Grid.Column width={4}>
                          {/* <Form.Field inline> */}
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            No of Board of Trustee/
                            <span className="mandatory-field"> * </span>
                            <br />
                            Commitee
                          </label>
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Field
                            component={TextInputTab}
                            name="NumberOfCommiteeMember"
                            inputstyle={{ width: "300px" }}
                            placeholder="No of Board of Trustee"
                          />
                        </Grid.Column>
                        {/* </Form.Field> */}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          {/* <Form.Field inline> */}
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px", marginTop: "26px" }}
                          >
                            Current Board of trustee/
                            <span className="mandatory-field"> * </span>
                            <br />
                            Management Profile
                          </label>
                          
                          <label style={{ marginLeft: "50px",color: 'red'}} > (Download sample template and fill the current board profile) </label>
                        </Grid.Column>

                        <Grid.Column width={4}>
                          <Field
                            name="TrusteeorManagementProfileFile"
                            uploadedFile={odPartnerDetail?.trusteeorManagementProfile}
                            style={{ width: "300px" }}
                            addformstyle={{
                              paddingRight: "4px",
                            }}
                            component={FileUpload}
                          />
                          {submitError &&
                            !dirtySinceLastSubmit &&
                            (
                              <ErrorMessage error={submitError} />
                            )}
                          
                          <Button
                            color="blue"
                            style={{ marginLeft: "9px", height: "37px" }}
                            href={
                              "https://cimodcb.in/document/CurrentBoardProfile.xlsx"
                            }
                            icon
                          >
                             <Icon name="download" />
                            Download Sample
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Button
                            className="actionButton"
                            style={{ marginLeft: "48px" }}
                            onClick={() => {
                              rootStore.registrationOTPStore.index = 3;
                            }}
                            content="Back"
                            color="blue"
                            type="button"
                          />
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                          <Button
                          disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                            (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)

                            }
                            className="actionButton"
                            //style={{ marginLeft: "815px" }}
                            // onClick={() => {
                            //   rootStore.registrationOTPStore.index = 5;
                            // }}
                            content="Save and Next"
                            color="blue"
                            validate={validateCommitee}
                            type="submit"
                            onClick={scrollTop}
                          ></Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                    <br />
                  </Fragment>
                )}

                {rootStore.registrationOTPStore.index === 5 && (
                  <Fragment>
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column width={11}>
                          <Button
                            className="actionButton"
                            style={{ marginLeft: "48px" }}
                            onClick={() => {
                              rootStore.registrationOTPStore.index = 4;
                            }}
                            content="Back"
                            color="blue"
                            type="button"
                          />
                        </Grid.Column>
                        <Grid.Column width={3} textAlign='right'>
                          <Button
                          disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                            (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)

                            }

                            className="actionButton"
                            type="submit"
                            loading={IsRegister}
                            content="Save"
                            color="blue"
                            onClick={() => {
                              saveData(true);
                            }}
                
                             //validate = {validateReference}
                          ></Button>
                          </Grid.Column>
                        <Grid.Column width={2} textAlign='right'>
                          <Button
                          disabled={ odPartnerDetail?.savePartnerDetailsId === undefined &&  ( props.registrationId === undefined?
                            (invalid && !dirtySinceLastSubmit) || pristine : dirtySinceLastSubmit)

                            }

                            className="actionButton"
                            type="submit"
                            loading={IsRegister}
                            //style={{marginLeft: "815px",}}
                            content="Register"
                            color="blue"
                            validate = {validateReference}
                          ></Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column width={2}>
                          {/* <Form.Field inline> */}
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Reference 1
                          </label>
                          <span className="mandatory-field"> * </span>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            name="RefTitle1"
                            component={SelectInputTab}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                            placeholder="Title"
                            options={Title}
                            select="Mr."
                          >

                          </Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefFirstName1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefLastName1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefMobile1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefEmail1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefDesignation1"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefOrganization1"
                            style={{ marginLeft: "10px" }}
                            placeholder="Organisation"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        {/* <Form.Field inline> */}
                        <Grid.Column width={2}>
                          <label
                            className="label-field"
                            style={{ marginLeft: "50px" }}
                          >
                            {" "}
                            Reference 2
                          </label>
                          <span className="mandatory-field"> * </span>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            name="RefTitle2"
                            component={SelectInputTab}
                            inputstyle={{ marginLeft: "2px", minWidth: "161px" }}
                            placeholder="Title"
                            options={Title}
                          >

                          </Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefFirstName2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" First Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefLastName2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder=" Last Name"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefMobile2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Mobile No"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefEmail2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Email"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefDesignation2"
                            inputstyle={{ marginLeft: "10px" }}
                            placeholder="Designation"
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Field
                            component={TextInputTab}
                            name="RefOrganization2"

                            placeholder="Organisation"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                     

                    <br />
                    {submitError && !dirtySinceLastSubmit && (
                      <ErrorMessage
                        error={submitError}
                        frontEndError={submitError}
                      />
                    )}

                    <br />

                    <br />
                    <br />
                  </Fragment>
                )}
              </Form>
            )}
          />
        </div>
      </Segment>
    </Fragment>
  );



  
};
export default observer(ODRegistration);
