export interface IAnswerOptionViewModel{
    answerOptionId?: number,
    optionName: string,
    pointValue?: number,
    actionPoints: string,
    questionsToDisplay:number[];
}

export class AnswerOptionViewModel implements IAnswerOptionViewModel{
    answerOptionId?: number = undefined;
    optionName: string = '';
    pointValue?: number = undefined;
    actionPoints: string = '';
    questionsToDisplay:number[] =[];

    constructor(init?: IAnswerOptionViewModel) {
      Object.assign(this, init);
  }
}


