import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { IFederationApproveOrReject } from "../../../../app/models/federation";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
    approveOrRejectFederation: IFederationApproveOrReject,
}

const FedApproveOrReject: React.FC<IProps> = ({ approveOrRejectFederation: approveOrRejectFederationProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {federationApprove} = rootStore.federationStore;

    return (
        <FinalForm
            initialValues={approveOrRejectFederationProps}
            onSubmit={(values: IFederationApproveOrReject) => 
                federationApprove(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting,submitError }) => (
                
            <Form onSubmit={handleSubmit} error>
                <Segment>
                 {/* <Header color={approveOrRejectFederationProps.IsApproved ? 'green' : 'red'} style={{ height: '6vh' }}>{approveOrRejectFederationProps.IsApproved ? 'Approve' : 'Reject'} !</Header> */}
                 Kindly confirm federation can be {approveOrRejectFederationProps.IsApproved ? 'Approved' : 'Rejected'}
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '150px'}} loading ={submitting}>{approveOrRejectFederationProps.IsApproved ? 'Approve' : 'Reject'}</Button>
               </Segment>
                </Form>
                 )}>
        </FinalForm>        
    )
}

export default observer(FedApproveOrReject);