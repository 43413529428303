import React from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useContext } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";

import { IState } from "../../../../app/models/state";

interface IProps {
  disableEnableState: IState;
}

const DisableState: React.FC<IProps> = ({ disableEnableState }) => {
  const rootStore = useContext(RootStoreContext);
  const { disableState } = rootStore.stateStore;
  return (
    <FinalForm
      initialValues={disableEnableState}
      onSubmit={(values: IState) => {
        disableState(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <Form onSubmit={handleSubmit}>
      
           
            Do you really want to{" "}
            {disableEnableState.isActive ? "disable" : "enable"} 
           <b> {disableEnableState.stateName}?</b>
        
          <br />
          <br />
          {submitError && <ErrorMessage error={submitError} />}

         
            <Button
              loading={submitting}
              color='blue' style={{ marginLeft: '130px'}}             
            >
              <Icon name={disableEnableState.isActive ? "delete" : "check"} />{" "}
              {disableEnableState.isActive ? "Disable" : "Enable"}
            </Button>
          
        </Form>
      )}
    ></FinalForm>
  );
};

export default observer(DisableState);
