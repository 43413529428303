import React, { useContext, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { Form as FinalForm } from "react-final-form";
import { observer } from 'mobx-react-lite';
import { IUpdateActionPoints } from '../../../app/models/partnerDashboard';
import { FORM_ERROR } from 'final-form';
import { UserRole } from '../../../app/common/enum';


function ActionPoints(props) {

  const partneractionpoint = JSON.parse(props.actionpoint)
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
      },
      inline: {
        display: 'inline',
      },
    }),
  );
  const rootStore = useContext(RootStoreContext);
  const { updateActionPoints } = rootStore.partnerdashboardStore
  useEffect(() => {
  }, [updateActionPoints]);

  const classes = useStyles();
  const [state, setState] = React.useState({
    actionPointId: [] as any,
    actionPointName: [] as any,
    partnerDashboard: partneractionpoint,
    pendingActionPoint: [] as any,
    check: false,
  });

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      state.actionPointId = state.actionPointId.concat(Number(event.target.value));
      state.partnerDashboard.pendingActionPoints[getIndex(Number(event.target.value), state.partnerDashboard.pendingActionPoints, "actionPointId")].selected = true;
      setState({
        actionPointId: state.actionPointId,
        actionPointName: state.actionPointName,
        partnerDashboard: state.partnerDashboard,
        pendingActionPoint: state.pendingActionPoint,
        check: state.check
      });
    }
    if (!event.target.checked) {
      state.actionPointId = state.actionPointId.filter(item => item !== (Number(event.target.value)));
      state.partnerDashboard.pendingActionPoints[getIndex(Number(event.target.value), state.partnerDashboard.pendingActionPoints, "actionPointId")].selected = false;
      setState({
        actionPointId: state.actionPointId,
        actionPointName: state.actionPointName,
        partnerDashboard: state.partnerDashboard,
        pendingActionPoint: state.pendingActionPoint,
        check: state.check
      });
    }

  };
  const handleEvent = () => {
    updateActionPoints(state);
    for (let currentActionpint of state.actionPointId) {
      console.log(currentActionpint);
      const pendingActionPoint = state.partnerDashboard?.pendingActionPoints?.find((element) => {
        return element.actionPointId === currentActionpint;
      })
      if (pendingActionPoint !== undefined) {
        state.partnerDashboard?.completedActionPoints?.push(pendingActionPoint);
        state.partnerDashboard.pendingActionPoints = state.partnerDashboard?.pendingActionPoints?.filter(item => item !== pendingActionPoint);
      }
    }
    state.partnerDashboard.pendingActionPoints.forEach(option => {
      option.selected = false
    });
    setState({
      actionPointId: state.actionPointId,
      actionPointName: state.actionPointName,
      partnerDashboard: state.partnerDashboard,
      pendingActionPoint: state.pendingActionPoint,
      check: state.check
    });
  }
  return (
    <FinalForm initialValues={null}
      onSubmit={(values: IUpdateActionPoints) => {
        updateActionPoints(values)
          .catch(error => ({
            [FORM_ERROR]: error
          }))
      }
      }
      render={({ handleSubmit: actionSubmit, form }) => (
        <Form onSubmit={async event => {
          actionSubmit(event);
        }}>
          <Grid columns={2} divided>
            <Grid.Column floated="left" width={8}>
              <h6 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>Pending</h6>
              <List className={classes.root}>
                {state.partnerDashboard?.pendingActionPoints && state.partnerDashboard?.pendingActionPoints.map((pending) => (
                  <ListItem button alignItems="flex-start">
                         {rootStore.commonStore.role === UserRole.ODPartner ? 
                    <label>
               
                      <input type="checkbox" style={{ textAlign: 'center', height: 20, width: 25 }}
                        value={pending.actionPointId}
                        checked={pending.selected} onChange={handleChange} name="actionPoint" />
                      {pending.actionPointName}
                    
                    </label>
                    : <label > {pending.actionPointName} </label>
      }
                  </ListItem>
                ))}
                <input type="hidden" value={state.actionPointId} name="actionPointId" />
                {rootStore.commonStore.role === UserRole.ODPartner &&
                  <Button color='green' style={{ marginLeft: '150px' }} icon onClick={handleEvent}>
                    <Icon name='arrow right' />
                  </Button>
                }
              </List>
            </Grid.Column>
            <Grid.Column floated="right" width={8} >
              <h6 style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontStyle: 'Italic' }}>Completed</h6>
              <List className={classes.root}>
                {state.partnerDashboard?.completedActionPoints.map((completed) => (
                  <ListItem button alignItems="flex-start">
                    {completed.actionPointName !== undefined &&
                      <label> {completed.actionPointName} </label>
                    }
                  </ListItem>
                ))}
              </List>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    ></FinalForm>
  );
}
export default observer(ActionPoints);
