export interface IDashboard {
  pendingCount: number;
  approvedCount: number;
  odPartnerCount: number;
  federationCount: number;
  donorIndividualCount: number;
  donorOrganisationCount: number;
  registeredCount: number;
  pendingAssessmentCount: number;
  approvedAssessmentCount: number;
  organisation: [
    {
      organisationId: number;
      organisationName: string;
      organisationType: string;
      organisationKey: string;
    }
  ];
  state: [
    {
      stateId: number;
      stateName: string;
      stateCount: number;
    }
  ];
  monthwise: [
    {
      month: string;
      sumValuebymonth: number;
    }
  ];
  yearwise: [
    {
      year: string;
      sumValuebymonth: number;
    }
  ];
  yearSectorwise: [
    {
      year: string;
      sectorSum: [
        {
          sectorId: number;
          sectorName: string;
          count: number;
        }
      ];
    }
  ];
  yearAreawise: [
    {
      year: string;
      areaCount: [
        {
          areaId: number;
          areaName: string;
          count: number;
        }
      ];
    }
  ];
}
export interface IRegistrationCount {
  odPartnerCount: number;
  federationCount: number;
  donorIndividualCount: number;
  donorOrganisationCount: number;
  registeredCount: number;
}

export interface IRegistrationApproval {
  pendingCount: number;
  approvedCount: number;
}

export interface IAssessmentReviewal {
  pendingAssessmentCount: number;
  approvedAssessmentCount: number;
}

export interface IOrganisation {
  organisationId: number;
  organisationName: string;
  organisationType: string;
  organisationKey: string;
}
export interface IAllOrganisation {
  organisationId: number;
  organisationName: string;
  organisationType: string;
  organisationKey: string;
  state: string;
  sector: string;
  mobile: string;
  emailId: string;
}

export interface IYearAreawise {
  year: string;
  areaCount: [
    {
      areaId: number;
      areaName: string;
      count: number;
    }
  ];
}

export interface IYearSectorwise {
  year: string;
  sectorSum: [
    {
      sectorId: number;
      sectorName: string;
      count: number;
    }
  ];
}
export interface IYearwise {
  year: string;
  sumValuebymonth: number;
}
export interface IPincode {
  pincode: string;
}
export interface IYearMonthwise {
  month: string;
  sumValuebymonth: number;
}
export interface IStatewise {
  stateId: number;
  stateName: string;
  stateCount: number;
}

export interface IRegistration {
  key: number;
  organisationId: number;
  organisationKey: string;
  organisationName: string;
  organisationType: string;
  status: string;
}

export class Registration implements IRegistration {
  key: number = 0;
  organisationId: number = 0;
  organisationKey: string = "";
  organisationName: string = "";
  organisationType: string = "";
  status: string = "";
  constructor(init?: IRegistration) {
    Object.assign(this, init);
  }
}

export interface IPendingOrApprove {
  isPending: boolean;
}

export class PendingOrApprove implements IPendingOrApprove {
  isPending: boolean = false;

  constructor(init?: IRegistration) {
    Object.assign(this, init);
  }
}

export interface IAssessmentCount {
  assessmentId: number;
  partnerId: number;
  organisationName: string;
  assessmentDate: string;
  score: string;
  verificationStatus: string;
}

export interface IOrganisation {
  organisationId: number;
  organisationName: string;
  organisationType: string;
  organisationKey: string;
}

export class Organisation implements IOrganisation {
  organisationId: number = 0;
  organisationName: string = "";
  organisationType: string = "";
  organisationKey: string = "";
  constructor(init?: IOrganisation) {
    Object.assign(this, init);
  }
}
export interface ISearchOrganisation {
  organisationKey: number;
  organisationName: string;
  organisationType: string;
}
