import React, { useContext, useEffect } from 'react';
import { Container, Segment, Item, Grid, Button, Image, Form, Loader, Label } from 'semantic-ui-react';
import { Form as FinalForm, Field } from "react-final-form";
import ActionPoints from './ActionPoints';
import ODComplianceChart from './odCharts/ODComplianceChart';
import ODArea from './odCharts/ODArea';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { LoadingComponent } from '../../../app/layout/LoadingComponent';
import CreateMessage from '../../mailBox/CreateMessage';
import PreviousAssessment from './PreviousAssessment';
import { UserRole } from '../../../app/common/enum';
import { IFederationEndorsement } from '../../../app/models/partnerDashboard';
import SelectInputTab from '../../../app/common/form/SelectInputTab';
import { toast } from 'react-toastify';
import { history } from "../../..";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import ReportComplianceChart from './odCharts/ReportComplianceChart';
// import ViewRegistration from './ViewRegistration';
// import AssessmentDetail from '../adminDashboard/assessmentReview/AssessmentDetail';


function ODDashboard(props) {
  const rootStore = useContext(RootStoreContext);
  const { openRightPanel } = rootStore.rightPanelStore;
  const { partnerdashboard, getPartnerDashboard, loadingDashboard, getActionPointDashboard, actionPointDashboard, acceptedFederation, getAcceptedFederation } = rootStore.partnerdashboardStore
  const { getNotMappedFederation, notMappedDropdownValues, loading, getPoint, point, getPointPercentage, pointPercentage,
    getAssessmentArea, odarea, checkPrevAssess, checkPreviousAssessments, getCommiteeMembers } = rootStore.partnerdashboardStore
  const { getOdPartnerById , odPartnerDetail } = rootStore.partnerStore
  const { getAssessmentAreaVerify, getAssessmentbyid, assessment } = rootStore.assessmentStore;
  const { getQuestionSetup } = rootStore.questionSetupStore;
  const { getLastAssessment, assessmentByArea, getActionPoints } = rootStore.assessmentStore;
  const { createFederationEndorse } = rootStore.partnerdashboardStore;
  const { role, registrationId } = rootStore.commonStore;
  const id = registrationId !== 0 ? registrationId : props.location.state.Id;
  useEffect(() => {
    getQuestionSetup(rootStore.commonStore.registrationId);
    if (role === UserRole.Administrator) {
      if (props.location.state.Id > 0) {
        checkPreviousAssessments(props.location.state.Id);
        getPartnerDashboard(props.location.state.Id);
        getActionPointDashboard(props.location.state.Id);
        getAcceptedFederation(props.location.state.Id);
        getPoint(props.location.state.Id);
        getPointPercentage(props.location.state.Id);
        getAssessmentArea(props.location.state.Id);
        getActionPoints(props.location.state.Id);

        getOdPartnerById(props.location.state.Id);


        getCommiteeMembers(props.location.state.Id);
        getAssessmentbyid(props.location.state.Id);

      }
    }
    else {
      checkPreviousAssessments(registrationId);
      getPartnerDashboard(registrationId);
      getActionPointDashboard(registrationId);
      getAcceptedFederation(registrationId);
      getPoint(registrationId);
      getPointPercentage(registrationId);
      getAssessmentArea(registrationId);
      getActionPoints(registrationId);
      getOdPartnerById(registrationId);
      getCommiteeMembers(registrationId);



    }
    getNotMappedFederation();
    getOdPartnerById(registrationId)
    getAssessmentAreaVerify(registrationId)
    getLastAssessment(registrationId, true)

  }, [checkPreviousAssessments, props, getPartnerDashboard, getNotMappedFederation, getOdPartnerById,
    getLastAssessment, role, registrationId, getAssessmentAreaVerify, getAcceptedFederation,
    getActionPointDashboard, getPointPercentage, getPoint, getAssessmentArea, getActionPoints, getCommiteeMembers]);
  // function toastDownload() {
  //   //toast.info("Please take the assessment first.");
  //   if (checkPrevAssess?.isNewPartner === true) {
  //     toast.info("Please take the assessment first.")
  //   }
  // }
  // function toastApproveDownload() {
  //   if (checkPrevAssess?.isApproved === false) {
  //     toast.info("Assessment yet to be approve. Please wait for some time");
  //   }
  //   //toast.info("Assessment yet to be approve. Please wait for some time");
  // }
  // function printDocument() {
  //   if (checkPrevAssess?.isNewPartner === true) {
  //     toast.info("Please take the assessment first.")
  //   }
  //   else if (checkPrevAssess?.isApproved === false) {
  //     toast.info("Assessment yet to be approve. Please wait for some time");
  //   }
  //   else {
  //     // Initialize the PDF.
  //     var pdf = new jsPDF("p", "in", [8.5, 11]);
  //     const len = 2;
  //     for (let i = 1; i <= len; i++) {
  //       html2canvas(document.getElementById("divToPrint" + i)!,
  //         {
  //           scale: 1 // Adjusts your resolution
  //         }).then(canvas => {
  //           pdf.addImage(canvas.toDataURL("images/png", 1), 'PNG', 0, 0, 210, 295);

  //           if (i === len) {
  //             pdf.save('sample-file.pdf');
  //           } else {
  //             pdf.addPage();
  //           }
  //         });
  //     }
  //   }

  // }
  return (

    <Container>
      <div>
        {
          loadingDashboard && <LoadingComponent content="Loading PartnerDashboard" />
        }

        <Segment>
          <Grid columns={3}>
            <Grid.Column>
              <Item.Group link>

                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={partnerdashboard?.partnerCEO?.companyLogo} />
                    <Item.Content>
                      <Item.Header onClick={() => {


                      }}>{partnerdashboard?.partnerCEO?.organisationName} </Item.Header>

                      <Item.Meta>{partnerdashboard?.partnerCEO?.ceoName}</Item.Meta>
                      <Item.Meta> {partnerdashboard?.partnerCEO?.ceoEmail} </Item.Meta>
                      <Item.Meta> {partnerdashboard?.partnerCEO?.ceoMobile}</Item.Meta>
                    </Item.Content>
                  </Item>
                ) : (
                  <Item>
                    <Item.Image height='92px' size='tiny' src={rootStore.commonStore.logo} />
                    <Item.Content>
                      <Item.Header onClick={() => {
                      }}>{rootStore.commonStore.organisation}</Item.Header>
                      <Item.Meta>{rootStore.commonStore.fullName}</Item.Meta>
                      <Item.Meta>  {rootStore.commonStore.email}  </Item.Meta>
                      <Item.Meta> {rootStore.commonStore.mobile}</Item.Meta>
                    </Item.Content>
                  </Item>
                )
                }
              </Item.Group>
            </Grid.Column>
            <Grid.Column>
              <Item.Group link>

                <Item>
                  <Item.Content>
                    <br />
                    <Item.Meta style={{ marginLeft: '100px' }}>State:  {partnerdashboard?.partnerCEO?.state}</Item.Meta>
                    <Item.Meta style={{ marginLeft: '100px' }}>Postal Code:  {partnerdashboard?.partnerCEO?.pincode}</Item.Meta>
                    <Item.Meta style={{ marginLeft: '100px' }}>Sector:   {partnerdashboard?.partnerCEO?.sector} </Item.Meta>
                    <Item.Meta style={{ marginLeft: '100px' }}> SubSector:  {partnerdashboard?.partnerCEO?.subSector}</Item.Meta>
                  </Item.Content>
                </Item>

              </Item.Group>
            </Grid.Column>
            {rootStore.commonStore.role === UserRole.ODPartner &&
              <Grid.Column >
                <Grid.Row>
                  <Segment color='blue' style={{ width: '325px' }}>
                    <Grid>
                      <FinalForm

                        onSubmit={(values: IFederationEndorsement) => {
                          values.partnerId = rootStore.commonStore.registrationId;
                          createFederationEndorse(values);
                        }
                        }


                        render={({
                          handleSubmit,
                          form,
                          submitting,
                          submitError,
                          invalid,
                          pristine,
                          dirtySinceLastSubmit,
                          error,
                        }) => (
                          <Form onSubmit={async event => {
                            await handleSubmit(event);
                            form.reset();
                          }}>
                            <Grid.Column width={12} style={{ marginTop: "12px" }}>


                              <Field
                                component={SelectInputTab}
                                placeholder='Member of' name="federationId"
                                search selections={"true"} options={notMappedDropdownValues} label='Membership'
                                labelstyle={{ marginLeft: "12px" }}
                                inputstyle={{ height: "10px" }}
                                errorstyle={{ marginLeft: "383px", marginTop: "8px" }}
                                multiple selection={"true"}
                                loading={loading}
                              >
                              </Field>
                            </Grid.Column>
                            <Grid.Column width={4} >
                              <Button color='blue' icon='right arrow' style={{ marginLeft: "217px" }} onClick={() => { }} />
                            </Grid.Column>
                          </Form>

                        )}>

                      </FinalForm >
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Row>

                <br />
                <Grid.Row>
                  <Button primary color='blue'
                    variant="contained"  >
                    Pay Now</Button>
                  {rootStore.commonStore.role === UserRole.ODPartner &&
                    <Button style={{ marginLeft: '25px' }} color='blue' icon='mail' onClick={() => {
                      openRightPanel('Contact Admin', <CreateMessage />)
                    }} />}

                </Grid.Row>

              </Grid.Column>

            }
          </Grid>
        </Segment>

        <Grid >
          {acceptedFederation.map((currentfed) => (
            <Grid.Column>
              <Image src={currentfed?.companyLogo} size={'mini'} title={currentfed?.organisationName} />
            </Grid.Column>
          ))}
        </Grid>

        {rootStore.commonStore.role === 'ODPartner' &&

          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
              </Grid.Column>
              <Grid.Column width={5}   >
                {!checkPrevAssess?.isNewPartner &&
                  <Button primary color='blue' style={{ marginLeft: '139px' }} onClick={() => {
                    openRightPanel('Previous Assessment', <PreviousAssessment id={id} />)
                  }} variant="contained"> Previous Assessments</Button>
                }
              </Grid.Column>
              <Grid.Column width={3}   >
                {rootStore.commonStore.role === UserRole.ODPartner &&
                  <Button primary color='blue'
                    onClick={() => history.push('/dod-assessment')} variant="contained"  >
                    New Assessment</Button>}
              </Grid.Column>
            </Grid.Row>
          </Grid>

        }


        {rootStore.commonStore.role === UserRole.Administrator && !checkPrevAssess?.isNewPartner &&
          <Grid>
            <Button color="blue" style={{ marginLeft: '925px', color: 'white', padding: '10px' }} onClick={() => {
              openRightPanel('Previous Assessment', <PreviousAssessment id={id} />)
            }} variant="contained"> Previous Assessments</Button>

          </Grid>
        }

        <Segment>
          <Grid>
            <Grid.Column width={12}>
              <div>
                <br />
                {
                  pointPercentage !== undefined
                    ? (
                      <div>
                        <ODComplianceChart data={pointPercentage.pointPercentage} />
                        {
                          (pointPercentage.pointPercentage == 0.0) &&
                          <Label color='blue'>If you have saved your assessment, kindly submit it for approval. If you have already submitted, kindly wait for CIM Approval.  </Label>
                        }
                      </div>

                    )
                    : <Loader active inline='centered' size='small' />}   <br />
                {point?.point !== undefined ? (
                  <div style={{ position: "absolute", top: '3000px' }} >
                    <ReportComplianceChart style={{ display: 'none', width: '100%', height: '80%' }} dataapex={point?.point} />

                  </div>

                ) : (<div></div>)}

                <br />
              </div>
            </Grid.Column>
            <Grid.Column width={4}>

              {assessment && checkPrevAssess?.isApproved === true &&
                <a href={window.location.origin + "/" + assessment.reportDoc} download="Report.pdf">
                  <Button type="button" variant="contained" primary color='blue'>Download Report</Button>
                </a>}
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment>
          <Grid>
            <Grid.Column width={15}>
              <br />
              {odarea !== undefined ? (
                <ODArea areagraph={JSON.stringify(odarea)} />
                // props props as model
              ) : <Loader active inline='centered' size='small' />
              }
              <br />
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment>

          <div>

            <br />
            <h6 style={{ marginLeft: "500px", fontSize: 20, fontWeight: 'bold', fontStyle: 'Italic' }}><b>Action Points</b></h6>
            <br />
            <br />
            {actionPointDashboard !== undefined ? (
              <ActionPoints actionpoint={JSON.stringify(actionPointDashboard)} />
            ) : <Loader active inline='centered' size='small' />}   <br />

          </div>

        </Segment>

      </div>
    </Container>
  );
}
export default observer(ODDashboard);


