import React from 'react'
import { Form, Button, Grid, Icon } from 'semantic-ui-react'

import { useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Form as FinalForm, Field } from 'react-final-form'
import ErrorMessage from '../../app/common/form/ErrorMessage';
import { combineValidators, composeValidators, isRequired, matchesField, matchesPattern } from 'revalidate';
import { FORM_ERROR } from 'final-form';
import TextInput from '../../app/common/form/TextInput';
import { observer } from 'mobx-react-lite';
import { IChangePasswordFormValues } from '../../app/models/user';

const validate = combineValidators({
   
    newPassword: composeValidators(
        isRequired({ message: 'Please enter the Password' }),
        matchesPattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"))({
          message: 'Password must contain at least six characters, including uppercase & lowercase letters, special character and numbers'
        })
      )(),  
      confirmPassword:
      composeValidators(
        isRequired({ message: 'Please enter the Confirm Password' }),
        matchesField('newPassword', 'Enter New Password')({
          message: 'Password and Confirm Password must be same'
        })
      )(),
     
})

interface IProps {
    editTopic?: IChangePasswordFormValues,
}

const ChangePassword: React.FC<IProps> = ({ editTopic }) => {

    const rootStore = useContext(RootStoreContext);
    const { changePassword } = rootStore.authStore;

    return (
        <FinalForm
            initialValues={editTopic}
            // onSubmit={()=>{}}
            onSubmit={(values: IChangePasswordFormValues) =>
                changePassword(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit,form }) => (

                <Form onSubmit={async event => {
                    await handleSubmit(event)
                    form.reset();
                    form.resetFieldState('currentPassword');
                    form.resetFieldState('newPassword');
                    form.resetFieldState('confirmPassword');
                  }} error>
                    <Field component={TextInput} fluid placeholder='Enter Old Password' name="currentPassword" type='password' />
                    <Field component={TextInput} fluid placeholder='Enter New Password' name="newPassword" type='password'/>
                    <Field component={TextInput} fluid placeholder='Confirm Password' name="confirmPassword" type='password' />
                    {submitError &&
                        !dirtySinceLastSubmit &&
                        (
                            <ErrorMessage
                             error={submitError}
                             frontEndError={submitError} />
                        )}
                    <br />
                    <Grid textAlign='center' style={{ margin: '4px' }}>

                        <Button disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting} positive><Icon name='check' /> Change Password </Button>
                    </Grid>
                </Form>
            )}>

        </FinalForm >

    )
}
export default observer(ChangePassword);

