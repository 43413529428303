import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Grid, GridColumn } from 'semantic-ui-react';

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
  FormFieldProps { }

const TextAreaInput: React.FC<IProps> = ({
  input,
  width,
  rows,
  label,
  hidden,
  placeholder,
  readOnly,
  onChange = undefined,
  meta: { touched, error }
}) => {
  return (
    <Grid columns={2} verticalAlign='middle' style={{ paddingTop: '0px', marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
      {label &&
        <GridColumn width={5}>
          <Form.Field hidden={hidden}>
            <label className='fieldLabel'>{label}</label>
            
          </Form.Field>
        </GridColumn>
      }
      <GridColumn width={label ? 11 : 16} >

        <Form.Field error={touched && !!error} width={width} hidden={hidden}>
        
          <textarea rows={rows} {...input} placeholder={placeholder} readOnly={readOnly} 
           onChange={
            (event) => {
              input.onChange(event)
              if (onChange)
                onChange(event)
            }}/>
          
          {touched && error && (
            <Label basic color='red'>
              
              {error}
            </Label>
          )}
        </Form.Field>

      </GridColumn>
    </Grid>
  );
};

export default TextAreaInput;

