
import { action, observable, runInAction } from 'mobx';
import agent from '../api/agent';
import { IFederationDashboard } from '../models/federationDashboard';
import { RootStore } from './rootStore';


export default class federationDashboardStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable loadingDashboard: boolean = false;
  
  @observable federationdashboard?: IFederationDashboard | undefined;
  @observable loadingFederation: boolean = false;

  @action getFederationDashboard = async (id: number) => {
    try {
    //  this.loadingDashboard = true;
      // this.partnerdashboard = undefined;

      const dashboard = await agent.FederationDashboard.getById(id);
      console.log(dashboard);
      runInAction(() => {
        this.federationdashboard = dashboard;
        console.log(this.federationdashboard);
      })

    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
       // this.loadingDashboard = false;
      })
    }
    
  };

}