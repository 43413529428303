import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import { history } from "../..";
import { TryAgain } from "../common/constant";
import {
  IAcceptPartnerEndorse,
  IFederationApproveOrReject,
  IFederationName,
  IFederationRegister,
  FederationRegisterValues,
  IFederationRegisterValues,
  IPartnerEndorse,
  IPartnerEndorsed,
} from "../models/federation";
import { IFedRegistrationDone } from "../models/fedregistrationDone";
import { IFedRegistrationAdmin } from "../models/fedregistrationAdmin";

export default class FederationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable federationList: IFederationRegisterValues[] = [];
  @observable federationName: IFederationName[] = [];
  @observable
  federationNameDropdownValues: DropdownItemProps[] = [];
  @observable partnerEndorseList: IPartnerEndorse[] = [];
  @observable partnerEndorsedList: IPartnerEndorsed[] = [];
  @observable loadingUsers: boolean = false;
  @observable userDropdownValues: DropdownItemProps[] = [];
  @observable roleDropdownValues: DropdownItemProps[] = [
    {
      text: "Administrator",
      value: "Administrator",
      key: 1,
    },
  ];
  @observable loadingFederationDetail: boolean = false;

  @observable IsRegister: boolean = false;
  @observable loadingFederation: boolean = false;
  @observable IsFedRegister: boolean = false;
  @observable IsAcceptOrReject: boolean = false;
  @observable federationDetail?: IFederationRegister | any;
  @observable federationApproveOrReject: IFederationApproveOrReject | any;
  @observable federationAcceptPartner: IAcceptPartnerEndorse | any;
  @observable savedandnextFederation: IFederationRegisterValues | undefined;
  @observable creatingFederation = false;
  @observable loadingSavedFederationDetails = false;
  @observable partnerDetails: DropdownItemProps[] = [];
  @observable userSendSms: IFedRegistrationDone | undefined;
  @observable userSendSmsAdmin: IFedRegistrationAdmin | undefined;
  //@observable userVerifySms: IRegistrationVerifySms | undefined;
  @observable loadingSendSms: boolean = false;
  @observable loadingSendSmsAdmin: boolean = false;
  @action createFederation = async (newUser: FederationRegisterValues) => {
    try {
      console.log(newUser);
      this.IsFedRegister = true;
      let dataMap: Map<string, string> = new Map();
      if (newUser.OrganizationName !== undefined)
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (newUser.YearOfInception !== undefined)
        dataMap.set("YearOfInception", newUser.YearOfInception.toString());
      if (newUser.RegisteredAddress !== undefined)
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (newUser.CommunicationAddress !== undefined)
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.OfficialEmailId !== undefined)
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined)
        dataMap.set("Password", newUser.Password);
      if (newUser.OfficeContactumber !== undefined)
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      if (newUser.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
      if (newUser.Pincode !== undefined)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined) dataMap.set("State", newUser.State);
      if (newUser.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.SectorId !== undefined)
        dataMap.set("SectorId", newUser.SectorId);
      if (newUser.SectorOther !== undefined)
        dataMap.set("SectorOther", newUser.SectorOther);
      if (newUser.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (newUser.OtherMediaLink !== undefined)
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
      if (newUser.CEOTitle !== undefined)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (newUser.CEODesignation !== undefined)
        dataMap.set("CEODesignation", newUser.CEODesignation);

      // console.log(dataMap)
      var obj = newUser.FederationContactJson;
      var stringObj = JSON.stringify(obj);
      dataMap.set("FederationContactJson", stringObj);
      console.log(dataMap);

      let fileMap: Map<string, string> = new Map();
      if (newUser.CEOPhoto)
        fileMap.set("CEOPhoto", newUser.CEOPhoto);
      if (newUser.OrganizationLogoFile)
        fileMap.set("OrganizationLogoFile", newUser.OrganizationLogoFile);
      if (newUser.TrusteeorManagementProfile)
        fileMap.set(
          "TrusteeorManagementProfile",
          newUser.TrusteeorManagementProfile
        );
      const userResponse = await agent.Federation.createWithFile(
        dataMap,
        fileMap
      );
      console.log(fileMap);
      runInAction(() => {
        this.IsFedRegister = false;
        this.federationList = [...this.federationList, userResponse];
        if (userResponse === -1) {
          toast.info("Email id already Registered.Try using different id");
        } else {
          toast.info("User Registered Successfully");
        }
        history.push("/thankyoufed");
      });
    } catch (error) {
      throw error;
    }
  };
  @action upsertFederation = async (user: FederationRegisterValues) => {
    if (user.FederationId === undefined || user.FederationId === 0)
      await this.createFederation(user);
    else await this.editFederation(user);
  };
  @action editFederation = async (newUser: FederationRegisterValues) => {
    try {
      this.IsRegister = true;
      let dataMap: Map<string, any> = new Map();
      if (newUser.FederationId !== undefined)
        dataMap.set("FederationBasicDetailId", newUser.FederationId);
      if (
        newUser.OrganizationName !== undefined &&
        newUser.OrganizationName !== null
      )
        dataMap.set("OrganizationName", newUser.OrganizationName);
      if (
        newUser.YearOfInception !== undefined &&
        newUser.YearOfInception !== null
      )
        dataMap.set("YearOfInception", newUser.YearOfInception);
      if (
        newUser.RegisteredAddress !== undefined &&
        newUser.RegisteredAddress !== null
      )
        dataMap.set("RegisteredAddress", newUser.RegisteredAddress);
      if (
        newUser.CommunicationAddress !== undefined &&
        newUser.CommunicationAddress !== null
      )
        dataMap.set("CommunicationAddress", newUser.CommunicationAddress);
      if (newUser.Pincode !== undefined && newUser.Pincode !== null)
        dataMap.set("Pincode", newUser.Pincode);
      if (newUser.State !== undefined && newUser.State !== null)
        dataMap.set("State", newUser.State);
      if (
        newUser.OfficialEmailId !== undefined &&
        newUser.OfficialEmailId !== null
      )
        dataMap.set("OfficialEmailId", newUser.OfficialEmailId);
      if (newUser.Password !== undefined && newUser.Password !== null)
        dataMap.set("Password", newUser.Password);
      if (
        newUser.OfficeContactumber !== undefined &&
        newUser.OfficeContactumber !== null
      ) {
        dataMap.set("OfficeContactumber", newUser.OfficeContactumber);
      }
      if (
        newUser.MobileContactNumber !== undefined &&
        newUser.MobileContactNumber !== null
      )
        dataMap.set("MobileContactNumber", newUser.MobileContactNumber);
      if (newUser.State !== undefined && newUser.State !== null)
        dataMap.set("State", newUser.State);
      if (
        newUser.WebsiteAddress !== undefined &&
        newUser.WebsiteAddress !== null
      )
        dataMap.set("WebsiteAddress", newUser.WebsiteAddress);
      if (newUser.SectorId !== undefined && newUser.SectorId !== null)
        dataMap.set("SectorId", newUser.SectorId);
      if (
        newUser.WhatsappNumber !== undefined &&
        newUser.WhatsappNumber !== null
      )
        dataMap.set("whatsappNumber", newUser.WhatsappNumber);
      if (newUser.FacebookID !== undefined && newUser.FacebookID !== null)
        dataMap.set("FacebookID", newUser.FacebookID);
      if (newUser.TwitterID !== undefined && newUser.TwitterID !== null)
        dataMap.set("TwitterID", newUser.TwitterID);
      if (
        newUser.OtherMediaLink !== undefined &&
        newUser.OtherMediaLink !== null
      )
        dataMap.set("OtherMediaLink", newUser.OtherMediaLink);
        if (newUser.TrusteeorManagementProfile) {
          if (
            newUser.TrusteeorManagementProfile !== undefined &&
            newUser.TrusteeorManagementProfile !== null
          )
            dataMap.set(
              "TrusteeorManagementProfile",
              newUser.TrusteeorManagementProfile.toString()
            );
        }
      if (newUser.CEOTitle !== undefined && newUser.CEOTitle !== null)
        dataMap.set("CEOTitle", newUser.CEOTitle);
      if (newUser.CEOFirstName !== undefined && newUser.CEOFirstName !== null)
        dataMap.set("CEOFirstName", newUser.CEOFirstName);
      if (newUser.CEOLastName !== undefined && newUser.CEOLastName !== null)
        dataMap.set("CEOLastName", newUser.CEOLastName);
      if (newUser.CEOMobile !== undefined && newUser.CEOMobile !== null)
        dataMap.set("CEOMobile", newUser.CEOMobile);
      if (newUser.CEOEmailId !== undefined && newUser.CEOEmailId !== null)
        dataMap.set("CEOEmailId", newUser.CEOEmailId);
      if (
        newUser.CEODesignation !== undefined &&
        newUser.CEODesignation !== null
      )
        dataMap.set("CEODesignation", newUser.CEODesignation);
      let fileMap: Map<string, string> = new Map();
      if (newUser.OrganizationLogoFile)
        fileMap.set("OrganizationLogoFile", newUser.OrganizationLogoFile[0]);
      if (newUser.CEOPhoto)
        fileMap.set("CEOPhoto", newUser.CEOPhoto[0]);
      if (newUser.TrusteeorManagementProfile)
        fileMap.set(
          "TrusteeorManagementProfile",
          newUser.TrusteeorManagementProfile[0]
        );
      if (newUser.ContactTitle !== undefined && newUser.ContactTitle !== null)
        dataMap.set("ContactTitle", newUser.ContactTitle);

      if (
        newUser.ContactFirstName !== undefined &&
        newUser.ContactFirstName !== null
      )
        dataMap.set("ContactFirstName", newUser.ContactFirstName);

      if (
        newUser.ContactLastName !== undefined &&
        newUser.ContactLastName !== null
      )
        dataMap.set("ContactLastName", newUser.ContactLastName);

      if (newUser.ContactMobile !== undefined && newUser.ContactMobile !== null)
        dataMap.set("ContactMobile", newUser.ContactMobile);

      if (
        newUser.ContactEmailId !== undefined &&
        newUser.ContactEmailId !== null
      )
        dataMap.set("ContactEmailId", newUser.ContactEmailId);

      if (
        newUser.ContactDesignation !== undefined &&
        newUser.ContactDesignation !== null
      )
        dataMap.set("ContactDesignation", newUser.ContactDesignation);

      // var obj1 = newUser.PartnerContactPersonJson;
      // var contactObj = JSON.stringify(obj1);
      // dataMap.set("PartnerContactPersonJson", contactObj);
      // console.log(dataMap);
      const userResponse = await agent.ODPartner.editWithFile(
        newUser.FederationId,
        dataMap,
        fileMap
      );
      runInAction(() => {
        this.IsRegister = false;
        this.federationList = [...this.federationList, userResponse];
        // if (userResponse === -1) {
        //   toast.error("Email id already Registered.Try using different id");
        // } else {
        toast.info("User Edited Successfully");
        this.rootStore.commonStore.federationId = 0;
        // }
      });
    } catch (error) {
      toast.error("Please try again after some time");
      this.rootStore.commonStore.federationId = 0;
    }
  };
  FederationArray: any;

  @action getFederationInfoById = async (id: number) => {
    try {
      this.loadingFederationDetail = true;

      this.federationDetail = undefined;

      const federation = await agent.Federation.getById(id);
      console.log(federation);
      runInAction(() => {
        this.federationDetail = federation;
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingFederationDetail = false;
      });
    }
  };

  //get federation Name in OD Register Dropdown
  @action getFederationName = async () => {
    try {
      this.loadingFederationDetail = true;
      const federation = await agent.Federation.list();
      console.log(federation);
      runInAction(() => {
        this.federationName = federation;
        this.updateDropdownValues();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingFederationDetail = false;
      });
    }
  };

  @action updateDropdownValues() {
    let dropDownValues: DropdownItemProps[] = [];

    this.federationName.map((reason) => {
      let item = {
        text: reason.organizationName,
        value: reason.fedarationBasicDetailId,
        key: reason.fedarationBasicDetailId,
      };
      dropDownValues.push(item);
      // }
      return reason;
    });

    this.federationNameDropdownValues = dropDownValues;
  }
  @action fedregisterationDone = async (values: IFedRegistrationDone) => {
    this.loadingSendSms = true;
    try {
      const user = await agent.FedDoneRegister.create(values);
      runInAction(() => {
        this.userSendSms = user;
        this.loadingSendSms = false;
      });
    } catch (error) {
      this.loadingSendSms = false;
      toast.error(TryAgain);
      throw error;
    }
  };
  @action fedregisterationAdmin = async (values: IFedRegistrationAdmin) => {
    this.loadingSendSmsAdmin = true;
    try {
      const user = await agent.FedRegisterAdmin.create(values);
      runInAction(() => {
        this.userSendSmsAdmin = user;
        this.loadingSendSmsAdmin = false;
      });
    } catch (error) {
      this.loadingSendSmsAdmin = false;
      toast.error(TryAgain);
      throw error;
    }
  };
  @action getWaitingPartnerEndorse = async (id: number, status: string) => {
    try {
      this.loadingFederation = true;
      this.loadingFederationDetail = true;
      const federation = await agent.Federation.getWaitingPartnerById(
        id,
        status
      );
      console.log(federation);
      runInAction(() => {
        if (status === "Pending") {
          this.partnerEndorseList = federation;
        } else if (status === "Accepted") {
          this.partnerEndorsedList = federation;
        }
        this.updateDropDownValue();
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingFederation = false;
        this.loadingFederationDetail = false;
      });
    }
  };

  @action updateDropDownValue() {
    let dropDownValues: DropdownItemProps[] = [];
    this.partnerEndorsedList.map((partner) => {
      let item = {
        text: partner.partnerName,
        value: partner.partnerKey,
        key: partner.partnerId,
      };

      dropDownValues.push(item);
      return partner;
    });

    this.partnerDetails = dropDownValues;
  }

  @action federationApprove = async (approve: IFederationApproveOrReject) => {
    try {
      console.log(approve);
      const federationApproveResponse = await agent.Federation.approve(approve);
      console.log(federationApproveResponse);
      runInAction(() => {
        this.federationApproveOrReject = federationApproveResponse;
        console.log(this.federationApproveOrReject);
        toast.info(
          "Federation " +
            (approve.IsApproved ? "approved" : "rejected")
        );
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };

  @action waitingPartnerApprove = async (approve: IAcceptPartnerEndorse) => {
    try {
      console.log(approve);
      this.IsAcceptOrReject = true;
      const federationApproveResponse = await agent.Federation.approvePartner(
        approve
      );
      console.log(federationApproveResponse);
      runInAction(() => {
        this.federationAcceptPartner = federationApproveResponse;
        console.log(this.federationApproveOrReject);
        this.IsAcceptOrReject = false;
        if (approve.isAccept === true) {
          toast.success(
            approve.partnerName +
              " has been a Member of " +
              approve.federationName
          );
        } else {
          toast.warn(
            approve.partnerName +
              " has not been a Member of " +
              approve.federationName
          );
        }
        this.rootStore.modalStore.closeModal();
      });
    } catch (error) {
      throw error;
    } finally {
    }
  };
  @action getSavedAndNextFederationDetails = async (
    contactNumber: FederationRegisterValues
  ) => {
    this.loadingSavedFederationDetails = true;
    try {
      const newBene = await agent.Federation.getSavedAndNextFederation(
        contactNumber
      );
      runInAction(() => {
        this.federationDetail = newBene;
        this.loadingSavedFederationDetails = false;
      });
    } catch (ex) {
      //history.push('/beneficiary');

      this.loadingSavedFederationDetails = false;
    }
  };
  @action saveandnextFederationDetails = async (
    federation: IFederationRegisterValues
  ) => {
    try {
      this.IsRegister = true;
      let dataMap: Map<string, any> = new Map();
      if (federation.OrganizationName !== undefined)
        dataMap.set("OrganizationName", federation.OrganizationName);
      if (federation.YearOfInception !== undefined)
        dataMap.set("YearOfInception", federation.YearOfInception);
      if (federation.RegisteredAddress !== undefined)
        dataMap.set("RegisteredAddress", federation.RegisteredAddress);
      if (federation.CommunicationAddress !== undefined)
        dataMap.set("CommunicationAddress", federation.CommunicationAddress);
      if (federation.Pincode !== undefined)
        dataMap.set("Pincode", federation.Pincode);
      if (federation.State !== undefined)
        dataMap.set("State", federation.State);
      if (federation.OfficialEmailId !== undefined)
        dataMap.set("OfficialEmailId", federation.OfficialEmailId);
      if (federation.Password !== undefined)
        dataMap.set("Password", federation.Password);
      if (federation.OfficeContactumber !== undefined) {
        dataMap.set("OfficeContactumber", federation.OfficeContactumber);
      }
      if (federation.MobileContactNumber !== undefined)
        dataMap.set("MobileContactNumber", federation.MobileContactNumber);
      if (federation.WebsiteAddress !== undefined)
        dataMap.set("WebsiteAddress", federation.WebsiteAddress);
      if (federation.WhatsappNumber !== undefined)
        dataMap.set("whatsappNumber", federation.WhatsappNumber);
      if (federation.FacebookID !== undefined)
        dataMap.set("FacebookID", federation.FacebookID);
      if (federation.TwitterID !== undefined)
        dataMap.set("TwitterID", federation.TwitterID);
      if (federation.CEOTitle !== undefined)
        dataMap.set("CEOTitle", federation.CEOTitle);
        if (federation.TrusteeorManagementProfile) {
          if (federation.TrusteeorManagementProfile !== undefined)
            dataMap.set(
              "TrusteeorManagementProfile",
              federation.TrusteeorManagementProfile.toString()
            );
        }
      if (federation.CEOFirstName !== undefined)
        dataMap.set("CEOFirstName", federation.CEOFirstName);
      if (federation.CEOLastName !== undefined)
        dataMap.set("CEOLastName", federation.CEOLastName);
      if (federation.CEOMobile !== undefined)
        dataMap.set("CEOMobile", federation.CEOMobile);
      if (federation.CEOEmailId !== undefined)
        dataMap.set("CEOEmailId", federation.CEOEmailId);
      if (federation.CEODesignation !== undefined)
        dataMap.set("CEODesignation", federation.CEODesignation);

      let fileMap: Map<string, string> = new Map();
      if (federation.OrganizationLogoFile)
        fileMap.set("OrganizationLogo", federation.OrganizationLogoFile);
      if (federation.CEOPhoto)
        fileMap.set("CEOPhoto", federation.CEOPhoto);
      if (federation.TrusteeorManagementProfile)
        fileMap.set(
          "TrusteeorManagementProfile",
          federation.TrusteeorManagementProfile
        );

      if (federation.ContactTitle !== undefined)
        dataMap.set("ContactTitle", federation.ContactTitle);

      if (federation.ContactFirstName !== undefined)
        dataMap.set("ContactFirstName", federation.ContactFirstName);

      if (federation.ContactLastName !== undefined)
        dataMap.set("ContactLastName", federation.ContactLastName);

      if (federation.ContactMobile !== undefined)
        dataMap.set("ContactMobile", federation.ContactMobile);

      if (federation.ContactEmailId !== undefined)
        dataMap.set("ContactEmailId", federation.ContactEmailId);

      if (federation.ContactDesignation !== undefined)
        dataMap.set("ContactDesignation", federation.ContactDesignation);

      // var obj1 = newUser.PartnerContactPersonJson;
      // var contactObj = JSON.stringify(obj1);
      // dataMap.set("PartnerContactPersonJson", contactObj);
      // console.log(dataMap);
      //     const userResponse = await agent.ODPartner.createWithFile(
      //       dataMap,
      //       fileMap
      //     );
      //     runInAction(() => {
      //       this.IsRegister = false;
      //       this.partnerList = [...this.partnerList, userResponse];
      //       if (userResponse === -1) {
      //         toast.error("Email id already Registered.Try using different id");
      //       } else {
      //         toast.info("Registered Successfully.Our Admin will review and approve shortly.");
      //       }
      //     });
      //   } catch (error) {
      //     toast.error("Please try again after some time");
      //   }
      // };

      const saveandnextFederation = await agent.Federation.saveandnextFederation(
        dataMap,
        fileMap
      );

      runInAction(() => {
        this.savedandnextFederation = saveandnextFederation;
        console.log(this.savedandnextFederation);
        // if (saveODPartner === -1) {
        //   toast.error("Email id Already Registered");
        // }
        this.IsRegister = false;
      });
      return saveandnextFederation;
    } catch (ex) {
      runInAction(() => {
        this.IsRegister = false;
      });
      //   toast.error(ex?.data.errors.message);
      throw ex;
    }
  };
}
