import { observable, action, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { toast } from "react-toastify";
import { IManageUser, IManageUserDisable } from "../models/manageUser";

export default class ManageUserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable UserDetail?: IManageUser | undefined;
  @observable manageUserList: IManageUser[] = [];
  @observable loadingPlan = false;
  @observable loadingSubmit :boolean= false;
  @observable selectedUserDetail: IManageUser | undefined = undefined;



  @action getAllUsers = async () => {
    this.loadingPlan = true;
    try {
      const userList: IManageUser[] = await agent.ManageUser.list();
      console.log(userList);
      runInAction(() => {
        this.manageUserList = userList;
        this.loadingPlan = false;
      });
    } catch {
      runInAction(() => {
        this.loadingPlan = false;
      });
    }
  };
  @action getUserFromId = (userId: string): IManageUser =>{
     
    let index = this.manageUserList.findIndex(b => b.userId === userId);
    return this.manageUserList[index];
}
  @action getById = async (userId: string) => {
    this.UserDetail = undefined;
    const detail = await agent.ManageUser.getById(userId);
    console.log(detail);

    runInAction(() => {
      this.selectedUserDetail = detail; 
    });
  };

  @action createUser = async (user: IManageUser) => {
    this.loadingSubmit =  true;
    let dataMap: Map<string, string> = new Map();
    if (user.userName !== undefined) dataMap.set('UserName', user.userName);
    if (user.fullName !== undefined) dataMap.set('FullName', user.fullName);
    if (user.userMobile !== undefined) dataMap.set('MobileNumber', user.userMobile);
    if (user.userEmail !== undefined) dataMap.set('UserEmail', user.userEmail);
    if (user.password !== undefined) dataMap.set('Password', user.password);
    if (user.role !== undefined) dataMap.set('Role', user.role);
    if (user.designation !== undefined) dataMap.set('Designation', user.designation);
    if (user.organisation !== undefined) dataMap.set('Organisation', user.organisation);
    let fileMap: Map<string, string> = new Map();
    if (user.logo)
      fileMap.set("Logo", user.logo[0]);
    const newUser = await agent.ManageUser.create(dataMap, fileMap);
    console.log(newUser)

    runInAction(() => {
      this.manageUserList = [...this.manageUserList, newUser];

      console.log(this.manageUserList);
      this.loadingSubmit= false;

      // this.updateDropdownValues();
      if(newUser.userId === "-1"){
        toast.info("User " + user.userName + " Already Exists");
      }
      else
      {
      toast.info("User " + user.userName + " successfully created");
      }
      this.rootStore.modalStore.closeModal();
    });
  };



  @action upsertUser = async (user: IManageUser) => {
    if (user.userId === undefined || user.userId === "")
      await this.createUser(user);
    else await this.editUser(user);
    await this.getAllUsers();
  };

  @action editUser = async (user: IManageUser) => {
    this.loadingSubmit =  true;
    let dataMap: Map<string, string> = new Map();
    if (user.userName !== undefined) dataMap.set('UserName', user.userName);
    if (user.fullName !== undefined) dataMap.set('FullName', user.fullName);
    if (user.userMobile !== undefined) dataMap.set('MobileNumber', user.userMobile);
    if (user.userEmail !== undefined) dataMap.set('UserEmail', user.userEmail);
    if (user.password !== undefined) dataMap.set('Password', user.password);
    if (user.role !== undefined) dataMap.set('Role', user.role);
    if (user.designation !== undefined && user.designation !== null) dataMap.set('Designation', user.designation);
    if (user.organisation !== undefined && user.organisation !== null ) dataMap.set('Organisation', user.organisation);
    let fileMap: Map<string, string> = new Map();
    if (user.logo)
      fileMap.set("Logo", user.logo[0]);
    const editUser = await agent.ManageUser.update(user.userId, dataMap, fileMap);
    console.log(editUser); 
    runInAction(() => {
      this.loadingSubmit = false;
      this.manageUserList = [...this.manageUserList, editUser];      
      toast.info("User " + user.userName + " successfully updated");
      this.rootStore.modalStore.closeModal();
    });
  };

  @action DisableUser = async (selectedUser: IManageUser) => {
    console.log(selectedUser.isDisabled);
    if (selectedUser.userId) {
      let disableUser: IManageUserDisable = {
        userId: selectedUser.userId,
        isDisabled: !selectedUser.isDisabled,
      };
      console.log(disableUser.isDisabled);
      await agent.ManageUser.disable(disableUser);
      runInAction(() => {
        let index = this.manageUserList.findIndex(
          (b) => b.userId === selectedUser.userId
        );
        //  Object.assign(this.planList[index], selectedPlan);
        this.manageUserList[index].isDisabled = disableUser.isDisabled;

        // this.departments[index].isDisabled = disableDepartment.isDisabled;

        if (selectedUser.isDisabled === true) {
          toast.info(
            "User" + selectedUser.userName + " successfully Enabled"
          );
        } else {
          toast.info("User " + selectedUser.userName + " successfully Disabled");
        }

        this.rootStore.modalStore.closeModal();
      });
    }
  };

}
