import React, { useContext, Fragment } from "react";

import { Button, Form, Icon } from "semantic-ui-react";
import { Form as FinalForm } from 'react-final-form'
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import { IManageUser } from "../../../app/models/manageUser";

interface IProps {
  disableEnableUser: IManageUser,
}
const DisableUser: React.FC<IProps> = ({ disableEnableUser }) => {
  const rootStore = useContext(RootStoreContext);
  const { DisableUser } = rootStore.manageUserStore;

  return (

    <Fragment>
      <FinalForm
        initialValues={disableEnableUser}
        onSubmit={(values: IManageUser) =>
          DisableUser(values)
            .catch(error => ({ [FORM_ERROR]: error }))
        }
        render={({ handleSubmit, submitting, submitError }) => (
          <Form onSubmit={handleSubmit} error>
            Are you sure want to {disableEnableUser?.isDisabled ? 'enable' : 'disable'} User {disableEnableUser?.userName}??
            <br />
            <br />
            {submitError &&
              (
                <ErrorMessage error={submitError} />
              )}

            <Button
              loading={submitting}
              color='blue' style={{ marginLeft: '130px' }}
            >
              <Icon name={disableEnableUser.isDisabled ? 'check' : 'delete'} />
              {disableEnableUser?.isDisabled ? 'Enable' : 'Disable'}
            </Button>

            <br /></Form>

        )} />
    </Fragment>


  );
}

export default observer(DisableUser);
