import React, {   useContext, useEffect } from 'react';
import { Button, Container } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import {  Form as FinalForm } from "react-final-form" ;
import UserList from './UserList';
import CreateUser from './CreateUser';
import { RootStoreContext } from "../../../app/stores/rootStore";

//const ODD = '/assets/images/ODDevelopment.jpg'


const ManageUser =() => {
    // const [index, setIndex] = useState(1);
    //const rootStore = useContext(RootStoreContext);
      //const { getPlan, loadingPlan, planList} = rootStore.planStore;
      const rootStore = useContext(RootStoreContext);
    
    const { openRightPanel} = rootStore.rightPanelStore;
      
      useEffect(() => {
       // getPlan();
        
    }, /* [getPlan] */);
    
        return (
            <FinalForm 
            onSubmit={() => {}}
        
    
        render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
        <Container>
               
                
               
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                   {/*  <h4 className="title" style={{ color: 'black' }}>Pricing Plan</h4> */}
                                    <div className='App-plan'>

                                        <h3 style={{ color: 'white' }}>Great strength comes from faith in  GOD</h3>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                     {/*    {loadingPlan && (!planList || planList?.length === 0) &&
                                <LoadingComponent content='Loading Plans' />} */}
                      
                        <Button
                       
             color='blue'
             content='CREATE USER'
             floated ='right'
             onClick={() => {
              
              openRightPanel('Create User', <CreateUser />)
              }} />
                    </div>
                </div>
         <UserList/>
            </Container>
            

    )}>
          
    </FinalForm>
        );
    }

export default observer(ManageUser);