import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import TextInput from "../../../../app/common/form/TextInput";
import { ISectorFormValues } from "../../../../app/models/sector";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
  editSector?: ISectorFormValues;
}

const CreateEditSector: React.FC<IProps> = ({ editSector }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    upsertSector,    
    loadingSectorerror,
  } = rootStore.sectorStore;

  return (
    <FinalForm
      initialValues={editSector}
      onSubmit={(values: ISectorFormValues) => {
        upsertSector(values).catch((error) => ({
          [FORM_ERROR]: error,
        }));
      }}
      render={({
        handleSubmit: sectorSubmit,
        form,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Fragment>
          <div style={{ marginLeft: "50px" }}>
            <Form
              onSubmit={async (event) => {
                await sectorSubmit(event);
                if (editSector === undefined) {
                  form.reset();
                }
              }}
            >
              <Field
                component={TextInput}
                fluid
                placeholder="Enter Sector Name"
                name="sectorName"
              />
              <Field
                component={TextInput}
                fluid
                placeholder="Enter Sector Description"
                name="sectorDescription"
              />

              <Grid textAlign="center" style={{ margin: "4px" }}>
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={loadingSectorerror}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </div>
        </Fragment>
      )}
    ></FinalForm>
  );
};
export default observer(CreateEditSector);
