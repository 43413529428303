import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormFieldProps, Form, Label } from "semantic-ui-react";

interface IProps extends FieldRenderProps<any, HTMLElement>, FormFieldProps {}

const TextInputTab: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  label,
  mandate,
  labelstyle,
  inputstyle,
  errorstyle,
  mandatestyle,
  hidden,
  readOnly = false,
  onChange = undefined,
  allowAge = false,
  minlength = false,
  meta: { touched, error },
}) => {
  return (
    <Form.Field>
      {label && (
        <label style={labelstyle} className="fieldLabel">
          {label}{" "}
          {mandate && (
            <span style={{ color: "red" , fontSize: 18, fontWeight: 'bold',}} className="fieldLabel">
              {mandate}
            </span>
          )}
        </label>
      )}

      <input
        autoComplete="new-password"
        {...input} 
        style={inputstyle}
        hidden = {hidden}
        placeholder={placeholder}
        readOnly={readOnly}
        width={width}
        // multiple={input.multiple}
        onChange={(event) => {
          input.onChange(event);
          if (onChange) onChange(event);
        }}
        min={allowAge ? 60 : undefined}
      />
      {touched && error && (
        <Label basic color="red" style={errorstyle}>
          {error}
        </Label>
      )}
    </Form.Field>
  );
};
export default TextInputTab;
