import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import {
  Button,
  Segment,
  Header,
  Icon,
  Form,
  Card,
  GridColumn,
  Grid,
} from "semantic-ui-react";
// import SelectInput from "../../../app/common/form/SelectInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import EditPlan from "./EditPlan";
import { UserRole } from "../../../app/common/enum";
import DisablePlan from "./DisablePlan";
import { IPlanFormValues } from "../../../app/models/plan";

const PlanList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getAllPlans, planList, getPlanFromId } = rootStore.planStore;
  const { openRightPanel } = rootStore.rightPanelStore;
  const secondColumnStart = Math.floor(planList.length / 2);
  const { openModal } = rootStore.modalStore;
  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  return (
    <Fragment>
      <br />
      <br />
      <Grid columns={2}>
        <GridColumn width={7}>
          {planList &&
            planList.slice(secondColumnStart).map((plans) => (
              <Segment style={{ marginLeft: "75px" }}>
                <Segment color="violet">
                  <Header as="h4" icon textAlign="center">
                    <Header.Content>
                      {plans.organisationType}
                      <br />
                      {plans.entityType}&nbsp;({plans.planType})
                    </Header.Content>
                  </Header>
                </Segment>
                {plans.planFeatureViewModel.map((featureModel) => (
                  <Card.Description
                    key={featureModel.planFeatureId}
                    style={{ textAlign: "center" }}
                  >
                    {featureModel.feature}
                  </Card.Description>
                ))}
                <Card.Description style={{ textAlign: "center" }}>
                  {plans.planAmount}
                </Card.Description>
                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Form>
                    <br />
                    <br />
                    <Button.Group attached="bottom">
                      <Button
                        basic
                        color="blue"
                        onClick={() => {
                          openRightPanel(
                            "Edit Plan",
                            <EditPlan editPlan={plans} />
                          );
                        }}
                      >
                        <Icon name="edit" />
                        View & Edit
                      </Button>
                      <Button
                        basic
                        color="blue"
                        onClick={() => {
                          let plan: IPlanFormValues = getPlanFromId(
                            plans.planId
                          );

                          openModal(
                            `${plan.isActive ? "Disable" : "Enable"} Plan`,
                            <DisablePlan disableEnablePlan={plan} />
                          );
                        }}
                      >
                        {plans.isActive === true && (
                          <Icon name="check" color="green" />
                        )}
                        {plans.isActive === false && (
                          <Icon name="close" color="red" />
                        )}
                        {plans.isActive ? "Disable" : "Enable"}
                      </Button>
                    </Button.Group>
                  </Form>
                ) : (
                  <div>
                    <Button basic color="blue" style={{ marginLeft: "114px" }}>
                      <Icon name="shopping cart" />
                      Buy Now
                    </Button>
                  </div>
                )}
              </Segment>
            ))}
        </GridColumn>

        <GridColumn width={7}>
          {planList &&
            planList.slice(0, secondColumnStart).map((plans) => (
              <Segment style={{ marginLeft: "75px" }}>
                <Segment color="violet">
                  <Header as="h4" icon textAlign="center">
                    <Header.Content>
                      {plans.organisationType}
                      <br />
                      {plans.entityType}&nbsp;({plans.planType})
                    </Header.Content>
                  </Header>
                </Segment>
                {plans.planFeatureViewModel.map((featureModel) => (
                  <Card.Description
                    key={featureModel.planFeatureId}
                    style={{ textAlign: "center" }}
                  >
                    {featureModel.feature}
                  </Card.Description>
                ))}
                <Card.Description style={{ textAlign: "center" }}>
                  {plans.planAmount}
                </Card.Description>
                {rootStore.commonStore.role === UserRole.Administrator ? (
                  <Form>
                    <br />
                    <br />

                    <Button.Group attached="bottom">
                      <Button
                        basic
                        color="blue"
                        onClick={() => {
                          openRightPanel(
                            "Edit Plan",
                            <EditPlan editPlan={plans} />
                          );
                        }}
                      >
                        <Icon name="edit" />
                        View & Edit
                      </Button>
                      <Button
                        basic
                        color="blue"
                        onClick={() => {
                          let plan: IPlanFormValues = getPlanFromId(
                            plans.planId
                          );
                          openModal(
                            `${plan.isActive ? "Disable" : "Enable"} Plan`,
                            <DisablePlan disableEnablePlan={plan} />
                          );
                        }}
                      >
                        {plans.isActive === true && (
                          <Icon name="check" color="green" />
                        )}
                        {plans.isActive === false && (
                          <Icon name="close" color="red" />
                        )}
                        {plans.isActive ? "Disable" : "Enable"}
                      </Button>
                    </Button.Group>
                  </Form>
                ) : (
                  <div>
                    <Button basic color="blue" style={{ marginLeft: "114px" }}>
                      <Icon name="shopping cart" />
                      Buy Now
                    </Button>
                  </div>
                )}
              </Segment>
            ))}
        </GridColumn>
      </Grid>
    </Fragment>
    /*  )}
    ></FinalForm>  */
  );
};
export default observer(PlanList);
