import React, { useEffect, useState } from "react";
import { Grid, Icon, Step, Table } from "semantic-ui-react";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import { LoadingComponent } from "../../app/layout/LoadingComponent";

function AdminInbox() {
  const rootStore = useContext(RootStoreContext);
  const {
    getContactList,
    contactList,
    loadingContact,
    readContact,
  } = rootStore.contactStore; 

  const [stepperIndex, setStepperIndex] = useState(1);
  console.log(contactList);
  useEffect(() => {
    getContactList("0");
    readContact("0");
  }, [getContactList,readContact]);
  return (
    <div>
      {loadingContact && <LoadingComponent content="Loading Contacts" />}

      <Step.Group widths={4} size="small">
        <Step active={stepperIndex === 1} onClick={() => setStepperIndex(1)}>
          <Icon name="mail" />
          <Step.Content>
            <Step.Title>Pre - Login Query</Step.Title>
          </Step.Content>
        </Step>
        <Step active={stepperIndex === 2} onClick={() => setStepperIndex(2)}>
          <Icon name="mail" />
          <Step.Content>
            <Step.Title>Partner Message</Step.Title>
          </Step.Content>
        </Step>
        <Step active={stepperIndex === 3} onClick={() => setStepperIndex(3)}>
          <Icon name="mail" />
          <Step.Content>
            <Step.Title>Endorsed Message</Step.Title>
          </Step.Content>
        </Step>
        <Step active={stepperIndex === 4} onClick={() => setStepperIndex(4)}>
          <Icon name="mail" />
          <Step.Content>
            <Step.Title> Donor Interest message</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
      {stepperIndex === 1 && (
        <Grid stackable padded>
          <Grid.Row>
            {/* <h3 style={{ marginLeft: '550px' }}> PRE-LOGIN ENQUIRY</h3> */}
            <Table attached="bottom">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Mobile Number </Table.HeaderCell>
                  <Table.HeaderCell>Email ID</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contactList &&
                  contactList.map(
                    (contact) =>
                      contact.sender === "PreLogin" &&
                      contact.receiver === "Admin" && (
                        <Table.Row key={contact.contactId}>
                          <Table.Cell>{contact.firstName}</Table.Cell>
                          <Table.Cell>{contact.lastName}</Table.Cell>
                          <Table.Cell>{contact.phone}</Table.Cell>
                          <Table.Cell>{contact.email}</Table.Cell>
                          <Table.Cell>{contact.message}</Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      )}
      {stepperIndex === 2 && (
        <Grid stackable padded>
          <Grid.Row>
            {/* <h3 style={{ marginLeft: '550px' }}> PARTNER QUERY</h3> */}
            <Table attached="bottom">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Org Id</Table.HeaderCell>
                  <Table.HeaderCell>Organisation</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contactList &&
                  contactList.map(
                    (contact) =>
                      contact.messageType === 2 &&
                      contact.receiver === "Admin" && (
                        <Table.Row key={contact.contactId}>
                          <Table.Cell>{contact.sender}</Table.Cell>
                          <Table.Cell>{contact.organisationName}</Table.Cell>
                          <Table.Cell>{contact.email}</Table.Cell>
                          <Table.Cell>{contact.message}</Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      )}
      {stepperIndex === 3 && (
        <Grid stackable padded>
          <Grid.Row>
            {/* <h3 style={{ marginLeft: '550px' }}> PARTNER QUERY</h3> */}
            <Table attached="bottom">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Org Id</Table.HeaderCell>
                  <Table.HeaderCell>Organisation</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contactList &&
                  contactList.map(
                    (contact) =>
                      contact.messageType === 3 &&
                      contact.receiver === "Admin" && (
                        <Table.Row key={contact.contactId}>
                          <Table.Cell>{contact.sender}</Table.Cell>
                          <Table.Cell>{contact.organisationName}</Table.Cell>
                          <Table.Cell>{contact.email}</Table.Cell>
                          <Table.Cell>{contact.message}</Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      )}
      {stepperIndex === 4 && (
        <Grid stackable padded>
          <Grid.Row>
            {/* <h3 style={{ marginLeft: '550px' }}> PARTNER QUERY</h3> */}
            <Table attached="bottom">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Org Id</Table.HeaderCell>
                  <Table.HeaderCell>Organisation</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contactList &&
                  contactList.map(
                    (contact) =>
                      contact.messageType === 4 &&
                      contact.receiver === "Admin" && (
                        <Table.Row key={contact.contactId}>
                          <Table.Cell>{contact.sender}</Table.Cell>
                          <Table.Cell>{contact.organisationName}</Table.Cell>
                          <Table.Cell>{contact.email}</Table.Cell>
                          <Table.Cell>{contact.message}</Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
}

export default observer(AdminInbox);
