import React, { useContext } from "react";
import { Modal, Header, Button, Grid, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

//import Logo from './../../../assets/images/Mission360.png'

const ModalContainer = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    modal: { open, title, body },
    closeModal,
  } = rootStore.modalStore;
  return (
    <Modal
      open={open}
      onClose={closeModal}
      size="tiny"
      // closeIcon
      style={{
        height: "auto",
        position: "relative",
        width: "400px",
        background: "#3F51B5",
      }}
    >
      <Grid>
        <Grid.Column width={12} style={{}}>
          <Header className="popup-header">{title}</Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Button
            style={{
              marginLeft: "32px",
              background: "transparent",
              paddingTop: "13px",
            }}
            onClick={() => {
              closeModal();
            }}
          >
            <Icon name="close" size="large">
              {" "}
            </Icon>
          </Button>
        </Grid.Column>
      </Grid>
      <Modal.Content>{body}</Modal.Content>
    </Modal>
  );
};

export default observer(ModalContainer);
