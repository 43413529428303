  import React, { useContext } from "react";
  import { RootStoreContext } from "../../../app/stores/rootStore";
  import { observer } from "mobx-react-lite";
  import { configure } from "mobx";
import BeneficiaryRegistration from "../../registration/OD/BeneficiaryRegistration";
  configure({
    enforceActions: "never",
  });
  
  
  const ViewRegistration = () => { 
   
    const rootStore = useContext(RootStoreContext);
    
    return (
 <div>
     <BeneficiaryRegistration  registrationId = {rootStore.commonStore.registrationId} />
 </div>
    );
  };
  export default observer(ViewRegistration);
  