import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { observer } from 'mobx-react-lite'; 
import { Container, Segment, Item, Grid, Button, Image, Form, Loader } from 'semantic-ui-react';
import barChart from "../../../../assets/images/bar-chart.jpg";
import clockChart from "../../../../assets/images/clock.png";
function ReportComplianceChart(props) {
 
  
  const [state] = React.useState({
    series: [props.dataapex],
    options: {
      chart: {
        id: "compliance",
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#737373",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "verticle",
          shadeIntensity: 0.15,
          inverseColors: true,
          gradientToColors: ["#FF6258", "#FFBC00"],
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 30, 70, 100],
          colorStops: [
            [
              {
                offset: 0,
                color: "#FF6258",
                opacity: 1,
              },
              {
                offset: 31,
                color: "#FFBC00",
                opacity: 70,
              },
              {
                offset: 100,
                color: "#77C579",
                opacity: 1,
              },
            ],
          ],
        },
      },
      labels: ["Average Results"],
    },
  });

  
  



  return (


    <div id="card">
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="radialBar" height={400}/>
      </div>

      <div id="html-dist"></div>
      {/* <button onClick  ={getURI}> GetURI</button> */}
    </div>
  );
} export default observer(ReportComplianceChart);