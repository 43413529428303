import React, { Fragment } from "react";
import { Segment } from "semantic-ui-react";
import HeaderThree from "../../../component/header/HeaderThree";

const ThankYouPageFed = () => {
  return (
    <Fragment>
      <HeaderThree
        homeLink="https://www.cimindia.in/"
        logo="symbol-dark"
        color="color-black"
      />{" "}
      <br />
      <div className="App-thankyou">
        <br />
        <br />
        <br />
        <br />
        <h1>
          {" "}
          <label
            style={{
              marginLeft: "550px",
              fontFamily: "verdana",
              fontSize: 20,
              color: "#0000CD",
            }}
          >
            {" "}
            Thank you for your Registration!{" "}
          </label>
        </h1>
        <br />
        <br />
        <h3>
          {" "}
          <label
            style={{
              marginLeft: "420px",
              fontFamily: "verdana",
              fontSize: 20,
              color: "#0000CD",
            }}
          >
            {" "}
            Admin will approve your Registration and get back to you
          </label>{" "}
        </h3>
        <br />
        <br />
        <h4>
          {" "}
          <label
            style={{
              marginLeft: "550px",
              fontFamily: "verdana",
              fontSize: 16,
              color: "#0000CD",
            }}
          >
            {" "}
            Please remember your Login Credentials
          </label>
        </h4>
      </div>
    </Fragment>
  );
};

export default ThankYouPageFed;
