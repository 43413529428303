import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext, Fragment } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, Form, Grid, Icon, Table } from "semantic-ui-react";
import { IPlanFormValues, IFeature } from "../../../app/models/plan";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { UserRole } from '../../../app/common/enum';
import SelectInputTab from "../../../app/common/form/SelectInputTab";
interface IProps {
  editPlan?: IPlanFormValues;
}
const entityType = [
  { text: "Single", value: "Single", key: 1 },
  { text: "Group", value: "Group", key: 2 },  
]

const planType = [
  { text: "Basic", value: "Basic", key: 1 },
  { text: "Advance", value: "Advance", key: 2 },  
]
const UserOrg = [
  { text:  UserRole.ODPartner, value: UserRole.ODPartner, key: 1 },
  { text:  UserRole.Federation, value:  UserRole.Federation, key: 2 },
  { text:  UserRole.DonorOrganisation, value:UserRole.DonorOrganisation, key: 3 },
  { text:  UserRole.DonorIndividual, value: UserRole.DonorIndividual, key: 4 },
]
const CreatePlan: React.FC<IProps> = ({ editPlan }) => {
  const [state] = React.useState({
    open: false,
    
  });
  const rootStore = useContext(RootStoreContext);
  const { upsertPlan, addFeature, Features, planList, selectedPlanDetail } = rootStore.planStore;
  console.log(planList)
  return (
    <Fragment>
      <FinalForm
        initialValues={selectedPlanDetail}
        onSubmit={(values: IPlanFormValues) =>
          upsertPlan(values).catch((error) => ({
            [FORM_ERROR]: error,
          }))
        }
        render={({
          handleSubmit,
          submitting,
          submitError,
          invalid,
          pristine,
          form,
          dirtySinceLastSubmit,
        }) => (
          <Fragment>

            <Form onSubmit={async event => {
              await handleSubmit(event);
              state.open = true;
              form.reset();
            }}
            >

              <Form.Field inline>                
                <Field name="organisationType" component= {SelectInputTab} inputstyle={{ marginLeft: '40px' }}
                 label ="Organisation Type" labelstyle =  {{ marginLeft: '30px' }}  placeholder='OrganisationType' 
                 options = {UserOrg}                
                >                 
                </Field>
                
              </Form.Field>
              <br />
              <Form.Field inline>               
                <Field
                  component= {SelectInputTab}
                  name="entityType"
                  label = "Entity Type"
                  labelstyle ={{ marginLeft: '30px' }}
                  inputstyle={{ marginLeft: "84px",width: '168px' }}
                  placeholder="Select Entity type"
                  options= {entityType}
                >                
                </Field>
              </Form.Field>
              <br />
              <Form.Field inline>   
                <Field labelstyle={{ marginLeft: '30px' }} label = "Plan Type" name="planType" component= {SelectInputTab}
                 inputstyle={{ marginLeft: '94px', width: '168px' }}
                  fluid placeholder='Plan Type' search selections={"true"} options = {planType}
                > </Field>
              </Form.Field>
              <br />
              <Form.Field inline>
                <label style={{ marginLeft: '30px' }} >Plan Amount  </label>
                <Field
                  component="input"
                  name="planAmount"
                  style={{ marginLeft: "78px",width :"195px" }}
                  placeholder="Enter Plan amount"
                />
              </Form.Field>
              <br />

              <FinalForm
                onSubmit={(featureValues: IFeature) => {
                  addFeature(featureValues);
                  state.open = false;
                }}
                render={({ handleSubmit: featureSubmit, form }) => (
                  <Form onSubmit={async event => {
                    await featureSubmit(event);
                    form.reset();
                  }
                  }>
                    <Form.Field inline>
                      <label style={{ marginLeft: '30px' }}>
                        Plan Feature {" "}
                      </label>

                      <Field
                        component="input"
                        name="feature"
                        style={{ marginLeft: '80px', width :"195px" }}
                        placeholder="Enter Plan Feature"
                      />
                      <Button style={{ marginLeft: "20px" }} size="tiny" color="blue" icon>
                        <Icon name="add" />
                      </Button>

                    </Form.Field>

                  </Form>
                )}
              />
              <Table style={{ width: "40%", marginLeft: "200px" }} >
                <Table.Row >
                  <Table.Header>
                    <Table.HeaderCell>Feature</Table.HeaderCell>
                  </Table.Header>
                </Table.Row>
                {
                  Features &&
                  Features.map((values) => (
                    <Table.Row style={{ width: "286px", marginLeft: "95px" }}>
                      <Table.Cell style={{ width: "286px", marginLeft: "95px" }}>
                        { state.open === false &&
                        values.feature
                         }
                      </Table.Cell>

                    </Table.Row>
                  )
                  )
                } 
              </Table>
 
              <br />
              <br />
              <Grid textAlign="center" >
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={submitting}
                  primary
                >
                  <Icon name="check" /> Submit{" "}
                </Button>
              </Grid>
            </Form>
          </Fragment>
        )}
      ></FinalForm>
    </Fragment>
  );
};
export default observer(CreatePlan);
