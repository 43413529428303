import { useContext } from "react";
import React from "react";
import { FORM_ERROR } from "final-form";
import { Form, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from 'react-final-form'
import { IAssessmentApproveOrReject } from "../../../../app/models/assessment";
import { RootStoreContext } from "../../../../app/stores/rootStore";

interface IProps {
    approveOrRejectAssessment: IAssessmentApproveOrReject,
}

const AssessmentDetailApproveOrReject: React.FC<IProps> = ({ approveOrRejectAssessment: approveOrRejectAssessmentProps }) => {

    const rootStore = useContext(RootStoreContext);
    const {assessmentApprove} = rootStore.assessmentStore;

    return (
        <FinalForm
            initialValues={approveOrRejectAssessmentProps}
            onSubmit={(values: IAssessmentApproveOrReject) => 
                assessmentApprove(values)
                    .catch(error => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                {/* <Header color={approveOrRejectAssessmentProps.IsApproved ? 'green' : 'red'} style={{ height: '6vh' }}> {approveOrRejectAssessmentProps.IsApproved ? 'Approve' : 'Reject'}!</Header> */}
                Kindly confirm assessment detail can be {approveOrRejectAssessmentProps.IsApproved ? 'Approved' : 'Rejected'} 
                <br/>
                <br/>
                <Button color='blue' style={{ marginLeft: '130px'}} loading ={submitting}>{approveOrRejectAssessmentProps.IsApproved ? 'Approve' : 'Reject'}</Button>
                </Form>
                 )}>
        </FinalForm>
    )
}

export default observer(AssessmentDetailApproveOrReject);